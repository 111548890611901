import React, { useContext, Fragment, useState } from 'react'
import * as styled from './MessageWindow.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { theme } from 'types/style.types'
import TypingDots from 'ui/components/shared/TypingDots'
import ScrollToBottom from 'react-scroll-to-bottom'
import { StoreContext, MessageSender, ChatTypingTypes } from 'models'
import { observer } from 'mobx-react'
import { format } from 'date-fns'
import Linkify from 'react-linkify'
import { toJS } from 'mobx'
import { mockedSortedMessages } from 'ui/components/ManagementView/TabPanels/Widget/Themes/ThemeMockData'
import { bannedMockedSortedMessages } from 'ui/components/ManagementView/TabPanels/Widget/BannedUsers/BannedUserMessageMockData'
import { PROVIDERS } from 'constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHammer } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import BanConfirmationModal from 'ui/components/Modals/BanConfirmationModal'

interface MessageWindowProps {
  typing?: ChatTypingTypes | null
  currentSenderTypes: MessageSender[]
  agent?: boolean
  theme?: any
  themePreview?: boolean
  endChat?: (id: string) => void
  chatId: string | null
  userDeviceBanned?: boolean
}

const Message = ({
  message,
  yourMessage,
  theme,
  userTypes,
  status,
}: {
  message: string
  yourMessage: boolean
  theme: theme
  userTypes: MessageSender[]
  status: boolean
}) => {
  const splitMessage = message.split('\n')
  return (
    <styled.MessageContainer theme={theme}>
      <styled.Message
        // @ts-ignore
        yourMessage={yourMessage}
        theme={theme}
        user={userTypes.includes(MessageSender.USER)}
        status={status}
      >
        {splitMessage.map((paragraph, i) => {
          return (
            <Fragment key={i}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'rgba(45,125,155,1.0)' }}
                    href={decoratedHref}
                    key={key}
                  >
                    {PROVIDERS.some((provider) =>
                      decoratedHref.includes(provider),
                    )
                      ? 'Image Preview'
                      : decoratedHref}
                  </a>
                )}
              >
                {paragraph}
              </Linkify>
              {!(splitMessage.length - 1 === i) && <p></p>}
            </Fragment>
          )
        })}
      </styled.Message>
    </styled.MessageContainer>
  )
}

const Typing = ({
  typingSenderType,
  typingSenderAlias,
  yourMessage,
  theme,
  userTypes,
  status,
}: {
  typingSenderType?: string | null
  typingSenderAlias?: string | null
  yourMessage: boolean
  theme: theme
  userTypes: MessageSender[]
  status: boolean
}) => {
  return (
    <styled.MessageContainer>
      <styled.Message
        // @ts-ignore
        typing={typingSenderType}
        yourMessage={false}
        theme={theme}
        user={userTypes.includes(MessageSender.USER)}
        status={status}
      >
        <TypingDots
          widthAndHeight="4px"
          color={theme.textOnWHiteColor.color!}
          margin="5px"
        />
      </styled.Message>
    </styled.MessageContainer>
  )
}

const determineUserLabel = (
  yourMessage: boolean,
  senderType?: string | null,
  senderAlias?: string | null,
) => {
  if (senderAlias) {
    return senderAlias
  }

  if (
    senderType === MessageSender.SYSTEMUSER ||
    senderType === MessageSender.SYSTEMAGENT ||
    senderType === MessageSender.SYSTEM
  ) {
    return 'System'
  }

  if (yourMessage && senderType === MessageSender.USER) {
    return 'You'
  }

  if (!yourMessage && senderType === MessageSender.USER) {
    return 'User'
  }

  return ''
}

const MessageWindow = observer(
  ({
    typing,
    currentSenderTypes,
    agent,
    theme,
    themePreview,
    endChat,
    chatId,
    userDeviceBanned,
  }: MessageWindowProps) => {
    const [visibility, setVisibiility] = useState(false)
    const [banned, setBanned] = useState(false)
    const store = useContext(StoreContext)
    let themeFromContext: theme = themeObject[useContext(ThemeContext).name]

    const ReOpenChat = () => {
      store.mutateReOpenChat({ chatId: chatId! })
    }

    // TODO Remove this
    let sortedMessages: any = []
    if (themePreview || theme || userDeviceBanned) {
      if (theme) {
        themeFromContext = JSON.parse(theme!.attributes)
      }
      if (userDeviceBanned) {
        sortedMessages = bannedMockedSortedMessages
      } else if (themePreview) {
        sortedMessages = mockedSortedMessages
      } else if (store.selectedChat) {
        sortedMessages = store.selectedChat!.sortedMessages
      }
    } else if (store.selectedChat) {
      sortedMessages = store.selectedChat?.sortedMessages
    }

    const banUser = () => {
      store.mutateBanUser({ input: { chatId: chatId! } })
      setVisibiility(false)
      // setBanned(true)
    }

    return (
      <styled.Container>
        <ScrollToBottom>
          <styled.InnerMessageContainer>
            {agent && (
              <styled.WidgetTitleContainer>
                <styled.WidgetTitle>
                  Chat on Widget: {store?.selectedChat?.widgetName}
                </styled.WidgetTitle>
              </styled.WidgetTitleContainer>
            )}
            {sortedMessages.map((message: any) => {
              let yourMessage = false
              if (
                (currentSenderTypes as any).includes(
                  MessageSender[message.senderType! as MessageSender],
                )
              ) {
                yourMessage = true
              }
              return (
                <Fragment key={message.id}>
                  <Message
                    key={`message_${message.id}`}
                    message={message.body || ''}
                    yourMessage={yourMessage}
                    theme={themeFromContext}
                    userTypes={currentSenderTypes}
                    status={store.onlineStatus}
                  />
                  <styled.AliasAndTimestamp
                    // @ts-ignore
                    yourMessage={yourMessage}
                    theme={themeFromContext}
                  >
                    {determineUserLabel(
                      yourMessage,
                      message.senderType,
                      message.senderAlias,
                    )}{' '}
                    {message.timestamp && <>on </>}
                    {message.timestamp ? (
                      format(new Date(message.timestamp), 'M/d/yyyy h:mm a')
                    ) : (
                      <>
                        {userDeviceBanned ? (
                          format(Date.now(), 'M/d/yyyy h:mm a')
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </styled.AliasAndTimestamp>
                </Fragment>
              )
            })}
            {typing &&
              typing.sender &&
              !currentSenderTypes.includes(typing.sender) && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typing
                    key={`user-typing`}
                    typingSenderType={typing && typing.sender!}
                    typingSenderAlias={typing && typing.alias!}
                    yourMessage={false}
                    theme={themeFromContext}
                    userTypes={currentSenderTypes}
                    status={store.onlineStatus}
                  />
                  <styled.AliasAndTimestamp
                    // @ts-ignore
                    yourMessage={false}
                    theme={themeFromContext}
                  >
                    {typing && typing.alias} is writing...
                  </styled.AliasAndTimestamp>
                </div>
              )}

            {!themePreview &&
              (store?.selectedChat?.timedOut ||
                store?.selectedChat?.completed) && (
                <styled.ChatEndMessage>
                  {store?.selectedChat?.timedOut ? (
                    <>
                      Chat Timed Out{' '}
                      {store!.selectedChat!.completedTime
                        ? format(
                            new Date(store!.selectedChat!.completedTime),
                            'M/d/yyyy h:mm a',
                          )
                        : ''}
                    </>
                  ) : (
                    store?.selectedChat?.completed && (
                      <>
                        Chat Completed{' '}
                        {store?.selectedChat?.completedTime
                          ? format(
                              new Date(store?.selectedChat?.completedTime),
                              'M/d/yyyy h:mm a',
                            )
                          : ''}{' '}
                      </>
                    )
                  )}
                </styled.ChatEndMessage>
              )}
            {!themePreview &&
              agent &&
              (store?.selectedChat?.timedOut ||
                store?.selectedChat?.completed) && (
                <styled.PostChatButtonContainer>
                  {store?.selectedChat?.sourcePhoneNumber && (
                    <Button
                      onClick={ReOpenChat}
                      disabled={!store.onlineStatus}
                      style={{
                        color: 'white',
                        backgroundColor: !store.onlineStatus
                          ? 'rgba(249, 33, 70, 1)'
                          : 'rgba(51, 125, 2, 1)',
                        textTransform: 'uppercase',
                        marginRight: 10,
                        marginTop: 8,
                      }}
                    >
                      Reopen Chat
                      <FontAwesomeIcon
                        size="1x"
                        icon={faArrowCircleUp}
                        style={{ marginLeft: 5 }}
                      />
                    </Button>
                  )}
                  <Button
                    disabled={banned}
                    onClick={() => setVisibiility(true)}
                    style={{
                      color: 'white',
                      backgroundColor: !banned
                        ? 'rgba(0, 0, 0, 0.85)'
                        : 'rgba(0, 0, 0, 0.25)',
                      textTransform: 'uppercase',
                      marginRight: 10,
                      marginTop: 8,
                    }}
                  >
                    {banned ? 'Banned' : 'Ban'}
                    <FontAwesomeIcon
                      size="1x"
                      icon={faHammer}
                      style={{ marginLeft: 5 }}
                    />
                  </Button>
                </styled.PostChatButtonContainer>
              )}
          </styled.InnerMessageContainer>
        </ScrollToBottom>
        <BanConfirmationModal
          openFunction={setVisibiility}
          visibility={visibility}
          banUser={banUser}
        />
      </styled.Container>
    )
  },
)

export default MessageWindow
