import React from 'react'
import { Bar } from '@nivo/bar'
import { toJS } from 'mobx'
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'

interface DataPoint {
  key: number
  value: number
}

interface ResponseTimeHistogramProps {
  data: DataPoint[]
}

const ResponseTimeHistogram = ({ data }: ResponseTimeHistogramProps) => {
  const keyMap = [
    { key: 5, keyTitle: '< 5 secs' },
    { key: 10, keyTitle: '< 10 secs' },
    { key: 15, keyTitle: '< 15 secs' },
    { key: 20, keyTitle: '< 20 secs' },
    { key: 30, keyTitle: '< 30 secs' },
    { key: 45, keyTitle: '< 45 secs' },
    { key: 60, keyTitle: '< 60 secs' },
    { key: 90, keyTitle: '< 90 secs' },
    { key: 120, keyTitle: '< 2 mins' },
    { key: 604800, keyTitle: '> 2 mins' },
  ]

  const massagedData = data.map((dataPoint: { key: number; value: number }) => {
    return {
      key: keyMap.find((keyFromMap) => dataPoint.key === keyFromMap.key)!
        .keyTitle,
      value: dataPoint.value,
    }
  })

  const reducedKeys = keyMap.reduce((acc: string[], item) => {
    const newAcc = acc
    newAcc.push(item.keyTitle)
    acc = newAcc
    return acc
  }, [])

  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <Bar
          height={height * 0.9}
          width={width}
          data={massagedData}
          indexBy="key"
          margin={{ bottom: 30, left: 38, top: 6 }}
          colors={['#f5f5f5']}
          axisBottom={{
            tickRotation: 0,
          }}
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#cce7e9',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[{ match: '*', id: 'lines' }]}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Chats',
            legendPosition: 'middle',
            legendOffset: -32,
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      )}
    </AutoSizer>
  )
}

export default ResponseTimeHistogram
