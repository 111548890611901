import styled from 'styled-components'
import { theme } from 'types/style.types'
import * as layout from 'ui/components/shared/StyledContainers/layout'

interface ThemeProps {
  theme?: theme
  backgroundColor?: string
}

export const HubPageContainer = styled(layout.PageContainer)`
  overflow: hidden;
  position: relative;
`

export const HeaderContainer = styled.div`
  flex-grow: 0;
  grid-column: 1 / span 2;
  height: 85px;
  background-color: ${(props: ThemeProps) =>
    props.theme!.primaryMidlight.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LogoContainer = styled.div`
  color: white;
  font-weight: bold;
  font-family: 'Roboto';
  font-size: 28px;
  margin-left: 130px;
  margin-top: 14px;
  text-align: left;
`

export const DashboardBodyContainer = styled.div`
  width: 100vw;
  flex-grow: 1;
`

export const ChatNavigationContainer = styled.div`
  padding-top: 15px;
  background-color: ${(props: ThemeProps) =>
    props.theme!.primaryLowlight.color};
`

export const ChatContainer = styled.div`
  flex-grow: 1;
  background-color: ${(props: ThemeProps) => props.backgroundColor};
`

export const ChatDetails = styled.div`
  width: 230px;
  background-color: rgba(209, 217, 208, 1);
`

export const StatusAndMenuContainer = styled.div`
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
`

export const LoggedOutModal = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

export const LogOutModalInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogOutModalMessage = styled.div`
  margin-bottom: 30px;
`

export const LogOutModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const NewPageContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 300px auto;
  grid-template-rows: 85px auto;
`
