import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { observer } from 'mobx-react'
import { TransitionProps } from '@material-ui/core/transitions'
import SoundSelection from 'ui/components/ManagementView/TabPanels/Widget/GeneralSettings/SoundSelect'
import * as styled from './index.styled'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { StoreContext } from 'models/reactUtils'
import { ShortcutPreference } from 'models'
import { $enum as enumToArray } from 'ts-enum-util'
import { toJS } from 'mobx'
import { SHORTCUTMETHODMAP } from 'constants/constants'

interface HubSettingsProps {
  visibility: boolean
  openFunction: (visibility: boolean) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const soundsNote = `This sound is what is played when you recieve a message.  This sound will be defaulted across all widgets you have access to.  If you clear your local cache you will have to set this again.`
const shortcutNote = `You may specify whether or not you want to select shortcuts via an additional toolbar or by a keyboard command.`

const HubSettings = observer(
  ({ visibility, openFunction }: HubSettingsProps) => {
    const closeModal = (event: React.ChangeEvent<{}>) => {
      openFunction(false)
    }

    const store = useContext(StoreContext)

    const saveShortcutPreference = (shortcutPreference: ShortcutPreference) => {
      store.mutateUpdateUserShortcutPreference(
        {
          input: {
            userId: store.retrievedUser?.id!,
            shortcutPreference,
          },
        },
        (data) => data.shortcutPreference,
      )
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        fullWidth
        TransitionComponent={Transition}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Agent Hub Settings</DialogTitle>
        <DialogContent>
          <SoundSelection
            widgetId={null}
            selectedSound={store.retrievedUser?.sound?.id}
            userId={store.retrievedUser?.id}
          />
          <styled.FormNote>{soundsNote}</styled.FormNote>
          <FormControl
            style={{ display: 'flex', flexGrow: 1, marginBottom: '2vh' }}
            variant="filled"
          >
            <InputLabel id="agentNotificationSoundLabel">
              Select Your Preferred Shortcut Selection Method
            </InputLabel>
            <Select
              labelId="agentShortcutMethodLabel"
              id="agentShortcutMethod"
              value={store.retrievedUser?.shortcutPreference}
              onChange={(event) =>
                saveShortcutPreference(event.target.value as ShortcutPreference)
              }
            >
              {enumToArray(ShortcutPreference).map((shortcutMethod) => {
                return (
                  <MenuItem value={shortcutMethod}>
                    {SHORTCUTMETHODMAP[shortcutMethod]}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <styled.FormNote>{shortcutNote}</styled.FormNote>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default HubSettings
