import React, { useState } from 'react'
import { faCogs, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import * as styled from './index.styled'
import { WidgetModelType } from 'models'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import DeleteWidgetModal from 'ui/components/Modals/DeleteWidgetModal'

interface DeleteWidgetIconProps {
  name: string
  id: string
  setDeleteWidgetDetails: (widgetDetails: { id: string; name: string }) => void
  setVisibility: (visibility: boolean) => void
}

const DeleteWidgetIcon = ({
  name,
  id,
  setDeleteWidgetDetails,
  setVisibility,
}: DeleteWidgetIconProps) => {
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setDeleteWidgetDetails({ id, name })
    setVisibility(true)
  }

  return (
    <styled.DeleteIconContainer onClick={handleClick}>
      <FontAwesomeIcon
        onClick={() => setVisibility(true)}
        style={{ color: 'e43845' }}
        size="xs"
        icon={faTrash}
      />
    </styled.DeleteIconContainer>
  )
}

interface WidgetProps {
  slug?: string | null
  name?: string | null
  id?: string | null
  setDeleteWidgetDetails: (orgDetails: { id: string; name: string }) => void
  setVisibiility: (visibility: boolean) => void
}

interface WidgetsProps {
  widgets: WidgetModelType[]
}

const Widget = ({
  slug,
  name,
  id,
  setDeleteWidgetDetails,
  setVisibiility,
}: WidgetProps) => {
  const { orgSlug } = useParams<Record<string, string | undefined>>()
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(true)

  return (
    <Link to={`/org/${orgSlug!}/widget/${slug}`}>
      <div
        onMouseEnter={() => setDeleteIconVisibility(false)}
        onMouseLeave={() => setDeleteIconVisibility(true)}
      >
        <styled.Organization>
          <div hidden={deleteIconVisibility}>
            <DeleteWidgetIcon
              id={id!}
              name={name || ''}
              setDeleteWidgetDetails={setDeleteWidgetDetails}
              setVisibility={setVisibiility}
            />
          </div>
          <styled.Icon>
            <FontAwesomeIcon size="2x" icon={faCogs} />
          </styled.Icon>
          <styled.Name>{name}</styled.Name>
        </styled.Organization>
      </div>
    </Link>
  )
}

const Widgets = observer(({ widgets }: WidgetsProps) => {
  const [visibility, setVisibiility] = useState(false)
  const [deleteWidgetDetails, setDeleteWidgetDetails] = useState({
    id: '',
    name: '',
  })

  return (
    <styled.Container>
      {orderBy(
        Array.from(widgets.values()).filter((widget) => !widget.deleted),
        'name',
        'asc',
      ).map((widget) => {
        return (
          <Widget
            id={widget.id}
            slug={widget.slug}
            name={widget.name}
            key={`widget_icon_${widget.slug}`}
            setDeleteWidgetDetails={setDeleteWidgetDetails}
            setVisibiility={setVisibiility}
          />
        )
      })}
      <DeleteWidgetModal
        openFunction={setVisibiility}
        visibility={visibility}
        widgetDetails={deleteWidgetDetails}
      />
    </styled.Container>
  )
})

export default Widgets
