/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ShortcutPreferenceEnumType } from "./ShortcutPreferenceEnum"
import { SoundModel, SoundModelType } from "./SoundModel"
import { SoundModelSelector } from "./SoundModel.base"
import { RootStoreType } from "./index"


/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    email: types.union(types.undefined, types.string),
    firstName: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    lastName: types.union(types.undefined, types.null, types.string),
    shortcutPreference: types.union(types.undefined, ShortcutPreferenceEnumType),
    sound: types.union(types.undefined, types.null, types.late((): any => SoundModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserModelSelector extends QueryBuilder {
  get email() { return this.__attr(`email`) }
  get firstName() { return this.__attr(`firstName`) }
  get id() { return this.__attr(`id`) }
  get lastName() { return this.__attr(`lastName`) }
  get shortcutPreference() { return this.__attr(`shortcutPreference`) }
  sound(builder?: string | SoundModelSelector | ((selector: SoundModelSelector) => SoundModelSelector)) { return this.__child(`sound`, SoundModelSelector, builder) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().email.firstName.lastName.shortcutPreference
