import styled from 'styled-components'

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(227, 245, 245, 1);
  margin-left: 18px;
  margin-right: 18px;
  height: 45px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 14px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    font-size: 14px;
  }

  div:first-child {
    width: 22px;
    margin-left: 10px;
    text-indent: 4px;
    margin-right: 5px;
  }
`
