import React, { useState } from 'react'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrganizationModal from 'ui/components/Modals/OrganizationModal'
import * as styled from './CreateOrganizationButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'

const CreateOrganizationButton = () => {
  const [orgnizationModalVisibility, setOrganizationModalVisibility] = useState(
    false,
  )

  return (
    <styled.MenuItemContainer onClick={() => setOrganizationModalVisibility(true)}>
      <div>
        <FontAwesomeIcon icon={faPlusSquare} />
      </div>
      <div>Create Organization</div>
      <OrganizationModal
        visibility={orgnizationModalVisibility}
        openFunction={setOrganizationModalVisibility}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageOrganization'], CreateOrganizationButton)