import { Instance, types } from 'mobx-state-tree'
import { MessageModelBase } from './MessageModel.base'
import { MessageSender } from 'models/MessageSenderEnum'
import { notificationSoundandBadgeRelay } from 'utilities/notifications'
import { toJS } from 'mobx'
import retry from 'retry'

/* The TypeScript type of an instance of MessageModel */
export interface MessageModelType extends Instance<typeof MessageModel.Type> {}

/* A graphql query fragment builders for MessageModel */
export {
  selectFromMessage,
  messageModelPrimitives,
  MessageModelSelector,
} from './MessageModel.base'

/**
 * MessageModel
 */
export const MessageModel = MessageModelBase.props({
  seen: types.optional(types.boolean, false),
  soundPlayed: types.optional(types.boolean, false),
}).actions((self) => ({
  checkForOrginationWidget(
    callback: (error: Error | null, response: boolean) => void,
  ) {
    const operation = retry.operation({
      minTimeout: 100,
      maxTimeout: 100,
      retries: 10,
      factor: 1.5,
    })
    let error: Error | null = null
    operation.attempt((currentAttempt) => {
      if (!self.chat.originationWidget) {
        error = new Error('orgination widget is not defined')
        operation.retry(error)
        return
      }

      callback(error ? operation.mainError() : null, true)
    })
  },
  postAttachmentMessageTasks() {
    if (
      !self.chat.completed &&
      new Date(self.timestamp) > new Date(self.store.agentOnlineTime) &&
      (self.senderType === MessageSender.USER ||
        self.senderType === MessageSender.SYSTEMAGENT)
    ) {
      const selectedChatId = self.store.selectedChat
        ? self.store.selectedChat.id
        : null

      const widgetInstance = self.store.widgets.get(
        self.chat.originationWidget.id,
      )

      const audioPromise = notificationSoundandBadgeRelay(
        self.chat.id,
        selectedChatId,
        self.store.windowFocus,
        self.body,
        self.store.browserNotifications.permission,
        self.store.agentSoundOverride || widgetInstance!.htmlAudioElement,
      )
      if (audioPromise !== undefined) {
        audioPromise.catch((error) => {
          // Autoplay was prevented.
          self.store.setAudioPlayingAllowed(false)
        })
      }

      self.store.setLastPlayedTime()
      self.store.setupNotificationInterval()
      if (
        self.store.selectedChat &&
        self.chat.id !== self.store.selectedChat.id
      )
        self.chat.setUnreadAndBolder(true)
    }
  },
  afterAttach() {
    // add a completed chat check so historical chats are not included in

    console.log(self.chat?.originationWidget?.id)

    this.checkForOrginationWidget((error, response) => {
      if (error) {
        console.error(`After 10 tries there is still no origination widget`)
      }

      this.postAttachmentMessageTasks()
    })

    self.chat.typing = null
  },
}))
