import React from 'react'

export interface ActionContextType {
  chatVisibility: boolean
  changeChatVisibility: ((visibility: boolean) => void) | null
  role: string
  organizationModalVisibility: boolean
  changeOrganizationModalVisibility: ((visibility: boolean) => void) | null
  widgetModalVisibility: boolean
  changeWidgetModalVisibility: ((visibility: boolean) => void) | null
  agentModalVisibilityFunction: ((visibility: boolean) => void) | null
}

const ActionContext = React.createContext<ActionContextType>({
  chatVisibility: false,
  changeChatVisibility: null,
  role: 'user',
  organizationModalVisibility: false,
  changeOrganizationModalVisibility: null,
  widgetModalVisibility: false,
  changeWidgetModalVisibility: null,
  agentModalVisibilityFunction: null,
})

export default ActionContext

export const modifyActionContext = (
  context: ActionContextType,
  attribute: string,
  value: any,
): ActionContextType => {
  const newContext: ActionContextType = context
  // @ts-ignore
  newContext[attribute] = value
  return newContext
}
