/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgentModel, AgentModelType } from "./AgentModel"
import { AgentModelSelector } from "./AgentModel.base"
import { RouteTagModel, RouteTagModelType } from "./RouteTagModel"
import { RouteTagModelSelector } from "./RouteTagModel.base"
import { RootStoreType } from "./index"


/**
 * AgentRouteTagBase
 * auto generated base class for the model AgentRouteTagModel.
 */
export const AgentRouteTagModelBase = ModelBase
  .named('AgentRouteTag')
  .props({
    __typename: types.optional(types.literal("AgentRouteTag"), "AgentRouteTag"),
    agent: types.union(types.undefined, types.null, types.late((): any => AgentModel)),
    agentId: types.union(types.undefined, types.integer),
    routeTag: types.union(types.undefined, types.null, types.late((): any => RouteTagModel)),
    routeTagId: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AgentRouteTagModelSelector extends QueryBuilder {
  get agentId() { return this.__attr(`agentId`) }
  get routeTagId() { return this.__attr(`routeTagId`) }
  agent(builder?: string | AgentModelSelector | ((selector: AgentModelSelector) => AgentModelSelector)) { return this.__child(`agent`, AgentModelSelector, builder) }
  routeTag(builder?: string | RouteTagModelSelector | ((selector: RouteTagModelSelector) => RouteTagModelSelector)) { return this.__child(`routeTag`, RouteTagModelSelector, builder) }
}
export function selectFromAgentRouteTag() {
  return new AgentRouteTagModelSelector()
}

export const agentRouteTagModelPrimitives = selectFromAgentRouteTag().agentId.routeTagId
