/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatModel, ChatModelType } from "./ChatModel"
import { ChatModelSelector } from "./ChatModel.base"
import { PostChatTagModel, PostChatTagModelType } from "./PostChatTagModel"
import { PostChatTagModelSelector } from "./PostChatTagModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  chat: ChatModelType;
}

/**
 * ChatPostChatTagBase
 * auto generated base class for the model ChatPostChatTagModel.
 */
export const ChatPostChatTagModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ChatPostChatTag')
  .props({
    __typename: types.optional(types.literal("ChatPostChatTag"), "ChatPostChatTag"),
    chat: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ChatModel))),
    chatId: types.identifier,
    postChatTag: types.union(types.undefined, types.null, types.late((): any => PostChatTagModel)),
    postChatTagId: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ChatPostChatTagModelSelector extends QueryBuilder {
  get chatId() { return this.__attr(`chatId`) }
  get postChatTagId() { return this.__attr(`postChatTagId`) }
  chat(builder?: string | ChatModelSelector | ((selector: ChatModelSelector) => ChatModelSelector)) { return this.__child(`chat`, ChatModelSelector, builder) }
  postChatTag(builder?: string | PostChatTagModelSelector | ((selector: PostChatTagModelSelector) => PostChatTagModelSelector)) { return this.__child(`postChatTag`, PostChatTagModelSelector, builder) }
}
export function selectFromChatPostChatTag() {
  return new ChatPostChatTagModelSelector()
}

export const chatPostChatTagModelPrimitives = selectFromChatPostChatTag().chatId.postChatTagId
