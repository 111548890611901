import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ManagementView from 'ui/pages/ManagementView'
import LoggedOut from 'ui/pages/LoggedOut'
import Hub from 'ui/pages/Hub'
// import UserAndThemePreview from 'ui/pages/UserAndThemePreview'
// import UserEntryPoint from 'ui/pages/UserEntryPoint'
import ChatLog from 'ui/pages/ChatLog'
import OrganizationWidgetAndChatLogView from 'ui/pages/OrganizationWidgetAndChatLogView'
import WidgetView from 'ui/pages/WidgetView'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import Loading from 'ui/components/shared/Loading'

const ProtectedRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={ManagementView} />
      <Route exact path="/logged-out" component={LoggedOut} />
      <Route exact path="/hub" component={Hub} />
      <Route exact path="/chat/:chatId" component={ChatLog} />
      <Route
        exact
        path="/org/:orgSlug"
        component={OrganizationWidgetAndChatLogView}
      />
      <Route
        exact
        path="/org/:orgSlug/widget/:widgetSlug"
        component={WidgetView}
      />
    </Switch>
  )
}

const UnProtectedRoutes = () => {
  return (
    <Switch>
      <Route exact path="/logged-out" component={LoggedOut} />
      <Route
        component={() => {
          window.location.replace('/login')
          return null
        }}
      />
    </Switch>
  )
}

const App = observer(() => {
  const { loading, error } = useQuery((store) => store.getUser())

  if (!loading) {
    if (error) {
      return (
        <Router>
          <UnProtectedRoutes />
        </Router>
      )
    } else {
      return (
        <Router>
          <ProtectedRoutes />
        </Router>
      )
    }
  }

  return <Loading />
})

export default App
