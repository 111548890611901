import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;

  a {
    text-decoration: none;
    color: black;
  }
`

export const Organization = styled.div`
  height: 90px;
  width: 90px;
  padding: 10px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  cursor: pointer;
  background-color: rgba(231, 239, 230, 1);
  position: relative;
`

export const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  font-size: 14px;
  flex-grow: 0;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`

export const DeleteIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px rgba(0, 0, 0, 0.08) solid;
  right: -10px;
  top: -10px;
  z-index: 100;
  border-radius: 50%;
  background-color: rgba(231, 239, 230, 1);
`
