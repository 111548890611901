import React from 'react'

const LogoSvg = ({
  scale,
  stopColorTop,
  stopColorBottom,
  dropShadow,
  width,
  height,
  strokeWidth,
  keyText,
}: {
  scale: string
  stopColorTop: string
  stopColorBottom: string
  dropShadow: boolean
  width?: number
  height?: number
  strokeWidth: number
  keyText?: string
}) => {
  return (
    <svg id={keyText} width={`${width}px`} height={`${height}px`}>
      <g transform={`scale(${scale})`}>
        <defs>
          <linearGradient
            id={`grad_${keyText}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              style={{
                stopColor: `${stopColorTop}`,
                stopOpacity: 1,
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: `${stopColorBottom}`,
                stopOpacity: 1,
              }}
            />
          </linearGradient>
          <filter
            id="dropShadow"
            x="-100%"
            y="-100%"
            width="300%"
            height="300%"
          >
            <feOffset result="offOut" in="SourceAlpha" dx="2" dy="4" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="25" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.2" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <path
          fill={`url(#grad_${keyText})`}
          filter={dropShadow ? 'url(#dropShadow)' : ''}
          stroke="white"
          strokeWidth={`${strokeWidth}`}
          stroke-alignment="outer"
          d="M160.4,315.737q-2.317-16.493-4.633-32.985c-0.438-3.134-.709-6.3-1.288-9.405-1.254-6.74-5.04-9.814-12.294-9.832q-32.79-.081-65.582-0.01c-11.848.009-15.6-3.667-15.6-15.483q0-74.566.028-149.133C61.038,89.113,65.278,85,75.1,85q75.842,0.02,151.682.009C236.62,85.014,240,88.37,240,98.1q0,76.819,0,153.637c0,7.681-3.887,11.666-11.548,11.738-7.507.071-15.027,0.306-22.52-.017-6.1-.264-9.907,2.588-13.143,7.334-10.041,14.726-20.262,29.33-30.412,43.982-0.28.4-.549,0.814-0.824,1.221Z"
        />
      </g>
    </svg>
  )
}

export default LogoSvg
