import styled from 'styled-components'

interface ConversationContainerProps {
  unread?: boolean
}

export const ConversationContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 80px;
  font-weight: ${(props: ConversationContainerProps) =>
    props.unread ? '900' : 'normal'};
`

interface ConversationProps {
  selected: boolean
}

export const Conversation = styled.div`
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: ${(props: ConversationProps) =>
    props.selected ? 'rgba(255, 255, 255, 1)' : 'rgba(92, 92, 92, 1)'};
  background-color: ${(props: ConversationProps) =>
    props.selected ? 'rgba(30, 30, 30, 1)' : 'rgba(227, 245, 245, 1)'};
  height: 80px;
  flex-grow: 1;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ConversationHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 3px;
  height: 16px;
  width: 94%;
`

export const ConversationPreview = styled.div`
  width: 94%;
  min-width: 0;
  margin-top: 2px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  flex-grow: 1;
`

export const ChatNote = styled.div`
  max-width: 265px;
  font-family: 'Roboto';
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`

interface ConversationActionProps {
  selected: boolean
}

export const ConversationActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  flex-grow: 1;
  height: 20px;
  z-index: 1;
  right: 4px;
  top: 4px;
  color: ${(props: ConversationActionProps) =>
    props.selected ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.3);'};

  &:hover {
    color: ${(props: ConversationActionProps) =>
      props.selected ? 'rgba(255, 255, 255, 1.0)' : 'rgba(0, 0, 0, 0.6);'};
  }
`

export const ConversationAndTime = styled.div`
  display: flex;
  flex-direction: row;
  width: 94%;
  align-items: flex-start;
  justify-content: space-between;
`

export const TimeAgoContainer = styled.div`
  flex-grow: 0;
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
`

export const ActiveAgent = styled.div`
  width: 94%;
  margin-top: 3px;
  font-weight: normal;
  font-size: 10px;
`

export const WidgetName = styled.div`
  margin-left: 4px;
  margin-top: -2px;
  font-size: 10px;
  font-weight: normal;
`
