import { useContext } from 'react'
import { StoreContext } from 'models/reactUtils'
import { toJS } from 'mobx'

export const usePermission = (requiredPermissions: any) => {
  const store = useContext(StoreContext)
  const permissions = store.permissions

  return (
    permissions &&
    permissions.some((permission) => requiredPermissions.includes(permission))
  )
}
