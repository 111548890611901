/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * OrganizationStatsDayBreakdownBase
 * auto generated base class for the model OrganizationStatsDayBreakdownModel.
 */
export const OrganizationStatsDayBreakdownModelBase = ModelBase
  .named('OrganizationStatsDayBreakdown')
  .props({
    __typename: types.optional(types.literal("OrganizationStatsDayBreakdown"), "OrganizationStatsDayBreakdown"),
    date: types.union(types.undefined, types.null, types.string),
    handledChats: types.union(types.undefined, types.integer),
    offlineChats: types.union(types.undefined, types.integer),
    organizationId: types.union(types.undefined, types.null, types.string),
    organizationName: types.union(types.undefined, types.null, types.string),
    totalChats: types.union(types.undefined, types.integer),
    unansweredChats: types.union(types.undefined, types.integer),
    widgetId: types.union(types.undefined, types.null, types.string),
    widgetName: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrganizationStatsDayBreakdownModelSelector extends QueryBuilder {
  get date() { return this.__attr(`date`) }
  get handledChats() { return this.__attr(`handledChats`) }
  get offlineChats() { return this.__attr(`offlineChats`) }
  get organizationId() { return this.__attr(`organizationId`) }
  get organizationName() { return this.__attr(`organizationName`) }
  get totalChats() { return this.__attr(`totalChats`) }
  get unansweredChats() { return this.__attr(`unansweredChats`) }
  get widgetId() { return this.__attr(`widgetId`) }
  get widgetName() { return this.__attr(`widgetName`) }
}
export function selectFromOrganizationStatsDayBreakdown() {
  return new OrganizationStatsDayBreakdownModelSelector()
}

export const organizationStatsDayBreakdownModelPrimitives = selectFromOrganizationStatsDayBreakdown().date.handledChats.offlineChats.organizationId.organizationName.totalChats.unansweredChats.widgetId.widgetName
