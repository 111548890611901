import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

const OrganizationModal = observer(
  ({
    visibility,
    openFunction,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
  }) => {
    const [name, setOrgName] = React.useState('')
    const { data, setQuery, store } = useQuery()

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    if (data) {
      return <Redirect to={`/org/${(data as any).addOrganization.slug}`} />
    }
    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Create a New Organization
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new organization please fill out the form below and
            click submit.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Organization Name"
            type="text"
            fullWidth
            onChange={(event) => setOrgName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              setQuery(
                store.mutateAddOrganization(
                  { input: { name } },
                  (data) => data.id.name.slug,
                ),
              )
            }
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default OrganizationModal
