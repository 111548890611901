import { createMuiTheme } from '@material-ui/core/styles'

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: `rgba(30, 30, 30, 1)`,
    },
    secondary: {
      main: `rgba(195, 231, 231, 1)`,
    },
  },
})

export const onlineAndOfflineSwitchTheme = createMuiTheme({
  palette: {
    primary: {
      main: `rgba(0, 250, 0, 1)`,
    },
    secondary: {
      main: `rgba(30, 30, 30, 1)`,
    },
  },
})

export const circleProgressTheme = createMuiTheme({
  palette: {
    primary: {
      main: `rgba(236, 50, 48, 1)`,
    },
    secondary: {
      main: `rgba(30, 30, 30, 1)`,
    },
  },
})

export const resetUserPasswordAppBar = createMuiTheme({
  palette: {
    primary: {
      main: `rgba(45, 125, 155, 1)`,
    },
    secondary: {
      main: `rgba(255, 255, 255, 1)`,
    },
  },
})

export const transferTabBar = createMuiTheme({
  palette: {
    primary: {
      main: `rgba(255, 255, 255, 0.75)`,
    },
    secondary: {
      main: `rgba(255, 255, 255, 1)`,
    },
  },
})
