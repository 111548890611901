import styled from 'styled-components'

export const Form = styled.div`
  width: 95%;
  height: 100px;
`

export const CreatePreChatQuestionnnaireText = styled.div`
  margin-bottom: 30px;
`
