import React, { useState } from 'react'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeUserPasswordModal from 'ui/components/Modals/ChangeUserPasswordModal'
import * as styled from './ChangeUserPasswordButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'

const ChangeUserPasswordButton = () => {
  const [changeUserPassword, setChangeUserPassword] = useState(false)
  return (
    <styled.MenuItemContainer onClick={() => setChangeUserPassword(true)}>
      <div>
        <FontAwesomeIcon icon={faUserCog} />
      </div>
      <div>Reset User Password</div>
      <ChangeUserPasswordModal
        visibility={changeUserPassword}
        openFunction={setChangeUserPassword}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageSpace'], ChangeUserPasswordButton)
