import styled from 'styled-components'

export const ActivityTitle = styled.div`
  margin-bottom: 6px;
  margin-top: 4px;
`

export const WidgetAnalytics = styled.div`
  display: grid;
  grid-template-areas:
    'name name name name name'
    'stats stats stats stats agents'
    'responseTime responseTime responseTime responseTime responseTime'
    'activity activity activity activity activity';
  grid-template-rows: 1fr 5fr 170px 2fr;
  grid-gap: 0.5rem;
  padding-bottom: 30px;
  border-bottom: 1px rgb(195, 195, 195) solid;
  width: 100%;
`

export const Name = styled.div`
  grid-area: name;
  font-size: 22px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
`

export const Stats = styled.div`
  grid-area: stats;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: 1fr 1fr;
`

const Stat = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  border-radius: 3px;
  font-size: 30px;
`

interface StatTitleProps {
  type?: string
}

export const StatTitle = styled.div`
  font-size: ${(props: StatTitleProps) =>
    props.type && props.type === 'subTitle' ? '12px' : '16px'};
  font-family: 'Roboto';
  text-decoration: underline;
`

export const StatTimePerChat = styled(Stat)`
  background-color: rgba(195, 231, 231, 0.5);
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const StatTotalChats = styled(Stat)`
  background-color: rgba(190, 190, 190, 0.5);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

interface StatChatsCountNumberAndTextProps {
  type?: string
}

export const StatChatsCountNumberAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding: 25px;
  ${(props: StatChatsCountNumberAndTextProps) =>
    props.type && props.type === 'offline'
      ? 'background-color: rgba(184, 61, 61, 0.3);'
      : ''}

  &:first-child {
    border-right: ${(props: StatChatsCountNumberAndTextProps) =>
        props.type && props.type === 'online' ? 0 : 1}px
      solid rgba(0, 0, 0, 0.1);
  }
`

export const StatTotalChatstext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 4px;
`

export const StatTotalChatSubtext = styled.div`
  font-size: 16px;
  text-indent: 5px;
`

export const Agents = styled.div`
  grid-area: agents;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px rgb(180, 180, 180) solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 3px;
`

export const AgentsTitle = styled.div`
  font-size: 16px;
  font-family: 'Roboto';
  padding-bottom: 0.3rem;
  border-bottom: 1px rgb(210, 210, 210) solid;
`

export const ResponseTimeHistogramContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 10px;
  font-family: 'Roboto';
  position: 'relative';
  grid-area: responseTime;
`

export const Activity = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: 'Roboto';
  grid-area: activity;
`

export const ResponseTimeNoChatsMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`
