import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import * as styled from './index.styled'

interface InlineAddButtonProps {
  passedFunction: () => void
}

const InlineAddButton = ({ passedFunction }: InlineAddButtonProps) => {
  return (
    <styled.Container>
      <Fab
        style={{ color: 'white', backgroundColor: 'rgba(230, 56, 68, 1)' }}
        aria-label="add"
        size="small"
        onClick={() => passedFunction()}
      >
        <AddIcon />
      </Fab>
    </styled.Container>
  )
}

export default InlineAddButton
