/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * SoundBase
 * auto generated base class for the model SoundModel.
 */
export const SoundModelBase = ModelBase
  .named('Sound')
  .props({
    __typename: types.optional(types.literal("Sound"), "Sound"),
    id: types.union(types.undefined, types.integer),
    location: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SoundModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get location() { return this.__attr(`location`) }
  get name() { return this.__attr(`name`) }
}
export function selectFromSound() {
  return new SoundModelSelector()
}

export const soundModelPrimitives = selectFromSound().location.name
