import React, { useContext, useEffect, useState } from 'react'
import * as styled from './ChatInput.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faArrowCircleUp,
  faHammer,
  faBus,
} from '@fortawesome/free-solid-svg-icons'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { ChatModelType } from 'models'
import { observer } from 'mobx-react'
import { StoreContext, BannedUserModel } from 'models'
import useShortcutAutoSuggest from 'utilities/useShortcutAutoSuggest'
import trim from 'lodash/trim'
import { filterShortcuts } from 'utilities/functions'
import { toJS } from 'mobx'

type shortcutType = { id: number; name: string; value: string }
export type shortcutsType = shortcutType[]

interface ChatInputProps {
  chat: ChatModelType
  sendMessage: (body: string) => void
  agent?: boolean
  endChat?: (id: string) => void
  inputRef: any
  theme?: any
  themePreview?: boolean
  shortcuts?: shortcutsType
  shortcutCommandSlash?: boolean
}

const ChatInput = observer(
  ({
    chat,
    sendMessage,
    agent,
    endChat,
    inputRef,
    theme,
    themePreview,
    shortcuts,
    shortcutCommandSlash,
  }: ChatInputProps) => {
    const [shortcutAutoSuggest, setShortcutAutoSuggest] = useState(false)
    const [selectedWithEnter, setSelectedWithEnter] = useState(false)
    const [arrowShortcutSelection, setArrowShortcutSelection] = useState<
      null | number
    >(null)
    const themeFromContext =
      themePreview || theme
        ? theme
          ? JSON.parse(theme.attributes)
          : themeObject[useContext(ThemeContext).name]
        : themeObject[useContext(ThemeContext).name]

    const store = useContext(StoreContext)

    const inputStyle = {
      color: `${themeFromContext.textOnWHiteColor.color}`,
    }

    useEffect(() => {
      inputRef!.current!.focus()
    }, [inputRef])

    const banUser = () => {
      store.mutateBanUser({ input: { chatId: chat.id } })
      endChat!(chatId)
    }

    const endShortcutSuggestAndRemoveSlash = (value: boolean) => {
      setShortcutAutoSuggest(value)
      setArrowShortcutSelection(null)
      if (!value && chat!.message.length > 0 && chat!.message.includes('/')) {
        removeLastSlash()
      }
    }

    const detectAndHandleEnter = (
      event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
      if (
        event.key === 'Enter' &&
        !event.shiftKey &&
        !arrowShortcutSelection &&
        arrowShortcutSelection !== 0
      ) {
        submitMessage()
        event.preventDefault()
        chat!.updateMessage('', agent)
      }
    }

    const removeLastSlash = () => {
      chat!.updateMessage(
        chat!.message.substring(0, chat!.message.lastIndexOf('/')) as string,
        agent,
      )
    }

    const submitMessage = () => {
      if (chat!.message.length > 0) {
        sendMessage(chat!.message)
        chat!.updateMessage('', agent)
        setShortcutAutoSuggest(false)
        setArrowShortcutSelection(null)
      }
    }

    const chatId = chat && chat.id ? chat.id : ''

    let filteredShortcuts = shortcuts
    if (shortcutAutoSuggest && shortcuts) {
      filteredShortcuts = filterShortcuts(chat?.message, shortcuts, true)
    }

    useEffect(() => {
      if (
        selectedWithEnter &&
        (arrowShortcutSelection || arrowShortcutSelection === 0)
      ) {
        const splicedFilteredShortcuts = filteredShortcuts!.splice(0, 5)

        const selectedShortcut =
          splicedFilteredShortcuts[arrowShortcutSelection]

        if (
          selectedShortcut &&
          splicedFilteredShortcuts[arrowShortcutSelection]
        ) {
          endShortcutSuggestAndRemoveSlash(false)
          chat!.appendShortcut!(selectedShortcut.value, false)
        }

        setSelectedWithEnter(false)
      }
    }, [
      selectedWithEnter,
      arrowShortcutSelection,
      filteredShortcuts,
      setSelectedWithEnter,
      chat,
      endShortcutSuggestAndRemoveSlash,
    ])

    const autoSuggestValue: {
      left: number
      top: number
    } = useShortcutAutoSuggest(
      inputRef,
      endShortcutSuggestAndRemoveSlash,
      shortcutAutoSuggest,
      arrowShortcutSelection,
      setArrowShortcutSelection,
      filteredShortcuts ? filteredShortcuts.length : null,
      chat,
      setSelectedWithEnter,
      setShortcutAutoSuggest,
      selectedWithEnter,
      filteredShortcuts,
      shortcutCommandSlash,
    )

    const appendShortcutandExitMenu = (value: string) => {
      chat!.appendShortcut!(value, true)
      setShortcutAutoSuggest(false)
      setArrowShortcutSelection(null)
    }

    return (
      <styled.Container
        alignItems="flex-start"
        minHeight="50px;"
        maxHeight="110px"
        theme={themeFromContext}
      >
        <styled.TextInput height="100%" alignItems="center">
          <TextField
            multiline
            fullWidth
            inputRef={inputRef}
            value={chat ? chat!.message : ''}
            onChange={(event) => {
              let value: string = event.target.value
              if (arrowShortcutSelection || arrowShortcutSelection === 0) {
                value = trim(value)
              }
              chat!.updateMessage(value, agent)
            }}
            onKeyDown={(event) => detectAndHandleEnter(event)}
            placeholder={
              agent && shortcutCommandSlash
                ? "Enter a message here... Type '/' to find a shortcut, press Esc to close the shortcut menu."
                : 'Enter a message here...'
            }
            rowsMax={3}
            rows={1}
            InputProps={{
              style: inputStyle,
              disableUnderline: true,
            }}
          />
        </styled.TextInput>
        <Button
          disabled={!store.onlineStatus && agent}
          onClick={submitMessage}
          style={{
            color: 'white',
            backgroundColor:
              store.onlineStatus && !agent
                ? theme
                  ? themeFromContext.secondaryHighlight.color
                  : 'rgba(249, 33, 70, 1)'
                : store.onlineStatus && agent
                ? 'rgba(51, 125, 2, 1)'
                : !store.onlineStatus && agent
                ? 'rgba(51, 125, 2, 0.4)'
                : 'rgba(230, 56, 68, 0.4)',
            textTransform: 'uppercase',
            marginRight: 10,
            marginTop: 8,
          }}
        >
          Send
          <FontAwesomeIcon
            size="1x"
            icon={faArrowCircleUp}
            style={{ marginLeft: 5 }}
          />
        </Button>
        {agent && (
          <Button
            onClick={store.setTransferVisibility}
            style={{
              color: 'white',
              backgroundColor: store.onlineStatus
                ? 'rgba(0, 0, 0, 0.85)'
                : 'rgba(0, 0, 0, 0.6)',
              textTransform: 'uppercase',
              marginRight: 10,
              marginTop: 8,
            }}
          >
            Transfer
            <FontAwesomeIcon size="1x" icon={faBus} style={{ marginLeft: 5 }} />
          </Button>
        )}
        {agent && (
          <Button
            onClick={() => endChat!(chatId)}
            style={{
              color: 'white',
              backgroundColor: store.onlineStatus
                ? 'rgba(0, 0, 0, 0.6)'
                : 'rgba(0, 0, 0, 0.4)',
              textTransform: 'uppercase',
              marginRight: 10,
              marginTop: 8,
            }}
          >
            End Chat
            <FontAwesomeIcon
              size="1x"
              icon={faTimesCircle}
              style={{ marginLeft: 5 }}
            />
          </Button>
        )}
        {/* {agent && (
          <Button
            onClick={banUser}
            style={{
              color: 'white',
              backgroundColor: store.onlineStatus
                ? 'rgba(0, 0, 0, 0.85)'
                : 'rgba(0, 0, 0, 0.6)',
              textTransform: 'uppercase',
              marginRight: 10,
              marginTop: 8,
            }}
          >
            Ban
            <FontAwesomeIcon
              size="1x"
              icon={faHammer}
              style={{ marginLeft: 5 }}
            />
          </Button>
        )} */}
        {agent && shortcutCommandSlash && (
          <Button
            disabled={!store.onlineStatus && agent}
            onClick={chat.store.setShortcutSearchVisibility}
            style={{
              color: 'white',
              backgroundColor: store.onlineStatus
                ? 'rgba(0, 0, 0, 0.85)'
                : 'rgba(0, 0, 0, 0.6)',
              textTransform: 'uppercase',
              marginRight: 10,
              marginTop: 8,
            }}
          >
            Shortcuts
            <FontAwesomeIcon
              size="1x"
              icon={faArrowCircleUp}
              style={{ marginLeft: 5 }}
            />
          </Button>
        )}
        {shortcutAutoSuggest && agent && (
          <styled.ShortCutAutoSuggestContainer
            left={autoSuggestValue.left}
            bottom={autoSuggestValue.top}
          >
            {shortcuts &&
            shortcuts.length > 0 &&
            filteredShortcuts &&
            filteredShortcuts.length > 0 ? (
              <styled.ShortcutTitle>Select a shortcut...</styled.ShortcutTitle>
            ) : (
              <>
                {shortcuts && shortcuts.length > 0 ? (
                  <styled.ShortcutTitle>
                    No shortcuts found...
                  </styled.ShortcutTitle>
                ) : (
                  <styled.ShortcutTitle>
                    You must first add a shortcut before you can select one.
                  </styled.ShortcutTitle>
                )}
              </>
            )}
            <ul>
              {filteredShortcuts &&
                filteredShortcuts.slice(0, 5).map((shortcut, i) => {
                  return (
                    <styled.ShortcutOption
                      onClick={() => appendShortcutandExitMenu!(shortcut.value)}
                      selected={
                        (arrowShortcutSelection ||
                          arrowShortcutSelection === 0) &&
                        arrowShortcutSelection ===
                          Math.abs(
                            i -
                              (filteredShortcuts!.length > 5
                                ? 4
                                : filteredShortcuts!.length - 1),
                          )
                      }
                    >
                      {shortcut.name}
                    </styled.ShortcutOption>
                  )
                })}
            </ul>
          </styled.ShortCutAutoSuggestContainer>
        )}
      </styled.Container>
    )
  },
)

export default ChatInput
