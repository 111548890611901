import React, { useState } from 'react'
import { observer } from 'mobx-react'
import * as styled from './index.styled'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import LogOutModalContent from 'ui/components/shared/LogOutModalContent'

const LoggedOut = observer(() => {
  const [themeName, setThemeName] = useState('california')
  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <styled.PageContainer theme={themeObject[ThemeProviderValue.name]}>
        <LogOutModalContent>
          <>You have been logged out.</>
        </LogOutModalContent>
      </styled.PageContainer>
    </ThemeContext.Provider>
  )
})

export default LoggedOut
