import styled from 'styled-components'

export const QuestionVariable = styled.div`
  width: 260px;
  flex-grow: 0;
  margin-right: 20px;
`

export const Question = styled.div`
  flex-grow: 1;
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FormNote = styled.div`
  margin-bottom: 35px;
  text-indent: 4px;
  font-size: 14px;
  margin-top: 8px;
`
