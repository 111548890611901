import styled from 'styled-components'

export const SubTitle = styled.div`
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: 'Roboto';
  margin-top: 20px;
`

export const AssignedTagNote = styled.div`
  width: 100%;
  font-family: 'Roboto';
  font-size: 12px;
`

export const TagSelectContainer = styled.div`
  width: 100%;
`
