import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { observer } from 'mobx-react'
import { RouteType } from 'models/RouteTypeEnum'

interface InputStyleProp {
  width: string
}

interface RouteProps {
  changeRoute: (
    type: RouteType,
    widgetId: string,
    defaultRoute: boolean,
    conditionId?: string,
  ) => void
  value: string
  placeHolder: string
  inputStyle: InputStyleProp
  widgetId: string
  defaultRoute: boolean
  conditionId?: string
  showRouteTagOption?: boolean
}

const Route = observer(
  ({
    changeRoute,
    value,
    placeHolder,
    inputStyle,
    widgetId,
    defaultRoute,
    conditionId,
    showRouteTagOption,
  }: RouteProps) => {
    return (
      <FormControl style={inputStyle} variant="filled">
        <InputLabel id="defaultRouteLabel">{placeHolder}</InputLabel>
        <Select
          labelId="defaultRouteLabl"
          id="defaultRoute"
          value={value}
          onChange={(event) =>
            changeRoute(
              RouteType[event.target.value as RouteType],
              widgetId,
              defaultRoute,
              conditionId,
            )
          }
        >
          <MenuItem value="">
            <em>Select one of the below routes:</em>
          </MenuItem>
          <MenuItem value={RouteType['AGENTQUEUE']}>Agent Queue</MenuItem>
          <MenuItem value={RouteType['PRECHATQUESTIONNAIRE']}>
            Prechat Questionnaire
          </MenuItem>
          {showRouteTagOption && (
            <MenuItem value={RouteType['ROUTETAG']}>Route Tag</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  },
)

export default Route
