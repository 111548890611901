import React, { useState, useEffect } from 'react'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import * as styled from './index.styled'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import ActionContext, { ActionContextType } from 'context/actionContext'
import ChatContainer from 'ui/components/Hub/ChatContainer'
import Status from 'ui/components/Hub/Status'
import { useQuery } from 'models/reactUtils'
import HeaderMenu from 'ui/components/shared/HeaderMenu'
import ChatNavigation from 'ui/components/Hub/ChatNavigation'
import Button from '@material-ui/core/Button'
import HeaderIcon from 'ui/components/ManagementView/HeaderIcon'
import FaviconStatusAndAlertCount from 'ui/components/shared/Notifications/FaviconStatusAndAlertCount'
import { observer } from 'mobx-react'
import {
  MessageSender,
  ChatModelType,
  ChatModelTypeWithLatestMessageTime,
} from 'models'
import LogOutModalContent from 'ui/components/shared/LogOutModalContent'
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import NoSleep from 'nosleep.js'
import ChatTagging from 'ui/components/shared/ChatTagging'

export interface SnackbarState extends SnackbarOrigin {
  open: boolean
}

const Hub = observer(() => {
  const [chatVisibility, setChatVisibility] = useState(true)
  const [themeName, setThemeName] = useState('california')
  const { store } = useQuery((store) => store.checkUserOnlineStatus())

  const [snackbarState, setSnackbarState] = React.useState<SnackbarState>({
    open: !store.audioPlayingAllowed,
    vertical: 'top',
    horizontal: 'center',
  })

  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  const closeSnackBar = () => {
    setSnackbarState({ ...snackbarState, open: false })
    store.setAudioPlayingAllowed(true)
  }

  const endChat = (chatId: string) => {
    store.mutateCompleteChat(
      { chatId },
      (data) => data.id.completed.timedOut.createdTimestamp.id,
    )
    store.setSelectedChat(null)
    store.showPostChatDialog(chatId)
  }

  useEffect(() => {
    const handleFocusActivity = (focusFlag: any) => {
      if (typeof focusFlag === 'boolean') {
        return focusFlag
          ? store.setWindowFocus(true)
          : store.setWindowFocus(false)
      }

      return document.hidden
        ? store.setWindowFocus(false)
        : store.setWindowFocus(true)
    }

    const noSleep = new NoSleep()
    document.addEventListener('visibilitychange', handleFocusActivity)
    document.addEventListener('blur', () => handleFocusActivity(false))
    window.addEventListener('blur', () => handleFocusActivity(false))
    window.addEventListener('focus', () => handleFocusActivity(true))
    document.addEventListener('focus', () => handleFocusActivity(true))
    noSleep.enable()

    return () => {
      window.removeEventListener('blur', handleFocusActivity)
      document.removeEventListener('blur', handleFocusActivity)
      window.removeEventListener('focus', handleFocusActivity)
      document.removeEventListener('focus', handleFocusActivity)
      document.removeEventListener('visibilitychange', handleFocusActivity)
      noSleep.disable()
    }
  }, [store])

  const ActionContextValue: ActionContextType = {
    chatVisibility,
    changeChatVisibility: setChatVisibility,
    role: 'agent',
    organizationModalVisibility: false,
    changeOrganizationModalVisibility: null,
    widgetModalVisibility: false,
    changeWidgetModalVisibility: null,
    agentModalVisibilityFunction: null,
  }

  const sortedHistoricalChats = (chats: ChatModelType[]) => {
    const chatsWithLatestMessageTimes = chats as ChatModelTypeWithLatestMessageTime[]
    if (chatsWithLatestMessageTimes && chatsWithLatestMessageTimes.length > 0) {
      chatsWithLatestMessageTimes.forEach((item) => {
        let max = item.messages[0]
        if (max && max.timestamp) {
          item.messages.forEach((message) => {
            const maxTimestamp = new Date(max.timestamp)
            const messageTimestamp = new Date(message.timestamp)

            if (maxTimestamp < messageTimestamp) {
              max = message
            }
          })
          item.latestMessageTime = max.timestamp
        }
      })
      return chatsWithLatestMessageTimes.sort(function (a, b) {
        const DateOne: Date = new Date(a.latestMessageTime)
        const DateTwo: Date = new Date(b.latestMessageTime)
        return DateOne > DateTwo ? -1 : DateOne < DateTwo ? 1 : 0
      })
    }
    return []
  }

  const sortedActiveChats = (chats: ChatModelType[]) => {
    if (chats && chats.length > 0) {
      return chats.sort(function (a, b) {
        const DateOne: Date = new Date(a.createdTimestamp)
        const DateTwo: Date = new Date(b.createdTimestamp)
        return DateOne > DateTwo ? -1 : DateOne < DateTwo ? 1 : 0
      })
    }

    return []
  }

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <ActionContext.Provider value={ActionContextValue}>
        <FaviconStatusAndAlertCount />
        {store.disconnectedModalVisibility && (
          <styled.LoggedOutModal>
            <LogOutModalContent>
              <styled.LogOutModalInnerContent>
                <styled.LogOutModalMessage>
                  You have been taken offline, would you like to remain online?
                </styled.LogOutModalMessage>
                <styled.LogOutModalButtonContainer>
                  <Button
                    onClick={store.closeStayOnlineModal}
                    variant="outlined"
                    style={{ marginRight: 10 }}
                  >
                    Stay Offline
                  </Button>
                  <Button
                    onClick={store.goOnline}
                    variant="contained"
                    color="primary"
                    style={{
                      color: 'white',
                      backgroundColor: 'rgba(230, 56, 68, 1)',
                    }}
                  >
                    Go Back Online
                  </Button>
                </styled.LogOutModalButtonContainer>
              </styled.LogOutModalInnerContent>
            </LogOutModalContent>
          </styled.LoggedOutModal>
        )}

        <styled.NewPageContainer>
          <styled.HeaderContainer theme={themeObject[ThemeProviderValue.name]}>
            <styled.LogoContainer>Unique Chat</styled.LogoContainer>
            <styled.StatusAndMenuContainer>
              <Status />
              <HeaderMenu hub />
            </styled.StatusAndMenuContainer>
          </styled.HeaderContainer>
          <styled.ChatNavigationContainer
            theme={themeObject[ThemeProviderValue.name]}
          >
            <ChatNavigation
              activeChats={sortedActiveChats(store.chatsInProgress)}
              chatHistory={sortedHistoricalChats(store.historicalChats)}
              setSelectedChat={store.setSelectedChat}
              onlineStatus={store.onlineStatus}
              selectedChat={store.selectedChat}
              openedChats={store.openedChats}
              chatHasBeenOpened={store.chatHasBeenOpened}
            />
          </styled.ChatNavigationContainer>
          <styled.ChatContainer>
            <ChatContainer
              selectedChat={store.selectedChat}
              agent
              sendMessage={store.agentSendMessage}
              currentSenderTypes={[
                MessageSender.AGENT,
                MessageSender.SYSTEMUSER,
                MessageSender.SYSTEM,
              ]}
              endChat={endChat}
            />
          </styled.ChatContainer>
          <HeaderIcon />
          <Snackbar
            anchorOrigin={{
              vertical: snackbarState.vertical,
              horizontal: snackbarState.horizontal,
            }}
            open={snackbarState.open}
            onClose={closeSnackBar}
            message="Click anywhere in the page to activate sounds"
            key={`activate_sounds_snackbar`}
          />
          {store.mostRecentlyClosedChatData.id &&
            store.mostRecentlyClosedChatData.requirePostChatTags && (
              <ChatTagging chatId={store.mostRecentlyClosedChatData.id} />
            )}
        </styled.NewPageContainer>
      </ActionContext.Provider>
    </ThemeContext.Provider>
  )
})

export default Hub
