import styled from 'styled-components'

interface ColorContainerProps {
  color: string
  editMode?: boolean
}

interface ThemeNameProps {
  showFonts?: boolean
}
export const ThemeName = styled.div`
  font-family: 'Roboto';
  width: 100px;
  font-size: ${(props: ThemeNameProps) => (props.showFonts ? '12px' : '16px')};
`

export const FontContainer = styled.div`
  font-size: 12px;
  margin-right: 4px;
`

export const ColorContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 2px;
  border: 1px dotted rgb(200, 200, 200);
  background-color: ${(props: ColorContainerProps) => props.color};
  ${(props: ColorContainerProps) => (props.editMode ? 'cursor: pointer;' : '')}
`

export const ColorAndPickerContainer = styled.div`
  position: relative;
`

export const PickerContainer = styled.div`
  position: absolute;
  left: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`

export const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`
