import React from 'react'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import Settings from './Settings'
import Loading from 'ui/components/shared/Loading'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import { PreChatQuestionnaireModelType } from 'models'

interface HTMLSettingsProp {
  widgetSlug: string
  organizationSlug: string
}

const HTMLSettings = observer(
  ({ widgetSlug, organizationSlug }: HTMLSettingsProp) => {
    const { loading, data, store } = useQuery((store) =>
      store.queryWidgetFromSlug(
        {
          input: {
            organizationSlug,
            widgetSlug,
          },
        },
        (data) =>
          data.id
            .preChatQuestionnaires((preChatQuestionnaire) =>
              preChatQuestionnaire.id.agentMessage.completionMessage.name
                .questions((question) =>
                  question.id.ordinal.question.variable(
                    (variable) => variable.id.name,
                  ),
                )
                .conditions((condition) =>
                  condition.id.expression.value.preChatQuestionnaireId
                    .route((route) => route.id.type.value)
                    .variable((variable) => variable.id.name),
                ),
            )
            .htmlSettings((htmlSettings) =>
              htmlSettings.greetingMessage.id.webIconImageURL.autoDisplay.autoDisplayDelay.hideIfOffline.position
                .responseVariable((variable) => variable.id.name)
                .conditions((condition) =>
                  condition.expression.id.value.route(
                    (route) => route.id.type.value,
                  ),
                )
                .route((route) => route.id.type.value),
            ),
      ),
    )

    if (loading) {
      return <Loading tabPanel />
    }

    return (
      <Settings
        settings={data!.widgetFromSlug.htmlSettings}
        widgetId={data!.widgetFromSlug.id}
        questionnaireData={
          data!.widgetFromSlug
            .preChatQuestionnaires as PreChatQuestionnaireModelType[]
        }
        routeTags={data!.widgetFromSlug.routeTags}
      />
    )
  },
)

export default WithPermission(
  ['ManageOrganization'],
  HTMLSettings,
  MissingPermission,
)
