import { Instance, types } from 'mobx-state-tree'
import { ChatModelBase } from './ChatModel.base'
import orderBy from 'lodash/orderBy'
import throttle from 'lodash/throttle'
import { autorun } from 'mobx'
import { MessageSender } from './MessageSenderEnum'

/* The TypeScript type of an instance of ChatModel */
export interface ChatModelType extends Instance<typeof ChatModel.Type> {}

export interface ChatModelTypeWithLatestMessageTime extends ChatModelType {
  latestMessageTime: string
}

export interface ChatTypingTypes {
  alias: string
  sender: MessageSender
  userId: string | null
}

/* A graphql query fragment builders for ChatModel */
export {
  selectFromChat,
  chatModelPrimitives,
  ChatModelSelector,
} from './ChatModel.base'

/**
 * ChatModel
 */
export const ChatModel = ChatModelBase.volatile((self) => ({
  message: '',
  throttleInstance: null as any,
  clearTypingFunction: null as any,
  typing: null as any,
}))
  .props({
    unreadAndBolded: types.optional(types.boolean, true),
  })
  .actions((self) => {
    let unsubscribe: () => void
    return {
      clearTyping() {
        self.typing = null
      },
      setUnreadAndBolder(state: boolean) {
        self.unreadAndBolded = state
      },
      afterAttach() {
        if (self.id) {
          unsubscribe = self.store.subscribeOnTyping(
            { chatId: self.id },
            (data) => data.alias.sender.userId,
            (result) => {
              if (self.store.userType !== result.sender) {
                self.typing = result
                clearTimeout(self.clearTypingFunction)
                self.clearTypingFunction = setTimeout(this.clearTyping, 3100)
              }
            },
          )

          if (self.store.userType === MessageSender.USER) {
            self.throttleInstance = throttle(
              () => self.store.mutateUserTyping({ chatId: self.id }),
              2500,
            )
          } else {
            self.throttleInstance = throttle(
              () => self.store.mutateAgentTyping({ chatId: self.id }),
              2500,
            )
          }
        }
        autorun(() => {
          if (self.completed) {
            unsubscribe && unsubscribe()
          }
        })
      },
      beforeDestroy() {
        unsubscribe && unsubscribe()
      },
      updateMessage(message: string, agent?: boolean) {
        self.message = message
        self.throttleInstance()
      },
      appendShortcut(shortcut: string, inlineShortcut?: boolean) {
        const message = inlineShortcut
          ? self.message.substring(0, self.message.lastIndexOf('/'))
          : self.message
        self.message = `${message}${shortcut}`
        if (self.store.shortcutSearchVisibility) {
          self.store.setShortcutSearchVisibility()
        }
      },
      markAllMessagesAsRead() {
        self.messages.forEach((message) => (message.seen = true))
      },
    }
  })
  .views((self) => ({
    get widgetName() {
      if (self.currentWidget) {
        return self.currentWidget.name
      }
      return self.originationWidget.name
    },
    get latestMessage() {
      const orderedChats = orderBy(
        self.messages,
        (message) => new Date(message.timestamp),
        'desc',
      )

      return orderedChats.length > 0 ? orderedChats[0] : null
    },
    get sortedMessages() {
      return orderBy(
        self.messages,
        (message) => new Date(message.timestamp),
        'asc',
      )
    },
  }))
