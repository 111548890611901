import React, { useContext } from 'react'
import * as styled from './index.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { theme } from 'types/style.types'
import LogoSvg from 'ui/components/shared/LogoSvg'
import { observer } from 'mobx-react'
import { PositionPreference } from 'models'

interface ChatIconProps {
  changeChatVisibility: () => void
  themePreview?: boolean
  theme?: any
  position?: PositionPreference
}

const ChatIcon = observer(
  ({ changeChatVisibility, themePreview, theme, position }: ChatIconProps) => {
    // TODO: Add transition to rounded close button after click to open
    const themeContext = useContext(ThemeContext)
    const themeFromContext: theme =
      themePreview || theme
        ? theme
          ? JSON.parse(theme.attributes)
          : themeObject[themeContext.name]
        : themeObject[themeContext.name]

    return (
      <styled.IconContainer
        themePreview={themePreview}
        onClick={changeChatVisibility}
        style={{ cursor: 'pointer' }}
        position={position}
      >
        <styled.IconInnerContainer>
          <LogoSvg
            scale=".25"
            stopColorTop={themeFromContext.secondaryLowlight.color!}
            stopColorBottom={themeFromContext.secondaryHighlight.color!}
            dropShadow={true}
            width={78}
            height={80}
            strokeWidth={16}
            keyText="chatIcon"
          />
        </styled.IconInnerContainer>
        <styled.TextWithinIcon>
          <styled.IconText>!</styled.IconText>
        </styled.TextWithinIcon>
      </styled.IconContainer>
    )
  },
)

export default ChatIcon
