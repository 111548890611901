import React, { useState } from 'react'
import { PreChatQuestionnaireModelType } from 'models'
import TextField from '@material-ui/core/TextField'
import * as styled from './ModifyQuestionnaire.styled'
import { objectsEqual } from 'utilities/functions'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import { toJS } from 'mobx'
import { observer, useLocalStore } from 'mobx-react'
import { ConditionExpression } from 'models'
import Question from './Question'
import ConditionalRoute from '../shared/ConditionalRoutes'
import { questionnaireDataLocalStore } from './ModifyQuestionnaire.utilities'
import InlineAddButton from 'ui/components/shared/InlineAddButton'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteType } from 'models/RouteTypeEnum'
import orderBy from 'lodash/orderBy'

const agentMessageNote = `A formatted message sent to the agent after questionniare has been completed.`
const completionMessageNote = `A formatted message sent to user after questionniare completed.`
const conditionalRouteNote = `A conditional route is the route a user will find themselves on when the specified conditions are met.  Currently only one is allowed per pre-chat questionnaire.`
const variableSelectionNote = `A question must be saved prior to it being selectable below as a route variable.`
const questionnaireConditionalNote = `*Required: You must select the questionnaire for this conditional.`
const questionnaireRouteTagNote = `*Required: You must select a route tag for this conditional.`

const modifyQuestionnaireInputStyle = { width: '100%' }

interface ModifyQuestionnaireProps {
  questionnaireData: PreChatQuestionnaireModelType[]
  questionnaireId: number
  widgetId: string
  returnToList: () => void
  routeTags?: any
}

const ModifyQuestionnaire = observer(
  ({
    questionnaireData,
    questionnaireId,
    widgetId,
    returnToList,
    routeTags,
  }: ModifyQuestionnaireProps) => {
    const questionnaireToEdit = questionnaireData.find(
      (questionnaire) => questionnaire.id === questionnaireId,
    )
    const defaultErrorState = { error: false, errorMessage: '' }
    const [submitError, setSubmitErrors] = useState(defaultErrorState)
    const { loading, setQuery, store } = useQuery()

    const questionnaireDataStore = useLocalStore(
      questionnaireDataLocalStore(
        JSON.parse(JSON.stringify(questionnaireToEdit)),
      ),
    )

    const checkForConditionalRouteErrors = () => {
      let error = false
      const conditionals =
        questionnaireDataStore.formattingConditionalsForUpdating() || []
      if (conditionals && conditionals.length > 0) {
        let anyCount = 0
        conditionals.forEach((conditional) => {
          if (conditional.expression === ConditionExpression.ANY) {
            anyCount = anyCount + 1
            if (anyCount > 1) {
              setSubmitErrors({
                error: true,
                errorMessage:
                  'You may have only one "any" conditional expression for this setting.',
              })
              error = true
            }
          }

          if (
            conditional.expression === ConditionExpression.EQUALS &&
            (!conditional.expressionValue ||
              (conditional.expressionValue &&
                (conditional.expressionValue === null ||
                  conditional.expressionValue.length < 1)))
          ) {
            setSubmitErrors({
              error: true,
              errorMessage:
                'You must complete the expression criteria for any "equals" conditional expression.',
            })
            error = true
          }

          if (
            conditional.routeType === RouteType['ROUTETAG'] &&
            !conditional.routeValue
          ) {
            setSubmitErrors({
              error: true,
              errorMessage:
                'You must add a route tag when your conditional route is set to "Route Tag."',
            })
          }
        })
      }
      return error ? false : conditionals
    }

    const submitUpdates = () => {
      setSubmitErrors(defaultErrorState)
      if (
        questionnaireDataStore.data.conditionsToUpdate &&
        questionnaireDataStore.data.conditions[0].expression ===
          ConditionExpression.ANY &&
        !questionnaireDataStore.data.conditions[0].route
      ) {
        setSubmitErrors({
          error: true,
          errorMessage:
            'You must select a conditional route when not defining a specific route variable.',
        })
        return
      }

      const conditions = checkForConditionalRouteErrors()
      if (!conditions) {
        return
      }

      if (
        questionnaireDataStore.data.agentMessageToUpdate ||
        questionnaireDataStore.data.completionMessageToUpdate ||
        questionnaireDataStore.data.questionsToUpdate ||
        questionnaireDataStore.data.conditionsToUpdate
      ) {
        questionnaireDataStore.changeSubmitState(true)
        const questions =
          questionnaireDataStore.data.questions &&
          questionnaireDataStore.data.questions.length > 0
            ? questionnaireDataStore.formattingQuestionsForUpdating()
            : []
        const conditions = questionnaireDataStore.formattingConditionalsForUpdating()
        setQuery(
          store.mutateUpdatePreChatQuestionnaire(
            {
              input: {
                agentMessage: questionnaireDataStore.data.agentMessage,
                completionMessage:
                  questionnaireDataStore.data.completionMessage,
                questions,
                id: questionnaireDataStore.data.id!,
                conditions,
              },
            },
            (data) =>
              data.preChatQuestionnaires((preChatQuestionnaire) =>
                preChatQuestionnaire.id.agentMessage.completionMessage.name
                  .questions((question) =>
                    question.id.ordinal.question.variable(
                      (variable) => variable.id.name,
                    ),
                  )
                  .conditions((condition) =>
                    condition.id.expression.value.preChatQuestionnaireId
                      .route((route) => route.id.type.value)
                      .variable((variable) => variable.id.name),
                  ),
              ),
          ),
        )
        questionnaireDataStore.updateConditionalFlag(false)
      }
    }

    const createCondition = () => {
      questionnaireDataStore.addCondition(widgetId)
    }

    const createQuestion = () => {
      questionnaireDataStore.addQuestion(widgetId)
    }

    let showQuestionCreation = true
    if (
      questionnaireDataStore &&
      questionnaireDataStore.data &&
      questionnaireDataStore.data.questions &&
      questionnaireDataStore.data.questions.length
    ) {
      showQuestionCreation = false
    }

    const variableArray: { id: number; name: string }[] = []
    if (questionnaireToEdit && questionnaireToEdit.questions) {
      questionnaireToEdit!.questions!.forEach((question: any) => {
        variableArray.push({
          id: parseInt(question.variable.id),
          name: question.variable.name,
        })
      })
    }

    // check for condition and question changes
    if (
      !objectsEqual(
        toJS(questionnaireToEdit!.conditions),
        toJS(questionnaireDataStore.data.conditions),
      ) &&
      questionnaireDataStore.data.submitState &&
      !loading
    ) {
      questionnaireDataStore.replaceConditions(
        toJS(questionnaireToEdit!.conditions),
      )
      questionnaireDataStore.replaceQuestions(
        toJS(questionnaireToEdit!.questions),
      )
      questionnaireDataStore.changeSubmitState(false)
    }

    return (
      <styled.Form>
        <styled.ReturnToListContainer>
          <Button onClick={returnToList}>
            <FontAwesomeIcon style={{ marginRight: 4 }} icon={faArrowLeft} />
            Return to Questionnaire List
          </Button>
        </styled.ReturnToListContainer>
        <styled.Title>Messages:</styled.Title>
        <TextField
          label={'Agent Message'}
          variant="filled"
          placeholder="Agent Message"
          value={questionnaireDataStore.data.agentMessage}
          style={modifyQuestionnaireInputStyle}
          rows={3}
          multiline
          rowsMax={4}
          onChange={(event) =>
            questionnaireDataStore.updateAgentMessage(event.target.value)
          }
        />
        <styled.FormNote>{agentMessageNote}</styled.FormNote>
        <TextField
          label={'Completion Message'}
          variant="filled"
          placeholder="Completion Message"
          value={questionnaireDataStore.data.completionMessage}
          style={modifyQuestionnaireInputStyle}
          rows={3}
          multiline
          rowsMax={4}
          onChange={(event) =>
            questionnaireDataStore.updateCompletionMessage(event.target.value)
          }
        />
        <styled.FormNote>{completionMessageNote}</styled.FormNote>
        <styled.Title>Questions:</styled.Title>
        {showQuestionCreation ? (
          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createQuestion} />
            <styled.InlineButtonText>
              Click here to add a new question.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
        ) : (
          <>
            {orderBy(
              questionnaireDataStore.data.questions,
              'ordinal',
              'asc',
            ).map((question) => {
              return (
                <styled.ConditionalWrapper
                  key={question.ordinal}
                  style={{ marginBottom: 16 }}
                >
                  <styled.ConditionalFormContainer>
                    <Question
                      variableValue={question.variable.name}
                      variableChangeValue={
                        questionnaireDataStore.updateQuestionVariable
                      }
                      questionValue={question.question}
                      questionChangeValue={
                        questionnaireDataStore.updateQuestion
                      }
                      ordinal={question.ordinal}
                    />
                  </styled.ConditionalFormContainer>
                  <styled.ConditionalDeleteIconContainer>
                    <InlineDeleteButton
                      passedFunction={questionnaireDataStore.deleteQuestion}
                      id={question.ordinal}
                    />
                  </styled.ConditionalDeleteIconContainer>
                </styled.ConditionalWrapper>
              )
            })}
          </>
        )}
        {!showQuestionCreation && (
          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createQuestion} />
            <styled.InlineButtonText>
              Click here to add an additional question.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
        )}
        <styled.FormNote>
          Add or modify the questions that are asked customers when they initate
          a chat.
        </styled.FormNote>
        <styled.Title>Conditional Route:</styled.Title>
        <styled.FormNote style={{ marginBottom: 8 }}>
          {variableSelectionNote}
        </styled.FormNote>
        {questionnaireDataStore &&
          questionnaireDataStore.data &&
          questionnaireDataStore.data.conditions &&
          orderBy(questionnaireDataStore.data.conditions, 'id', 'asc').map(
            (condition) => {
              return (
                <styled.ConditionalWrapper key={`condition_${condition.id}`}>
                  <styled.ConditionalFormContainer>
                    <ConditionalRoute
                      questionnaireData={questionnaireData}
                      helperText={questionnaireConditionalNote}
                      routeTagHelperText={questionnaireRouteTagNote}
                      conditionId={condition.id}
                      value={
                        condition.route && condition.route.type
                          ? condition.route.type
                          : ''
                      }
                      variables={variableArray}
                      changeValue={questionnaireDataStore.updateRoute}
                      widgetId={widgetId}
                      defaultRoute={false}
                      questionnaireSelectValue={
                        condition.route && condition.route.value
                          ? condition.route.value
                          : ''
                      }
                      questionnaireSelectChangeValue={
                        questionnaireDataStore.updateRouteWithQuestionnaire
                      }
                      questionnaire
                      selectedVariable={
                        condition.variable
                          ? String(condition.variable.id)
                          : null
                      }
                      setSelectedVariable={
                        questionnaireDataStore.attachVariableToCondition
                      }
                      selectedExpression={
                        condition.expression ? condition.expression : null
                      }
                      setConditionalExpression={
                        questionnaireDataStore.setConditionalExpression
                      }
                      expressionCriteria={condition.value}
                      changeExpressionCriteria={
                        questionnaireDataStore.changeExpressionCriteria
                      }
                      routeTags={routeTags}
                      changeConditionalRouteTag={
                        questionnaireDataStore.setRouteTag
                      }
                      selectedRouteTag={condition.route}
                    />
                  </styled.ConditionalFormContainer>
                  <styled.ConditionalDeleteIconContainer>
                    <InlineDeleteButton
                      passedFunction={questionnaireDataStore.deleteCondition}
                      id={condition.id}
                    />
                  </styled.ConditionalDeleteIconContainer>
                </styled.ConditionalWrapper>
              )
            },
          )}
        <styled.InlineAddButtonContainer>
          <InlineAddButton passedFunction={createCondition} />
          <styled.InlineButtonText>
            Click here to add a conditional route.
          </styled.InlineButtonText>
        </styled.InlineAddButtonContainer>
        <styled.FormNote>{conditionalRouteNote}</styled.FormNote>
        {submitError.error && (
          <styled.SubmissionError>
            {submitError.errorMessage}
          </styled.SubmissionError>
        )}
        <Button
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            color: 'white',
            backgroundColor: 'rgba(230, 56, 68, 1)',
            marginBottom: 20,
          }}
          onClick={submitUpdates}
        >
          Update Questionnaire
        </Button>
      </styled.Form>
    )
  },
)

export default ModifyQuestionnaire
