import React, { useState } from 'react'
import {
  HtmlSettingsModelType,
  PreChatQuestionnaireModelType,
  ConditionExpression,
  PositionPreference,
} from 'models'
import * as styled from './Settings.styled'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import InlineAddButton from 'ui/components/shared/InlineAddButton'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import { observer, useLocalStore } from 'mobx-react'
import Route from 'ui/components/ManagementView/TabPanels/Widget/shared/Route'
import ConditionalRoute from 'ui/components/ManagementView/TabPanels/Widget/shared/ConditionalRoutes'
import GreetingMessage from 'ui/components/ManagementView/TabPanels/Widget/shared/GreetingMessage'
import QuestionnaireSelect from 'ui/components/ManagementView/TabPanels/Widget/shared/QuestionnaireSelect'
import * as copy from 'ui/components/ManagementView/TabPanels/Widget/shared/Settings.copy'
import { settingsLocalStore } from 'ui/components/ManagementView/TabPanels/Widget/shared/Settings.utilities'
import { Channel } from 'models/ChannelEnum'
import { RouteType } from 'models/RouteTypeEnum'
import { toJS } from 'mobx'
import orderBy from 'lodash/orderBy'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { $enum as enumToArray } from 'ts-enum-util'

interface HTMLSettingsProp {
  settings: HtmlSettingsModelType
  widgetId: string
  questionnaireData?: PreChatQuestionnaireModelType[]
  routeTags?: any
}

const inputStyle = { width: '100%' }

const WidgetCode = ({ widgetId }: { widgetId: string }) => {
  const host = document.location.host

  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <div>
      <h3 style={{ cursor: 'pointer' }} onClick={toggleExpanded}>
        + Widget Code
      </h3>
      <div hidden={!expanded}>
        <p>
          Add this snippet before the closing body tag in every HTML page that
          should have chat.
        </p>
        <pre style={{ backgroundColor: 'rgba(220,220,220,.5)' }}>
          {`
          <script type="text/javascript">
            (function () {
              var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
              se.src = 'https://${host}/web-chat.js';
              var done = false;
              se.onload = se.onreadystatechange = function () {
                if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                  done = true;
                  window.UniqueChat.load('https://${host}', '${widgetId}')
                }
              };
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
            })();
          </script>
          `}
        </pre>
      </div>
    </div>
  )
}

const Settings = observer(
  ({ settings, widgetId, questionnaireData, routeTags }: HTMLSettingsProp) => {
    const { setQuery, store } = useQuery()

    // apply snap shot or reinitailize local store post mutate or just yolo  and assume the local store = remote.
    const settingsStore = useLocalStore(
      settingsLocalStore(toJS(settings), undefined, {
        autoDisplay: settings.autoDisplay!,
        autoDisplayDelay: settings.autoDisplayDelay!,
        hideIfOffline: settings.hideIfOffline!,
        position: PositionPreference[settings.position!],
      }),
    )
    const errorDefaultState = {
      error: false,
      errorMessage: '',
    }
    const [errors, setErrors] = useState(errorDefaultState)

    const createCondition = () => {
      settingsStore.addCondition(widgetId)
    }

    const deleteConditional = (id?: number) => {
      setQuery(
        store.mutateRemoveSettingsRouteCondition({
          input: {
            channel: Channel['HTML'],
            routeConditionId: id!,
            widgetId,
          },
        }),
      )
    }

    const checkForConditionalRouteErrors = () => {
      let error = false
      const conditionals =
        settingsStore.formattingConditionalsForUpdating() || []
      if (conditionals && conditionals.length > 0) {
        let anyCount = 0
        conditionals.forEach((conditional) => {
          if (conditional.expression === ConditionExpression.ANY) {
            anyCount = anyCount + 1
            if (anyCount > 1) {
              setErrors({
                error: true,
                errorMessage:
                  'You may have only one "any" conditional expression for this setting.',
              })
              error = true
            }
          }

          if (
            conditional.expression === ConditionExpression.EQUALS &&
            (!conditional.expressionValue ||
              (conditional.expressionValue &&
                (conditional.expressionValue === null ||
                  conditional.expressionValue.length < 1)))
          ) {
            setErrors({
              error: true,
              errorMessage:
                'You must complete the expression criteria for any "equals" conditional expression.',
            })
            error = true
          }
        })
      }
      return error ? false : conditionals
    }

    const submitUpdates = () => {
      setErrors(errorDefaultState)
      // Upate anything with a true update flag
      if (settingsStore.conditionsToUpdate) {
        const conditions = checkForConditionalRouteErrors()

        if (conditions) {
          setQuery(
            store.mutateUpdateSettingsRouteConditions({
              input: {
                channel: Channel.HTML,
                conditions,
                widgetId,
              },
            }),
          )
          settingsStore.updateConditionalFlag(false)
        }
      }

      if (settingsStore.routeToUpdate) {
        setQuery(
          store.mutateUpdateDefaultRoute({
            input: {
              channel: Channel['HTML'],
              routeType: settingsStore.route.type,
              routeValue: String(settingsStore.route.value),
              widgetId,
            },
          }),
        )
        settingsStore.updateRouteFlag(false)
      }

      if (settingsStore.greetingMessageToUpdate) {
        setQuery(
          store.mutateUpdateWidgetChannelSettings({
            input: {
              channel: Channel['HTML'],
              greetingMessage: settingsStore.greetingMessage || '',
              widgetId,
            },
          }),
        )
        settingsStore.updateGreetingMessageFlag(false)
      }

      if (settingsStore.webWidgetOptionsToUpdate) {
        setQuery(
          store.mutateUpdateWebWidgetSettings({
            input: {
              autoDisplay: settingsStore.autoDisplay!,
              autoDisplayDelay: settingsStore.autoDisplayDelay!,
              hideIfOffline: settingsStore.hideIfOffline!,
              position: settingsStore.position!,
              widgetId,
            },
          }),
        )
        settingsStore.setWebWidgetUpdateFlag(false)
      }
    }

    return (
      <styled.Container>
        <styled.Form>
          <styled.Title>Messages:</styled.Title>
          <GreetingMessage
            value={settingsStore.greetingMessage}
            changeValue={settingsStore.updateGreetingMessage}
          />
          <styled.FormNote>{copy.greetingMessageNote}</styled.FormNote>
          <styled.Title>Routes:</styled.Title>
          <styled.SubTitle>{copy.unspecifiedConditionsNotes}</styled.SubTitle>
          <Route
            value={settingsStore.route.type}
            changeRoute={settingsStore.updateRoute}
            placeHolder="Default Route"
            inputStyle={inputStyle}
            widgetId={widgetId}
            defaultRoute={true}
          />
          <styled.FormNote>{copy.defaultRouteNote}</styled.FormNote>
          {settingsStore.route.type === RouteType['PRECHATQUESTIONNAIRE'] && (
            <>
              <QuestionnaireSelect
                questionnaireData={questionnaireData}
                helperText={copy.questionnaireNote}
                value={settingsStore.route.value}
                defaultRoute={true}
                changeValue={settingsStore.updateRouteWithQuestionnaire}
              />
              <styled.FormNote />
            </>
          )}
          <styled.Title>Conditional Route:</styled.Title>
          {settingsStore &&
            settingsStore.conditions &&
            orderBy(settingsStore.conditions, 'id', 'asc').map((condition) => {
              return (
                <styled.ConditionalWrapper
                  key={`conditional_wrapper_${condition.id}`}
                >
                  <styled.ConditionalFormContainer>
                    <ConditionalRoute
                      questionnaireData={questionnaireData}
                      helperText={copy.questionnaireConditionalNote}
                      conditionId={condition.id}
                      selectedVariable={condition.variable}
                      setSelectedVariable={() => null}
                      variables={[settingsStore.responseVariable]}
                      value={condition.route.type}
                      changeValue={settingsStore.updateRoute}
                      widgetId={widgetId}
                      defaultRoute={false}
                      questionnaireSelectValue={condition.route.value}
                      questionnaireSelectChangeValue={
                        settingsStore.updateRouteWithQuestionnaire
                      }
                      selectedExpression={
                        condition.expression ? condition.expression : null
                      }
                      setConditionalExpression={
                        settingsStore.setConditionalExpression
                      }
                      expressionCriteria={condition.value}
                      changeExpressionCriteria={
                        settingsStore.changeExpressionCriteria
                      }
                      source="settings"
                      routeTags={routeTags}
                      changeConditionalRouteTag={settingsStore.setRouteTag}
                      selectedRouteTag={condition.route}
                    />
                  </styled.ConditionalFormContainer>
                  <styled.ConditionalDeleteIconContainer>
                    <InlineDeleteButton
                      passedFunction={settingsStore.deleteCondition}
                      deleteQuery={deleteConditional}
                      id={condition.id}
                    />
                  </styled.ConditionalDeleteIconContainer>
                </styled.ConditionalWrapper>
              )
            })}

          {errors.error && (
            <styled.SubmissionError>
              {errors.errorMessage}
            </styled.SubmissionError>
          )}

          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createCondition} />
            <styled.InlineButtonText>
              Click here to add a conditional route.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
          <styled.FormNote>{copy.conditionalRouteNote}</styled.FormNote>

          <styled.Title>Web Widget Display Options:</styled.Title>

          <styled.WebWidgetDisplayOptions>
            <styled.WebWidgetDisplayOption>
              <styled.WebWidgetDisplayOptionInput background>
                <Checkbox
                  color="primary"
                  checked={settingsStore.autoDisplay}
                  onChange={(event) =>
                    settingsStore.setAutoDisplay(
                      event.target.checked as boolean,
                    )
                  }
                />
              </styled.WebWidgetDisplayOptionInput>
              <div>Auto-display chat window on load</div>
            </styled.WebWidgetDisplayOption>
            <styled.WebWidgetDisplayOption>
              <styled.WebWidgetDisplayOptionInput>
                <TextField
                  label={'Delay (in seconds)'}
                  variant="filled"
                  placeholder="Delay (in seconds)"
                  type="number"
                  value={settingsStore.autoDisplayDelay}
                  style={{ width: '100%' }}
                  onChange={(event) =>
                    settingsStore.setAutoDisplayDelay(
                      parseInt(event.target.value) as number,
                    )
                  }
                  disabled={!settingsStore.autoDisplay}
                />
              </styled.WebWidgetDisplayOptionInput>
              <div>
                Auto-display delay: Time between page load and autodisplaying of
                chat window.
              </div>
            </styled.WebWidgetDisplayOption>
            <styled.WebWidgetDisplayOption>
              <styled.WebWidgetDisplayOptionInput background>
                <Checkbox
                  color="primary"
                  checked={settingsStore.hideIfOffline}
                  onChange={(event) =>
                    settingsStore.setHideIfOffline(
                      event.target.checked as boolean,
                    )
                  }
                />
              </styled.WebWidgetDisplayOptionInput>
              <div>If agents are offline, hide widget completely.</div>
            </styled.WebWidgetDisplayOption>
            <styled.WebWidgetDisplayOption>
              <styled.WebWidgetDisplayOptionInput>
                <FormControl style={{ width: '100%' }} variant="filled">
                  <InputLabel id="widgetPositionLabel">
                    Widget position:
                  </InputLabel>
                  <Select
                    labelId="widgetPositionLabel"
                    id="widgetPosition"
                    value={settingsStore.position}
                    placeholder="Widget position:"
                    onChange={(event) =>
                      settingsStore.setPosition(
                        event.target.value as PositionPreference,
                      )
                    }
                  >
                    {enumToArray(PositionPreference).map((position) => {
                      if (!position.includes('MIDDLE')) {
                        return (
                          <MenuItem
                            key={`positionPreference_${position}`}
                            value={position}
                          >
                            {position}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </FormControl>
              </styled.WebWidgetDisplayOptionInput>
              <div>Where should the widget be displayed on the page?</div>
            </styled.WebWidgetDisplayOption>
          </styled.WebWidgetDisplayOptions>

          <Button
            style={{
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
              marginBottom: 20,
            }}
            onClick={submitUpdates}
          >
            Save Web Settings
          </Button>

          <WidgetCode widgetId={widgetId} />
        </styled.Form>
      </styled.Container>
    )
  },
)

export default WithPermission(
  ['ManageOrganization'],
  Settings,
  MissingPermission,
)
