import React, { useContext } from 'react'
import * as styled from './index.styled'
import { toJS } from 'mobx'
import { StoreContext, BannedUserModelType } from 'models'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ThemeProvider } from '@material-ui/core/styles'
import { onlineAndOfflineSwitchTheme } from 'ui/theme/ManagementView'
import { format } from 'date-fns'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import Loading from 'ui/components/shared/Loading'

interface BannedUsersProps {
  widgetSlug: string
  organizationSlug: string
}

const BannedUsers = observer(
  ({ widgetSlug, organizationSlug }: BannedUsersProps) => {
    const { loading, data, store } = useQuery((store) =>
      store.queryWidgetFromSlug(
        { input: { organizationSlug, widgetSlug } },
        (data) =>
          data.id.bannedUsers(
            (bannedUser) => bannedUser.id.source.permanent.channel.banLiftDate,
          ),
      ),
    )

    if (loading) {
      return <Loading tabPanel />
    }

    return (
      <styled.Container>
        {data?.widgetFromSlug?.bannedUsers &&
        data?.widgetFromSlug?.bannedUsers?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell align="right">Channel</TableCell>
                <TableCell align="right">Temporary or Permanent Ban</TableCell>
                <TableCell align="right">Ban Lift Date</TableCell>
                <TableCell align="center">Unban</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data?.widgetFromSlug?.bannedUsers as BannedUserModelType[]).map(
                (bannedUser: BannedUserModelType, i: number) => {
                  let style = {}
                  if (i % 2 === 0) {
                    style = { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
                  }

                  return (
                    <TableRow style={style}>
                      <TableCell>{bannedUser.source}</TableCell>
                      <TableCell align="right">{bannedUser.channel}</TableCell>
                      <TableCell align="right">
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="flex-end"
                            justify="flex-end"
                            spacing={1}
                          >
                            <Grid item>Temporary</Grid>
                            <Grid item>
                              <ThemeProvider
                                theme={onlineAndOfflineSwitchTheme}
                              >
                                <Switch
                                  checked={bannedUser.permanent}
                                  onChange={() =>
                                    store.mutateUpdateBannedUser(
                                      {
                                        input: {
                                          banId: bannedUser.id!,
                                          permanent: !bannedUser.permanent,
                                          widgetId: data?.widgetFromSlug?.id,
                                        },
                                      },
                                      (data) =>
                                        data.bannedUsers(
                                          (bannedUser) =>
                                            bannedUser.id.source.channel
                                              .permanent.banLiftDate,
                                        ),
                                    )
                                  }
                                  color="default"
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid item>Permanent</Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {bannedUser.banLiftDate ? (
                          format(
                            new Date(bannedUser.banLiftDate),
                            'M/d/yyyy h:mm a',
                          )
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() =>
                            store.mutateUnbanUser(
                              {
                                input: {
                                  banId: bannedUser.id!,
                                  widgetId: data?.widgetFromSlug?.id,
                                },
                              },
                              (data) =>
                                data.bannedUsers(
                                  (bannedUser) =>
                                    bannedUser.id.source.channel.permanent
                                      .banLiftDate,
                                ),
                            )
                          }
                          style={{
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                            textTransform: 'uppercase',
                            marginRight: 10,
                            marginTop: 8,
                          }}
                        >
                          Unban
                          <FontAwesomeIcon
                            size="1x"
                            style={{ marginLeft: 6 }}
                            icon={faThumbsUp}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                },
              )}
            </TableBody>
          </Table>
        ) : (
          <styled.NoBansMessage>
            There are no banned users for this widget.
          </styled.NoBansMessage>
        )}
      </styled.Container>
    )
  },
)

export default BannedUsers
