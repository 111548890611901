import React, { useCallback, useEffect } from 'react'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import { Button, CircularProgress } from '@material-ui/core'

const Loader = () => {
  return (
    <styled.LoadingContainer>
      <CircularProgress
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        color="inherit"
      />
    </styled.LoadingContainer>
  )
}

const ChatTagging = observer(({ chatId }: { chatId: string }) => {
  const { loading, data, setQuery, store } = useQuery()

  useEffect(() => {
    setQuery(store.queryPostChatTags({ chatId }, (data) => data.id.name))
  }, [store, chatId, setQuery])

  const saveSelectedTag = useCallback(
    (tagId: number) => {
      store.setPostChatTag(chatId, [tagId])
    },
    [store, chatId, setQuery],
  )

  return (
    <>
      <styled.ModalBackround />
      <styled.TagContainer>
        <styled.TagDialog>
          <styled.Title>Select an appropriate tag for this chat:</styled.Title>
          <styled.Tags>
            {loading ? (
              <Loader />
            ) : (
              <>
                {(data as any)?.postChatTags?.map(
                  (tag: { id: number; name: string }) => (
                    <Button
                      onClick={() => saveSelectedTag(tag.id)}
                      key={`post_chat_tag${tag.id}`}
                      variant="contained"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        backgroundColor: 'black',
                        color: 'white',
                      }}
                    >
                      {tag.name}
                    </Button>
                  ),
                )}
              </>
            )}
          </styled.Tags>
        </styled.TagDialog>
      </styled.TagContainer>
    </>
  )
})

export default ChatTagging
