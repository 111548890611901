import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;

  a {
    text-decoration: none;
    color: black;
  }
`
export const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`

export const Subtitle = styled.div`
  font-size: 12px;
`

export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`
