import { Instance, getEnv, types, cast } from 'mobx-state-tree'
import { RootStoreBase } from './RootStore.base'
import { ChatModelType, ChatModel, MessageModelType, UserModel } from 'models'
import { observable } from 'mobx'
import { MessageSender } from './MessageSenderEnum'
import { playSound } from 'utilities/notifications'
import * as Sentry from '@sentry/react'
import { AgentStatusPayloadModelType } from './AgentStatusPayloadModel'
import { toJS } from 'mobx'
import { PositionPreference } from './PositionPreferenceEnum'

export interface RootStoreType extends Instance<typeof RootStore.Type> {}

export interface MaxChatValue {
  key: string
  max: number
}

export const Sounds = [
  { id: 1, name: 'Human Whistle', sound: new Audio('/humanWhistle.mp3') },
  { id: 2, name: 'Bubble Pop', sound: new Audio('/lipSound.mp3') },
  { id: 3, name: 'Tech Pluck Sound', sound: new Audio('/thumbPluck.mp3') },
  { id: 4, name: 'Door Chime', sound: new Audio('/doorChime.mp3') },
  { id: 5, name: 'Tube Bonk', sound: new Audio('/tubeBonk.mp3') },
  { id: 6, name: 'Alert Chime', sound: new Audio('/alertChime.mp3') },
  {
    id: 7,
    name: 'Sci Fi Arpeggio Descending',
    sound: new Audio('/sciFiArpeggioDescending.mp3'),
  },
  { id: 8, name: 'Xylophone Accet', sound: new Audio('/xylophoneAccent.mp3') },
]

export const RootStore = RootStoreBase.volatile((self) => ({
  onlineStatus: false,
  soundLevel: 0.25,
  audio: new Audio('/humanWhistle.mp3'),
  // playNotification: false,
  browserNotifications: observable({
    permission: false,
  }),
  mostRecentlyClosedChatData: observable({
    id: null as string | null,
    requirePostChatTags: false as boolean,
  }),
  notificationInterval: null as any,
  alertCount: 0,
  agentOnlineTime: new Date(Date.now()),
  userType: MessageSender.SYSTEM,
  lastSoundPlayedTime: null as any,
  refreshUserInterval: null as any,
  webUserTheme: null as any,
  webUserThemeTitle: null as any,
  webUserIconImageURL: null as any,
  webUserPostchatQuestionnaireEnabled: false,
  webUserPostchatQuestionnaire: null as any,
  webUserPostchatQuestionnaireCompleted: false,
}))
  .props({
    selectedChat: types.maybeNull(types.reference(ChatModel)),
    retrievedUser: types.maybeNull(types.reference(UserModel)),
    openedChats: types.optional(types.array(types.string), []),
    disconnectedModalVisibility: types.optional(types.boolean, false),
    windowFocus: types.optional(types.boolean, true),
    unreadMessageBadgeCount: types.optional(types.number, 0),
    audioPlayingAllowed: types.optional(types.boolean, false),
    displayWebWidget: types.optional(types.boolean, false),
    autoDisplayWebWidget: types.optional(types.boolean, false),
    autoDisplayDelayWebWidget: types.optional(types.number, 0),
    hideIfOfflineWebWidget: types.optional(types.boolean, false),
    webWidgetPosition: types.optional(
      types.enumeration<PositionPreference>(
        'PositionPreference',
        Object.values(PositionPreference),
      ),
      PositionPreference.BOTTOMRIGHT,
    ),
    shortcutSearchVisibility: types.optional(types.boolean, false),
    refreshUserError: types.optional(types.boolean, false),
    userDeviceBanned: types.optional(types.boolean, false),
    spaceId: types.maybeNull(types.string),
    organizationId: types.maybeNull(types.string),
    widgetOffline: types.optional(types.boolean, false),
    transferVisibility: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get permissions() {
      if (self.organizationId) {
        const organization = self.organizations.get(self.organizationId)
        return organization?.privileges?.map((x: any) => x.privilegeName)
      }
      const space = self.spaces.get(self.spaceId!)
      return space?.privileges?.map((x: any) => x.privilegeName)
    },
    get chatsInProgress() {
      return (
        Array.from(self.chats.values()).filter((chat) => !chat.completed) || []
      )
    },
    get historicalChats() {
      return (
        Array.from(self.chats.values()).filter((chat) => chat.completed) || []
      )
    },
    get badgeCount() {
      let badgeCount = 0

      self.chats.forEach((chat) => {
        chat &&
          chat.messages &&
          chat.messages.forEach((message) => {
            if (
              !message.seen &&
              new Date(message.timestamp) > new Date(self.agentOnlineTime) &&
              (!self.selectedChat ||
                (self.selectedChat &&
                  message.chat.id !== self.selectedChat?.id))
            ) {
              badgeCount = badgeCount + 1
            }
          })
      })
      return badgeCount
    },
    get agentSoundOverride() {
      if (self.retrievedUser?.sound) {
        const foundSound = Sounds.find(
          (sound) => sound.id === self.retrievedUser!.sound!.id,
        )
        return foundSound!.sound
      }
      return null
    },
  }))
  .actions((self) => ({
    setTransferVisibility() {
      self.transferVisibility = !self.transferVisibility
    },
    deleteOrganizationFromStore(id: string) {
      const organization = self.organizations.get(id)
      organization!.space.organizations.remove(organization!)
    },
    setShortcutSearchVisibility() {
      self.shortcutSearchVisibility = !self.shortcutSearchVisibility
    },
    setAudioPlayingAllowed(status: boolean) {
      self.audioPlayingAllowed = status
    },
    setupNotificationInterval() {
      if (!self.notificationInterval) {
        self.lastSoundPlayedTime = new Date()
        self.notificationInterval = setInterval(
          this.useNotificationInterval,
          1000,
        )
      }
    },
    setLastPlayedTime() {
      self.lastSoundPlayedTime = new Date()
    },
    useNotificationInterval() {
      const now = new Date()
      const lastPlayedTime = new Date(self.lastSoundPlayedTime)
      const timeDifference = Math.floor(
        (now.getTime() - lastPlayedTime.getTime()) / 1000,
      )
      if (timeDifference >= 30) {
        let soundPlayCount = 0
        self.chats.forEach((chat) => {
          if (
            !chat.activeAgentAlias &&
            soundPlayCount === 0 &&
            !chat.completed
          ) {
            const widgetInstance = self.widgets.get(chat.originationWidget.id)
            soundPlayCount = 1
            const selectedChatId = self.selectedChat
              ? self.selectedChat.id
              : null
            this.setLastPlayedTime()
            const audioPromise = playSound(
              self.agentSoundOverride || widgetInstance!.htmlAudioElement,
              self.windowFocus,
              chat.id,
              selectedChatId,
            )
            if (audioPromise !== undefined) {
              audioPromise.catch((error) => {
                // Autoplay was prevented.
                this.setAudioPlayingAllowed(false)
              })
            }
          }
        })
        soundPlayCount === 0 && this.setLastPlayedTime()
      }
    },
    clearPreviousChatLogs() {
      self.chats.clear()
      return
    },
    setWindowFocus(focus: boolean) {
      self.windowFocus = focus
    },
    agentSendMessage(body: string) {
      self
        .mutateAgentSendMessage({
          input: { id: self.selectedChat!.id, body },
        })
        .then(() => {
          self.selectedChat!.throttleInstance.cancel()
        })
    },
    chatHasBeenOpened(id: string) {
      if (!self.openedChats.find((chatId) => chatId === id)) {
        self.openedChats.push(id)
      }
    },
    setMostRecentlyClosedChatData(
      chatId?: string | null,
      requirePostChatTags?: boolean,
    ) {
      if (chatId && requirePostChatTags) {
        self.mostRecentlyClosedChatData.id = chatId
        self.mostRecentlyClosedChatData.requirePostChatTags = requirePostChatTags
      } else {
        self.mostRecentlyClosedChatData.id = null
        self.mostRecentlyClosedChatData.requirePostChatTags = false
      }
    },
    setSelectedChat(chat: ChatModelType | null, user?: boolean) {
      if (!user) {
        if (chat) {
          chat.markAllMessagesAsRead()
          chat.setUnreadAndBolder(false)
        }
      }
      self.selectedChat = chat
    },
    showPostChatDialog(chatId: string) {
      const chat = self.chats.get(chatId)
      this.setMostRecentlyClosedChatData(
        chat?.id || null,
        chat!.currentWidget?.requirePostChatTags || false,
      )
    },
    setPostChatTag(chatId: string, tagIds: number[]) {
      const query = self.mutateUpdateChatTag(
        {
          input: { chatId, postChatTagsIds: tagIds },
        },
        (data) =>
          data.id.postChatTags((tag) =>
            tag.chatId.postChatTagId.postChatTag((tag) => tag.id.name),
          ),
      )

      query.then(() => {
        this.setMostRecentlyClosedChatData()
      })
    },
    setHeaders(spaceId: string, organizationId: string | null) {
      self.spaceId = spaceId
      self.organizationId = organizationId
      getEnv(self).gqlHttpClient.setHeader('X-Space', spaceId)
      getEnv(self).gqlHttpClient.setHeader('X-Organization', organizationId)
    },
    getSpaces(history: any) {
      const query = self.querySpaces(
        {},
        (space) =>
          space.id.name
            .organizations((organization) => organization.name.slug.id.deleted)
            .privileges((privilege) => privilege.privilegeName),
        { fetchPolicy: 'cache-first' },
      )

      query.then(
        (data) => {
          if (data.spaces.length === 0) {
            history.push('/hub')
            return
          }
          this.setHeaders(data.spaces[0].id, null)
        },
        (error) => {
          console.log(error)
          Sentry.captureMessage(`Error in getSpaces(): ${error}`)
        },
      )
      return query
    },
    getOrganizationFromSlug(slug: string) {
      const query = self.queryOrganizationFromSlug(
        { slug },
        (data) =>
          data.id.name
            .organizations((org) => org.id.name.slug.deleted)
            .widgets((widget) =>
              widget.id.deleted.name.slug.agents((agent) => agent.id.alias),
            )
            .space((space) => space.id.name)
            .privileges((privilege) => privilege.privilegeName)
            .themes((theme) => theme.id.name.attributes),
        { fetchPolicy: 'cache-first' },
      )

      query.then(
        (data) => {
          this.setHeaders(
            data.organizationFromSlug.space.id,
            data.organizationFromSlug.id,
          )
        },
        (error) => {
          console.error(error)
          Sentry.captureMessage(`Error in getOrganizationFromSlug(): ${error}`)
        },
      )

      return query
    },
    getWidgetFromSlug(orgSlug: string, widgetSlug: string) {
      const query = self.queryWidgetFromSlug(
        { input: { organizationSlug: orgSlug, widgetSlug } },
        (data) =>
          data.id.slug.name.chatTimeout.offlineMesage.title.smsProvider.deleted
            .sound((sound) => sound.id.location.name)
            .routeTags((tag) => tag.id.name.widgetId)
            .shortcuts((shortcut) => shortcut.id.name.value)
            .organization((organization) =>
              organization.id.name
                // .space((space) => space.id)
                .themes((theme) => theme.attributes.id.name),
            ),
      )

      // set headers for auth'ing future calls
      query.then(
        (data) => {
          this.setHeaders(
            data.widgetFromSlug.organization.space.id,
            data.widgetFromSlug.organization.id,
          )
          data.widgetFromSlug.selectedTheme = data.widgetFromSlug.theme
        },
        (error) => {
          console.log(error)
          Sentry.captureMessage(`Error in getWidgetFromSlug(): ${error}`)
        },
      )
      return query
    },
    goOffline() {
      const query = self.mutateGoOffline({})
      query.then(
        () => {
          self.onlineStatus = false
        },
        (error) => {
          Sentry.captureMessage(`Error in goOffline():${error}`)
        },
      )

      clearInterval(self.refreshUserInterval)
      self.refreshUserInterval = null
      return query
    },
    setAgentOnlineTime() {
      self.agentOnlineTime = new Date(Date.now())
    },
    goOnline() {
      const query = self.mutateGoOnline({}, (data) =>
        data.id.createdTimestamp.completed.activeAgentAlias.completedTime.sourcePhoneNumber.timedOut
          .postChatTags(
            (tag) => tag.postChatTag((tag) => tag.id.name).postChatTagId.chatId,
          )
          .originationWidget(
            (widget) =>
              widget.id.name.postChatTags((tag) => tag.id.name.widgetId)
                .requirePostChatTags,
          )
          .currentWidget(
            (widget) =>
              widget.id.name.postChatTags((tag) => tag.id.name.widgetId)
                .requirePostChatTags,
          )
          .messages((message) =>
            message.id.body.senderAlias.senderType.timestamp.chat(
              (chat) => chat.id.sourcePhoneNumber.timedOut.offline,
            ),
          ),
      )

      query.then(
        (data) => {
          self.subscribeAgentOnChat(
            {},
            (data) =>
              data.id.createdTimestamp.completed.activeAgentAlias.completedTime.sourcePhoneNumber.timedOut
                .postChatTags(
                  (tag) =>
                    tag.postChatTag((tag) => tag.id.name).postChatTagId.chatId,
                )
                .originationWidget(
                  (widget) =>
                    widget.id.name.postChatTags((tag) => tag.id.name.widgetId)
                      .requirePostChatTags,
                )
                .currentWidget(
                  (widget) =>
                    widget.id.name.postChatTags((tag) => tag.id.name.widgetId)
                      .requirePostChatTags,
                )
                .messages((message) =>
                  message.id.body.senderAlias.senderType.timestamp.chat(
                    (chat) =>
                      chat.id.timedOut.sourcePhoneNumber.activeAgentAlias,
                  ),
                ),
            (result: ChatModelType) => {
              if (!result || result.id === undefined) return
              if (
                result.messages &&
                result.messages.some(
                  (message) =>
                    message.chat === null || message.chat === undefined,
                )
              ) {
                Sentry.captureMessage(
                  'Recieved a chat with some messages missing chat property',
                  (scope) => scope.setContext('chat', result),
                )
              }

              // Filter only new messages to be added to the store so that we don't see
              // the chat populated in it's entirety over and over.
              const currentMessages = Array.from(self.messages.values()).filter(
                (message) => message.chat !== undefined,
              )

              const reRetrievedMessages = currentMessages.filter(
                (message) =>
                  message.chat.id === result.id &&
                  !result.messages.includes(message),
              )
              result.messages.push(...reRetrievedMessages)
            },
          )
          self.onlineStatus = true
          self.disconnectedModalVisibility = false
          self.userType = MessageSender.AGENT
        },
        (error) => {
          console.error('Error')
          console.log(error)
          Sentry.captureMessage(`Error in goOnline(): ${error}`)
        },
      )

      query.then(() => {
        self.browserNotifications.permission = true
        if (!self.refreshUserInterval) {
          self.refreshUserInterval = setInterval(this.refreshUser, 900000)
        }
      })

      query.then(() => {
        self.queryWidgets({}, (data) =>
          data.id.sound((sound) => sound.id.location.name),
        )
      })

      return query
    },
    userGetWidgetAnicllaryDetails(
      widgetId: string,
      setChatVisibility: (value: boolean) => void,
    ) {
      const query = self.queryUserGetAncillaryWidgetDetails(
        { widgetId },
        (data) => {
          return data
            .theme((theme) => theme.id.name.attributes)
            .widgetTitle.widgetIconImageUrl.autoDisplay.autoDisplayDelay.hideIfOffline.position.isOffline.enablePostChatQuestionnaire.postChatQuestionnaire(
              (questionnaire) =>
                questionnaire.completionMessage.questions(
                  (question) => question.id.ordinal.question,
                ),
            )
        },
      )

      query.then((data) => {
        self.webUserTheme = data.userGetAncillaryWidgetDetails.theme
        self.webUserPostchatQuestionnaire =
          data.userGetAncillaryWidgetDetails.postChatQuestionnaire
        self.webUserPostchatQuestionnaireEnabled =
          data.userGetAncillaryWidgetDetails.enablePostChatQuestionnaire ||
          false
        self.webUserIconImageURL =
          data.userGetAncillaryWidgetDetails.widgetIconImageUrl
        self.webUserThemeTitle =
          data.userGetAncillaryWidgetDetails &&
          data.userGetAncillaryWidgetDetails!.widgetTitle &&
          data.userGetAncillaryWidgetDetails!.widgetTitle!.length > 0
            ? data.userGetAncillaryWidgetDetails.widgetTitle
            : null

        if (
          !(
            data.userGetAncillaryWidgetDetails.hideIfOffline &&
            data.userGetAncillaryWidgetDetails.isOffline
          )
        ) {
          if (
            data.userGetAncillaryWidgetDetails.autoDisplayDelay! > 0 &&
            data.userGetAncillaryWidgetDetails.autoDisplay!
          ) {
            setTimeout(
              () => setChatVisibility(true),
              data.userGetAncillaryWidgetDetails.autoDisplayDelay! * 1000,
            )
          } else if (data.userGetAncillaryWidgetDetails.autoDisplay!) {
            setChatVisibility(true)
          }
        }

        self.webWidgetPosition =
          PositionPreference[data.userGetAncillaryWidgetDetails.position!]
        self.autoDisplayWebWidget = data.userGetAncillaryWidgetDetails.autoDisplay!
        self.autoDisplayDelayWebWidget = data.userGetAncillaryWidgetDetails.autoDisplayDelay!
        self.hideIfOfflineWebWidget = data.userGetAncillaryWidgetDetails.hideIfOffline!

        if (
          data.userGetAncillaryWidgetDetails.hideIfOffline &&
          data.userGetAncillaryWidgetDetails.isOffline
        ) {
          self.widgetOffline = true
        }
      })

      return this.userCreateChat(widgetId)
    },
    userCreateChat(widgetId: string) {
      self.userType = MessageSender.USER
      const query = self.mutateUserCreateChat(
        {
          widgetId,
        },
        (data) => {
          return data.id.hash.completed.createdTimestamp.timedOut
            .originationWidget((widget) => widget.id.name)
            .messages((message) =>
              message.id.senderAlias.senderType.body.chat(
                (chat) => chat.completed.completedTime.id.offline.timedOut,
              ),
            )
        },
      )

      query.then(
        (data) => {
          if (!data.userCreateChat.completed) {
            self.subscribeUserOnMessageSent(
              {
                input: {
                  id: data.userCreateChat.id,
                  hash: data.userCreateChat.hash,
                },
              },
              (data) =>
                data.id.hash.completed.timedOut.createdTimestamp
                  .originationWidget((widget) => widget.id.name)
                  .messages((message) =>
                    message.id.senderAlias.senderType.body.timestamp.chat(
                      (chat) =>
                        chat.completed.completedTime.id.offline.timedOut,
                    ),
                  ),
              (result: MessageModelType) => {
                if (!result) return
                const preeviouslyExistingMessages = Array.from(
                  self.messages.values(),
                )
                const selectedChat = self.selectedChat
                selectedChat!.messages = observable(preeviouslyExistingMessages)
              },
            )
            self.onlineStatus = true
          }
        },
        (error) => {
          error.response.errors.forEach((error: any) => {
            if (error.extensions.code === 'BANNED_DEVICE') {
              self.userDeviceBanned = true
            } else {
              Sentry.captureMessage(`Error in userCreateChat(): ${error}`)
            }
          })
        },
      )

      return query
    },
    getUser() {
      const query = self.queryUser(
        {},
        (data) =>
          data.id.email.shortcutPreference.sound(
            (sound) => sound.id.location.name,
          ),
        {
          fetchPolicy: 'cache-first',
        },
      )

      query.then(
        (data) => {
          self.retrievedUser = data.user
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: data.user.id,
              email: data.user.email || undefined,
            })
          })
        },
        (error) => {
          return error
          // Sentry.captureMessage(`Error in getUser(): ${error}`)
        },
      )

      return query
    },
    refreshUser() {
      const query = self.queryUser({}, (data) => data.id.email, {
        fetchPolicy: 'network-only',
      })

      query.then(
        (data) => data.user.email,
        (error) => {
          self.refreshUserError = true
          clearInterval(self.refreshUserInterval)
          self.refreshUserInterval = null
          Sentry.captureMessage(`Error in refreshUser(): ${error}`)
        },
      )

      return query
    },
    createUserOnlineSubscription() {
      self.subscribeAgentOnStatusChange(
        {},
        (data) => data.online,
        (result: AgentStatusPayloadModelType) => {
          if (!result.online && result.online !== self.onlineStatus) {
            this.goOffline()
            self.disconnectedModalVisibility = true
          }
        },
      )
    },
    checkUserOnlineStatus() {
      const query = self.queryUserOnline({}, (data) => data.online)

      query.then(
        (data) => {
          data.userOnline.online && this.goOnline()
        },
        (error) => {
          Sentry.captureMessage(`Error in checkUserOnlineStatus(): ${error}`)
        },
      )

      this.createUserOnlineSubscription()
      return query
    },
    closeStayOnlineModal() {
      self.disconnectedModalVisibility = false
    },
  }))
