import styled from 'styled-components'

export const MenuContainer = styled.div`
  margin-top: 12px;
  margin-left: -6px;
  display: flex;
  flex-direction: row;

  svg {
    cursor: pointer;
  }

  svg:not(:last-child) {
    margin-right: 17px;
  }
`
