import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const GoToHub = () => {
  return (
    <Link to="/hub" target="_blank">
      <div style={{ display: 'inline-block ' }}>
        <Button
          style={{ marginRight: 24, textDecoration: 'none', marginTop: 12 }}
          variant="contained"
          size="small"
        >
          Go To Hub{' '}
          <FontAwesomeIcon
            style={{ marginLeft: 5 }}
            icon={faArrowRight}
            size="1x"
          />
        </Button>
      </div>
    </Link>
  )
}

export default GoToHub
