import React, { useContext, useState } from 'react'
import * as styled from './ChatNavigation.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { theme } from 'types/style.types'
import { ChatModelType } from 'models'
import HistoricalChats from './HistoricalChats'
import ActiveChats from './ActiveChats'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'

interface Chats {
  activeChats: ChatModelType[]
  chatHistory: ChatModelType[]
  onlineStatus: boolean
  setSelectedChat: (chat: ChatModelType) => void
  selectedChat?: ChatModelType | null
  openedChats: string[]
  chatHasBeenOpened: (id: string) => void
}

const ChatNavigation = ({
  activeChats,
  chatHistory,
  setSelectedChat,
  onlineStatus,
  selectedChat,
  openedChats,
  chatHasBeenOpened,
}: Chats) => {
  const themeContext = useContext(ThemeContext)
  const themeFromContext: theme = themeObject[themeContext.name]
  const [historicalChatExpanded, changeHistoricalChatExpanded] = useState(false)

  return (
    <styled.Container theme={themeFromContext}>
      <styled.Title style={{ marginBottom: 6, marginTop: 40 }}>
        Active Chats
      </styled.Title>

      <ActiveChats
        activeChats={activeChats}
        setSelectedChat={setSelectedChat}
        onlineStatus={onlineStatus}
        selectedChat={selectedChat}
        openedChats={openedChats}
        chatHasBeenOpened={chatHasBeenOpened}
      />

      <styled.HistoricalTitleAndCollapseContainer>
        <styled.Title style={{ marginBottom: 6, marginTop: 10 }}>
          History
        </styled.Title>
        {chatHistory && chatHistory.length > 0 && (
          <styled.ExpandOrContractHistory
            onClick={() =>
              changeHistoricalChatExpanded(!historicalChatExpanded)
            }
          >
            {historicalChatExpanded ? (
              <>
                Hide Chat History{' '}
                <FontAwesomeIcon icon={faMinusSquare} size="sm" />
              </>
            ) : (
              <>
                Show Chat History{' '}
                <FontAwesomeIcon icon={faPlusSquare} size="sm" />
              </>
            )}
          </styled.ExpandOrContractHistory>
        )}
      </styled.HistoricalTitleAndCollapseContainer>
      <div
        style={{ width: '100%', marginBottom: 30 }}
        hidden={!historicalChatExpanded}
      >
        <HistoricalChats
          setSelectedChat={setSelectedChat}
          chatHistory={chatHistory}
        />
      </div>
      {!historicalChatExpanded && chatHistory && chatHistory.length > 0 && (
        <styled.ChatNote>
          Click <strong>"Show Chat History"</strong> above in order to view
          historical chats.
        </styled.ChatNote>
      )}
      {!(chatHistory && chatHistory.length > 0) && (
        <styled.ChatNote>
          There are no historical chats you can review at this time. They have
          either expired per your organization's settings or you have not yet
          had any conversations.
        </styled.ChatNote>
      )}
    </styled.Container>
  )
}

export default ChatNavigation
