import styled from 'styled-components'

export const AnalyticsContainer = styled.div`
  width: 100%;
  min-height: 70vh;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const RefreshButtonAndTimeSelectContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 10px;
  right: 10px;
  z-index: 1000;
`
