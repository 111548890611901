/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PositionPreferenceEnumType } from "./PositionPreferenceEnum"
import { RouteModel, RouteModelType } from "./RouteModel"
import { RouteModelSelector } from "./RouteModel.base"
import { VariableModel, VariableModelType } from "./VariableModel"
import { VariableModelSelector } from "./VariableModel.base"
import { WidgetSettingsRouteConditionModel, WidgetSettingsRouteConditionModelType } from "./WidgetSettingsRouteConditionModel"
import { WidgetSettingsRouteConditionModelSelector } from "./WidgetSettingsRouteConditionModel.base"
import { RootStoreType } from "./index"


/**
 * HtmlSettingsBase
 * auto generated base class for the model HtmlSettingsModel.
 */
export const HtmlSettingsModelBase = ModelBase
  .named('HtmlSettings')
  .props({
    __typename: types.optional(types.literal("HtmlSettings"), "HtmlSettings"),
    autoDisplay: types.union(types.undefined, types.boolean),
    autoDisplayDelay: types.union(types.undefined, types.integer),
    conditions: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => WidgetSettingsRouteConditionModel)))),
    greetingMessage: types.union(types.undefined, types.null, types.string),
    hideIfOffline: types.union(types.undefined, types.boolean),
    id: types.union(types.undefined, types.integer),
    position: types.union(types.undefined, PositionPreferenceEnumType),
    responseVariable: types.union(types.undefined, types.null, types.late((): any => VariableModel)),
    route: types.union(types.undefined, types.null, types.late((): any => RouteModel)),
    webIconImageURL: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class HtmlSettingsModelSelector extends QueryBuilder {
  get autoDisplay() { return this.__attr(`autoDisplay`) }
  get autoDisplayDelay() { return this.__attr(`autoDisplayDelay`) }
  get greetingMessage() { return this.__attr(`greetingMessage`) }
  get hideIfOffline() { return this.__attr(`hideIfOffline`) }
  get id() { return this.__attr(`id`) }
  get position() { return this.__attr(`position`) }
  get webIconImageURL() { return this.__attr(`webIconImageURL`) }
  conditions(builder?: string | WidgetSettingsRouteConditionModelSelector | ((selector: WidgetSettingsRouteConditionModelSelector) => WidgetSettingsRouteConditionModelSelector)) { return this.__child(`conditions`, WidgetSettingsRouteConditionModelSelector, builder) }
  responseVariable(builder?: string | VariableModelSelector | ((selector: VariableModelSelector) => VariableModelSelector)) { return this.__child(`responseVariable`, VariableModelSelector, builder) }
  route(builder?: string | RouteModelSelector | ((selector: RouteModelSelector) => RouteModelSelector)) { return this.__child(`route`, RouteModelSelector, builder) }
}
export function selectFromHtmlSettings() {
  return new HtmlSettingsModelSelector()
}

export const htmlSettingsModelPrimitives = selectFromHtmlSettings().autoDisplay.autoDisplayDelay.greetingMessage.hideIfOffline.position.webIconImageURL
