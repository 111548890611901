import React from 'react'
import { ChatModelType, SortOperationKind } from 'models'
import * as styled from './HistoricalChats.styled'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import TimeAgo from 'timeago-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobeAmericas,
  faMobile,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import Linkify from 'react-linkify'
import { PROVIDERS } from 'constants/constants'
import { toJS } from 'mobx'

interface HistoricalChats {
  chatHistory: ChatModelType[]
  setSelectedChat: (chat: ChatModelType) => void
}

const HistoricalChats = observer(
  ({ chatHistory, setSelectedChat }: HistoricalChats) => {
    const { data, store, setQuery } = useQuery((store) =>
      store.queryChatHistory(
        {
          orderBy: { createdTimestamp: SortOperationKind.DESC },
          first: 5,
        },
        (data) =>
          data
            .nodes((node) =>
              node.createdTimestamp.id.completed.sourcePhoneNumber.completedTime.sourcePhoneNumber
                .postChatTags(
                  (tag) =>
                    tag.postChatTag((tag) => tag.id.name).postChatTagId.chatId,
                )
                .originationWidget((widget) => widget.id.name)
                .currentWidget((widget) =>
                  widget.requirePostChatTags.postChatTags(
                    (tag) => tag.id.name.widgetId,
                  ),
                )
                .messages((message) =>
                  message.id.senderAlias.senderType.timestamp.body.chat(
                    (chat) =>
                      chat.id.timedOut.sourcePhoneNumber.activeAgentAlias,
                  ),
                ),
            )
            .pageInfo((info) => info.endCursor),
      ),
    )

    return (
      <>
        {chatHistory &&
          chatHistory.length > 0 &&
          chatHistory.map((chat) => {
            const time =
              chat.latestMessage !== null
                ? new Date(chat.latestMessage.timestamp)
                : new Date(chat.createdTimestamp)
            const body =
              chat.latestMessage !== null ? chat.latestMessage.body : ''
            return (
              chat.messages.length > 0 && (
                <styled.ConversationContainer
                  key={chat.id}
                  onClick={() => setSelectedChat(chat)}
                >
                  <styled.Conversation
                    selected={
                      store.selectedChat
                        ? store.selectedChat.id === chat.id
                          ? true
                          : false
                        : false
                    }
                  >
                    <styled.ConversationHeader>
                      <styled.ConversationHeaderIconSource>
                        {chat.sourcePhoneNumber ? (
                          <>
                            <FontAwesomeIcon
                              icon={faMobile}
                              size="1x"
                              style={{ marginRight: 5 }}
                            />{' '}
                            {chat.sourcePhoneNumber
                              .replace('+1', '')
                              .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faGlobeAmericas}
                              size="1x"
                              style={{ marginRight: 5 }}
                            />{' '}
                            Web
                          </>
                        )}
                      </styled.ConversationHeaderIconSource>
                      {chat.postChatTags && chat.postChatTags.length > 0 ? (
                        <styled.ChatTagContainer>
                          {chat.postChatTags?.map((tag: any) => {
                            if (tag) {
                              return (
                                <styled.Tag
                                  key={`${chat.id}_postChatTag_${tag.postChatTag.id}`}
                                >
                                  {tag.postChatTag.name}
                                </styled.Tag>
                              )
                            } else {
                              return <></>
                            }
                          })}
                        </styled.ChatTagContainer>
                      ) : (
                        <styled.ChatTagContainer>
                          {(chat?.originationWidget?.postChatTags ||
                            chat?.currentWidget?.postChatTags) && (
                            <styled.AddPostChatTag
                              onClick={() => store.showPostChatDialog(chat.id)}
                            >
                              +
                            </styled.AddPostChatTag>
                          )}
                        </styled.ChatTagContainer>
                      )}
                    </styled.ConversationHeader>
                    <styled.ConversationAndTime>
                      <styled.ConversationPreview>
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key,
                          ) => (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              key={key}
                              style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                color: `${
                                  store.selectedChat &&
                                  store.selectedChat.id === chat.id
                                    ? 'rgba(255, 255, 255, 1.0)'
                                    : 'rgba(45,125,155,1.0)'
                                }`,
                              }}
                              href={decoratedHref}
                            >
                              {PROVIDERS.some((provider) =>
                                decoratedHref.includes(provider),
                              )
                                ? 'Image Preview'
                                : decoratedHref}
                            </a>
                          )}
                        >
                          {body}
                        </Linkify>
                      </styled.ConversationPreview>
                      <styled.TimeAgoContainer>
                        <TimeAgo datetime={time} />
                      </styled.TimeAgoContainer>
                    </styled.ConversationAndTime>
                  </styled.Conversation>
                </styled.ConversationContainer>
              )
            )
          })}
        {data &&
        data.chatHistory &&
        data.chatHistory.pageInfo &&
        data.chatHistory.pageInfo.endCursor ? (
          <styled.LoadMoreButtonContainer>
            <Button
              style={{
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              size="small"
              onClick={() =>
                setQuery(
                  store.queryChatHistory(
                    {
                      orderBy: { createdTimestamp: SortOperationKind.DESC },
                      first: 5,
                      after: data!.chatHistory.pageInfo.endCursor,
                    },
                    (data) =>
                      data
                        .nodes((node) =>
                          node.createdTimestamp.id.completed.sourcePhoneNumber.completedTime.sourcePhoneNumber
                            .postChatTags(
                              (tag) =>
                                tag.postChatTag((tag) => tag.id.name).chatId
                                  .postChatTagId,
                            )
                            .originationWidget((widget) => widget.id.name)
                            .currentWidget((widget) =>
                              widget.requirePostChatTags.postChatTags(
                                (tag) => tag.id.name.widgetId,
                              ),
                            )
                            .messages((message) =>
                              message.id.senderAlias.senderType.timestamp.body.chat(
                                (chat) =>
                                  chat.id.timedOut.sourcePhoneNumber
                                    .activeAgentAlias,
                              ),
                            ),
                        )
                        .pageInfo((info) => info.endCursor),
                  ),
                )
              }
            >
              Load More
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="1x"
                style={{ marginTop: '-3px', marginLeft: 5 }}
              />
            </Button>
          </styled.LoadMoreButtonContainer>
        ) : (
          ''
        )}
      </>
    )
  },
)

export default HistoricalChats
