import styled from 'styled-components'

export const IconContainer = styled.div`
  position: absolute;
  top: -4px;
  margin-top: 0px;
  left: 20px;
  width: 110px;
  height: 130px;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`

export const IconInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
`

export const TextWithinIcon = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  font-size: 42px;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconText = styled.div`
  margin-top: 6px;
  margin-left: 7px;
  transform: rotate(5deg);
`
