import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  margin-left: 30px;
  width: 100%;
`

export const Form = styled.div`
  width: 95%;
  height: 100px;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Roboto';
`

export const FormNote = styled.div`
  margin-bottom: 35px;
  text-indent: 4px;
  font-size: 14px;
  margin-top: 8px;
`

export const SubTitle = styled.div`
  margin-bottom: 14px;
  text-indent: 0px;
  font-size: 14px;
  margin-top: -14px;
  margin-left: 4px;
`

export const InlineAddButtonContainer = styled.div`
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`

export const InlineButtonText = styled.div`
  margin-left: 6px;
`

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
  align-items: center;
`

export const CurrentTheme = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const OfflineMessageContainer = styled.div`
  position: relative;
`
