/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ConditionExpressionEnumType } from "./ConditionExpressionEnum"
import { RouteModel, RouteModelType } from "./RouteModel"
import { RouteModelSelector } from "./RouteModel.base"
import { VariableModel, VariableModelType } from "./VariableModel"
import { VariableModelSelector } from "./VariableModel.base"
import { RootStoreType } from "./index"


/**
 * PreChatQuestionnaireRouteConditionBase
 * auto generated base class for the model PreChatQuestionnaireRouteConditionModel.
 */
export const PreChatQuestionnaireRouteConditionModelBase = ModelBase
  .named('PreChatQuestionnaireRouteCondition')
  .props({
    __typename: types.optional(types.literal("PreChatQuestionnaireRouteCondition"), "PreChatQuestionnaireRouteCondition"),
    expression: types.union(types.undefined, ConditionExpressionEnumType),
    id: types.union(types.undefined, types.integer),
    preChatQuestionnaireId: types.union(types.undefined, types.integer),
    route: types.union(types.undefined, types.null, types.late((): any => RouteModel)),
    value: types.union(types.undefined, types.null, types.string),
    variable: types.union(types.undefined, types.null, types.late((): any => VariableModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PreChatQuestionnaireRouteConditionModelSelector extends QueryBuilder {
  get expression() { return this.__attr(`expression`) }
  get id() { return this.__attr(`id`) }
  get preChatQuestionnaireId() { return this.__attr(`preChatQuestionnaireId`) }
  get value() { return this.__attr(`value`) }
  route(builder?: string | RouteModelSelector | ((selector: RouteModelSelector) => RouteModelSelector)) { return this.__child(`route`, RouteModelSelector, builder) }
  variable(builder?: string | VariableModelSelector | ((selector: VariableModelSelector) => VariableModelSelector)) { return this.__child(`variable`, VariableModelSelector, builder) }
}
export function selectFromPreChatQuestionnaireRouteCondition() {
  return new PreChatQuestionnaireRouteConditionModelSelector()
}

export const preChatQuestionnaireRouteConditionModelPrimitives = selectFromPreChatQuestionnaireRouteCondition().expression.preChatQuestionnaireId.value
