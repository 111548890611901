import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as styled from './ResetUserPassword.styled'
import TextField from '@material-ui/core/TextField'
import { resetUserPasswordAppBar } from 'ui/theme/ManagementView'
import { observer } from 'mobx-react'
import { ThemeProvider } from '@material-ui/core/styles'

interface ResetUserPasswordProps {
  setResetPasswordText: (value: string) => void
  setEmailOfUserToEdit: (value: string) => void
  resetPasswordText: string
  emailOfUserToEdit: string
}

const ResetUserPassword = observer(
  ({
    setResetPasswordText,
    setEmailOfUserToEdit,
    resetPasswordText,
    emailOfUserToEdit,
  }: ResetUserPasswordProps) => {
    const [errorText, setErrorText] = useState('')
    const { loading, data, setQuery, store } = useQuery()

    const pressedKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const code = event.keyCode || event.which
      if (code === 13) {
        resetPassword()
      }
    }

    const returnToSearchResults = () => {
      setResetPasswordText('')
      setEmailOfUserToEdit('')
    }

    const resetPassword = () => {
      if (resetPasswordText && resetPasswordText.length > 0) {
        setQuery(() => {
          const query = store.mutateChangeUserPassword({
            password: resetPasswordText,
            userEmail: emailOfUserToEdit!,
          })

          query.then(
            () => {
              setResetPasswordText('')
              setEmailOfUserToEdit('')
            },
            (errors) => {
              errors.response.errors.forEach((error: any) => {
                setErrorText(error.message)
              })
            },
          )
          return query
        })
      }
    }

    return (
      <>
        <styled.SearchMessage>
          Enter a new password for this user (email: {emailOfUserToEdit}) then
          click submit below:
        </styled.SearchMessage>
        <styled.SearchInput>
          <TextField
            value={resetPasswordText}
            onChange={(event) => setResetPasswordText(event.target.value)}
            placeholder="New password"
            onKeyPress={(event) => pressedKey(event)}
          />
          <Button
            onClick={returnToSearchResults}
            disabled={loading}
            style={{
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              marginLeft: 12,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={resetPassword}
            disabled={loading}
            style={{
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              marginLeft: 12,
            }}
          >
            {loading ? (
              <ThemeProvider theme={resetUserPasswordAppBar}>
                <CircularProgress size="18px" color="secondary" />
              </ThemeProvider>
            ) : (
              'Submit'
            )}
          </Button>
        </styled.SearchInput>
        <styled.ErrorText>{errorText}</styled.ErrorText>
      </>
    )
  },
)

export default ResetUserPassword
