import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as styled from './ShortcutSearch.styled'
import TextField from '@material-ui/core/TextField'
import { shortcutsType } from './ChatInput'
import { filterShortcuts } from 'utilities/functions'
import { ChatModelType } from 'models'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'

interface ShortcutSearchProps {
  shortcuts?: shortcutsType
  chat: ChatModelType
}

const inputStyle = {
  color: `rgb(38, 38, 38)`,
  backgroundColor: 'rgb(220, 220, 220)',
}

const ShortcutSearch = ({ shortcuts, chat }: ShortcutSearchProps) => {
  const [shortcutSearchText, setShortcutSearchText] = useState('')

  let filteredShortcuts = shortcuts
  if (shortcuts) {
    filteredShortcuts = filterShortcuts(shortcutSearchText, shortcuts)
  }

  const appendShortcutandExitMenu = (value: string) => {
    chat!.appendShortcut!(value, false)
  }

  const shortcutSearchRef: any = useRef()

  useEffect(() => {
    shortcutSearchRef!.current!.focus()
  }, [shortcutSearchRef])

  const handleKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        chat.store.setShortcutSearchVisibility()
        return
      }
    },
    [chat],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKey, false)
    document.addEventListener('keypress', handleKey, false)
    return () => {
      document.removeEventListener('keydown', handleKey, false)
      document.addEventListener('keypress', handleKey, false)
    }
  }, [handleKey])

  return (
    <styled.ShortcutSearchContainer>
      <styled.ShortcutSearchForm>
        <TextField
          fullWidth
          value={shortcutSearchText}
          inputRef={shortcutSearchRef}
          id="input-with-icon-textfield"
          label="Search for a shortcut..."
          variant="filled"
          placeholder="Shortcut name"
          onChange={(event) =>
            setShortcutSearchText(event.target.value as string)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: inputStyle,
          }}
        />
      </styled.ShortcutSearchForm>
      <styled.ShortcutSearchResults>
        <ul>
          {filteredShortcuts &&
            filteredShortcuts.map((shortcut, i) => {
              return (
                <styled.ShortcutOption
                  onClick={() => appendShortcutandExitMenu!(shortcut.value)}
                  selected={false}
                >
                  {shortcut.name}:{' '}
                  <styled.ShortcutValue>
                    "{shortcut.value}"
                  </styled.ShortcutValue>
                </styled.ShortcutOption>
              )
            })}
        </ul>
      </styled.ShortcutSearchResults>
    </styled.ShortcutSearchContainer>
  )
}

export default ShortcutSearch
