import React, { useContext } from 'react'
import * as styled from './Status.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { theme } from 'types/style.types'
import { useQuery } from 'models/reactUtils'
import Switch from '@material-ui/core/Switch'
import { observer } from 'mobx-react'
import { onlineAndOfflineSwitchTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import StatusBubble from 'ui/components/shared/StatusBubble'

const Status = observer(() => {
  const themeContext = useContext(ThemeContext)
  const themeFromContext: theme = themeObject[themeContext.name]

  const { setQuery, store } = useQuery()
  const status = store.onlineStatus

  const onChange = (event: React.ChangeEvent<{}>) => {
    if (!status) {
      store.setAgentOnlineTime()
      setQuery(store.goOnline())
    } else {
      setQuery(store.goOffline())
    }
  }

  return (
    <styled.Container theme={themeFromContext}>
      <strong>Status:</strong>
      <StatusBubble status={status} />
      {status ? 'Online' : 'Offline'}
      <ThemeProvider theme={onlineAndOfflineSwitchTheme}>
        <Switch color="primary" checked={status} onChange={onChange} />
      </ThemeProvider>
    </styled.Container>
  )
})

export default Status
