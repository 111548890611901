/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * AgentSendMessagePayloadBase
 * auto generated base class for the model AgentSendMessagePayloadModel.
 */
export const AgentSendMessagePayloadModelBase = ModelBase
  .named('AgentSendMessagePayload')
  .props({
    __typename: types.optional(types.literal("AgentSendMessagePayload"), "AgentSendMessagePayload"),
    id: types.identifier,
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AgentSendMessagePayloadModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
}
export function selectFromAgentSendMessagePayload() {
  return new AgentSendMessagePayloadModelSelector()
}

export const agentSendMessagePayloadModelPrimitives = selectFromAgentSendMessagePayload()
