import React from 'react'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import * as styled from './index.styled'

interface InlineEditButtonProps {
  passedFunction: (id: string | number, deleteQuery?: () => void) => void
  deleteQuery?: () => void
  id: string | number
}

const InlineEditButton = ({
  passedFunction,
  deleteQuery,
  id,
}: InlineEditButtonProps) => {
  return (
    <styled.Container>
      <Fab
        style={{ color: 'white', backgroundColor: 'rgba(230, 56, 68, 1)' }}
        aria-label="add"
        size="small"
        onClick={() => passedFunction(id, deleteQuery)}
      >
        <EditIcon />
      </Fab>
    </styled.Container>
  )
}

export default InlineEditButton
