import styled, { keyframes } from 'styled-components'

const BounceAnimation = keyframes`
  0% { margin-bottom: 5px; }
  25% { margin-bottom: 12px; }
  50% { margin-bottom: 5px; }
  75% { margin-bottom: 5px; }
  100% { margin-bottom: 5px; }
`

interface DotProps {
  delay: string
  widthAndHeight: string
  margin: string
  color: string
}

export const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 24px;
  margin-bottom: 2px;
`

export const Dot = styled.div`
  background-color: ${(props: DotProps) => props.color};
  border-radius: 50%;
  width: ${(props: DotProps) => props.widthAndHeight};
  height: ${(props: DotProps) => props.widthAndHeight};
  margin: 0 ${(props: DotProps) => props.margin};
  animation: ${BounceAnimation} 1s linear infinite;
  animation-delay: ${(props: DotProps) => props.delay};
`
