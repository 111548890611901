import {
  HtmlSettingsModelType,
  SmsSettingsModelType,
  ConditionExpression,
  PositionPreference,
} from 'models'
import { RouteType } from 'models/RouteTypeEnum'

export interface conditionsReturnType {
  expression: ConditionExpression
  expressionValue?: string
  routeType: RouteType
  routeValue?: string
}

export interface webWidgetOptions {
  autoDisplay: boolean
  autoDisplayDelay: number
  hideIfOffline: boolean
  position: PositionPreference
}

export const settingsLocalStore = (
  settings: HtmlSettingsModelType | SmsSettingsModelType,
  phoneNumber?: string,
  webWidgetOptions?: webWidgetOptions,
) => {
  return () => ({
    greetingMessage: settings.greetingMessage,
    autoDisplay: webWidgetOptions?.autoDisplay,
    autoDisplayDelay: webWidgetOptions?.autoDisplayDelay,
    hideIfOffline: webWidgetOptions?.hideIfOffline,
    position: webWidgetOptions?.position || null,
    phoneNumber: phoneNumber || null,
    responseVariable: settings.responseVariable as {
      id: number
      name: string | null
    },
    conditions: settings.conditions as {
      id: string
      route: { id: number | string; type: RouteType; value: string }
      expression: ConditionExpression
      value: string
      variable: string
    }[],
    route: settings.route as {
      id: number
      type: RouteType
      value: string
    },
    routeToUpdate: false,
    conditionsToUpdate: false,
    webWidgetOptionsToUpdate: false,
    phoneNumberToUpdate: false,
    greetingMessageToUpdate: false,
    setRouteTag(conditionId: string, id: number, value: string) {
      const foundCondition = this.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.route = { id, value, type: RouteType.ROUTETAG }
      filteredConditions.push(foundCondition!)
      this.conditions = filteredConditions
      this.conditionsToUpdate = true
    },
    updateGreetingMessage(greetingMessage: string) {
      this.greetingMessage = greetingMessage
      this.updateGreetingMessageFlag(true)
    },
    updateGreetingMessageFlag(value: boolean) {
      this.greetingMessageToUpdate = value
    },
    updateRoute(
      type: RouteType,
      widgetId: string,
      defaultRoute: boolean,
      conditionId?: string,
    ) {
      if (defaultRoute) {
        const route = this.route
        route.type = type
        route.value = type === 'AGENTQUEUE' ? widgetId : ''
        this.updateRouteFlag(true)
      } else {
        const conditions = this.conditions
        const indexOfCondition = conditions.findIndex(
          (condition) => condition.id === conditionId,
        )
        const conditionToUpdate = conditions[indexOfCondition]
        conditionToUpdate.route.type = type
        conditionToUpdate.route.value = type === 'AGENTQUEUE' ? widgetId : ''
        conditions[indexOfCondition] = conditionToUpdate
        this.conditions = conditions
        this.updateConditionalFlag(true)
      }
    },
    updateRouteFlag(value: boolean) {
      this.routeToUpdate = value
    },
    updateConditionalFlag(value: boolean) {
      this.conditionsToUpdate = value
    },
    updateRouteWithQuestionnaire(
      defaultRoute: boolean,
      questionnaireId: string,
      conditionId?: string,
    ) {
      if (defaultRoute) {
        const route = this.route
        route.value = questionnaireId
        this.updateRouteFlag(true)
      } else {
        const conditions = this.conditions
        const indexOfCondition = conditions.findIndex(
          (condition) => condition.id === conditionId,
        )
        const conditionToUpdate = conditions[indexOfCondition]
        conditionToUpdate.route.value = questionnaireId
        this.conditions = conditions
      }
    },
    updatePhoneNumber(phoneNumber: string) {
      this.phoneNumber = phoneNumber
      this.phoneNumberToUpdate = true
    },
    addCondition(widgetId: string) {
      const conditions = this.conditions || []
      const max = Math.max(...conditions.map((item) => parseInt(item.id)))

      conditions.push({
        id: `${max + 1}`,
        route: {
          id: 'new',
          type: RouteType['AGENTQUEUE'],
          value: widgetId,
        },
        expression: ConditionExpression.ANY,
        value: '',
        variable: '',
      })
      this.conditions = conditions
      this.updateConditionalFlag(true)
    },
    deleteCondition(
      conditionId: string | number,
      query?: (id?: number) => void,
    ) {
      if (query) {
        query(parseInt(`${conditionId}`))
      }
      const conditions = this.conditions
      const filteredConditions = conditions.filter(
        (condition) => condition.id !== conditionId,
      )
      this.conditions = filteredConditions
    },
    setConditionalExpression(
      conditionId: string,
      expression: ConditionExpression,
    ) {
      const foundCondition = this.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.expression = expression
      filteredConditions.push(foundCondition!)
      this.conditions = filteredConditions
      this.conditionsToUpdate = true
    },
    changeExpressionCriteria(conditionId: string, value: string) {
      const foundCondition = this.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.value = value
      filteredConditions.push(foundCondition!)
      this.conditions = filteredConditions
      this.conditionsToUpdate = true
    },
    formattingConditionalsForUpdating(): conditionsReturnType[] {
      const conditions = this.conditions
      const conditionsToUpdate: conditionsReturnType[] = []
      conditions.forEach((condition) => {
        conditionsToUpdate.push({
          expression: condition.expression,
          expressionValue: condition.value,
          routeType: RouteType[condition.route.type],
          routeValue:
            condition.expression === ConditionExpression.MATCHESTAG
              ? undefined
              : String(condition.route.value),
        })
      })
      return conditionsToUpdate
    },
    setPosition(position: PositionPreference) {
      this.position = position
      this.webWidgetOptionsToUpdate = true
    },
    setAutoDisplay(value: boolean) {
      this.autoDisplay = value
      this.webWidgetOptionsToUpdate = true
    },
    setAutoDisplayDelay(value: number) {
      this.autoDisplayDelay = value
      this.webWidgetOptionsToUpdate = true
    },
    setHideIfOffline(value: boolean) {
      this.hideIfOffline = value
      this.webWidgetOptionsToUpdate = true
    },
    setWebWidgetUpdateFlag(value: boolean) {
      this.webWidgetOptionsToUpdate = value
    },
  })
}
