import React, { useEffect, useState, useContext } from 'react'
import * as styled from './Transfer.styled'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { ThemeProvider } from '@material-ui/core/styles'
import { transferTabBar } from 'ui/theme/ManagementView'
import TabPanel from 'ui/components/shared/TabPanel'
import { useQuery } from 'models/reactUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { StoreContext } from 'models/reactUtils'

interface routeTagType {
  id: string
  name: string
}

interface routeTagsType {
  agent: string
  agentId: string
  routeTag: routeTagType
  routeTagId: string
}

interface agentDataType {
  id: string
  alias: string
  online: boolean
  routeTags?: routeTagsType[]
}

const TransferLoader = () => {
  return (
    <styled.LoadingContainer>
      <CircularProgress
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        color="primary"
      />
    </styled.LoadingContainer>
  )
}

const TabContent = ({ children }: { children?: React.ReactElement }) => {
  return <styled.TabContent>{children}</styled.TabContent>
}

interface transferButtonProps {
  id: string
  chatId: string
  type: string
  setQuery: any
}

const TransferButton = ({
  id,
  chatId,
  type,
  setQuery,
}: transferButtonProps) => {
  const store = useContext(StoreContext)
  const handleClick = () => {
    switch (type) {
      case 'agents':
        setQuery(
          store.mutateTransferToAgent(
            {
              input: { chatId, agentId: parseInt(id) },
            },
            (data) => data.id.activeAgentAlias,
          ),
        )
        store.setTransferVisibility()
        break
      case 'widgets':
        setQuery(
          store.mutateTransferToWidget(
            {
              input: { chatId, widgetId: id },
            },
            (data) => data.id.currentWidget((widget) => widget.id.name),
          ),
        )
        store.setTransferVisibility()
        break
      case 'tags':
        setQuery(
          store.mutateTransferToTag(
            {
              input: { chatId, tagId: parseInt(id) },
            },
            (data) => data.id,
          ),
        )
        store.setTransferVisibility()
        break
    }
  }

  return (
    <Button
      onClick={handleClick}
      style={{
        color: 'white',
        backgroundColor: 'rgba(90, 90, 90, 1)',
        textTransform: 'uppercase',
        marginRight: 10,
        marginTop: 8,
      }}
    >
      Transfer
      <FontAwesomeIcon size="1x" icon={faBus} style={{ marginLeft: 5 }} />
    </Button>
  )
}

interface transferProps {
  chatId: string
}

const Transfer = observer(({ chatId }: transferProps) => {
  const [tab, setTab] = useState('widgets')
  const { loading, data, setQuery, store } = useQuery()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue)
  }

  const getDistinctTags = (data: agentDataType[]) => {
    const tags: { id: string; name: string }[] = []
    data.forEach((agent: agentDataType) => {
      agent!.routeTags &&
        agent!.routeTags!.length > 0 &&
        agent!.routeTags &&
        agent.routeTags!.forEach((routeTag) => {
          if (
            !tags.find((storedTag) => storedTag.id === routeTag.routeTag.id)
          ) {
            tags.push({
              id: routeTag.routeTag.id,
              name: routeTag.routeTag.name,
            })
          }
        })
    })
    return tags
  }

  useEffect(() => {
    if (tab === 'widgets') {
      console.log('get available widgets')
      setQuery(
        store.queryAvailableWidgetTransfers({ chatId }, (data) => data.id.name),
      )
    }
    if (tab === 'agents' || tab === 'tags') {
      console.log('get available agents and tags')
      setQuery(
        store.queryAvailableAgentTransfers({ chatId }, (data) =>
          data.alias.id.routeTags((routeTag) =>
            routeTag.routeTag((tag) => tag.id.name),
          ),
        ),
      )
    }
  }, [tab, store, chatId, setQuery])

  return (
    <styled.Container>
      <Paper
        elevation={3}
        style={{
          backgroundColor: '#424242',
        }}
        square
      >
        <ThemeProvider theme={transferTabBar}>
          <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              style={{ color: 'rgba(255, 255, 255, 0.7)' }}
              label="Widgets"
              value="widgets"
            />
            <Tab
              value="agents"
              style={{ color: 'rgba(255, 255, 255, 0.7)' }}
              label="Agents"
            />
            <Tab
              value="tags"
              style={{ color: 'rgba(255, 255, 255, 0.7)' }}
              label="Tags"
            />
          </Tabs>
          <TabPanel value={tab} index="widgets">
            {tab === 'widgets' && (
              <TabContent>
                {loading ? (
                  <TransferLoader />
                ) : (
                  <>
                    {(data as any)?.availableWidgetTransfers?.map(
                      (widget: { id: string; name: string }) => (
                        <styled.ItemRow key={`widget_${widget.id}`}>
                          <styled.ItemName>{widget.name}</styled.ItemName>
                          <styled.ItemButton>
                            <TransferButton
                              id={widget.id}
                              setQuery={setQuery}
                              type="widgets"
                              chatId={chatId}
                            />
                          </styled.ItemButton>
                        </styled.ItemRow>
                      ),
                    )}
                  </>
                )}
              </TabContent>
            )}
          </TabPanel>
          <TabPanel value={tab} index="agents">
            {tab === 'agents' && (
              <TabContent>
                {loading ? (
                  <TransferLoader />
                ) : (
                  <>
                    {(data as any)?.availableAgentTransfers?.map(
                      (agent: {
                        id: string
                        alias: string
                        online: boolean
                      }) => (
                        <styled.ItemRow key={`agent_${agent.id}`}>
                          <styled.ItemName>{agent.alias}</styled.ItemName>
                          <styled.ItemButton>
                            <TransferButton
                              id={agent.id}
                              setQuery={setQuery}
                              type="agents"
                              chatId={chatId}
                            />
                          </styled.ItemButton>
                        </styled.ItemRow>
                      ),
                    )}
                  </>
                )}
              </TabContent>
            )}
          </TabPanel>
          <TabPanel value={tab} index="tags">
            {tab === 'tags' && (
              <TabContent>
                {loading ? (
                  <TransferLoader />
                ) : (
                  <>
                    {(data as any)?.availableAgentTransfers &&
                      getDistinctTags(
                        (data as any)?.availableAgentTransfers,
                      ).map((tag: { id: string; name: string }) => (
                        <styled.ItemRow key={`tag${tag.id}`}>
                          <styled.ItemName>{tag.name}</styled.ItemName>
                          <styled.ItemButton>
                            <TransferButton
                              id={tag.id}
                              setQuery={setQuery}
                              type="tags"
                              chatId={chatId}
                            />
                          </styled.ItemButton>
                        </styled.ItemRow>
                      ))}
                  </>
                )}
              </TabContent>
            )}
          </TabPanel>
        </ThemeProvider>
      </Paper>
    </styled.Container>
  )
})

export default Transfer
