import styled from 'styled-components'

interface ActivityBarContainerProps {
  week?: boolean
  axis?: boolean
}

export const ActivityBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${(props: ActivityBarContainerProps) =>
    props.axis ? 0 : props.week ? 2 : 10}px;
`

interface ActivityTicProps {
  backGroundColor: string
  week?: boolean
  axis?: boolean
  finalPosition?: boolean
}

export const ActivityTic = styled.div`
  height: ${(props: ActivityTicProps) => (props.week || props.axis ? 9 : 45)}px;
  max-width: 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: ${(props: ActivityTicProps) =>
    props.finalPosition ? 'flex-end' : 'center'};
  white-space: nowrap;
  font-size: 8px;
  overflow: visible;
  background-color: ${(props: ActivityTicProps) => props.backGroundColor};
  flex-grow: 1;
`

export const Day = styled.div`
  width: 26px;
  font-size: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ActivityBarOuterContainer = styled.div`
  display: flex;
  flex-direction: row;
`
