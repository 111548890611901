/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ShortcutPreference {
  BUBBLEBAR="BUBBLEBAR",
SLASHCOMMANDANDSEARCH="SLASHCOMMANDANDSEARCH",
BOTH="BOTH"
}

/**
* ShortcutPreference
*/
export const ShortcutPreferenceEnumType = types.enumeration("ShortcutPreference", [
        "BUBBLEBAR",
  "SLASHCOMMANDANDSEARCH",
  "BOTH",
      ])
