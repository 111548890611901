import { Instance } from 'mobx-state-tree'
import { OrganizationStatsDayBreakdownModelBase } from './OrganizationStatsDayBreakdownModel.base'
import { types } from 'mobx-state-tree'

/* The TypeScript type of an instance of OrganizationStatsDayBreakdownModel */
export interface OrganizationStatsDayBreakdownModelType
  extends Instance<typeof OrganizationStatsDayBreakdownModel.Type> {}

/* A graphql query fragment builders for OrganizationStatsDayBreakdownModel */
export {
  selectFromOrganizationStatsDayBreakdown,
  organizationStatsDayBreakdownModelPrimitives,
  OrganizationStatsDayBreakdownModelSelector,
} from './OrganizationStatsDayBreakdownModel.base'

/**
 * OrganizationStatsDayBreakdownModel
 */
export const OrganizationStatsDayBreakdownModel = OrganizationStatsDayBreakdownModelBase.props(
  {
    widgetId: types.string,
  },
)
