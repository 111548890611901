import React from 'react'
import * as styled from './QuestionnaireSelect.styled'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { PreChatQuestionnaireModelType } from 'models'

interface QuestionnaireSelectProps {
  questionnaireData?: PreChatQuestionnaireModelType[]
  helperText?: string
  value: string
  defaultRoute: boolean
  conditionId?: string
  changeValue: (
    defaultRoute: boolean,
    questionnaireId: string,
    conditionId?: string,
  ) => void
}

const QuestionnaireSelect = ({
  questionnaireData,
  helperText,
  value,
  defaultRoute,
  conditionId,
  changeValue,
}: QuestionnaireSelectProps) => {
  return (
    <>
      {questionnaireData && questionnaireData.length ? (
        <FormControl
          style={{ width: '100%' }}
          variant="filled"
          error={helperText && !value ? true : false}
        >
          <InputLabel id="conditionalExpressionLabel">
            Select a Questionnaire:
          </InputLabel>
          <Select
            labelId="conditionalExpressionLabel"
            id="conditionalExpression"
            value={value}
            placeholder="Select a Questionnaire:"
            onChange={(event) =>
              changeValue(
                defaultRoute,
                event.target.value as string,
                conditionId,
              )
            }
          >
            <MenuItem value="">
              <em>Select one the available questionnaires below:</em>
            </MenuItem>
            {questionnaireData.map((questionnaire) => {
              return (
                <MenuItem
                  key={`menu_item_questionnaire_${questionnaire.id}`}
                  value={questionnaire.id}
                >
                  {questionnaire.name}
                </MenuItem>
              )
            })}
          </Select>
          {helperText && !value && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      ) : (
        <styled.ZeroQuestionnairesWarning>
          There are no questionnaires to select from, please create one.
        </styled.ZeroQuestionnairesWarning>
      )}
    </>
  )
}

export default QuestionnaireSelect
