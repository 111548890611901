import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import * as styled from './ActivityBar.styled'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import subMinutes from 'date-fns/subMinutes'
import { MaxChatValue } from 'models'
import { toJS } from 'mobx'

interface ActivityBarDataPoint {
  key: string
  value: number
}

interface ActivityBarProps {
  data: ActivityBarDataPoint[]
  total: number
  maxChatValue?: MaxChatValue
  week: boolean
  printDate: string
  daysAgo: number
}

// 210

const ActivityBar = ({
  data,
  total,
  maxChatValue,
  week,
  printDate,
  daysAgo,
}: ActivityBarProps) => {
  // const now = new Date()
  const periodLengthInMins = 30
  // const nearest30Mins = roundToNearestMinutes(now, {
  //   nearestTo: periodLengthInMins,
  // })

  var interval = periodLengthInMins * 60 * 1000 // 30 minutes in milliseconds
  let next30Minutes = new Date(
    Math.ceil(new Date().getTime() / interval) * interval,
  )
  next30Minutes.setDate(next30Minutes.getDate() - daysAgo)
  const rows = Array(48).fill(0)

  return (
    <styled.ActivityBarOuterContainer>
      <styled.Day>{printDate}</styled.Day>
      <styled.ActivityBarContainer week={week}>
        {rows
          .map((row, i) => {
            const timeKey = subMinutes(next30Minutes, periodLengthInMins * i)
            const dataPoint =
              data.find((dataPoint) => {
                return new Date(dataPoint.key).getTime() === timeKey.getTime()
              })?.value || row
            return (
              <Tooltip
                title={`${dataPoint} chats | ${timeKey.toLocaleString()}`}
                placement="top"
              >
                <styled.ActivityTic
                  week={week}
                  key={`timeKey_${timeKey}`}
                  backGroundColor={
                    dataPoint > 0
                      ? `rgba(134, 202, 206, ${
                          (dataPoint / (maxChatValue ? maxChatValue.max : 1)) *
                          1
                        })`
                      : 'rgb(230, 230, 230)'
                  }
                />
              </Tooltip>
            )
          })
          .reverse()}
      </styled.ActivityBarContainer>
    </styled.ActivityBarOuterContainer>
  )
}

export default ActivityBar
