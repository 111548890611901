import { useEffect, useState, useCallback } from 'react'
import getCaretCoordinates from 'textarea-caret'
import { shortcutsType } from 'ui/components/Chat/ChatInput'
import { ChatModelType } from 'models'

const useShortcutAutoSuggest = (
  inputRef: any,
  endShortcutSuggestAndRemoveSlash: any,
  shortcutAutoSuggest: boolean,
  arrowShortcutSelection: null | number,
  setArrowShortcutSelection: any,
  lengthOfFilteredShortcuts: number | null,
  chat: ChatModelType,
  setSelectedWithEnter: (value: boolean) => void,
  setShortcutAutoSuggest: (value: boolean) => void,
  selectedWithEnter: boolean,
  filteredShortcuts?: shortcutsType,
  shortcutCommandSlash?: boolean,
) => {
  const [left, setLeft] = useState<number>(0)
  const [top, setTop] = useState<number>(0)

  const handleKey = useCallback(
    (event: KeyboardEvent) => {
      const setCoordinate = () => {
        try {
          const cursor = getCaretCoordinates(
            inputRef?.current,
            inputRef?.current?.selectionEnd,
          )
          setLeft(cursor.left)
          setTop(cursor.top)
        } catch (error) {
          // console.log(error)
        }
      }

      if (
        shortcutAutoSuggest &&
        (arrowShortcutSelection || arrowShortcutSelection === 0) &&
        event.key === 'Enter' &&
        !selectedWithEnter
      ) {
        event.preventDefault()
        setSelectedWithEnter(true)
      }

      if (shortcutAutoSuggest && left === 0 && top === 0) {
        setCoordinate()
        return
      }

      if (
        event.key === '/' &&
        !shortcutAutoSuggest &&
        !chat?.store.shortcutSearchVisibility
      ) {
        endShortcutSuggestAndRemoveSlash(true)
        return
      }

      if (event.key === 'Escape' && shortcutAutoSuggest) {
        endShortcutSuggestAndRemoveSlash(false)
        setLeft(0)
        setTop(0)
        return
      }

      if (event.key === 'Tab' && shortcutAutoSuggest) {
        event.preventDefault()
        endShortcutSuggestAndRemoveSlash(false)
      }

      if (event.key === ' ' && shortcutAutoSuggest) {
        setShortcutAutoSuggest(false)
        setArrowShortcutSelection(null)
        setLeft(0)
        setTop(0)
      }

      if (
        event.key !== ' ' &&
        event.key !== 'Tab' &&
        event.key !== 'ArrowUp' &&
        event.key !== 'ArrowDown'
      ) {
        setArrowShortcutSelection(null)
      }

      if (event.key === 'ArrowUp' && shortcutAutoSuggest) {
        const lengthOfFilteredShortcutsOrMin = Math.min(
          lengthOfFilteredShortcuts! - 1,
          4,
        )
        event.preventDefault()
        if (!arrowShortcutSelection && arrowShortcutSelection !== 0) {
          setArrowShortcutSelection(0)
        } else if (arrowShortcutSelection < lengthOfFilteredShortcutsOrMin) {
          setArrowShortcutSelection(arrowShortcutSelection + 1)
        } else if (arrowShortcutSelection === lengthOfFilteredShortcutsOrMin) {
          setArrowShortcutSelection(0)
        }
      }

      if (event.key === 'ArrowDown' && shortcutAutoSuggest) {
        const lengthOfFilteredShortcutsOrMin = Math.min(
          lengthOfFilteredShortcuts! - 1,
          4,
        )
        event.preventDefault()
        if (!arrowShortcutSelection) {
          setArrowShortcutSelection(lengthOfFilteredShortcutsOrMin)
        } else if (arrowShortcutSelection > 0) {
          setArrowShortcutSelection(arrowShortcutSelection - 1)
        } else if (arrowShortcutSelection === 0) {
          setArrowShortcutSelection(lengthOfFilteredShortcutsOrMin)
        }
      }
    },
    [
      shortcutAutoSuggest,
      endShortcutSuggestAndRemoveSlash,
      inputRef,
      left,
      top,
      arrowShortcutSelection,
      setArrowShortcutSelection,
      lengthOfFilteredShortcuts,
      chat,
      setSelectedWithEnter,
      selectedWithEnter,
      filteredShortcuts,
    ],
  )

  useEffect(() => {
    if (shortcutCommandSlash) {
      document.addEventListener('keydown', handleKey, false)
      document.addEventListener('keypress', handleKey, false)
      return () => {
        document.removeEventListener('keydown', handleKey, false)
        document.addEventListener('keypress', handleKey, false)
      }
    }
  }, [handleKey, shortcutCommandSlash])

  return { left, top }
}

export default useShortcutAutoSuggest
