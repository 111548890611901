import React, { useState, useContext } from 'react'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ActionContext, { modifyActionContext } from 'context/actionContext'
import * as styled from './AddAdminButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'
import ModifyApiKeysModal from 'ui/components/Modals/ModifyApiKeys'

const ModifyApiKeysButton = ({
  organizationId,
}: {
  organizationId?: string
}) => {
  const [modalVisibility, setModalVisibility] = useState(false)

  const actionContext = useContext(ActionContext)
  modifyActionContext(
    actionContext,
    'agentModalVisibilityFunction',
    setModalVisibility,
  )
  return (
    <styled.MenuItemContainer onClick={() => setModalVisibility(true)}>
      <div>
        <FontAwesomeIcon icon={faUserLock} />
      </div>
      <div>Modify Keys</div>
      <ModifyApiKeysModal
        organizationId={organizationId!}
        visibility={modalVisibility}
        openFunction={setModalVisibility}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageOrganization'], ModifyApiKeysButton)
