import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import * as styled from './index.styled'
import ThemeContext from 'context/themeContext'
import ActionContext from 'context/actionContext'
import themeObject from 'ui/theme/CustomerView'
import { theme } from 'types/style.types'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ChatInput from './ChatInput'
import MessageWindow from './MessageWindow'
import { ChatModelType, MessageSender } from 'models'
import { useQuery } from 'models/reactUtils'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import { materialTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import ShortcutSearch from './ShortcutSearch'
import { ShortcutPreference, PostChatQuestionnaireModelType } from 'models'
import Transfer from 'ui/components/Chat/Transfer'
import PostChatQuestionnaire from 'ui/components/Chat/PostChatQuestionnaire'

interface ChatProps {
  chat?: ChatModelType | null
  sendMessage: (body: string) => void
  currentSenderTypes: MessageSender[]
  agent?: boolean
  endChat?: (id: string) => void
  theme?: any
  themePreview?: boolean
  title?: string | null
  userDeviceBanned?: boolean
  enablePostChatQuestionnaire?: boolean
  postChatQuestionnaire?: PostChatQuestionnaireModelType
}

const Chat = observer(
  ({
    chat,
    sendMessage,
    currentSenderTypes,
    agent,
    endChat,
    theme,
    themePreview,
    title,
    userDeviceBanned,
    enablePostChatQuestionnaire,
    postChatQuestionnaire,
  }: ChatProps) => {
    const themeContext = useContext(ThemeContext)
    const themeFromContext: theme =
      themePreview || theme
        ? theme
          ? JSON.parse(theme.attributes)
          : themeObject[themeContext.name]
        : chat &&
          chat!.originationWidget &&
          chat!.originationWidget!.selectedTheme &&
          chat!.originationWidget!.selectedTheme!.attributes
        ? JSON.parse(chat!.originationWidget!.selectedTheme!.attributes)
        : themeObject[themeContext.name]
    const actionContext = useContext(ActionContext)
    const [shortcutBarHover, setShortcutBarHover] = useState(false)
    const [showQuestionnaire, setShowQuestionnaire] = useState(false)
    const [chatStarted, setChatStarted] = useState(false)

    const shortcutContainerRef: any = useRef()
    const shortcutContentRef: any = useRef()

    const { data, store } = useQuery((store) =>
      store.queryWidgets({}, (data) =>
        data.shortcuts((shortcut) => shortcut.id.name.value),
      ),
    )

    const chatInputRef: any = useRef()
    const appendShortcut = (
      shortcutValue: string,
      inlineShortcut?: boolean,
    ) => {
      chat!.appendShortcut(shortcutValue, true)
      chatInputRef!.current!.focus()
    }

    let shortcuts: any = []
    type shortcutType = {
      id: number
      name: string
      value: string
      widgetId: string
    }

    type shortcutsType = shortcutType[]
    if (!chat?.completed && agent && data) {
      shortcuts = data?.widgets.find(
        (widget) => widget.id === chat?.originationWidget.id,
      )?.shortcuts
    }

    let shortcutBubbleBar = false
    let shortcutCommandSlash = false

    if (
      store.retrievedUser?.shortcutPreference ===
        ShortcutPreference.BUBBLEBAR ||
      store.retrievedUser?.shortcutPreference === ShortcutPreference.BOTH
    ) {
      shortcutBubbleBar = true
    }

    if (
      store.retrievedUser?.shortcutPreference ===
        ShortcutPreference.SLASHCOMMANDANDSEARCH ||
      store.retrievedUser?.shortcutPreference === ShortcutPreference.BOTH
    ) {
      shortcutCommandSlash = true
    }

    const selectShortcutFromBar = (
      chat: ChatModelType,
      shortcutValue: string,
    ) => {
      chat!.appendShortcut(shortcutValue, false)
      chatInputRef!.current!.focus()
    }

    useEffect(() => {
      if (chat && !chat?.completed) {
        setChatStarted(true)
      }
    }, [chat, chat?.completed, setChatStarted])

    useEffect(() => {
      if (
        chatStarted &&
        enablePostChatQuestionnaire &&
        !!postChatQuestionnaire &&
        chat?.completed &&
        !agent &&
        actionContext.role === 'user'
      ) {
        setShowQuestionnaire(true)
      }
    }, [
      chat?.completed,
      agent,
      actionContext.role,
      setShowQuestionnaire,
      chatStarted,
    ])

    const completeQuestionnaireMiddleware = useCallback(() => {
      setShowQuestionnaire(false)
      setChatStarted(false)
      actionContext.changeChatVisibility!(!actionContext.chatVisibility)
    }, [setShowQuestionnaire, setChatStarted])

    return (
      <styled.Container theme={themeFromContext}>
        <layout.ContainerColumn width="100%" height="100%">
          {!showQuestionnaire && actionContext.role === 'user' && (
            <styled.Header
              alignItems="center"
              width="100%"
              height="52px"
              minHeight="52px"
              theme={themeFromContext}
              // @ts-ignore
              highlightedTheme={store.themeColorHighlightHover}
            >
              {!themePreview && (
                <styled.Exit
                  onClick={() =>
                    actionContext.changeChatVisibility!(
                      !actionContext.chatVisibility,
                    )
                  }
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </styled.Exit>
              )}

              <styled.ConfiguredTitle theme={themeFromContext}>
                {title
                  ? title
                  : chat &&
                    chat?.originationWidget?.title &&
                    chat?.originationWidget?.title?.length > 0
                  ? chat?.originationWidget.title
                  : 'Curbside Pickup'}
              </styled.ConfiguredTitle>
            </styled.Header>
          )}

          {!themePreview && showQuestionnaire && (
            <PostChatQuestionnaire
              postChatQuestionnaire={postChatQuestionnaire}
              themeFromContext={themeFromContext}
              chatId={chat?.id}
              completeQuestionnaireMiddleware={completeQuestionnaireMiddleware}
            />
          )}

          {!showQuestionnaire && (
            <>
              <MessageWindow
                typing={chat ? chat.typing : {}}
                currentSenderTypes={currentSenderTypes}
                agent={agent}
                theme={theme}
                themePreview={themePreview}
                endChat={endChat}
                chatId={chat ? chat.id : null}
                userDeviceBanned={userDeviceBanned}
              />
              {chat?.store.shortcutSearchVisibility &&
                !chat.completed &&
                agent &&
                shortcutCommandSlash && (
                  <ShortcutSearch shortcuts={shortcuts} chat={chat} />
                )}
              {store.transferVisibility && <Transfer chatId={chat!.id} />}
              {chat && !chat.completed && (
                <>
                  {agent && shortcutBubbleBar && (
                    <styled.Toolbar
                      ref={shortcutContainerRef}
                      onMouseEnter={() => setShortcutBarHover(true)}
                      onMouseLeave={() => setShortcutBarHover(false)}
                      scrollbar={
                        shortcutBarHover &&
                        shortcutContainerRef?.current?.getBoundingClientRect()
                          .width <
                          shortcutContentRef?.current?.getBoundingClientRect()
                            .width
                      }
                    >
                      <ThemeProvider theme={materialTheme}>
                        <AppBar
                          style={{
                            height: '100%',
                            width: 'auto',
                            minWidth: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                          }}
                          position="static"
                        >
                          <styled.ShortcutContainer ref={shortcutContentRef}>
                            <styled.ShortcutLabel>
                              Shortcuts:
                            </styled.ShortcutLabel>
                            {data &&
                              data!.widgets &&
                              shortcuts &&
                              orderBy(
                                shortcuts,
                                [(shortcut) => shortcut.name.toLowerCase()],
                                'asc',
                              ).map((shortcut: any) => {
                                return (
                                  <styled.Shortcut key={shortcut.id}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{
                                        marginRight: 6,
                                        marginLeft: 6,
                                      }}
                                      onClick={() =>
                                        selectShortcutFromBar(
                                          chat!,
                                          shortcut.value,
                                        )
                                      }
                                    >
                                      {shortcut.name}
                                    </Button>
                                  </styled.Shortcut>
                                )
                              })}
                          </styled.ShortcutContainer>
                        </AppBar>
                      </ThemeProvider>
                    </styled.Toolbar>
                  )}
                  <styled.ChatInputContainer>
                    <ChatInput
                      sendMessage={sendMessage}
                      chat={chat}
                      agent={agent}
                      endChat={endChat}
                      inputRef={chatInputRef}
                      theme={theme}
                      themePreview={themePreview}
                      shortcuts={shortcuts}
                      shortcutCommandSlash={shortcutCommandSlash}
                    />
                  </styled.ChatInputContainer>
                </>
              )}
            </>
          )}
        </layout.ContainerColumn>
      </styled.Container>
    )
  },
)

export default Chat
