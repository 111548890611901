import styled from 'styled-components'

interface LoadingContainerProps {
  tabPanel?: boolean
}

export const LoadingContainer = styled.div`
  width: 100%;
  height: ${(props: LoadingContainerProps) =>
    props.tabPanel ? '500px' : '100vh'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 1);
`

export const LogoContainer = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-content: center;
  animation: longPulse 2s linear infinite;
  animation-delay: 0.5s;

  @keyframes longPulse {
    0% {
      transform: scale(1);
    }
    4% {
      transform: scale(0.8);
    }
    12% {
      transform: scale(1.15);
    }
    16% {
      transform: scale(1);
    }
  }
`

export const Text = styled.div`
  margin-top: -20px;
  font-size: 16px;
`

export const IconText = styled.div`
  margin-top: -12px;
  margin-left: -8px;
  transform: rotate(5deg);
`

export const IconWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const TextWithinIcon = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  font-size: 30px;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`
