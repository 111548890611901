import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observer } from 'mobx-react'
import * as styled from './index.styled'
import { WidgetModelType } from 'models'
import { circleProgressTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const WidgetIconImageUploadModal = observer(
  ({
    visibility,
    openFunction,
    widget,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    widget: WidgetModelType
  }) => {
    const [uploading, setUploading] = useState(false)
    const [fileName, setFileName] = useState('')
    const [file, setFile] = useState(false)
    let { orgSlug } = useParams<Record<string, string | undefined>>()

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    const uploadImage = (event: any) => {
      setFile(true)
      setFileName(event.target.value)
      setUploading(true)
      widget.uploadIconImage(event.target.files[0], orgSlug!, openFunction)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Upload Widget Icon Image
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click the gray area below in order to select an image to upload to
            replace either the default icon or an already selected image icon.
          </DialogContentText>
          <styled.UploadDialogueContent>
            <styled.DropZone>
              <input
                accept="image/*"
                style={{ display: 'none', height: '100%', width: '100%' }}
                id="image-button-file"
                onChange={uploadImage}
                type="file"
              />
              <label
                htmlFor="image-button-file"
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <styled.InnerDropZone>
                  {uploading && (
                    <ThemeProvider theme={circleProgressTheme}>
                      <CircularProgress
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        color="primary"
                      />
                    </ThemeProvider>
                  )}
                  {!file ? (
                    <>
                      Click here in order to select a file
                      <br /> for uploading as your widget icon.
                    </>
                  ) : (
                    <>
                      {file && uploading && (
                        <>
                          Uploading. Please Wait...
                          <styled.FileName>
                            File to be uploaded:{' '}
                            {fileName.replace(/.*[\/\\]/, '')}
                          </styled.FileName>
                        </>
                      )}
                    </>
                  )}
                </styled.InnerDropZone>
              </label>
            </styled.DropZone>
          </styled.UploadDialogueContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default WidgetIconImageUploadModal
