/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ConditionExpression {
  ANY="ANY",
EQUALS="EQUALS",
MATCHESTAG="MATCHESTAG"
}

/**
* ConditionExpression
*/
export const ConditionExpressionEnumType = types.enumeration("ConditionExpression", [
        "ANY",
  "EQUALS",
  "MATCHESTAG",
      ])
