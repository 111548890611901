import React from 'react'
import TextField from '@material-ui/core/TextField'

interface GreetingMessageProps {
  value?: string | null
  changeValue: (value: string) => void
}

const GreetingMessage = ({ value, changeValue }: GreetingMessageProps) => {
  return (
    <TextField
      label={'Greeting Message'}
      variant="filled"
      placeholder="Greeting Message"
      value={value ? value : ''}
      style={{ width: '100%' }}
      rows={3}
      multiline
      rowsMax={4}
      onChange={(e) => changeValue(e.target.value)}
    />
  )
}

export default GreetingMessage
