/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PreChatQuestionModel, PreChatQuestionModelType } from "./PreChatQuestionModel"
import { PreChatQuestionModelSelector } from "./PreChatQuestionModel.base"
import { PreChatQuestionnaireRouteConditionModel, PreChatQuestionnaireRouteConditionModelType } from "./PreChatQuestionnaireRouteConditionModel"
import { PreChatQuestionnaireRouteConditionModelSelector } from "./PreChatQuestionnaireRouteConditionModel.base"
import { WidgetModel, WidgetModelType } from "./WidgetModel"
import { WidgetModelSelector } from "./WidgetModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  widget: WidgetModelType;
}

/**
 * PreChatQuestionnaireBase
 * auto generated base class for the model PreChatQuestionnaireModel.
 */
export const PreChatQuestionnaireModelBase = withTypedRefs<Refs>()(ModelBase
  .named('PreChatQuestionnaire')
  .props({
    __typename: types.optional(types.literal("PreChatQuestionnaire"), "PreChatQuestionnaire"),
    agentMessage: types.union(types.undefined, types.null, types.string),
    completionMessage: types.union(types.undefined, types.null, types.string),
    conditions: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => PreChatQuestionnaireRouteConditionModel)))),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    questions: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => PreChatQuestionModel)))),
    widget: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => WidgetModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class PreChatQuestionnaireModelSelector extends QueryBuilder {
  get agentMessage() { return this.__attr(`agentMessage`) }
  get completionMessage() { return this.__attr(`completionMessage`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  conditions(builder?: string | PreChatQuestionnaireRouteConditionModelSelector | ((selector: PreChatQuestionnaireRouteConditionModelSelector) => PreChatQuestionnaireRouteConditionModelSelector)) { return this.__child(`conditions`, PreChatQuestionnaireRouteConditionModelSelector, builder) }
  questions(builder?: string | PreChatQuestionModelSelector | ((selector: PreChatQuestionModelSelector) => PreChatQuestionModelSelector)) { return this.__child(`questions`, PreChatQuestionModelSelector, builder) }
  widget(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`widget`, WidgetModelSelector, builder) }
}
export function selectFromPreChatQuestionnaire() {
  return new PreChatQuestionnaireModelSelector()
}

export const preChatQuestionnaireModelPrimitives = selectFromPreChatQuestionnaire().agentMessage.completionMessage.name
