import React, { useState, useEffect } from 'react'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import * as styled from './index.styled'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView/index'
import Menu from 'ui/components/ManagementView/Menu'
import Organizations from 'ui/components/ManagementView/TabPanels/Org/Orgs'
import Widgets from 'ui/components/ManagementView/TabPanels/Org/Widgets'
import ChatLogs from 'ui/components/ManagementView/TabPanels/Org/ChatLogs'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import HeaderIcon from 'ui/components/ManagementView/HeaderIcon'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { materialTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import TabPanel from 'ui/components/shared/TabPanel'
import GoToHub from 'ui/components/shared/GoToHub'
import HeaderMenu from 'ui/components/shared/HeaderMenu'
import Reports from 'ui/components/ManagementView/TabPanels/Org/Reports'
import { useQueryParams } from 'utilities/useQueryParams'
import WithSpaces from 'ui/components/shared/WithSpaces'
import Loading from 'ui/components/shared/Loading'

const tabs = ['organizations', 'widgets', 'reports', 'chatLogs']

const OrganizationWidgetAndChatLogView = observer(() => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const tab = queryParams.get('tab')
  const [activeTab, setActiveTab] = useState(
    tab && tabs.includes(tab) ? tab : 'organizations',
  )
  const [themeName, setThemeName] = useState('california')
  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  const handleTabBarChange = (event: React.ChangeEvent<{}>, value: string) => {
    setActiveTab(value)
    history.push(`/org/${orgSlug}?tab=${value}`)
  }

  const { orgSlug } = useParams<Record<string, string | undefined>>()

  const { loading, data, store, setQuery } = useQuery((store) =>
    store.getOrganizationFromSlug(orgSlug!),
  )

  if (loading) {
    return <Loading />
  }

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <layout.NewPageContainer>
        <styled.HeaderContainer theme={themeObject[ThemeProviderValue.name]}>
          <styled.LogoContainer>Unique Chat</styled.LogoContainer>
          <styled.MenuAndAccountContainer>
            <GoToHub />
            <HeaderMenu />
          </styled.MenuAndAccountContainer>
        </styled.HeaderContainer>

        <styled.FunctionContainer theme={themeObject[ThemeProviderValue.name]}>
          <Menu organizationId={data?.organizationFromSlug.id} />
        </styled.FunctionContainer>
        <styled.ContentContainer>
          <styled.ContentTitle>
            Organization - {data!.organizationFromSlug.name}
          </styled.ContentTitle>
          <styled.Content>
            <ThemeProvider theme={materialTheme}>
              <AppBar position="static">
                <Tabs
                  value={activeTab}
                  onChange={handleTabBarChange}
                  aria-label="Select one of these tabs in order to navigate"
                >
                  <Tab value="organizations" label="Organizations" wrapped />
                  <Tab value="widgets" label="Widgets" />
                  <Tab value="reports" label="Reports" />
                  <Tab value="chatLogs" label="Chat Logs" />
                </Tabs>
              </AppBar>
              <TabPanel value={activeTab} index="organizations">
                <Organizations
                  organizations={data!.organizationFromSlug.organizations}
                  space={data!.organizationFromSlug.space}
                />
              </TabPanel>
              <TabPanel value={activeTab} index="widgets">
                <Widgets widgets={data!.organizationFromSlug.widgets} />
              </TabPanel>
              <TabPanel value={activeTab} index="reports">
                <Reports />
              </TabPanel>
              <TabPanel value={activeTab} index="chatLogs">
                {data!.organizationFromSlug.id && activeTab === 'chatLogs' && (
                  <ChatLogs
                    org={data!.organizationFromSlug}
                    widgets={data!.organizationFromSlug.widgets}
                  />
                )}
              </TabPanel>
            </ThemeProvider>
          </styled.Content>
        </styled.ContentContainer>
        <HeaderIcon />
      </layout.NewPageContainer>
    </ThemeContext.Provider>
  )
})

export default WithSpaces(OrganizationWidgetAndChatLogView)
