import React, { useState } from 'react'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WidgetModal from 'ui/components/Modals/WidgetModal'
import * as styled from './CreateWidgetButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'

const CreateWidgetButton = () => {
  const [widgetModalVisibility, setWidgetModalVisibility] = useState(false)

  return (
    <styled.MenuItemContainer onClick={() => setWidgetModalVisibility(true)}>
      <div>
        <FontAwesomeIcon icon={faCogs} />
      </div>
      <div>Create Widget</div>
      <WidgetModal
        visibility={widgetModalVisibility}
        openFunction={setWidgetModalVisibility}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageOrganization'], CreateWidgetButton)
