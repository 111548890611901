import React from 'react'
import * as styled from './index.styled'

interface TextLimitDecoratorProps {
  characterCount: number
  characterLimit: number
  showMessage: boolean
}

const TextLimitDecorator = ({
  characterCount,
  characterLimit,
  showMessage,
}: TextLimitDecoratorProps) => {
  return (
    <styled.TextLimitContainer
      characterCount={characterCount}
      characterLimit={characterLimit}
    >
      {characterCount}/{characterLimit}
    </styled.TextLimitContainer>
  )
}

export default TextLimitDecorator
