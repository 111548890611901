/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MessageSender {
  AGENT="AGENT",
USER="USER",
SYSTEMAGENT="SYSTEMAGENT",
SYSTEMUSER="SYSTEMUSER",
SYSTEM="SYSTEM"
}

/**
* MessageSender
*/
export const MessageSenderEnumType = types.enumeration("MessageSender", [
        "AGENT",
  "USER",
  "SYSTEMAGENT",
  "SYSTEMUSER",
  "SYSTEM",
      ])
