/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * GetApiKeysPayloadBase
 * auto generated base class for the model GetApiKeysPayloadModel.
 */
export const GetApiKeysPayloadModelBase = ModelBase
  .named('GetApiKeysPayload')
  .props({
    __typename: types.optional(types.literal("GetApiKeysPayload"), "GetApiKeysPayload"),
    bandwidthApplicationId: types.union(types.undefined, types.null, types.string),
    plivoApiKeyId: types.union(types.undefined, types.null, types.string),
    plivoApiKeyToken: types.union(types.undefined, types.null, types.string),
    telnyxApiKey: types.union(types.undefined, types.null, types.string),
    twilioApiKeyId: types.union(types.undefined, types.null, types.string),
    twilioApiKeyToken: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GetApiKeysPayloadModelSelector extends QueryBuilder {
  get bandwidthApplicationId() { return this.__attr(`bandwidthApplicationId`) }
  get plivoApiKeyId() { return this.__attr(`plivoApiKeyId`) }
  get plivoApiKeyToken() { return this.__attr(`plivoApiKeyToken`) }
  get telnyxApiKey() { return this.__attr(`telnyxApiKey`) }
  get twilioApiKeyId() { return this.__attr(`twilioApiKeyId`) }
  get twilioApiKeyToken() { return this.__attr(`twilioApiKeyToken`) }
}
export function selectFromGetApiKeysPayload() {
  return new GetApiKeysPayloadModelSelector()
}

export const getApiKeysPayloadModelPrimitives = selectFromGetApiKeysPayload().bandwidthApplicationId.plivoApiKeyId.plivoApiKeyToken.telnyxApiKey.twilioApiKeyId.twilioApiKeyToken
