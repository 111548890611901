import styled from 'styled-components'

export const ShortcutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const ShortcutNameContainer = styled.div`
  width: 200px;
  flex-grow: 0;
  margin-right: 20px;
`

export const ShortcutTextContainer = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`

export const DeleteButtonContainer = styled.div`
  width: 38px;
`
