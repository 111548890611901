import React from 'react'
import * as styled from './ConditionalRoutes.styled'
import Route from './Route'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import QuestionnaireSelect from './QuestionnaireSelect'
import RouteTagSelect from './RouteTagSelect'
import { PreChatQuestionnaireModelType, ConditionExpression } from 'models'
import { RouteType } from 'models/RouteTypeEnum'
import TextField from '@material-ui/core/TextField'

interface Variable {
  id?: number
  name?: string | null
}

interface ConditionalRouteProps {
  questionnaireData?: PreChatQuestionnaireModelType[]
  helperText?: string
  routeTagHelperText?: string
  conditionId: string
  value: string
  changeValue: (
    type: RouteType,
    widgetId: string,
    defaultRoute: boolean,
    conditionId?: string,
  ) => void
  widgetId: string
  defaultRoute: boolean
  questionnaireSelectValue: string
  questionnaireSelectChangeValue: (
    defaultRoute: boolean,
    questionnaireId: string,
    conditionId?: string,
  ) => void
  variables?: Variable[]
  selectedVariable?: number | string | null
  questionnaire?: boolean
  setSelectedVariable: (conditionId: string, questionId: number) => void
  setConditionalExpression: (
    conditionId: string,
    expression: ConditionExpression,
  ) => void
  selectedExpression?: string | null
  expressionCriteria?: string
  changeExpressionCriteria: (conditionId: string, value: string) => void
  source?: string
  routeTags?: any
  changeConditionalRouteTag?: (
    conditionId: string,
    id: number,
    value: string,
  ) => void
  selectedRouteTag?: {
    id: number | React.ReactText
    type: RouteType
    value: string
  }
}

const ConditionalRoute = ({
  questionnaireData,
  helperText,
  routeTagHelperText,
  conditionId,
  value,
  changeValue,
  widgetId,
  defaultRoute,
  questionnaireSelectValue,
  questionnaireSelectChangeValue,
  variables,
  selectedVariable,
  questionnaire,
  setSelectedVariable,
  setConditionalExpression,
  selectedExpression,
  expressionCriteria,
  changeExpressionCriteria,
  source,
  routeTags,
  changeConditionalRouteTag,
  selectedRouteTag,
}: ConditionalRouteProps) => {
  return (
    <styled.ConditionalRouteContainer>
      <styled.ConditionalRouteItem>
        <FormControl style={{ width: '100%' }} variant="filled">
          <InputLabel id="conditionalExpressionLabel">
            Select a Route Variable
          </InputLabel>
          <Select
            labelId="conditionalExpressionLabel"
            id="conditionalExpression"
            value={
              selectedVariable
                ? selectedVariable
                : variables!.find((variable) => variable.name === 'response')
                ? variables!.find((variable) => variable.name === 'response')!
                    .id
                : ConditionExpression.ANY
            }
            disabled={!questionnaire}
            onChange={(event) =>
              setSelectedVariable(conditionId, event.target.value as number)
            }
          >
            <MenuItem value="">
              <em>Select one of the route variables below:</em>
            </MenuItem>
            <MenuItem value={ConditionExpression.ANY}>Any</MenuItem>
            {variables &&
              variables!.map((variable) => {
                return (
                  <MenuItem
                    key={`variable_id_${variable.id}`}
                    value={variable.id || ''}
                  >
                    {variable.name ? variable.name : ''}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </styled.ConditionalRouteItem>

      <styled.ConditionalRouteItem>
        <FormControl style={{ width: '100%' }} variant="filled">
          <InputLabel id="conditionalExpressionLabel">
            Select a Conditional Expression
          </InputLabel>
          <Select
            labelId="conditionalExpressionLabel"
            id="conditionalExpression"
            value={
              !selectedVariable && source !== 'settings'
                ? ConditionExpression.ANY
                : selectedVariable === ConditionExpression.ANY
                ? ConditionExpression.ANY
                : selectedExpression
            }
            disabled={
              (!selectedVariable ||
                selectedVariable === ConditionExpression.ANY) &&
              source !== 'settings'
            }
            onChange={(event) =>
              setConditionalExpression(
                conditionId,
                event.target.value as ConditionExpression,
              )
            }
          >
            <MenuItem value="">
              <em>Select one of conditional expressions below:</em>
            </MenuItem>
            <MenuItem value={ConditionExpression.ANY}>Any</MenuItem>
            <MenuItem value={ConditionExpression.EQUALS}>Equals</MenuItem>
            {selectedVariable && selectedVariable > 0 && (
              <MenuItem value={ConditionExpression.MATCHESTAG}>
                Matches Tag
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </styled.ConditionalRouteItem>

      {selectedExpression !== ConditionExpression.MATCHESTAG && (
        <>
          {selectedExpression === ConditionExpression.EQUALS &&
            !(
              !(
                selectedVariable || selectedVariable === ConditionExpression.ANY
              ) && source !== 'settings'
            ) && (
              <styled.ConditionalRouteItem>
                <TextField
                  label={'Expression Criteria'}
                  variant="filled"
                  placeholder="Expression Criteria"
                  value={expressionCriteria || ''}
                  style={{ width: '100%' }}
                  onChange={(event) =>
                    changeExpressionCriteria(
                      conditionId,
                      event.target.value as string,
                    )
                  }
                />
              </styled.ConditionalRouteItem>
            )}
          <styled.ConditionalRouteItem>
            <Route
              value={value}
              changeRoute={changeValue}
              placeHolder="Conditional Route"
              inputStyle={{ width: '100%' }}
              widgetId={widgetId}
              defaultRoute={defaultRoute}
              conditionId={conditionId}
              showRouteTagOption={
                selectedExpression === ConditionExpression.ANY ||
                selectedExpression === ConditionExpression.EQUALS
              }
            />
          </styled.ConditionalRouteItem>
          {value === RouteType['PRECHATQUESTIONNAIRE'] && (
            <styled.ConditionalRouteItem>
              <QuestionnaireSelect
                questionnaireData={questionnaireData}
                helperText={helperText}
                value={questionnaireSelectValue}
                defaultRoute={defaultRoute}
                conditionId={conditionId}
                changeValue={questionnaireSelectChangeValue}
              />
            </styled.ConditionalRouteItem>
          )}
          {value === RouteType['ROUTETAG'] && (
            <styled.ConditionalRouteItem>
              <RouteTagSelect
                helperText={routeTagHelperText}
                value={selectedRouteTag ? selectedRouteTag!.value : null}
                conditionId={conditionId}
                changeValue={changeConditionalRouteTag}
                routeTags={routeTags}
              />
            </styled.ConditionalRouteItem>
          )}
        </>
      )}
    </styled.ConditionalRouteContainer>
  )
}

export default ConditionalRoute
