import React, { useState } from 'react'
import * as styled from './UserSearch.styled'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { toJS } from 'mobx'
import { useQuery } from 'models/reactUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'
import { circleProgressTheme } from 'ui/theme/ManagementView'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ThemeProvider } from '@material-ui/core/styles'

interface UserSearchDataProps {
  id: string
  email: string
  firstName: string
  lastName: string
}

interface UserSearchProps {
  setEmailOfUserToEdit: (email: string) => void
}

const UserSearch = observer(({ setEmailOfUserToEdit }: UserSearchProps) => {
  const [searchText, setSearchText] = useState('')
  const { loading, data, setQuery, store } = useQuery()

  const searchUsers = () => {
    setQuery(
      store.querySearchUsers(
        { userEmail: searchText },
        (data) => data.id.email.firstName.lastName,
      ),
    )
  }

  const pressedKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const code = event.keyCode || event.which
    if (code === 13) {
      searchUsers()
    }
  }

  return (
    <styled.SearchContainer>
      <styled.SearchInput>
        <TextField
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          placeholder="Enter a users email here to search..."
          onKeyPress={(event) => pressedKey(event)}
          style={{
            width: 265,
          }}
        />
        <Button
          onClick={searchUsers}
          style={{
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            marginLeft: 12,
          }}
        >
          Search
          <FontAwesomeIcon
            size="1x"
            icon={faSearch}
            style={{ marginLeft: 5 }}
          />
        </Button>
      </styled.SearchInput>
      <styled.SearchOutput>
        {(data as any) ? (
          <>
            {(data as any).searchUsers && (data as any).searchUsers.length ? (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Name (If available)</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data as any).searchUsers.map(
                      (user: UserSearchDataProps, i: number) => {
                        let style = {
                          backgroundColor: 'rgba(0, 0, 0, .04)',
                        }
                        if (i % 2) {
                          style = {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                          }
                        }

                        return (
                          <TableRow style={style}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell align="left">{user.email}</TableCell>
                            <TableCell align="left">{`${
                              user.firstName ? user.firstName : ''
                            } ${
                              user.lastName ? user.lastName : ''
                            }`}</TableCell>
                            <TableCell align="left">
                              <Button
                                style={{
                                  color: 'white',
                                  backgroundColor: 'rgba(0, 0, 0, 0.85)',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={() => setEmailOfUserToEdit(user.email)}
                              >
                                Reset Password
                                <FontAwesomeIcon
                                  size="1x"
                                  icon={faEdit}
                                  style={{ marginLeft: 5 }}
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      },
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <styled.SearchMessage>
                There were no results for that email. Please try again.
              </styled.SearchMessage>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <styled.LoaderContainer>
                <ThemeProvider theme={circleProgressTheme}>
                  <CircularProgress
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                    color="primary"
                  />
                </ThemeProvider>
              </styled.LoaderContainer>
            ) : (
              <styled.SearchMessage>
                Enter an email and press enter, or click the button, to search.
              </styled.SearchMessage>
            )}
          </>
        )}
      </styled.SearchOutput>
    </styled.SearchContainer>
  )
})

export default UserSearch
