import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from 'models'
import Favicon from 'react-favicon'

const FaviconStatusAndAlertCount = observer(() => {
  const store = useContext(StoreContext)

  if (store.onlineStatus) {
    return (
      <Favicon
        url="/faviconOnline.ico"
        animated={true}
        alertCount={store.badgeCount}
      />
    )
  } else {
    return <Favicon url="/favicon.ico" />
  }
})

export default FaviconStatusAndAlertCount
