import styled from 'styled-components'

export const Menu = styled.div`
  width: 100%;
  min-width: 225px;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`

export const Icon = styled.div`
  margin-left: 8px;
  margin-right: 4px;
`
