import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme: theme
}

interface StatusProps {
  status: boolean
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  font-family: ${(props: ThemeProps) => props.theme.contentFont.attribute};
  color: ${(props: ThemeProps) => props.theme.agentDashboardTextColor.color};
  padding: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 14px;
  margin-right: -6px;
`

export const StatusBubble = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: ${(props: StatusProps) =>
    props.status ? 'rgba(0, 255, 0, 1)' : 'rgba(230, 57, 69, 1)'};
  margin-left: 4px;
  margin-right: 4px;
`
