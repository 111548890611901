import styled from 'styled-components'

export const ContentPositionReset = styled.div`
  position: relative;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;

  a {
    text-decoration: none;
    color: black;
  }
`

export const ChatLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
  background-color: rgba(231, 239, 230, 1);
  font-family: 'Roboto';
  font-size: 12px;
`

export const LogItem = styled.div`
  display: flex;
  flex-direction: row;
`

export const LogItemTitle = styled.div`
  width: 150px;
  height: 100%;
`

export const LogItemContent = styled.div`
  height: 100%;
`

export const LoadMoreButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  flex-wrap: wrap;
`

export const Subtitle = styled.div`
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
`

interface FilterItemProps {
  paddingTop?: boolean
}

export const FilterItem = styled.div`
  margin-right: 20px;
  ${(props: FilterItemProps) => (props.paddingTop ? 'padding-top: 6px;' : '')}
`
