import styled from 'styled-components'
import { PositionPreference } from 'models'

interface IconContainerProps {
  themePreview?: boolean
  position?: PositionPreference
}

export const IconContainer = styled.div`
  position: ${(props: IconContainerProps) =>
    props.themePreview ? 'absolute' : 'fixed'};

  ${(props: IconContainerProps) => {
    if (props.themePreview) {
      return `
        bottom: -40px;
      `
    } else {
      if (props.position?.includes('BOTTOM')) {
        return `bottom: 10px;`
      } else if (props.position?.includes('TOP')) {
        return `top: -5px;`
      }
    }
  }}
  
  ${(props: IconContainerProps) => {
    if (props.themePreview) {
      return `
      right: 0px;
    `
    } else {
      if (props.position?.includes('RIGHT')) {
        return `
          right: -20px;
        `
      } else if (props.position?.includes('LEFT')) {
        return `
          left: 0px;
        `
      } else {
        return `
          left: 50%;
          margin-left: -50px;
        `
      }
    }
  }}

  right: ${(props: IconContainerProps) =>
    props.themePreview ? '-20px' : '0px'};
  width: 100px;
  height: 100px;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`

export const IconInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
`

export const TextWithinIcon = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  font-size: 24px;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconText = styled.div`
  margin-top: 6px;
  margin-right: 3px;
  transform: rotate(5deg);
`
