import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { observer } from 'mobx-react'
import { TransitionProps } from '@material-ui/core/transitions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { resetUserPasswordAppBar } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import UserSearch from './UserSearch'
import ResetUserPassword from './ResetUserPassword'

interface ChangeUserPasswordModalProps {
  visibility: boolean
  openFunction: (visibility: boolean) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const ChangeUserPasswordModal = observer(
  ({ visibility, openFunction }: ChangeUserPasswordModalProps) => {
    const [resetPasswordText, setResetPasswordText] = useState<string>(
      'Curbside2020!',
    )
    const [emailOfUserToEdit, setEmailOfUserToEdit] = useState<string | null>(
      null,
    )

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        fullScreen
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <ThemeProvider theme={resetUserPasswordAppBar}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Reset User Password</Typography>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <DialogContent style={{ paddingTop: 84 }}>
          {emailOfUserToEdit ? (
            <ResetUserPassword
              setResetPasswordText={setResetPasswordText}
              setEmailOfUserToEdit={setEmailOfUserToEdit}
              resetPasswordText={resetPasswordText}
              emailOfUserToEdit={emailOfUserToEdit}
            />
          ) : (
            <UserSearch setEmailOfUserToEdit={setEmailOfUserToEdit} />
          )}
        </DialogContent>
      </Dialog>
    )
  },
)

export default ChangeUserPasswordModal
