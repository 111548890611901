import React, { useState } from 'react'
import * as styled from './WidgetStatDetail.styled'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

interface WidgetStatDetailProps {
  widget: any
}

const WidgetStatDetail = observer(({ widget }: WidgetStatDetailProps) => {
  const [visibility, setVisibility] = useState(false)

  return (
    <styled.WidgetAndDataContainer
      key={`widget_id_${widget.id}`}
      expanded={!visibility}
    >
      <styled.WidgetName>
        <styled.ExpandButton onClick={() => setVisibility(!visibility)}>
          <styled.ExpandButtonText>
            {visibility ? `-` : `+`}
          </styled.ExpandButtonText>
        </styled.ExpandButton>
        Widget: {widget.name}
      </styled.WidgetName>
      {visibility && (
        <styled.WidgetData>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={`widget_report_row${widget.id}`}>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">Handled Chats</TableCell>
                  <TableCell align="left">Offline Chats</TableCell>
                  <TableCell align="left">Unanswered Chats</TableCell>
                  <TableCell align="left">Total Chats</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(widget.dates)
                  .sort()
                  .map((date: any) => {
                    return (
                      <TableRow key={`widget_report_row_${widget.id}_date_${date}`}>
                        <TableCell>
                          {date}
                        </TableCell>
                        <TableCell
                          align="left"
                        >
                          {widget.dates[date].handledChats}
                        </TableCell>
                        <TableCell
                          align="left"
                        >
                          {widget.dates[date].offlineChats}
                        </TableCell>
                        <TableCell
                          align="left"
                        >
                          {widget.dates[date].unansweredChats}
                        </TableCell>
                        <TableCell align="left">
                          <strong>{widget.dates[date].totalChats}</strong>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                <TableRow key={`widget_report_row_${widget.id}_total`}>
                  <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{widget.total.handledChats}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{widget.total.offlineChats}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{widget.total.unansweredChats}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{widget.total.totalChats}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </styled.WidgetData>
      )}
    </styled.WidgetAndDataContainer>
  )
})

export default WidgetStatDetail
