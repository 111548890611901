/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * AgentStatusPayloadBase
 * auto generated base class for the model AgentStatusPayloadModel.
 */
export const AgentStatusPayloadModelBase = ModelBase
  .named('AgentStatusPayload')
  .props({
    __typename: types.optional(types.literal("AgentStatusPayload"), "AgentStatusPayload"),
    online: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AgentStatusPayloadModelSelector extends QueryBuilder {
  get online() { return this.__attr(`online`) }
}
export function selectFromAgentStatusPayload() {
  return new AgentStatusPayloadModelSelector()
}

export const agentStatusPayloadModelPrimitives = selectFromAgentStatusPayload().online
