/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatModel, ChatModelType } from "./ChatModel"
import { ChatModelSelector } from "./ChatModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  node: ChatModelType;
}

/**
 * ChatEdgeBase
 * auto generated base class for the model ChatEdgeModel.
 *
 * An edge in a connection.
 */
export const ChatEdgeModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ChatEdge')
  .props({
    __typename: types.optional(types.literal("ChatEdge"), "ChatEdge"),
    /** A cursor for use in pagination. */
    cursor: types.union(types.undefined, types.string),
    /** The item at the end of the edge. */
    node: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ChatModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ChatEdgeModelSelector extends QueryBuilder {
  get cursor() { return this.__attr(`cursor`) }
  node(builder?: string | ChatModelSelector | ((selector: ChatModelSelector) => ChatModelSelector)) { return this.__child(`node`, ChatModelSelector, builder) }
}
export function selectFromChatEdge() {
  return new ChatEdgeModelSelector()
}

export const chatEdgeModelPrimitives = selectFromChatEdge().cursor
