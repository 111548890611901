/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChannelEnumType } from "./ChannelEnum"
import { RootStoreType } from "./index"


/**
 * BannedUserBase
 * auto generated base class for the model BannedUserModel.
 */
export const BannedUserModelBase = ModelBase
  .named('BannedUser')
  .props({
    __typename: types.optional(types.literal("BannedUser"), "BannedUser"),
    banLiftDate: types.union(types.undefined, types.null, types.frozen()),
    channel: types.union(types.undefined, ChannelEnumType),
    id: types.union(types.undefined, types.integer),
    permanent: types.union(types.undefined, types.boolean),
    source: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class BannedUserModelSelector extends QueryBuilder {
  get banLiftDate() { return this.__attr(`banLiftDate`) }
  get channel() { return this.__attr(`channel`) }
  get id() { return this.__attr(`id`) }
  get permanent() { return this.__attr(`permanent`) }
  get source() { return this.__attr(`source`) }
}
export function selectFromBannedUser() {
  return new BannedUserModelSelector()
}

export const bannedUserModelPrimitives = selectFromBannedUser().banLiftDate.channel.permanent.source
