import React from 'react'
import * as styled from './index.styled'

interface StatusBubbleProps {
  status?: boolean
  color?: string
  style?: any
}

const StatusBubble = ({ status, color, style }: StatusBubbleProps) => {
  return <styled.Bubble style={style} status={status} color={color} />
}

export default StatusBubble
