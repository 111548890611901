import styled from 'styled-components'

interface ConditionalRouteItemProps {
  maxWidth: string
}

export const ConditionalRouteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ConditionalRouteItem = styled.div`
  width: 100%;
  & > div {
    display: flex;
  }

  &:not(:last-child) {
    padding-right: 10px;
  }
`
