import styled from 'styled-components'

export const NoAgentsMessage = styled.div`
  color: rgba(87, 87, 87, 1);
  font-size: 14px;
  font-family: 'Roboto';
  height: 200px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

export const TableOuterContainer = styled.div`
  flex-basis: 98%;
  box-sizing: border-box;
  margin-top: 30px;
`

export const Title = styled.div`
  font-size: 16px;
  margin-left: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Roboto';
`

export const AgentActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const AgentActionButton = styled.div`
  margin-right: 8px;
`

export const AssignedAgentTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 250px;
`

export const StatusBubbleContainer = styled.div`
  padding-left: 10px;
`
