import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const WidgetModal = observer(
  ({
    visibility,
    openFunction,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
  }) => {
    const [name, setWidgetName] = useState('')
    const { data, setQuery, store } = useQuery()
    const { orgSlug } = useParams<Record<string, string | undefined>>()
    if (data) {
      return (
        <Redirect
          to={`/org/${orgSlug!}/widget/${(data as any).addWidget.slug}`}
        />
      )
    }

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create a New Widget</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new widget please fill out the form below and click
            submit.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Widget Name"
            type="text"
            fullWidth
            value={name}
            onChange={(event) => setWidgetName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => setQuery(store.mutateAddWidget({ input: { name } }))}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default WidgetModal
