import React, { useState } from 'react'
import * as styled from './ThemeRow.styled'
import { observer } from 'mobx-react'
import ThemeAttributes from 'ui/components/shared/ThemeAttributes'
import Button from '@material-ui/core/Button'
import { WidgetModelType } from 'models'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { theme } from 'types/style.types'
import { ThemeModelType, ThemeAttributeEnum } from 'models'
import themeObject from 'ui/theme/CustomerView'

interface ThemeRowProps {
  theme?: any
  widget: WidgetModelType
}

const ThemeRow = observer(({ theme, widget }: ThemeRowProps) => {
  const [editMode, setEditMode] = useState(false)
  const bubbleSpacing = useState(10)
  const bubblePadding = useState(10)

  const changeSelectedTheme = () => {
    widget.setSelectedTheme(theme)
  }

  const cancelAndUndo = () => {
    setEditMode(false)
  }

  const enterEditMode = () => {
    changeSelectedTheme()
    setEditMode(true)
  }

  const saveTheme = () => {
    setEditMode(false)
    theme.saveTheme()
  }

  /** */

  let themeAttributes = themeObject['california']
  let themeInstance: any = null

  if (theme && theme.attributes) {
    themeAttributes = JSON.parse(theme!.attributes)
    themeInstance = theme
  }

  const saveSpacingOptions = (attribute: string, value: number) => {
    themeInstance.modifyAttributes(
      attribute,
      value,
      ThemeAttributeEnum.ATTRIBUTE,
    )
    // setSavedColor(colorPicker)
  }

  const setAsDefault = (unset: boolean) => {
    if (!unset) {
      widget.setDefaultTheme(theme)
    }
  }

  let setTheme = false

  if (
    (!theme && !widget.theme) ||
    (theme && widget.theme && theme.id === widget.theme.id)
  ) {
    setTheme = true
  }

  const buttonStyles = {
    color: 'white',
    backgroundColor: 'rgba(80, 80, 80, 1)',
    marginLeft: 16,
    height: '34px',
  }

  return (
    <styled.ThemeRow editMode={editMode}>
      <Checkbox
        checked={setTheme}
        color="primary"
        onChange={() => setAsDefault(setTheme)}
      />

      <styled.OptionsContainer>
        <ThemeAttributes editMode={editMode} theme={theme} showFonts />
        {editMode && (
          <styled.NonColorOptionsContainer>
            <styled.Option>
              <TextField
                label={'Chat Bubble Spacing'}
                variant="filled"
                placeholder="Chat Bubble Spacing"
                value={
                  themeAttributes.chatBubbleSpacing
                    ? themeAttributes.chatBubbleSpacing.attribute
                    : 4
                }
                type="number"
                style={{
                  width: '100%',
                }}
                onChange={(event) =>
                  saveSpacingOptions(
                    'chatBubbleSpacing',
                    parseInt(event.target.value),
                  )
                }
              />
            </styled.Option>
            <styled.Option>
              <TextField
                label={'Chat Bubble Padding'}
                variant="filled"
                placeholder="Chat Bubble Padding"
                type="number"
                value={
                  themeAttributes.chatBubblePadding
                    ? themeAttributes.chatBubblePadding.attribute
                    : 6
                }
                style={{
                  width: '100%',
                }}
                onChange={(event) =>
                  saveSpacingOptions(
                    'chatBubblePadding',
                    parseInt(event.target.value),
                  )
                }
              />
            </styled.Option>
          </styled.NonColorOptionsContainer>
        )}
      </styled.OptionsContainer>

      {!editMode && theme && (
        <FontAwesomeIcon
          size="xs"
          icon={faEdit}
          style={{
            marginLeft: 8,
            cursor: 'pointer',
          }}
          onClick={enterEditMode}
        />
      )}
      {editMode && (
        <Button style={buttonStyles} onClick={cancelAndUndo}>
          Cancel
        </Button>
      )}
      {editMode && (
        <Button style={buttonStyles} onClick={saveTheme}>
          Save
        </Button>
      )}
    </styled.ThemeRow>
  )
})

export default ThemeRow
