import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import Loading from 'ui/components/shared/Loading'

const WithSpaces = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const SpaceWrappedComponent = (props: any) => {
    const history = useHistory()
    const { data } = useQuery((store) => store.getSpaces(history))

    if (data && data.spaces) {
      return <WrappedComponent {...(props as P)} />
    }

    return <Loading />
  }
  return observer(SpaceWrappedComponent)
}

export default WithSpaces
