import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 480px;
  bottom: 120px;
  border-radius: 4px;
  right: 20px;
  background-color: rgb(49, 49, 49);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  color: white;
`

export const TabContent = styled.div`
  background-color: rgba(49, 49, 49);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
  color: white;
`

export const ItemRow = styled.div`
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(60, 60, 60);
  }
`

export const ItemButton = styled.div``

export const ItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
