import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: black;
  }
`

export const Questionnaire = styled.div`
  height: 30px;
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: rgba(231, 239, 230, 1);
`

export const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 20px;
  width: 100%;
  font-size: 14px;
  flex-grow: 0;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`
