import React, { useState } from 'react'
import FloatingAddButton from 'ui/components/shared/FloatingAddButton'
import NewTheme from './NewTheme'
import ListThemes from './ListThemes'
import * as styled from './index.styled'
import { useQuery } from 'models/reactUtils'
import Loading from 'ui/components/shared/Loading'
import { observer } from 'mobx-react'

interface ThemesProps {
  organizationSlug: string
  widgetSlug: string
}

const Themes = observer(({ organizationSlug, widgetSlug }: ThemesProps) => {
  const [newThemeMode, setNewThemeMode] = useState(false)

  const { loading, data, store } = useQuery((store) =>
    store.queryWidgetFromSlug(
      { input: { organizationSlug, widgetSlug } },
      (data) =>
        data.id
          .organization((organization) =>
            organization.themes((theme) => theme.id.name.attributes),
          )
          .theme((theme) => theme.id.name.attributes),
    ),
  )

  if (loading) {
    return <Loading tabPanel />
  }

  return (
    <styled.Container>
      {newThemeMode ? (
        <NewTheme returnFunction={() => setNewThemeMode(false)} />
      ) : (
        <ListThemes
          organizationThemes={data!.widgetFromSlug!.organization!.themes}
          widget={store.widgets.get(data!.widgetFromSlug!.id)!}
        />
      )}
      <FloatingAddButton passedFunction={() => setNewThemeMode(true)} />
    </styled.Container>
  )
})

export default Themes
