import React from 'react'
import * as styled from './Menu.styled'
import CreateOrganizationButton from 'ui/components/ManagementView/MenuButtons/CreateOrganizationButton'
import CreateWidgetButton from 'ui/components/ManagementView/MenuButtons/CreateWidgetButton'
import AddAgentButton from 'ui/components/ManagementView/MenuButtons/AddAgentButton'
import AddAdminButton from 'ui/components/ManagementView/MenuButtons/AddAdminButton'
import ModifyApiKeysButton from 'ui/components/ManagementView/MenuButtons/ModifyApiKeysButton'
import AddOrganizationUserButton from 'ui/components/ManagementView/MenuButtons/AddOrganizationUserButton'
import ChangeUserPasswordButton from 'ui/components/ManagementView/MenuButtons/ChangeUserPasswordButton'
import { useParams } from 'react-router-dom'

interface MenuProps {
  widgetId?: string
  organizationId?: string
}

const Menu = ({ widgetId, organizationId }: MenuProps) => {
  const { orgSlug, widgetSlug } = useParams<
    Record<string, string | undefined>
  >()
  return (
    <styled.Menu>
      {!widgetSlug && <CreateOrganizationButton />}
      {!widgetSlug && <CreateWidgetButton />}
      {orgSlug && <AddOrganizationUserButton />}
      {widgetSlug && orgSlug && <AddAgentButton widgetId={widgetId!} />}
      {!widgetSlug && !orgSlug && <AddAdminButton widgetId={widgetId!} />}
      {orgSlug && <ModifyApiKeysButton organizationId={organizationId} />}
      {!widgetSlug && !orgSlug && <ChangeUserPasswordButton />}
    </styled.Menu>
  )
}

export default Menu
