/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * KeyValuePairOfDateTimeOffsetAndInt32Base
 * auto generated base class for the model KeyValuePairOfDateTimeOffsetAndInt32Model.
 */
export const KeyValuePairOfDateTimeOffsetAndInt32ModelBase = ModelBase
  .named('KeyValuePairOfDateTimeOffsetAndInt32')
  .props({
    __typename: types.optional(types.literal("KeyValuePairOfDateTimeOffsetAndInt32"), "KeyValuePairOfDateTimeOffsetAndInt32"),
    key: types.union(types.undefined, types.frozen()),
    value: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class KeyValuePairOfDateTimeOffsetAndInt32ModelSelector extends QueryBuilder {
  get key() { return this.__attr(`key`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromKeyValuePairOfDateTimeOffsetAndInt32() {
  return new KeyValuePairOfDateTimeOffsetAndInt32ModelSelector()
}

export const keyValuePairOfDateTimeOffsetAndInt32ModelPrimitives = selectFromKeyValuePairOfDateTimeOffsetAndInt32().key.value
