import styled from 'styled-components'

export const ThemeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props: { editMode?: boolean }) =>
    props.editMode && `background-color: rgba(0, 0, 0, 0.09);`}

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NonColorOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;

  &:last-child {
    margin-bottom: 10px;
  }
`

export const Option = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`
