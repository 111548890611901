import { PostChatQuestionnaireModelType } from 'models'

interface questionReturnType {
  ordinal: number
  question: string
}

interface postchatQuestionsType {
  ordinal: number
  question: string
}

export const questionnaireDataLocalStore = (
  questionnaireData: PostChatQuestionnaireModelType,
) => {
  return () => ({
    data: {
      completionMessage: questionnaireData.completionMessage || '',
      completionMessageToUpdate: false,
      id: questionnaireData.id,
      name: questionnaireData.name,
      questions: questionnaireData.questions as postchatQuestionsType[],
      questionsToUpdate: false,
      submitState: false,
    },
    changeSubmitState(state: boolean) {
      this.data.submitState = state
    },
    updateCompletionMessage(message: string) {
      this.data.completionMessage = message
      this.data.completionMessageToUpdate = true
    },
    replaceQuestions(questions: any) {
      this.data.questions = questions as postchatQuestionsType[]
    },
    addQuestion(widgetId: string) {
      const questions = this.data.questions || []
      questions.push({
        ordinal: questions.length + 1 || 1,
        question: '',
      })
      this.data.questions = questions
      this.data.questionsToUpdate = true
    },
    deleteQuestion(ordinal: number | string) {
      const questions = this.data.questions
      const newQuestions = questions.filter(
        (question) => question.ordinal !== ordinal,
      )
      this.data.questions = newQuestions
      this.data.questionsToUpdate = true
    },
    updateQuestion(ordinal: number, value: string) {
      const questions = this.data.questions
      const questionToModify = questions.find(
        (question) => question.ordinal === ordinal,
      )
      questionToModify!.question = value
      const questionsSansModifiedQuestion = questions.filter(
        (question) => question.ordinal !== ordinal,
      )
      questionsSansModifiedQuestion.push(questionToModify!)
      this.data.questions = questionsSansModifiedQuestion
      this.data.questionsToUpdate = true
    },
    formattingQuestionsForUpdating(): questionReturnType[] {
      const questions = this.data.questions
      const questionsToUpdate: questionReturnType[] = []
      questions
        .sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
        .forEach((question, i) => {
          // build questions and recalculate ordinal
          questionsToUpdate.push({
            ordinal: i + 1, //ordinals are 1 indexed
            question: question.question,
          })
        })
      return questionsToUpdate
    },
    resetAllUpdateFlags() {
      this.data.completionMessageToUpdate = false
      this.data.questionsToUpdate = false
    },
  })
}
