export const startOfYesterday = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 30)
  yesterday.setHours(0, 0, 0, 0)
  return yesterday
}

export const startOfToday = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return now
}

export const oneDayAfterEndDate = (endDate: Date) => {
  const newEndDate = new Date(endDate)
  newEndDate.setDate(endDate.getDate() + 1)
  return newEndDate
}
