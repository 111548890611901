/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatEdgeModel, ChatEdgeModelType } from "./ChatEdgeModel"
import { ChatEdgeModelSelector } from "./ChatEdgeModel.base"
import { ChatModel, ChatModelType } from "./ChatModel"
import { ChatModelSelector } from "./ChatModel.base"
import { PageInfoModel, PageInfoModelType } from "./PageInfoModel"
import { PageInfoModelSelector } from "./PageInfoModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  nodes: IObservableArray<ChatModelType>;
}

/**
 * ChatConnectionBase
 * auto generated base class for the model ChatConnectionModel.
 *
 * A connection to a list of items.
 */
export const ChatConnectionModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ChatConnection')
  .props({
    __typename: types.optional(types.literal("ChatConnection"), "ChatConnection"),
    /** A list of edges. */
    edges: types.union(types.undefined, types.null, types.array(types.late((): any => ChatEdgeModel))),
    /** A flattened list of the nodes. */
    nodes: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => ChatModel))))),
    /** Information to aid in pagination. */
    pageInfo: types.union(types.undefined, types.late((): any => PageInfoModel)),
    totalCount: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ChatConnectionModelSelector extends QueryBuilder {
  get totalCount() { return this.__attr(`totalCount`) }
  edges(builder?: string | ChatEdgeModelSelector | ((selector: ChatEdgeModelSelector) => ChatEdgeModelSelector)) { return this.__child(`edges`, ChatEdgeModelSelector, builder) }
  nodes(builder?: string | ChatModelSelector | ((selector: ChatModelSelector) => ChatModelSelector)) { return this.__child(`nodes`, ChatModelSelector, builder) }
  pageInfo(builder?: string | PageInfoModelSelector | ((selector: PageInfoModelSelector) => PageInfoModelSelector)) { return this.__child(`pageInfo`, PageInfoModelSelector, builder) }
}
export function selectFromChatConnection() {
  return new ChatConnectionModelSelector()
}

export const chatConnectionModelPrimitives = selectFromChatConnection().totalCount
