import styled from 'styled-components'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import { theme } from '../../../types/style.types'

interface ThemeProps {
  theme: theme
}

export const ChatOuterContainer = styled(layout.ContainerColumn)`
  overflow: hidden;
`

export const ChatContainerPrompt = styled.div`
  color: ${(props: ThemeProps) => props.theme.agentDashboardTextColor.color};
  font-size: 12px;
  font-weight: medium;
  font-family: ${(props: ThemeProps) => props.theme.contentFont.attribute};
  overflow: hidden;
  position: absolute;
  z-index: 3;
`

export const ChatContainerBackgroundGradientCover = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    rgba(240, 250, 239, 0.65) 0%,
    rgba(240, 250, 239, 0.9) 100%
  );
`

export const ChatContainerBackgroundImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  z-index: 1;
  overflow: hidden;
`

export const ChatContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 4;
`
