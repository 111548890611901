import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  margin-left: 30px;
  width: 100%;
`

export const Form = styled.div`
  width: 95%;
  height: 100px;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Roboto';
`

export const FormNote = styled.div`
  margin-bottom: 35px;
  text-indent: 4px;
  font-size: 14px;
  margin-top: 8px;
`

export const ConditionalRouteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ConditionalRouteItem = styled.div`
  flex-grow: 1;
  max-width: 49%;
`

export const SubTitle = styled.div`
  margin-bottom: 35px;
  text-indent: 0px;
  font-size: 14px;
  margin-top: -16px;
  margin-left: 4px;
`

export const InlineAddButtonContainer = styled.div`
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 18px;
`

export const InlineButtonText = styled.div`
  margin-left: 6px;
`

export const ConditionalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

export const ConditionalDeleteIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 48px;
  flex-grow: 0;
`

export const ConditionalFormContainer = styled.div`
  flex-grow: 1;
`

export const SubmissionError = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  text-indent: 10px;
  width: 100%;
  margin-top: 16px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: rgba(255, 0, 0, 0.1);
  color: rgba(255, 50, 50, 1);
`

export const GreetingMessageContainer = styled.div`
  position: relative;
`
