/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrganizationModel, OrganizationModelType } from "./OrganizationModel"
import { OrganizationModelSelector } from "./OrganizationModel.base"
import { PrivilegeModel, PrivilegeModelType } from "./PrivilegeModel"
import { PrivilegeModelSelector } from "./PrivilegeModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  organizations: IObservableArray<OrganizationModelType>;
  privileges: IObservableArray<PrivilegeModelType>;
}

/**
 * SpaceBase
 * auto generated base class for the model SpaceModel.
 */
export const SpaceModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Space')
  .props({
    __typename: types.optional(types.literal("Space"), "Space"),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    organizations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => OrganizationModel))))),
    privileges: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => PrivilegeModel))))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class SpaceModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  organizations(builder?: string | OrganizationModelSelector | ((selector: OrganizationModelSelector) => OrganizationModelSelector)) { return this.__child(`organizations`, OrganizationModelSelector, builder) }
  privileges(builder?: string | PrivilegeModelSelector | ((selector: PrivilegeModelSelector) => PrivilegeModelSelector)) { return this.__child(`privileges`, PrivilegeModelSelector, builder) }
}
export function selectFromSpace() {
  return new SpaceModelSelector()
}

export const spaceModelPrimitives = selectFromSpace().name
