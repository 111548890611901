import React from 'react'
import * as styled from './index.styled'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'

const AddAgentModal = observer(
  ({
    visibility,
    openFunction,
    widgetId,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    widgetId: string
  }) => {
    const [alias, setAgentAlias] = React.useState('')
    const [email, setAgentEmail] = React.useState('')
    const [assignedTags, setAssignedTags] = React.useState<number[]>([])
    const { data, setQuery, store } = useQuery()

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setAssignedTags(event.target.value as number[])
    }

    if (data && (data as any).addAgent) {
      openFunction(false)
    }

    const widgets = Array.from(store.widgets.values())
    const widget = widgets.find((widget) => widget.id === widgetId)
    const routeTags = widget!.routeTags || []

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add a New Agent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new agent, please complete the form filling out an alias
            and as well as an email address and invitation will be sent to them.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Agent Alias (Name)"
            type="text"
            fullWidth
            value={alias}
            onChange={(event) => setAgentAlias(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Agent Email"
            type="text"
            fullWidth
            value={email}
            onChange={(event) => setAgentEmail(event.target.value)}
          />
          <styled.SubTitle>Assign Tags</styled.SubTitle>
          <DialogContentText>
            These tags can be assigned to agents in transfer customers to the
            appropriate agent or department.
          </DialogContentText>
          <styled.TagSelectContainer>
            {routeTags && routeTags.length > 0 && (
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="assigned_tags_label">
                  Assigned Agent Tags
                </InputLabel>
                <Select
                  labelId="assigned_tags_label"
                  id="assigned_tags"
                  multiple
                  style={{ width: '100%' }}
                  value={assignedTags.length > 0 ? assignedTags : []}
                  onChange={handleChange}
                  input={<Input id="select-multiple-tags" />}
                  renderValue={(selected) => {
                    const newlySelected: Array<
                      string | number
                    > = (selected as Array<string | number>).filter(
                      (item: string | number) => item !== '' && item !== null,
                    )
                    if (newlySelected.length > 0) {
                      return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {newlySelected.map((value) => (
                            <Chip
                              key={value}
                              style={{
                                marginLeft: 4,
                                marginRight: 4,
                                marginBottom: 4,
                                backgroundColor: 'black',
                                color: 'white',
                              }}
                              label={
                                (value &&
                                  (routeTags as {
                                    id: number
                                    name: string
                                  }[]).find((tag) => {
                                    return tag.id === value
                                  })!.name) ||
                                ''
                              }
                            />
                          ))}
                        </div>
                      )
                    } else {
                      return <></>
                    }
                  }}
                >
                  {routeTags.length > 0 &&
                    (routeTags as { id: number; name: string }[]).map(
                      (routeTag: { id: number; name: string }) => (
                        <MenuItem key={routeTag.name} value={routeTag.id}>
                          {routeTag.name}
                        </MenuItem>
                      ),
                    )}
                </Select>
              </FormControl>
            )}
          </styled.TagSelectContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              setQuery(
                store.mutateAddAgent(
                  {
                    input: {
                      alias,
                      email,
                      widgetId,
                      routeTagIds: assignedTags,
                    },
                  },
                  (data) =>
                    data.agents((agent) =>
                      agent.id.alias
                        .user((user) => user.email)
                        .routeTags((routeTag) =>
                          routeTag.routeTag((tag) => tag.id.name),
                        ),
                    ),
                ),
              )
            }
            color="primary"
          >
            Add Agent
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default AddAgentModal
