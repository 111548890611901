import React from 'react'
import * as styled from './HeaderIcon.styled'
import { Link } from 'react-router-dom'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import LogoSvg from 'ui/components/shared/LogoSvg'

const HeaderIcon = observer(() => {
  const { store } = useQuery()
  const status = store.onlineStatus
  return (
    <styled.IconContainer style={{ cursor: 'pointer' }}>
      <Link to="/">
        <styled.IconInnerContainer key="headerLogoIconContainer">
          <LogoSvg
            scale=".385"
            width={110}
            keyText="headerLogoIcon"
            height={130}
            stopColorTop={
              status ? 'rgba(0, 150, 0, 1.0)' : 'rgba(220, 57, 69, 1.0)'
            }
            stopColorBottom={
              status ? 'rgba(20, 200, 20, 1.0)' : 'rgba(246, 57, 69, 1.0)'
            }
            dropShadow={false}
            strokeWidth={13}
          />
        </styled.IconInnerContainer>
        <styled.TextWithinIcon>
          <styled.IconText>!</styled.IconText>
        </styled.TextWithinIcon>
      </Link>
    </styled.IconContainer>
  )
})

export default HeaderIcon
