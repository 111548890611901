import React from 'react'
import { useQuery } from 'models/reactUtils'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import Loading from 'ui/components/shared/Loading'

const WithOrganizations = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const OrganizationWrappedComponent = (props: any) => {
    const { orgSlug } = useParams<Record<string, string | undefined>>()
    const { data } = useQuery((store) =>
      store.getOrganizationFromSlug(orgSlug!),
    )

    if (data && data.organizationFromSlug) {
      return <WrappedComponent {...(props as P)} />
    }

    return <Loading />
  }
  return observer(OrganizationWrappedComponent)
}

export default WithOrganizations
