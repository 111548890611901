import React from 'react'
import * as styled from './Shortcut.styled'
import TextField from '@material-ui/core/TextField'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'

interface ConditionalRouteProps {
  id: number
  nameValue?: string | null
  changeNameValue: (id: number, value: string) => void
  shortcutValue?: string | null
  changeShortcutValue: (id: number, value: string) => void
  deleteShortcut: (id: number) => void
}

const ConditionalRoute = ({
  id,
  nameValue,
  changeNameValue,
  shortcutValue,
  changeShortcutValue,
  deleteShortcut,
}: ConditionalRouteProps) => {
  return (
    <styled.ShortcutContainer>
      <styled.ShortcutNameContainer>
        <TextField
          label={''}
          variant="filled"
          placeholder="Shortcut Name"
          key={`short_name_${id}`}
          value={nameValue}
          style={{ width: '100%' }}
          onChange={(e) => changeNameValue(id, e.target.value)}
        />
      </styled.ShortcutNameContainer>
      <styled.ShortcutTextContainer>
        <TextField
          label={''}
          variant="filled"
          placeholder="Shortcut"
          key={`short_value_${id}`}
          value={shortcutValue}
          style={{ width: '100%' }}
          onChange={(e) => changeShortcutValue(id, e.target.value)}
        />
      </styled.ShortcutTextContainer>
      <styled.DeleteButtonContainer onClick={() => deleteShortcut(id)}>
        <InlineDeleteButton
          passedFunction={() => null}
          id={id}
          deleteQuery={() => null}
        />
      </styled.DeleteButtonContainer>
    </styled.ShortcutContainer>
  )
}

export default ConditionalRoute
