import styled from 'styled-components'

export const ShortcutSearchContainer = styled.div`
  margin: 10px;
  flex-grow: 1;
  min-height: 100px;
  max-height: 190px;
  background-color: rgb(38, 38, 38);
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

export const ShortcutSearchForm = styled.div`
  width: 100%;
  height: 60px;
`

export const ShortcutSearchResults = styled.div`
  color: white;
  margin-top: 6px;
  width: 100%;
  overflow-y: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 20px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 0px;
      padding-left: 0px;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    li:hover {
      background-color: rgba(100, 100, 100, 1);
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
`

interface ShortcutOptionProps {
  selected?: boolean | null | number
}

export const ShortcutOption = styled.li`
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  ${(props: ShortcutOptionProps) =>
    props.selected ? 'background-color: rgba(100, 100, 100, 1);' : ''}
  ${(props: ShortcutOptionProps) =>
    props.selected ? 'color: rgba(255, 255, 255, 1);' : ''}

  &:hover {
    background-color: rgba(100, 100, 100, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`

export const ShortcutValue = styled.span`
  font-style: italic;
`
