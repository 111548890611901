import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { CSVLink } from 'react-csv'
import WidgetStatDetail from './WidgetStatDetail'
import { startOfYesterday, startOfToday } from 'utilities/orgReports'
import DetailedReportDownload from './DetailedReportDownload'

const Reports = observer(() => {
  const [startDate, changeStartDate] = useState<Date | null>(startOfYesterday)
  const [endDate, changeEndDate] = useState<Date | null>(startOfToday)
  const { loading, data, setQuery, store } = useQuery()

  if (loading) {
    return (
      <styled.Container key="loading_report_container">
        Loading...
      </styled.Container>
    )
  }

  let nested = {}
  if (data) {
    nested = (data! as any).organizationStats.aggregatedChatData
  }

  const nestedDataPopulated = nested && Object.keys(nested).length > 0

  return (
    <styled.Container>
      <styled.Subtitle>Actions and Filters:</styled.Subtitle>
      <styled.FilterBar>
        <div key="startDatePicker" style={{ marginRight: 20 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="start-date-picker-dialog"
              label="Start Date"
              format="MM/dd/yyyy"
              style={{ width: '100%' }}
              value={startDate}
              onChange={changeStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div key="endDatePicker" style={{ marginRight: 20 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="end-date-picker-dialog"
              label="End Date"
              format="MM/dd/yyyy"
              style={{ width: '100%' }}
              value={endDate}
              onChange={changeEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div key="runReport">
          <Button
            style={{
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
              marginRight: 5,
            }}
            onClick={() =>
              setQuery(
                store.queryOrganizationStats(
                  { input: { startDate, endDate } },
                  (data: any) =>
                    data.edges(
                      (edge: any) =>
                        edge.handledChats.offlineChats.organizationId
                          .organizationName.totalChats.unansweredChats.widgetId
                          .widgetName.date,
                    ),
                ),
              )
            }
            color="primary"
          >
            Run Report
          </Button>
          {nestedDataPopulated && (
            <>
              <CSVLink
                data={(data! as any).organizationStats.csvData}
                filename={`unique-chat-summary-data.csv`}
              >
                <Button
                  style={{
                    color: 'white',
                    marginRight: 5,
                    backgroundColor: 'rgba(75, 75, 75, 1)',
                  }}
                  color="primary"
                >
                  Download Report
                </Button>
              </CSVLink>
              <DetailedReportDownload startDate={startDate} endDate={endDate} />
            </>
          )}
        </div>
      </styled.FilterBar>
      {nestedDataPopulated && (
        <>
          <styled.Subtitle>Organization Data:</styled.Subtitle>
          {Object.keys(nested as any).map((orgId: any) => {
            return Object.keys((nested as any)[orgId].widgets).map(
              (widgetId: any) => {
                return (
                  <WidgetStatDetail
                    widget={(nested as any)[orgId].widgets[widgetId]}
                  />
                )
              },
            )
          })}
        </>
      )}
    </styled.Container>
  )
})

export default Reports
