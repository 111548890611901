import axios from 'axios'
import { Instance, cast, types, flow } from 'mobx-state-tree'
import { WidgetModelBase } from './WidgetModel.base'
import { ModelBase } from './ModelBase'
import { ThemeModel, ThemeModelType } from './ThemeModel'
import { toJS } from 'mobx'
import { Sounds } from 'models'
import { apiHelper } from 'utilities/apiHelper'

/* The TypeScript type of an instance of WidgetModel */
export interface WidgetModelType extends Instance<typeof WidgetModel.Type> {}

/* A graphql query fragment builders for WidgetModel */
export {
  selectFromWidget,
  widgetModelPrimitives,
  WidgetModelSelector,
} from './WidgetModel.base'

/**
 * WidgetModel
 */

interface Shortcut {
  id: number
  name?: string
  value?: string
}

export const ShortcutUpdateModel = ModelBase.named('shortcutUpdateModel').props(
  {
    id: types.number,
    status: types.string,
  },
)

export const WidgetModel = WidgetModelBase.volatile((self) => ({
  sounds: Sounds,
}))
  .props({
    theme: types.maybeNull(types.reference(ThemeModel)),
    updateArray: types.optional(types.array(ShortcutUpdateModel), []),
    createdShortcuts: types.optional(types.array(types.number), []),
    selectedTheme: types.maybeNull(types.reference(ThemeModel)),
    uploadComplete: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get htmlAudioElement() {
      let name = 'Human Whistle'
      if (self.sound && self.sound!.name) {
        name = self.sound!.name
      }

      const foundSound = self.sounds.find((sound) => sound.name === name)
      return foundSound!.sound
    },
  }))
  .actions((self) => {
    const uploadIconImage = flow(function* (
      file: File,
      organizationSlug: string,
      callback: (modalVisibility: boolean) => void,
    ) {
      self.uploadComplete = true
      try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('widgetId', `${self.id}`)
        const header = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        yield apiHelper<any>(() =>
          axios.post('/api/widget-image', formData, header),
        )
        self.store.queryWidgetFromSlug(
          {
            input: { organizationSlug, widgetSlug: self.slug! },
          },
          (data) => data.htmlSettings((setting) => setting.webIconImageURL),
        )
        callback(false)
      } catch (error) {
        console.log(error)
      }
      self.uploadComplete = false
    })
    const setUpdateShortcutFlag = (id: number, status: string) => {
      const filteredUpdateArray: {
        id: number
        status: string
      }[] = self.updateArray.filter((item) => item.id !== id)
      filteredUpdateArray.push({ id, status: status })
      self.updateArray = cast(filteredUpdateArray)
    }
    const setSelectedTheme = (theme: ThemeModelType) => {
      self.selectedTheme = theme
    }
    const setDefaultTheme = (theme: ThemeModelType) => {
      if (theme) {
        const query = self.store.mutateUpdateWidgetTheme(
          {
            input: { themeId: theme.id, widgetId: self.id },
          },
          (data) => data.theme((theme) => theme.id.attributes.name),
        )

        query.then(() => {
          self.selectedTheme = theme
        })
      } else {
        self.store.mutateRemoveWidgetTheme(
          {
            input: { widgetId: self.id },
          },
          (data) => data.theme((theme) => theme.id.attributes.name),
        )
        self.selectedTheme = null
      }
    }
    const createShortcut = () => {
      const shortcuts = self.shortcuts || []

      let max = 0
      if (shortcuts.length > 0) {
        max = shortcuts.reduce(
          (max: number, shortcut: any) =>
            shortcut.id > max ? shortcut.id : max,
          (shortcuts as any)[0].id,
        )
      }

      const newShortcut = {
        id: max + 1,
        name: '',
        value: '',
      }
      self.shortcuts!.push(newShortcut)
      self.createdShortcuts.push(max + 1)
    }
    const deleteShortcut = (id?: number) => {
      const shortcuts = self.shortcuts
      if (shortcuts && shortcuts.length) {
        self.shortcuts = shortcuts.filter(
          (shortcut) => (shortcut as any).id !== id!,
        )
      }
      setUpdateShortcutFlag(id!, 'delete')
    }
    const removeAllShortcutUpdateFlags = () => {
      self.updateArray = cast([])
    }
    const removeAllShortcutCreatedFlags = () => {
      self.createdShortcuts = cast([])
    }
    const modifyShortcutName = (id: number, value: string) => {
      const shortcuts = self.shortcuts
      if (shortcuts && shortcuts.length) {
        const foundIndex = shortcuts.findIndex(
          (shortcut) => (shortcut as any).id === id,
        )
        const filteredShortcuts = shortcuts.filter(
          (shortcut) => (shortcut as any).id !== id,
        )
        filteredShortcuts.push({
          id: (shortcuts[foundIndex] as any).id,
          name: value,
          value: (shortcuts[foundIndex] as any).value,
        })
        self.shortcuts = filteredShortcuts
        setUpdateShortcutFlag(id, 'updateName')
      }
    }
    const modifyShortcutText = (id: number, value: string) => {
      const shortcuts = self.shortcuts
      if (shortcuts && shortcuts.length) {
        const foundIndex = shortcuts.findIndex(
          (shortcut) => (shortcut as any).id === id,
        )
        const filteredShortcuts = shortcuts.filter(
          (shortcut) => (shortcut as any).id !== id,
        )
        filteredShortcuts.push({
          id: (shortcuts[foundIndex] as any).id,
          name: (shortcuts[foundIndex] as any).name,
          value,
        })
        self.shortcuts = filteredShortcuts
        setUpdateShortcutFlag(id, 'updateText')
      }
    }

    return {
      uploadIconImage,
      setUpdateShortcutFlag,
      setSelectedTheme,
      setDefaultTheme,
      createShortcut,
      deleteShortcut,
      removeAllShortcutUpdateFlags,
      removeAllShortcutCreatedFlags,
      modifyShortcutName,
      modifyShortcutText,
    }
  })
