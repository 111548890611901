import React, { useState } from 'react'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddOrganizationUserModal from 'ui/components/Modals/AddOrganizationUserModal'
import * as styled from './AddAdminButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'

const AddOrganizationUserButton = ({ widgetId }: { widgetId?: string }) => {
  const [
    organizationUserModalVisibility,
    setOrganizationUserModalVisibility,
  ] = useState(false)

  return (
    <styled.MenuItemContainer
      onClick={() => setOrganizationUserModalVisibility(true)}
    >
      <div>
        <FontAwesomeIcon icon={faUserLock} />
      </div>
      <div>Add Organization User</div>
      <AddOrganizationUserModal
        widgetId={widgetId!}
        visibility={organizationUserModalVisibility}
        openFunction={setOrganizationUserModalVisibility}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageOrganization'], AddOrganizationUserButton)
