import React from 'react'

export interface ThemeContextType {
  name: string
  changeThemeName: ((name: string) => void) | null
  mobileView: boolean
}

const ThemeContext = React.createContext<ThemeContextType>({
  name: 'california',
  changeThemeName: null,
  mobileView: false,
})

export default ThemeContext
