/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PositionPreferenceEnumType } from "./PositionPreferenceEnum"
import { PostChatQuestionnaireModel, PostChatQuestionnaireModelType } from "./PostChatQuestionnaireModel"
import { PostChatQuestionnaireModelSelector } from "./PostChatQuestionnaireModel.base"
import { ThemeModel, ThemeModelType } from "./ThemeModel"
import { ThemeModelSelector } from "./ThemeModel.base"
import { RootStoreType } from "./index"


/**
 * AncillaryWidgetDetailsPayloadBase
 * auto generated base class for the model AncillaryWidgetDetailsPayloadModel.
 */
export const AncillaryWidgetDetailsPayloadModelBase = ModelBase
  .named('AncillaryWidgetDetailsPayload')
  .props({
    __typename: types.optional(types.literal("AncillaryWidgetDetailsPayload"), "AncillaryWidgetDetailsPayload"),
    autoDisplay: types.union(types.undefined, types.boolean),
    autoDisplayDelay: types.union(types.undefined, types.integer),
    enablePostChatQuestionnaire: types.union(types.undefined, types.boolean),
    hideIfOffline: types.union(types.undefined, types.boolean),
    isOffline: types.union(types.undefined, types.boolean),
    position: types.union(types.undefined, PositionPreferenceEnumType),
    postChatQuestionnaire: types.union(types.undefined, types.null, types.late((): any => PostChatQuestionnaireModel)),
    theme: types.union(types.undefined, types.late((): any => ThemeModel)),
    widgetIconImageUrl: types.union(types.undefined, types.null, types.string),
    widgetTitle: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AncillaryWidgetDetailsPayloadModelSelector extends QueryBuilder {
  get autoDisplay() { return this.__attr(`autoDisplay`) }
  get autoDisplayDelay() { return this.__attr(`autoDisplayDelay`) }
  get enablePostChatQuestionnaire() { return this.__attr(`enablePostChatQuestionnaire`) }
  get hideIfOffline() { return this.__attr(`hideIfOffline`) }
  get isOffline() { return this.__attr(`isOffline`) }
  get position() { return this.__attr(`position`) }
  get widgetIconImageUrl() { return this.__attr(`widgetIconImageUrl`) }
  get widgetTitle() { return this.__attr(`widgetTitle`) }
  postChatQuestionnaire(builder?: string | PostChatQuestionnaireModelSelector | ((selector: PostChatQuestionnaireModelSelector) => PostChatQuestionnaireModelSelector)) { return this.__child(`postChatQuestionnaire`, PostChatQuestionnaireModelSelector, builder) }
  theme(builder?: string | ThemeModelSelector | ((selector: ThemeModelSelector) => ThemeModelSelector)) { return this.__child(`theme`, ThemeModelSelector, builder) }
}
export function selectFromAncillaryWidgetDetailsPayload() {
  return new AncillaryWidgetDetailsPayloadModelSelector()
}

export const ancillaryWidgetDetailsPayloadModelPrimitives = selectFromAncillaryWidgetDetailsPayload().autoDisplay.autoDisplayDelay.enablePostChatQuestionnaire.hideIfOffline.isOffline.position.widgetIconImageUrl.widgetTitle
