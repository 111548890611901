/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrganizationDetiledReportBreakdownModel, OrganizationDetiledReportBreakdownModelType } from "./OrganizationDetiledReportBreakdownModel"
import { OrganizationDetiledReportBreakdownModelSelector } from "./OrganizationDetiledReportBreakdownModel.base"
import { RootStoreType } from "./index"


/**
 * GetOrganizationDetailedReportPayloadBase
 * auto generated base class for the model GetOrganizationDetailedReportPayloadModel.
 */
export const GetOrganizationDetailedReportPayloadModelBase = ModelBase
  .named('GetOrganizationDetailedReportPayload')
  .props({
    __typename: types.optional(types.literal("GetOrganizationDetailedReportPayload"), "GetOrganizationDetailedReportPayload"),
    edges: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => OrganizationDetiledReportBreakdownModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GetOrganizationDetailedReportPayloadModelSelector extends QueryBuilder {
  edges(builder?: string | OrganizationDetiledReportBreakdownModelSelector | ((selector: OrganizationDetiledReportBreakdownModelSelector) => OrganizationDetiledReportBreakdownModelSelector)) { return this.__child(`edges`, OrganizationDetiledReportBreakdownModelSelector, builder) }
}
export function selectFromGetOrganizationDetailedReportPayload() {
  return new GetOrganizationDetailedReportPayloadModelSelector()
}

export const getOrganizationDetailedReportPayloadModelPrimitives = selectFromGetOrganizationDetailedReportPayload()
