import {
  PreChatQuestionnaireModelType,
  ConditionExpression,
  RouteType,
} from 'models'

interface questionReturnType {
  ordinal: number
  question: string
  variableName: string
}

interface conditionsReturnType {
  expression: ConditionExpression
  expressionValue?: string
  routeType?: RouteType
  routeValue?: string
  variableName?: string
}

interface conditionsType {
  id: string
  route: {
    id: number
    type: RouteType
    value: string
  }
  expression: ConditionExpression
  preChatQuestionnaireId: number | null
  value: string
  variable: { id: number; name: string } | null
}

interface questionsType {
  ordinal: number
  question: string
  variable: { id: number; name: string }
}

export const questionnaireDataLocalStore = (
  questionnaireData: PreChatQuestionnaireModelType,
) => {
  return () => ({
    data: {
      agentMessage: questionnaireData.agentMessage || '',
      agentMessageToUpdate: false,
      completionMessage: questionnaireData.completionMessage || '',
      completionMessageToUpdate: false,
      conditions: questionnaireData.conditions as conditionsType[],
      conditionsToUpdate: false,
      id: questionnaireData.id,
      name: questionnaireData.name,
      questions: questionnaireData.questions as questionsType[],
      questionsToUpdate: false,
      submitState: false,
    },
    setRouteTag(conditionId: string, id: number, value: string) {
      const foundCondition = this.data.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.data.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.route = { id, value, type: RouteType.ROUTETAG }
      filteredConditions.push(foundCondition!)
      this.data.conditions = filteredConditions
      this.data.conditionsToUpdate = true
    },
    setConditionalExpression(
      conditionId: string,
      expression: ConditionExpression,
    ) {
      const foundCondition = this.data.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.data.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.expression = expression
      filteredConditions.push(foundCondition!)
      this.data.conditions = filteredConditions
      this.data.conditionsToUpdate = true
    },
    changeSubmitState(state: boolean) {
      this.data.submitState = state
    },
    attachVariableToCondition(conditionId: string, variableId: number) {
      const foundCondition = this.data.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.data.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      if (
        variableId === null ||
        variableId === void 0 ||
        variableId.toString() === 'ANY'
      ) {
        foundCondition!.variable = null
        this.setConditionalExpression(conditionId, ConditionExpression.ANY)
        return
      }

      foundCondition!.variable = {
        id: variableId,
        name: this.data.questions!.find(
          (question) => question!.variable.id === variableId,
        )!.variable.name,
      }

      filteredConditions.push(foundCondition!)
      this.data.conditions = filteredConditions
      this.data.conditionsToUpdate = true
    },
    updateAgentMessage(message: string) {
      this.data.agentMessage = message
      this.data.agentMessageToUpdate = true
    },
    updateCompletionMessage(message: string) {
      this.data.completionMessage = message
      this.data.completionMessageToUpdate = true
    },
    updateRoute(
      type: RouteType,
      widgetId: string,
      defaultRoute: boolean,
      conditionId?: string,
    ) {
      const conditions = this.data.conditions
      const indexOfCondition = conditions.findIndex(
        (condition) => condition.id === conditionId,
      )
      const conditionToUpdate = conditions[indexOfCondition]
      if (conditionToUpdate.route) {
        conditionToUpdate.route.type = type
        conditionToUpdate.route.value = type === 'AGENTQUEUE' ? widgetId : ''
      } else {
        const route = { id: 1, type, value: '' }
        conditionToUpdate.route = route
      }
      this.data.conditions = conditions
      this.data.conditionsToUpdate = true
    },
    updateRouteWithQuestionnaire(
      defaultRoute: boolean,
      questionnaireId: string,
      conditionId?: string,
    ) {
      const conditions = this.data.conditions
      const indexOfCondition = conditions.findIndex(
        (condition) => condition.id === conditionId,
      )
      const conditionToUpdate = conditions[indexOfCondition]
      conditionToUpdate.route.value = questionnaireId
      this.data.conditions = conditions
      this.data.conditionsToUpdate = true
    },
    addCondition(widgetId: string) {
      const conditions = this.data.conditions || []
      const max = Math.max(...conditions.map((item) => parseInt(item.id)))
      conditions.push({
        id: String(max + 1),
        route: { id: 0, type: RouteType.AGENTQUEUE, value: widgetId },
        expression: ConditionExpression.ANY,
        preChatQuestionnaireId: null,
        value: '',
        variable: null,
      })
      this.data.conditions = conditions
      this.data.conditionsToUpdate = true
    },
    replaceConditions(conditions: any) {
      this.data.conditions = conditions as conditionsType[]
    },
    replaceQuestions(questions: any) {
      this.data.questions = questions as questionsType[]
    },
    addQuestion(widgetId: string) {
      const questions = this.data.questions || []
      questions.push({
        ordinal: questions.length + 1 || 1,
        question: '',
        variable: { id: questions.length + 1, name: '' },
      })
      this.data.questions = questions
      this.data.questionsToUpdate = true
    },
    deleteCondition(conditionId: string | number) {
      const conditions = this.data.conditions
      const filteredConditions = conditions.filter(
        (condition) => condition.id !== conditionId,
      )
      this.data.conditions = filteredConditions
      this.data.conditionsToUpdate = true
    },
    deleteQuestion(ordinal: number | string) {
      const questions = this.data.questions
      const newQuestions = questions.filter(
        (question) => question.ordinal !== ordinal,
      )
      this.data.questions = newQuestions
      this.data.questionsToUpdate = true
    },
    updateQuestionVariable(ordinal: number, value: string) {
      const questions = this.data.questions || []
      const questionToModify = questions.find(
        (question) => question.ordinal === ordinal,
      )
      questionToModify!.variable.name = value
      const questionsSansModifiedQuestion = questions.filter(
        (question) => question.ordinal !== ordinal,
      )
      questionsSansModifiedQuestion.push(questionToModify!)
      this.data.questions = questionsSansModifiedQuestion
      this.data.questionsToUpdate = true
    },
    updateQuestion(ordinal: number, value: string) {
      const questions = this.data.questions
      const questionToModify = questions.find(
        (question) => question.ordinal === ordinal,
      )
      questionToModify!.question = value
      const questionsSansModifiedQuestion = questions.filter(
        (question) => question.ordinal !== ordinal,
      )
      questionsSansModifiedQuestion.push(questionToModify!)
      this.data.questions = questionsSansModifiedQuestion
      this.data.questionsToUpdate = true
    },
    formattingQuestionsForUpdating(): questionReturnType[] {
      const questions = this.data.questions
      const questionsToUpdate: questionReturnType[] = []
      questions
        .sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
        .forEach((question, i) => {
          // build questions and recalculate ordinal
          questionsToUpdate.push({
            ordinal: i + 1, //ordinals are 1 indexed
            question: question.question,
            variableName: question.variable.name,
          })
        })
      return questionsToUpdate
    },
    formattingConditionalsForUpdating(): conditionsReturnType[] {
      const conditions = this.data.conditions
      const conditionsToUpdate: conditionsReturnType[] = []
      conditions.forEach((condition) => {
        conditionsToUpdate.push({
          expression: condition.expression,
          expressionValue: condition.value,
          routeType:
            condition.expression === ConditionExpression.MATCHESTAG
              ? undefined
              : condition.route.type,
          routeValue:
            condition.expression === ConditionExpression.MATCHESTAG
              ? undefined
              : String(condition.route.value),
          variableName: condition.variable
            ? String(condition.variable.name)
            : undefined,
        })
      })
      return conditionsToUpdate
    },
    resetAllUpdateFlags() {
      this.data.completionMessageToUpdate = false
      this.data.agentMessageToUpdate = false
      this.data.questionsToUpdate = false
    },
    updateConditionalFlag(state: boolean) {
      this.data.conditionsToUpdate = state
    },
    changeExpressionCriteria(conditionId: string, value: string) {
      const foundCondition = this.data.conditions.find(
        (condition) => condition.id === conditionId,
      )
      const filteredConditions = this.data.conditions.filter(
        (condition) => condition.id !== conditionId,
      )

      foundCondition!.value = value
      filteredConditions.push(foundCondition!)
      this.data.conditions = filteredConditions
      this.data.conditionsToUpdate = true
    },
  })
}
