import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchInput = styled.div`
  display: flex;
  flex-direction: row;
`

export const SearchOutput = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchMessage = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
`
