import { Instance } from 'mobx-state-tree'
import { AgentModelBase } from './AgentModel.base'

/* The TypeScript type of an instance of AgentModel */
export interface AgentModelType extends Instance<typeof AgentModel.Type> {}

/* A graphql query fragment builders for AgentModel */
export {
  selectFromAgent,
  agentModelPrimitives,
  AgentModelSelector,
} from './AgentModel.base'

/**
 * AgentModel
 */
export const AgentModel = AgentModelBase.actions((self) => ({}))
