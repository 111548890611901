/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * OrganizationDetiledReportBreakdownBase
 * auto generated base class for the model OrganizationDetiledReportBreakdownModel.
 */
export const OrganizationDetiledReportBreakdownModelBase = ModelBase
  .named('OrganizationDetiledReportBreakdown')
  .props({
    __typename: types.optional(types.literal("OrganizationDetiledReportBreakdown"), "OrganizationDetiledReportBreakdown"),
    agentAlias: types.union(types.undefined, types.null, types.string),
    chatType: types.union(types.undefined, types.null, types.string),
    end: types.union(types.undefined, types.null, types.string),
    organizationId: types.union(types.undefined, types.null, types.string),
    organizationName: types.union(types.undefined, types.null, types.string),
    phone: types.union(types.undefined, types.null, types.string),
    start: types.union(types.undefined, types.null, types.string),
    widgetId: types.union(types.undefined, types.null, types.string),
    widgetName: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrganizationDetiledReportBreakdownModelSelector extends QueryBuilder {
  get agentAlias() { return this.__attr(`agentAlias`) }
  get chatType() { return this.__attr(`chatType`) }
  get end() { return this.__attr(`end`) }
  get organizationId() { return this.__attr(`organizationId`) }
  get organizationName() { return this.__attr(`organizationName`) }
  get phone() { return this.__attr(`phone`) }
  get start() { return this.__attr(`start`) }
  get widgetId() { return this.__attr(`widgetId`) }
  get widgetName() { return this.__attr(`widgetName`) }
}
export function selectFromOrganizationDetiledReportBreakdown() {
  return new OrganizationDetiledReportBreakdownModelSelector()
}

export const organizationDetiledReportBreakdownModelPrimitives = selectFromOrganizationDetiledReportBreakdown().agentAlias.chatType.end.organizationId.organizationName.phone.start.widgetId.widgetName
