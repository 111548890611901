import { AxiosPromise, AxiosResponse } from 'axios'

export async function apiHelper<T>(
  request: () => AxiosPromise<T>,
): Promise<AxiosResponse<T>> {
  try {
    return Promise.resolve(await request())
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return Promise.reject(error.response)
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject('No response')
    } else {
      // Something happened in setting up the request that triggered an Error
    }
  }
  return Promise.reject()
}
