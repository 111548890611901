import React, { useContext, useState } from 'react'
import * as styled from './ListThemes.styled'
import { observer } from 'mobx-react'
import { StoreContext, MessageSender, ThemeModelType } from 'models'
import ChatIcon from 'ui/components/shared/ChatIcon'
import ChatImageIcon from 'ui/components/shared/ChatImageIcon'
import Chat from 'ui/components/Chat'
import ThemeRow from 'ui/components/ManagementView/TabPanels/Widget/Themes/ThemeRow'
import Button from '@material-ui/core/Button'
import WidgetIconImageUploadModal from 'ui/components/Modals/WidgetIconImageUploadModal'
import { WidgetModelType } from 'models'

interface ListThemesProps {
  organizationThemes: any
  widget: WidgetModelType
}

const ListThemes = observer(
  ({ organizationThemes, widget }: ListThemesProps) => {
    const store = useContext(StoreContext)
    const [visibility, setVisibiility] = useState(false)

    return (
      <styled.ThemeListAndPreview>
        <styled.List>
          <styled.Title>Themes:</styled.Title>
          {organizationThemes &&
            organizationThemes.map((theme: ThemeModelType) => {
              return <ThemeRow widget={widget} theme={theme} />
            })}
          <ThemeRow widget={widget} />
          <Button
            style={{
              color: 'white',
              marginTop: 20,
              backgroundColor: 'rgba(230, 56, 68, 1)',
            }}
            onClick={() => setVisibiility(true)}
          >
            Replace Icon Image
          </Button>
          {widget?.htmlSettings?.webIconImageURL && (
            <Button
              style={{
                color: 'white',
                marginLeft: 10,
                marginTop: 20,
                backgroundColor: 'rgba(30, 30, 30, 1)',
              }}
              onClick={() =>
                store.mutateRemoveWidgetIconImage(
                  { input: { widgetId: widget.id } },
                  (data) =>
                    data.htmlSettings((setting) => setting.webIconImageURL),
                )
              }
            >
              Remove Widget Image
            </Button>
          )}
        </styled.List>
        <styled.Preview>
          <styled.Title>Customer Preview:</styled.Title>
          <div
            style={{
              height: '98%',
              width: 325,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <Chat
                chat={store.selectedChat}
                currentSenderTypes={[MessageSender.USER]}
                sendMessage={() => null}
                agent={false}
                theme={widget.selectedTheme}
                themePreview
                endChat={() => null}
                title={widget.title}
              />
            </div>
            <div
              style={{
                flexGrow: 0,
                height: 40,
                position: 'relative',
              }}
            >
              {widget?.htmlSettings?.webIconImageURL ? (
                <ChatImageIcon
                  imageUrl={widget?.htmlSettings?.webIconImageURL}
                  changeChatVisibility={() => null}
                  themePreview
                />
              ) : (
                <ChatIcon
                  themePreview
                  theme={widget.selectedTheme}
                  changeChatVisibility={() => null}
                />
              )}
            </div>
          </div>
        </styled.Preview>
        <WidgetIconImageUploadModal
          openFunction={setVisibiility}
          visibility={visibility}
          widget={widget}
        />
      </styled.ThemeListAndPreview>
    )
  },
)

export default ListThemes
