import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import * as styled from './PostChatQuestionnaire.styled'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import {
  PostChatQuestionModelType,
  PostChatQuestionnaireModelType,
} from 'models'
import { useQuery } from 'models/reactUtils'

interface QuestionProps {
  theme: any
  setCurrentQuestionMiddleware: () => void
  questionMetaData: PostChatQuestionModelType
  chatId?: string
}

const Question: FC<QuestionProps> = ({
  theme,
  setCurrentQuestionMiddleware,
  questionMetaData,
  chatId,
}) => {
  const [response, setResponse] = useState('')
  const { loading, error, data, setQuery, store } = useQuery()

  const submitResponse = () => {
    setQuery(
      store.mutateAddPostChatQuestionnaireResponse({
        input: {
          chatId: chatId!,
          postChatQuestionId: questionMetaData.id!,
          ordinal: questionMetaData.ordinal!,
          response,
        },
      }),
    )
    setCurrentQuestionMiddleware()
  }

  const detectAndHandleEnter = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        submitResponse()
        setResponse('')
        event.preventDefault()
      }
    },
    [submitResponse, setResponse],
  )

  const handleNextButtonPress = () => {
    submitResponse()
    setResponse('')
  }

  return (
    <styled.QuestionContainer>
      <styled.Question theme={theme}>
        {questionMetaData.question}
      </styled.Question>
      <TextField
        multiline
        fullWidth
        style={{ marginTop: 20, paddingTop: '10px !important' }}
        value={response}
        onChange={(event) => setResponse(event.target.value)}
        onKeyDown={(event) => detectAndHandleEnter(event)}
        placeholder="Please enter your response here"
        rowsMax={4}
        rows={2}
        variant="filled"
        InputProps={{
          style: {
            color: 'rgba(255, 255, 255, 1)',
            paddingTop: '10px !important',
          },
          disableUnderline: true,
        }}
      />
      <styled.SubmitButton>
        <Button onClick={handleNextButtonPress} variant="contained">Next</Button>
      </styled.SubmitButton>
    </styled.QuestionContainer>
  )
}

interface PostChatQuestionnaireProps {
  themeFromContext: any
  postChatQuestionnaire?: PostChatQuestionnaireModelType
  chatId?: string
  completeQuestionnaireMiddleware: () => void
}

const PostChatQuestionnaire: FC<PostChatQuestionnaireProps> = ({
  themeFromContext,
  postChatQuestionnaire,
  chatId,
  completeQuestionnaireMiddleware,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [
    questionMetaData,
    setQuestionMetaData,
  ] = useState<PostChatQuestionModelType | null>(null)

  const setCurrentQuestionMiddleware = useCallback(() => {
    setCurrentQuestion(currentQuestion + 1)
  }, [currentQuestion, setCurrentQuestion])

  useEffect(() => {
    if (
      postChatQuestionnaire &&
      postChatQuestionnaire.questions &&
      currentQuestion <= postChatQuestionnaire?.questions?.length &&
      postChatQuestionnaire?.questions[currentQuestion - 1]
    ) {
      // @ts-ignore
      const foundQuestion: PostChatQuestionModelType | null = postChatQuestionnaire.questions.find((question: PostChatQuestionModelType) => question.ordinal === currentQuestion) || null
      if (foundQuestion) {
        setQuestionMetaData(foundQuestion)
      }
    }
  }, [currentQuestion, postChatQuestionnaire, setQuestionMetaData])

  const showQuestions = useMemo(() => {
    return !!(
      questionMetaData &&
      postChatQuestionnaire &&
      postChatQuestionnaire.questions &&
      currentQuestion <= postChatQuestionnaire?.questions?.length &&
      postChatQuestionnaire?.questions[currentQuestion - 1]
    )
  }, [
    questionMetaData,
    postChatQuestionnaire,
    postChatQuestionnaire?.questions,
    currentQuestion,
  ])

  return (
    <styled.Container theme={themeFromContext}>
      <styled.Title theme={themeFromContext}>
        Post Chat Questionnaire
      </styled.Title>
      {showQuestions && (
        <Question
          questionMetaData={questionMetaData!}
          setCurrentQuestionMiddleware={setCurrentQuestionMiddleware}
          theme={themeFromContext}
          chatId={chatId}
        />
      )}
      {!showQuestions && (
        <styled.QuestionContainer>
          <styled.Question theme={themeFromContext}>
            {postChatQuestionnaire?.completionMessage}
          </styled.Question>
          <styled.SubmitButton>
            <Button
              variant="contained"
              onClick={completeQuestionnaireMiddleware}
            >
              Done
            </Button>
          </styled.SubmitButton>
        </styled.QuestionContainer>
      )}
      <div></div>
    </styled.Container>
  )
}

export default PostChatQuestionnaire
