import React, { useState } from 'react'
import { faLandmark, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { OrganizationModelType } from 'models'
import orderBy from 'lodash/orderBy'
import DeleteOrgModal from 'ui/components/Modals/DeleteOrgModal'
import { SpaceModelType } from 'models'

interface DeleteOrganizationIconProps {
  name: string
  id: string
  setDeleteOrgDetails: (orgDetails: { id: string; name: string }) => void
  setVisibility: (visibility: boolean) => void
}

const DeleteOrganizationIcon = ({
  name,
  id,
  setDeleteOrgDetails,
  setVisibility,
}: DeleteOrganizationIconProps) => {
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setDeleteOrgDetails({ id, name })
    setVisibility(true)
    // deleteOrganization()
  }

  return (
    <styled.DeleteIconContainer onClick={handleClick}>
      <FontAwesomeIcon
        onClick={() => setVisibility(true)}
        style={{ color: 'e43845' }}
        size="xs"
        icon={faTrash}
      />
    </styled.DeleteIconContainer>
  )
}

interface OrganizationProps {
  slug?: string | null
  id: string
  name?: string | null
  setDeleteOrgDetails: (orgDetails: { id: string; name: string }) => void
  setVisibiility: (visibility: boolean) => void
}

const Organization = ({
  id,
  slug,
  name,
  setDeleteOrgDetails,
  setVisibiility,
}: OrganizationProps) => {
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(true)

  return (
    <Link to={`/org/${slug}`}>
      <div
        onMouseEnter={() => setDeleteIconVisibility(false)}
        onMouseLeave={() => setDeleteIconVisibility(true)}
      >
        <styled.Organization>
          <div hidden={deleteIconVisibility}>
            <DeleteOrganizationIcon
              id={id}
              name={name || ''}
              setDeleteOrgDetails={setDeleteOrgDetails}
              setVisibility={setVisibiility}
            />
          </div>
          <styled.Icon>
            <FontAwesomeIcon size="2x" icon={faLandmark} />
          </styled.Icon>
          <styled.Name>{name}</styled.Name>
        </styled.Organization>
      </div>
    </Link>
  )
}

interface OrganizationsProps {
  organizations: OrganizationModelType[]
  space: SpaceModelType
}

const Organizations = observer(
  ({ organizations, space }: OrganizationsProps) => {
    const [visibility, setVisibiility] = useState(false)
    const [deleteOrgDetails, setDeleteOrgDetails] = useState({
      id: '',
      name: '',
    })

    return (
      <styled.Container>
        {orderBy(
          Array.from(organizations.values()).filter(
            (organization) => !organization.deleted,
          ),
          'name',
          'asc',
        ).map((organization) => {
          return (
            <Organization
              slug={organization.slug}
              name={organization.name}
              id={organization.id}
              key={`org_icon_${organization.slug}`}
              setDeleteOrgDetails={setDeleteOrgDetails}
              setVisibiility={setVisibiility}
            />
          )
        })}
        <DeleteOrgModal
          openFunction={setVisibiility}
          visibility={visibility}
          organizationDetails={deleteOrgDetails}
          space={space}
        />
      </styled.Container>
    )
  },
)

export default Organizations
