import React, { useState } from 'react'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import * as styled from './index.styled'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView/index'
import Menu from 'ui/components/ManagementView/Menu'
import { useParams, useHistory } from 'react-router-dom'
import HeaderIcon from 'ui/components/ManagementView/HeaderIcon'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { materialTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import TabPanel from 'ui/components/shared/TabPanel'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import { PreChatQuestionnaireModelType, BannedUserModelType } from 'models'
import Agents from 'ui/components/ManagementView/TabPanels/Widget/Agents'
import HTMLSettings from 'ui/components/ManagementView/TabPanels/Widget/HTMLSettings'
import SMSSettings from 'ui/components/ManagementView/TabPanels/Widget/SMSSettings'
import PrechatQuestionnaire from 'ui/components/ManagementView/TabPanels/Widget/PrechatQuestionnaires'
import PostchatQuestionnaire from 'ui/components/ManagementView/TabPanels/Widget/PostchatQuestionnaires'
import GeneralSettings from 'ui/components/ManagementView/TabPanels/Widget/GeneralSettings'
import GoToHub from 'ui/components/shared/GoToHub'
import HeaderMenu from 'ui/components/shared/HeaderMenu'
import { usePermission } from 'utilities/usePermission'
import { useQueryParams } from 'utilities/useQueryParams'
import WithSpaces from 'ui/components/shared/WithSpaces'
import WithOrganizations from 'ui/components/shared/WithOrganizations'
import Themes from 'ui/components/ManagementView/TabPanels/Widget/Themes'
import BannedUsers from 'ui/components/ManagementView/TabPanels/Widget/BannedUsers'
import Loading from 'ui/components/shared/Loading'
import * as Sentry from '@sentry/react'

const tabs = [
  'generalSettings',
  'agents',
  'webSettings',
  'smsSettings',
  'prechatQuestionnaires',
  'postchatQuestionnaires',
]

const WidgetView = observer(() => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const permission = usePermission(['ManageOrganization'])
  const viewOrgPermission = usePermission([
    'ManageOrganization',
    'ViewOrganization',
  ])
  const { orgSlug, widgetSlug } = useParams<
    Record<string, string | undefined>
  >()
  const tab = queryParams.get('tab')
  const [activeTab, setActiveTab] = useState(
    tab && tabs.includes(tab) ? tab : 'generalSettings',
  )
  const [themeName, setThemeName] = useState('california')

  const { loading, data } = useQuery((store) => {
    const query = store.queryWidgetFromSlug(
      { input: { organizationSlug: orgSlug, widgetSlug } },
      (data) =>
        data.organization((organization) =>
          organization.id.name
            .space((space) => space.id)
            .routeTags((tag) => tag.id.name),
        ),
    )

    query.then(
      (data) => {
        store.setHeaders(
          data.widgetFromSlug.organization.space.id,
          data.widgetFromSlug.organization.id,
        )
        data.widgetFromSlug.selectedTheme = data.widgetFromSlug.theme
      },
      (error) => {
        console.log(error)
        Sentry.captureMessage(`Error in getWidgetFromSlug(): ${error}`)
      },
    )
    return query
  })

  const handleTabBarChange = (
    event: React.ChangeEvent<{}> | null,
    value: string,
  ) => {
    setActiveTab(value)
    history.push(`/org/${orgSlug!}/widget/${widgetSlug!}?tab=${value}`)
  }

  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  if (loading) {
    return <Loading />
  }

  const featureFlagPostChatQuestionnaires = false

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <layout.NewPageContainer>
        <styled.HeaderContainer theme={themeObject[ThemeProviderValue.name]}>
          <styled.LogoContainer>Unique Chat</styled.LogoContainer>
          <styled.MenuAndAccountContainer>
            <GoToHub />
            <HeaderMenu />
          </styled.MenuAndAccountContainer>
        </styled.HeaderContainer>
        <styled.FunctionContainer theme={themeObject[ThemeProviderValue.name]}>
          <Menu widgetId={data!.widgetFromSlug.id} />
        </styled.FunctionContainer>

        <styled.ContentContainer>
          <styled.ContentTitle>
            Widget - {data!.widgetFromSlug.name} for Organization -{' '}
            {data!.widgetFromSlug.organization.name}
          </styled.ContentTitle>
          <styled.Content>
            <ThemeProvider theme={materialTheme}>
              <AppBar position="static">
                <Tabs
                  value={activeTab}
                  onChange={handleTabBarChange}
                  aria-label="Select one of these tabs in order to navigate"
                >
                  {viewOrgPermission && (
                    <Tab value="generalSettings" label="General Settings" />
                  )}
                  {viewOrgPermission && <Tab value="agents" label="Agents" />}
                  {permission && (
                    <Tab value="webSettings" label="Web Settings" />
                  )}
                  {permission && (
                    <Tab value="smsSettings" label="SMS Settings" />
                  )}
                  {permission && (
                    <Tab
                      value="prechatQuestionnaires"
                      label="Prechat Questionnaires"
                    />
                  )}
                  {permission && featureFlagPostChatQuestionnaires && (
                    <Tab
                      value="postchatQuestionnaires"
                      label="Postchat Questionnaires"
                    />
                  )}
                  {permission && (
                    <Tab value="bannedUsers" label="Banned Users" />
                  )}
                  {permission && <Tab value="themes" label="Themes" />}
                </Tabs>
              </AppBar>
              <TabPanel value={activeTab} index="generalSettings">
                {activeTab === 'generalSettings' && (
                  <GeneralSettings
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="agents">
                {activeTab === 'agents' && (
                  <Agents
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                    routeTags={data?.widgetFromSlug.routeTags}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="webSettings">
                {activeTab === 'webSettings' && (
                  <HTMLSettings
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="smsSettings">
                {activeTab === 'smsSettings' && (
                  <SMSSettings
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="prechatQuestionnaires">
                {activeTab === 'prechatQuestionnaires' && (
                  <PrechatQuestionnaire
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="postchatQuestionnaires">
                {activeTab === 'postchatQuestionnaires' && (
                  <PostchatQuestionnaire
                    widgetSlug={widgetSlug}
                    organizationSlug={orgSlug}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="bannedUsers">
                {activeTab === 'bannedUsers' && (
                  <BannedUsers
                    widgetSlug={widgetSlug!}
                    organizationSlug={orgSlug!}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index="themes">
                {activeTab === 'themes' && (
                  <Themes
                    widgetSlug={widgetSlug!}
                    organizationSlug={orgSlug!}
                  />
                )}
              </TabPanel>
            </ThemeProvider>
          </styled.Content>
        </styled.ContentContainer>
        <HeaderIcon />
      </layout.NewPageContainer>
    </ThemeContext.Provider>
  )
})

export default WithSpaces(WithOrganizations(WidgetView))
