/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { WidgetModel, WidgetModelType } from "./WidgetModel"
import { WidgetModelSelector } from "./WidgetModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  widget: WidgetModelType;
}

/**
 * WidgetPostChatTagBase
 * auto generated base class for the model WidgetPostChatTagModel.
 */
export const WidgetPostChatTagModelBase = withTypedRefs<Refs>()(ModelBase
  .named('WidgetPostChatTag')
  .props({
    __typename: types.optional(types.literal("WidgetPostChatTag"), "WidgetPostChatTag"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    widget: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => WidgetModel))),
    widgetId: types.identifier,
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class WidgetPostChatTagModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get widgetId() { return this.__attr(`widgetId`) }
  widget(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`widget`, WidgetModelSelector, builder) }
}
export function selectFromWidgetPostChatTag() {
  return new WidgetPostChatTagModelSelector()
}

export const widgetPostChatTagModelPrimitives = selectFromWidgetPostChatTag().name.widgetId
