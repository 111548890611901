/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum Channel {
  HTML="HTML",
SMS="SMS"
}

/**
* Channel
*/
export const ChannelEnumType = types.enumeration("Channel", [
        "HTML",
  "SMS",
      ])
