import React, { useState, useContext } from 'react'
import { AgentModelType } from 'models'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import FloatingAddButton from 'ui/components/shared/FloatingAddButton'
import ActionConext from 'context/actionContext'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'
import InlineEditButton from 'ui/components/shared/InlineEditButton'
import Button from '@material-ui/core/Button'
import { materialTheme } from 'ui/theme/ManagementView'
import { ThemeProvider } from '@material-ui/core/styles'
import StatusBubble from 'ui/components/shared/StatusBubble'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import Fab from '@material-ui/core/Fab'
import ExitToApp from '@material-ui/icons/ExitToAppRounded'
import HasPermission from 'ui/components/shared/HasPermission'
import Loading from 'ui/components/shared/Loading'
import { toJS } from 'mobx'

interface AgentsProps {
  organizationSlug: string
  widgetSlug: string
  routeTags?: any
}

const Agents = observer(
  ({ organizationSlug, widgetSlug, routeTags }: AgentsProps) => {
    const [agentEditMode, changeAgentEditMode] = useState(false)
    const [agentToBeEdited, setAgentToBeEdited] = useState<string | null>(null)
    const [agentAlias, changeAgentAlias] = useState<string | null>(null)
    const [assignedTags, setAssignedTags] = React.useState<number[]>([])
    const { loading, data, setQuery, store } = useQuery((store) =>
      store.queryWidgetFromSlug(
        {
          input: {
            organizationSlug,
            widgetSlug,
          },
        },
        (data) =>
          data.id.agents((agent) =>
            agent.alias.id.online
              .routeTags((routeTag) => routeTag.routeTag((tag) => tag.id.name))
              .user((user) => user.email.id),
          ),
      ),
    )

    const deleteAgent = (widgetId: string, agentId: string) => {
      store.mutateDeleteAgent(
        {
          input: {
            agentId: parseInt(agentId),
            widgetId,
          },
        },
        (data) =>
          data.agents((agents) =>
            agents.online.alias.id.user((user) => user.email.id),
          ),
      )
    }

    const kickAgent = (
      widgetId: string,
      userIdToLogOff: string,
      agentId: string,
    ) => {
      store.mutateGoOffline(
        { userIdToLogOff },
        (data) => data.id,
        () => {
          const widget = store.widgets.get(widgetId)
          const agents = widget!.agents
          const agent = (agents as AgentModelType[])!.find(
            (agent) => agent.id === agentId,
          )
          agent!.online = false
        },
      )
    }

    const setEditMode = (agentId: string) => {
      changeAgentEditMode(!agentEditMode)
      setAgentToBeEdited(agentId!)
    }

    const saveEdits = (
      widgetId: string,
      agentId: string,
      alias: string,
      agentRouteTags: any,
    ) => {
      if (agentAlias || assignedTags) {
        store.mutateUpdateAgent(
          {
            input: {
              agentId: parseInt(agentId),
              alias: agentAlias || alias,
              widgetId,
              routeTagIds: assignedTags || agentRouteTags,
            },
          },
          (data) =>
            data.alias.id.online.routeTags((routeTag) =>
              routeTag.routeTag((tag) => tag.id.name),
            ),
        )
        setAssignedTags([])
        changeAgentEditMode(false)
      }
    }

    const cancelEdits = () => {
      changeAgentEditMode(false)
      setAgentToBeEdited(null)
      changeAgentAlias(null)
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setAssignedTags(event.target.value as number[])
    }

    let agents: any = null
    if (!loading && data?.widgetFromSlug?.agents) {
      agents = data!.widgetFromSlug!.agents!
    }

    if (loading) {
      return <Loading tabPanel />
    }

    return (
      <styled.Container>
        {!agents ? (
          <styled.NoAgentsMessage>
            There are currently no agents for this widget. Add one by clicking
            the <strong>Add Agent</strong> button on the menu.
          </styled.NoAgentsMessage>
        ) : (
          <styled.TableOuterContainer>
            <styled.Title>Agents:</styled.Title>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Agent Email</TableCell>
                    <TableCell align="left">Agent Alias</TableCell>
                    <TableCell align="left">Assigned Tags</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agents?.map((agent: AgentModelType) => {
                    return (
                      <TableRow key={`agent_${agent.id}`}>
                        <TableCell>
                          <styled.StatusBubbleContainer>
                            <StatusBubble
                              color="rgba(11, 152, 0, 1)"
                              status={agent.online}
                            />
                          </styled.StatusBubbleContainer>
                        </TableCell>
                        <TableCell>
                          {agent.user ? agent.user.email : ''}
                        </TableCell>
                        <TableCell>
                          {agentEditMode &&
                          agentToBeEdited &&
                          agentToBeEdited === agent.id ? (
                            <>
                              <TextField
                                label={''}
                                variant="filled"
                                placeholder="Agent Alias"
                                key={`agent_alias_edit_${agent.id}`}
                                value={agentAlias || agent.alias}
                                style={{ width: '100%' }}
                                onChange={(e) =>
                                  changeAgentAlias(e.target.value)
                                }
                              />
                            </>
                          ) : (
                            <>{agent.alias}</>
                          )}
                        </TableCell>

                        <TableCell>
                          {agentEditMode &&
                          agentToBeEdited &&
                          agentToBeEdited === agent.id ? (
                            <FormControl>
                              <InputLabel id="assigned_tags_label">
                                Assigned Agent Tags
                              </InputLabel>
                              <Select
                                labelId="assigned_tags_label"
                                id="assigned_tags"
                                multiple
                                style={{ width: 200 }}
                                value={
                                  assignedTags.length > 0
                                    ? assignedTags
                                    : agent && agent!.routeTags!.length
                                    ? agent!.routeTags!.map(
                                        (tag: any) => tag.routeTag.id,
                                      )
                                    : []
                                }
                                onChange={handleChange}
                                input={<Input id="select-multiple-tags" />}
                                renderValue={(selected) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {(selected as number[]).map((value) => (
                                      <Chip
                                        key={value}
                                        style={{
                                          marginLeft: 4,
                                          marginRight: 4,
                                          marginBottom: 4,
                                          backgroundColor: 'black',
                                          color: 'white',
                                        }}
                                        label={
                                          routeTags.find(
                                            (tag: {
                                              id: number
                                              name: string
                                            }) => tag.id === value,
                                          )!.name
                                        }
                                      />
                                    ))}
                                  </div>
                                )}
                              >
                                {routeTags &&
                                  routeTags.map(
                                    (routeTag: {
                                      id: number
                                      name: string
                                    }) => (
                                      <MenuItem
                                        key={routeTag.name}
                                        value={routeTag.id}
                                      >
                                        {routeTag.name}
                                      </MenuItem>
                                    ),
                                  )}
                              </Select>
                            </FormControl>
                          ) : (
                            <styled.AssignedAgentTags>
                              {agent &&
                              agent!.routeTags &&
                              agent!.routeTags!.length > 0 ? (
                                <>
                                  {agent!.routeTags!.map((tag: any) => {
                                    if (
                                      tag &&
                                      tag!.routeTag &&
                                      tag!.routeTag!.name
                                    ) {
                                      return (
                                        <Chip
                                          key={tag.routeTag.id}
                                          style={{
                                            marginLeft: 4,
                                            marginRight: 4,
                                            marginBottom: 4,
                                            backgroundColor: 'black',
                                            color: 'white',
                                          }}
                                          label={tag.routeTag.name}
                                        />
                                      )
                                    } else {
                                      return <></>
                                    }
                                  })}
                                </>
                              ) : (
                                <>No assigned tags</>
                              )}
                            </styled.AssignedAgentTags>
                          )}
                        </TableCell>

                        <TableCell>
                          {agentEditMode &&
                          agentToBeEdited &&
                          agentToBeEdited === agent.id ? (
                            <ThemeProvider theme={materialTheme}>
                              <styled.AgentActionsContainer>
                                <styled.AgentActionButton>
                                  <Button
                                    onClick={() =>
                                      saveEdits(
                                        data!.widgetFromSlug!.id,
                                        agent.id,
                                        agent.alias || '',
                                        agent.routeTags,
                                      )
                                    }
                                    style={{
                                      color: 'white',
                                      backgroundColor: 'rgba(230, 56, 68, 1)',
                                    }}
                                    color="primary"
                                  >
                                    Save
                                  </Button>
                                </styled.AgentActionButton>
                                <styled.AgentActionButton>
                                  <Button
                                    onClick={() => cancelEdits()}
                                    style={{
                                      color: 'white',
                                      backgroundColor: 'rgba(230, 56, 68, 1)',
                                    }}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                </styled.AgentActionButton>
                              </styled.AgentActionsContainer>
                            </ThemeProvider>
                          ) : (
                            <styled.AgentActionsContainer>
                              <HasPermission
                                requiredPermissions={['ManageOrganization']}
                              >
                                <styled.AgentActionButton
                                  onClick={() => setEditMode(agent.id)}
                                >
                                  <InlineEditButton
                                    id={agent.id}
                                    passedFunction={() => null}
                                    deleteQuery={() => null}
                                  />
                                </styled.AgentActionButton>
                              </HasPermission>
                              <HasPermission
                                requiredPermissions={['ManageOrganization']}
                              >
                                <styled.AgentActionButton
                                  onClick={() =>
                                    deleteAgent(
                                      data!.widgetFromSlug!.id,
                                      agent.id,
                                    )
                                  }
                                >
                                  <InlineDeleteButton
                                    id={agent.id}
                                    passedFunction={() => null}
                                    deleteQuery={() => null}
                                  />
                                </styled.AgentActionButton>
                              </HasPermission>
                              {agent.online && (
                                <HasPermission
                                  requiredPermissions={[
                                    'ManageOrganization',
                                    'ViewOrganization',
                                  ]}
                                >
                                  <styled.AgentActionButton
                                    onClick={() =>
                                      kickAgent(
                                        data!.widgetFromSlug!.id,
                                        agent.user.id,
                                        agent.id,
                                      )
                                    }
                                  >
                                    <Fab
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'rgba(230, 56, 68, 1)',
                                      }}
                                      aria-label="add"
                                      size="small"
                                    >
                                      <ExitToApp />
                                    </Fab>
                                  </styled.AgentActionButton>
                                </HasPermission>
                              )}
                            </styled.AgentActionsContainer>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </styled.TableOuterContainer>
        )}
        <HasPermission requiredPermissions={['ManageOrganization']}>
          <FloatingAddButton
            passedFunction={
              useContext(ActionConext).agentModalVisibilityFunction!
            }
          />
        </HasPermission>
      </styled.Container>
    )
  },
)

export default WithPermission(
  ['ViewOrganization', 'ManageOrganization'],
  Agents,
  MissingPermission,
)
