import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import * as styled from './index.styled'

interface FloatingAddButtonProps {
  passedFunction: (addVisibility: boolean) => void
}

const FloatingAddButton = ({ passedFunction }: FloatingAddButtonProps) => {
  return (
    <styled.Container>
      <Fab
        style={{ color: 'white', backgroundColor: 'rgba(230, 56, 68, 1)' }}
        aria-label="add"
        onClick={() => passedFunction(true)}
      >
        <AddIcon />
      </Fab>
    </styled.Container>
  )
}

export default FloatingAddButton
