import { themes } from 'types/style.types'

const themeObject: themes = {
  california: {
    background: { color: 'rgba(240, 250, 239, 1.0)', show: false },
    secondaryBackground: { color: 'rgba(231, 239, 230, 1.0)', show: true },
    primaryHighlight: { color: 'rgba(16, 54, 85, 1.0)', show: false },
    primaryMidlight: { color: 'rgba(45, 125, 155, 1.0)', show: false },
    primaryLowlight: { color: 'rgba(195, 231, 231, 1.0)', show: false },
    secondaryHighlight: { color: 'rgba(249, 33, 70, 1.0)', show: true },
    secondaryLowlight: { color: 'rgba(249, 66, 33, 1.0)', show: true },
    secondaryTextColor: { color: 'rgba(255, 255, 255, 1.0)', show: true },
    secondaryDarkTextColor: { color: 'rgba(152, 19, 41, 1.0)', show: false },
    secondaryButtonBackgroundColor: {
      color: 'rgba(255, 255, 255, 1.0)',
      show: true,
    },
    chatInputBackgroundColor: { color: 'rgba(255, 255, 255, 1.0)', show: true },
    chatTopBorderColor: { color: 'rgba(200, 200, 200, 1.0)', show: true },
    textOnWHiteColor: { color: 'rgba(65, 65, 65, 1.0)', show: true },
    agentDashboardTextColor: { color: 'rgba(87, 87, 87, 1.0)', show: false },
    titleFont: { attribute: 'Roboto', show: false },
    titleFontSize: { attribute: '48px', show: false },
    subtitleFontSize: { attribute: '16px', show: false },
    chatFontSize: { attribute: '14px', show: false },
    contentFont: { attribute: 'Roboto', show: false },
    chatWindowHeight: { attribute: '520px;', show: false },
    chatWindowWidth: { attribute: '320px;', show: false },
    chatBubblePadding: { attribute: 6, show: false },
    chatBubbleSpacing: { attribute: 4, show: false },
  },
}

export default themeObject
