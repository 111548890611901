/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * UserOnlinePayloadBase
 * auto generated base class for the model UserOnlinePayloadModel.
 */
export const UserOnlinePayloadModelBase = ModelBase
  .named('UserOnlinePayload')
  .props({
    __typename: types.optional(types.literal("UserOnlinePayload"), "UserOnlinePayload"),
    online: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserOnlinePayloadModelSelector extends QueryBuilder {
  get online() { return this.__attr(`online`) }
}
export function selectFromUserOnlinePayload() {
  return new UserOnlinePayloadModelSelector()
}

export const userOnlinePayloadModelPrimitives = selectFromUserOnlinePayload().online
