import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme?: theme
  backgroundColor?: string
}

export const HeaderContainer = styled.div`
  width: 100vw;
  flex-grow: 0;
  height: 85px;
  grid-column: 1 / span 2;
  min-height: 85px;
  background-color: ${(props: ThemeProps) =>
    props.theme!.primaryMidlight.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LogoContainer = styled.div`
  color: white;
  font-weight: bold;
  font-family: 'Roboto';
  font-size: 28px;
  margin-left: 130px;
  margin-top: 14px;
  text-align: left;
`

export const DashboardBodyContainer = styled.div`
  width: 100vw;
  flex-grow: 1;
`

export const FunctionContainer = styled.div`
  width: 225px;
  background-color: ${(props: ThemeProps) =>
    props.theme!.primaryLowlight.color};
`

export const ContentContainer = styled.div`
  flex-grow: 1;
  background-color: ${(props: ThemeProps) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: none;
  max-height: 100%;
`

export const ContentTitle = styled.div`
  height: 60px;
  min-height: 60px;
  width: 100%;
  flex-grow: 0;
  background-color: rgba(231, 239, 230, 1);
  display: flex;
  align-items: center;
  text-indent: 20px;
  font-size: 18px;
  font-weight: medium;
  font-family: 'Roboto';
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`

export const MenuAndAccountContainer = styled.div`
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
`
