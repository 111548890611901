import styled from 'styled-components'
import { theme } from 'types/style.types'
import * as layout from 'ui/components/shared/StyledContainers/layout'

interface ThemeProps {
  theme: theme
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background-color: ${(props: ThemeProps) =>
    props.theme.secondaryBackground.color};
`

export const Header = styled(layout.ContainerRow)`
  background-color: ${(props: ThemeProps) =>
    props.theme.secondaryBackground.color};
  background: ${(props: ThemeProps) =>
    `linear-gradient(${props.theme.secondaryLowlight.color}, ${props.theme.secondaryHighlight.color})`};
  color: ${(props: ThemeProps) => props.theme.secondaryTextColor.color};
  width: 100%;
  flex-grow: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

export const BackArrow = styled(layout.ContainerRow)`
  width: 50px;
  position: absolute;
  left: 6px;
  top: 1px;
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
`

export const Exit = styled(layout.ContainerRow)`
  width: 50px;
  position: absolute;
  right: 6px;
  top: 1px;
  opacity: 0.5;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: 1;
  }
`

export const ConfiguredTitle = styled.div`
  font-family: ${(props: ThemeProps) => props.theme.titleFont.attribute};
  font-size: ${(props: ThemeProps) => props.theme.subtitleFontSize.attribute};
  width: 100%;
  text-align: center;
  font-weight: 400;
`

export const Title = styled.div`
  font-family: ${(props: ThemeProps) => props.theme.titleFont.attribute};
  font-size: ${(props: ThemeProps) => props.theme.titleFontSize.attribute};
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  margin-right: 18px;
`

export const SubTitle = styled.div`
  font-family: ${(props: ThemeProps) => props.theme.titleFont.attribute};
  font-size: 16px;
  margin-right: 18px;
`

export const MessageWindowContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

export const ChatInputContainer = styled.div`
  flex-grow: 0;
`

interface ToolBarProps {
  scrollbar: boolean
}

export const Toolbar = styled.div`
  min-width: 100%;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  position: relative;

  scrollbar-width: thin;
  scrollbar-color: #e0e0e0 #cecece;

  &:hover {
    overflow-x: auto;
    ${(props: ToolBarProps) => (props.scrollbar ? 'height: 60px;' : '')}
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 6px;
    border: 2px solid white;
  }
`

export const ShortcutContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ShortcutLabel = styled.div`
  font-size: 14px;
  font-family: 'Roboto';
  color: white;
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const Shortcut = styled.div`
  margin-left: 0px;
  flex-grow: 1;
  margin-right: 0px;
`
