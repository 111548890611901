/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatPostChatTagModel, ChatPostChatTagModelType } from "./ChatPostChatTagModel"
import { ChatPostChatTagModelSelector } from "./ChatPostChatTagModel.base"
import { MessageModel, MessageModelType } from "./MessageModel"
import { MessageModelSelector } from "./MessageModel.base"
import { PostChatQuestionnaireResponseModel, PostChatQuestionnaireResponseModelType } from "./PostChatQuestionnaireResponseModel"
import { PostChatQuestionnaireResponseModelSelector } from "./PostChatQuestionnaireResponseModel.base"
import { WidgetModel, WidgetModelType } from "./WidgetModel"
import { WidgetModelSelector } from "./WidgetModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  currentWidget: WidgetModelType;
  messages: IObservableArray<MessageModelType>;
  originationWidget: WidgetModelType;
}

/**
 * ChatBase
 * auto generated base class for the model ChatModel.
 */
export const ChatModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Chat')
  .props({
    __typename: types.optional(types.literal("Chat"), "Chat"),
    activeAgentAlias: types.union(types.undefined, types.null, types.string),
    completed: types.union(types.undefined, types.boolean),
    completedTime: types.union(types.undefined, types.null, types.frozen()),
    createdTimestamp: types.union(types.undefined, types.frozen()),
    currentWidget: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => WidgetModel))),
    hash: types.union(types.undefined, types.string),
    id: types.identifier,
    messages: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => MessageModel)))),
    offline: types.union(types.undefined, types.boolean),
    originationWidget: types.union(types.undefined, MSTGQLRef(types.late((): any => WidgetModel))),
    postChatQuestionnaireResponses: types.union(types.undefined, types.null, types.array(types.late((): any => PostChatQuestionnaireResponseModel))),
    postChatTags: types.union(types.undefined, types.null, types.array(types.late((): any => ChatPostChatTagModel))),
    sourceIP: types.union(types.undefined, types.null, types.string),
    sourcePhoneNumber: types.union(types.undefined, types.null, types.string),
    timedOut: types.union(types.undefined, types.boolean),
    widgetPhoneNumber: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ChatModelSelector extends QueryBuilder {
  get activeAgentAlias() { return this.__attr(`activeAgentAlias`) }
  get completed() { return this.__attr(`completed`) }
  get completedTime() { return this.__attr(`completedTime`) }
  get createdTimestamp() { return this.__attr(`createdTimestamp`) }
  get hash() { return this.__attr(`hash`) }
  get id() { return this.__attr(`id`) }
  get offline() { return this.__attr(`offline`) }
  get sourceIP() { return this.__attr(`sourceIP`) }
  get sourcePhoneNumber() { return this.__attr(`sourcePhoneNumber`) }
  get timedOut() { return this.__attr(`timedOut`) }
  get widgetPhoneNumber() { return this.__attr(`widgetPhoneNumber`) }
  currentWidget(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`currentWidget`, WidgetModelSelector, builder) }
  messages(builder?: string | MessageModelSelector | ((selector: MessageModelSelector) => MessageModelSelector)) { return this.__child(`messages`, MessageModelSelector, builder) }
  originationWidget(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`originationWidget`, WidgetModelSelector, builder) }
  postChatQuestionnaireResponses(builder?: string | PostChatQuestionnaireResponseModelSelector | ((selector: PostChatQuestionnaireResponseModelSelector) => PostChatQuestionnaireResponseModelSelector)) { return this.__child(`postChatQuestionnaireResponses`, PostChatQuestionnaireResponseModelSelector, builder) }
  postChatTags(builder?: string | ChatPostChatTagModelSelector | ((selector: ChatPostChatTagModelSelector) => ChatPostChatTagModelSelector)) { return this.__child(`postChatTags`, ChatPostChatTagModelSelector, builder) }
}
export function selectFromChat() {
  return new ChatModelSelector()
}

export const chatModelPrimitives = selectFromChat().activeAgentAlias.completed.completedTime.createdTimestamp.hash.offline.sourceIP.sourcePhoneNumber.timedOut.widgetPhoneNumber
