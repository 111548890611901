import React from 'react'
import * as styled from './index.styled'
import LogoSvg from 'ui/components/shared/LogoSvg'

const Loading = ({ tabPanel }: { tabPanel?: boolean }) => {
  return (
    <styled.LoadingContainer tabPanel={tabPanel}>
      <styled.LogoContainer>
        <styled.IconWrapper>
          <LogoSvg
            scale=".3"
            stopColorTop="rgba(220, 57, 69, 1.0)"
            stopColorBottom="rgba(246, 57, 69, 1.0)"
            dropShadow={true}
            width={100}
            height={120}
            strokeWidth={13}
          />
          <styled.TextWithinIcon>
            <styled.IconText>!</styled.IconText>
          </styled.TextWithinIcon>
        </styled.IconWrapper>
      </styled.LogoContainer>
      <styled.Text>Loading... Please wait</styled.Text>
    </styled.LoadingContainer>
  )
}

export default Loading
