import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
  style?: { [key: string]: string | number }
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, style } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {children}
    </div>
  )
}

export default TabPanel
