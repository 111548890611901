import styled from 'styled-components'

export const UploadDialogueContent = styled.div`
  padding-top: 20px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  border: 1px dashed rgba(140, 140, 140, 0.8);
  border-radius: 3px;
  width: 100%;
  height: 200px;
`

export const InnerDropZone = styled.div`
  width: 100%;
  min-height: 200px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FileName = styled.span`
  font-style: italic;
  margin-top: 16px;
  font-weight: bolder;
`
