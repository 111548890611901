import styled from 'styled-components'

interface WidgetAndDataContainerProps {
  expanded: boolean
}

export const WidgetAndDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 15px;
  ${(props: WidgetAndDataContainerProps) =>
    props.expanded && 'border-bottom: 1px rgba(0, 0, 0, 0.1) solid;'}
  padding-bottom: 14px;
`

export const WidgetName = styled.div`
  font-size: 14px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WidgetData = styled.div`
  width: 100%;
`

export const ExpandButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  height: 26px;
  width: 26px;
  font-size: 14px;
  color: white;
  background-color: rgba(230, 56, 68, 1);
  border-radius: 50%;
  cursor: pointer;
`

export const ExpandButtonText = styled.span`
  margin-top: -2px;
`
