/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VariableModel, VariableModelType } from "./VariableModel"
import { VariableModelSelector } from "./VariableModel.base"
import { RootStoreType } from "./index"


/**
 * PreChatQuestionBase
 * auto generated base class for the model PreChatQuestionModel.
 */
export const PreChatQuestionModelBase = ModelBase
  .named('PreChatQuestion')
  .props({
    __typename: types.optional(types.literal("PreChatQuestion"), "PreChatQuestion"),
    id: types.union(types.undefined, types.integer),
    ordinal: types.union(types.undefined, types.integer),
    question: types.union(types.undefined, types.null, types.string),
    variable: types.union(types.undefined, types.null, types.late((): any => VariableModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PreChatQuestionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get question() { return this.__attr(`question`) }
  variable(builder?: string | VariableModelSelector | ((selector: VariableModelSelector) => VariableModelSelector)) { return this.__child(`variable`, VariableModelSelector, builder) }
}
export function selectFromPreChatQuestion() {
  return new PreChatQuestionModelSelector()
}

export const preChatQuestionModelPrimitives = selectFromPreChatQuestion().ordinal.question
