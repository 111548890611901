/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RouteModel, RouteModelType } from "./RouteModel"
import { RouteModelSelector } from "./RouteModel.base"
import { VariableModel, VariableModelType } from "./VariableModel"
import { VariableModelSelector } from "./VariableModel.base"
import { WidgetSettingsRouteConditionModel, WidgetSettingsRouteConditionModelType } from "./WidgetSettingsRouteConditionModel"
import { WidgetSettingsRouteConditionModelSelector } from "./WidgetSettingsRouteConditionModel.base"
import { RootStoreType } from "./index"


/**
 * SmsSettingsBase
 * auto generated base class for the model SmsSettingsModel.
 */
export const SmsSettingsModelBase = ModelBase
  .named('SmsSettings')
  .props({
    __typename: types.optional(types.literal("SmsSettings"), "SmsSettings"),
    conditions: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => WidgetSettingsRouteConditionModel)))),
    greetingMessage: types.union(types.undefined, types.null, types.string),
    id: types.union(types.undefined, types.integer),
    phoneNumber: types.union(types.undefined, types.null, types.string),
    responseVariable: types.union(types.undefined, types.null, types.late((): any => VariableModel)),
    route: types.union(types.undefined, types.null, types.late((): any => RouteModel)),
    webIconImageURL: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SmsSettingsModelSelector extends QueryBuilder {
  get greetingMessage() { return this.__attr(`greetingMessage`) }
  get id() { return this.__attr(`id`) }
  get phoneNumber() { return this.__attr(`phoneNumber`) }
  get webIconImageURL() { return this.__attr(`webIconImageURL`) }
  conditions(builder?: string | WidgetSettingsRouteConditionModelSelector | ((selector: WidgetSettingsRouteConditionModelSelector) => WidgetSettingsRouteConditionModelSelector)) { return this.__child(`conditions`, WidgetSettingsRouteConditionModelSelector, builder) }
  responseVariable(builder?: string | VariableModelSelector | ((selector: VariableModelSelector) => VariableModelSelector)) { return this.__child(`responseVariable`, VariableModelSelector, builder) }
  route(builder?: string | RouteModelSelector | ((selector: RouteModelSelector) => RouteModelSelector)) { return this.__child(`route`, RouteModelSelector, builder) }
}
export function selectFromSmsSettings() {
  return new SmsSettingsModelSelector()
}

export const smsSettingsModelPrimitives = selectFromSmsSettings().greetingMessage.phoneNumber.webIconImageURL
