import React from 'react'
import * as styled from './index.styled'

const MissingPermission = () => {
    return (
        <styled.MessageContainer>
            You do not have the required access for this content.  Please contact your administrator if you believe this to be in error.
        </styled.MessageContainer>
    )
}

export default MissingPermission