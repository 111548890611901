import React, { useState } from 'react'
import * as styled from './index.styled'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import { TransitionProps } from '@material-ui/core/transitions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// import WidgetAnalytics from './WidgetAnalytics'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ThemeProvider } from '@material-ui/core/styles'
import { onlineAndOfflineSwitchTheme } from 'ui/theme/ManagementView'
import { MaxChatValue } from 'models'
import { toJS } from 'mobx'
import WidgetAnalytics from 'ui/components/Modals/Analytics/WidgetAnalytics'

interface AnalyticsProps {
  visibility: boolean
  openFunction: (visibility: boolean) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Analytics = observer(({ visibility, openFunction }: AnalyticsProps) => {
  const [timePeriod, setTimePeriod] = useState(24)
  const closeModal = (event: React.ChangeEvent<{}>) => {
    openFunction(false)
  }

  const { data, store, setQuery } = useQuery((store) =>
    store.queryWidgetAnalytics({ input: { timePeriod } }, (data) =>
      data.averageAnswerTimeSeconds.averageChatLengthSeconds.longestResponseTimeSeconds.numberOfChats.numberOfOnlineChats.shortestResponseTimeSeconds.widgetId.widgetName
        .widgetAgents((agent) => agent.id.alias.online)
        .responseTimeHistogram((time) => time.key.value)
        .chatDistribution((chat) => chat.key.value),
    ),
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timePeriod = event.target.checked ? 168 : 24

    setQuery(
      store.queryWidgetAnalytics({ input: { timePeriod } }, (data) =>
        data.averageAnswerTimeSeconds.averageChatLengthSeconds.longestResponseTimeSeconds.numberOfChats.numberOfOnlineChats.shortestResponseTimeSeconds.widgetId.widgetName
          .widgetAgents((agent) => agent.id.alias.online)
          .responseTimeHistogram((time) => time.key.value)
          .chatDistribution((chat) => chat.key.value),
      ),
    )
    setTimePeriod(timePeriod)
  }

  const analyticsTotals: MaxChatValue[] = []

  if (data?.widgetAnalytics) {
    data?.widgetAnalytics.forEach((widget) => {
      const max: number = widget?.chatDistribution?.reduce(
        (acc: number, dateValue: { key: string; value: number }) => {
          if (acc > dateValue.value) {
            return acc
          } else {
            return dateValue.value
          }
        },
        0,
      )
      const analyticTotal = {
        key: widget.widgetId,
        max,
      }
      analyticsTotals.push(analyticTotal)
    })
  }

  return (
    <Dialog
      open={visibility}
      onClose={closeModal}
      fullWidth
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Analytics</DialogTitle>
      <DialogContent>
        {data?.widgetAnalytics && data?.widgetAnalytics.length > 0 && (
          <styled.AnalyticsContainer>
            {data?.widgetAnalytics.map((analytics) => {
              return (
                <WidgetAnalytics
                  key={`widget_analytics_${analytics.widgetId}`}
                  analytics={analytics}
                  maxChatValue={analyticsTotals?.find(
                    (maxWidget) => maxWidget.key === analytics.widgetId,
                  )}
                  timePeriod={timePeriod}
                />
              )
            })}
          </styled.AnalyticsContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Close
        </Button>
      </DialogActions>
      <styled.RefreshButtonAndTimeSelectContainer>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Last 24 Hours</Grid>
            <Grid item>
              <ThemeProvider theme={onlineAndOfflineSwitchTheme}>
                <Switch
                  checked={timePeriod === 168}
                  onChange={handleChange}
                  color="default"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item>Last 7 Days</Grid>
          </Grid>
        </Typography>
        <Button
          onClick={() =>
            setQuery(
              store.queryWidgetAnalytics({ input: { timePeriod } }, (data) =>
                data.averageAnswerTimeSeconds.averageChatLengthSeconds.longestResponseTimeSeconds.numberOfChats.numberOfOnlineChats.shortestResponseTimeSeconds.widgetId.widgetName
                  .widgetAgents((agent) => agent.id.alias.online)
                  .responseTimeHistogram((time) => time.key.value)
                  .chatDistribution((chat) => chat.key.value),
              ),
            )
          }
        >
          <FontAwesomeIcon
            size="1x"
            icon={faRedoAlt}
            style={{ marginRight: 5 }}
          />
          Refresh
        </Button>
      </styled.RefreshButtonAndTimeSelectContainer>
    </Dialog>
  )
})

export default Analytics
