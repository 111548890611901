import React from 'react'
import * as styled from './Agent.styled'
import { AgentModelType } from 'models'
import StatusBubble from 'ui/components/shared/StatusBubble'

interface AgentProps {
  agent: AgentModelType
}

const Agent = ({ agent }: AgentProps) => {
  return (
    <styled.Agent>
      <StatusBubble
        color="rgba(11, 152, 0, 1)"
        style={{ marginTop: 2 }}
        status={agent.online}
      />
      {agent.alias}
    </styled.Agent>
  )
}

export default Agent
