import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import { SpaceModelType, StoreContext } from 'models'

const DeleteOrgModal = observer(
  ({
    visibility,
    openFunction,
    organizationDetails,
    space,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    organizationDetails: { id: string; name: string }
    space: SpaceModelType
  }) => {
    const store = useContext(StoreContext)

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    const deleteOrganization = () => {
      const query = store.mutateDeleteOrganization(
        { input: { id: organizationDetails.id } },
        (data) =>
          data.id.name.deleted.organizations(
            (organization) => organization.id.name.deleted,
          ),
      )

      query.then((data) => {
        space.deleteOrganization(data.deleteOrganization.id)
        openFunction(false)
      })
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Delete Organization: {organizationDetails.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking continue will delete this organization. This action is
            irreversible. Continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteOrganization} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default DeleteOrgModal
