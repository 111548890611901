export const mockedSortedMessages = [
  {
    __typename: 'Message',
    body: 'Customer has arrived at the North Entrance.',
    chat: '01749584a1e143c4928dd378c90d75d3',
    id: '01749584dbeb4f17b88d190c9c0d3a64',
    senderAlias: '',
    senderType: 'SYSTEMAGENT',
    timestamp: '2020-09-16T02:05:46.347-04:00',
    seen: true,
    soundPlayed: false,
    agent: {},
    agentId: '',
  },
  {
    __typename: 'Message',
    body: 'Hello, Im here to pick up my book.',
    chat: '01749584a1e143c4928dd378c90d75d3',
    id: '01749584f88f491e9582ae7c877e5e6a',
    senderAlias: '',
    senderType: 'USER',
    timestamp: '2020-09-16T02:05:53.679-04:00',
    seen: true,
    soundPlayed: false,
    agent: {},
    agentId: '',
  },
  {
    __typename: 'Message',
    body: "We'll bring your book right out!",
    chat: '01749584a1e143c4928dd378c90d75d3',
    id: '017495851efc4dc4809526abe9afa29a',
    senderAlias: 'Agent Alias',
    senderType: 'AGENT',
    timestamp: '2020-09-16T02:06:03.516-04:00',
    seen: true,
    soundPlayed: false,
    agent: {},
    agentId: '',
  },
]
