import React, { useState, useContext } from 'react'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddAdminModal from 'ui/components/Modals/AddAdminModal'
import ActionContext, { modifyActionContext } from 'context/actionContext'
import * as styled from './AddAdminButton.styled'
import WithPermission from 'ui/components/shared/WithPermission'

const AddAdminButton = ({ widgetId }: { widgetId?: string }) => {
  const [agentModalVisibility, setAgentModalVisibility] = useState(false)

  const actionContext = useContext(ActionContext)
  modifyActionContext(
    actionContext,
    'agentModalVisibilityFunction',
    setAgentModalVisibility,
  )
  return (
    <styled.MenuItemContainer onClick={() => setAgentModalVisibility(true)}>
      <div>
        <FontAwesomeIcon icon={faUserLock} />
      </div>
      <div>Add Admin</div>
      <AddAdminModal
        widgetId={widgetId!}
        visibility={agentModalVisibility}
        openFunction={setAgentModalVisibility}
      />
    </styled.MenuItemContainer>
  )
}

export default WithPermission(['ManageOrganization'], AddAdminButton)
