import styled from 'styled-components'

export const ErrorText = styled.div`
  font-size: 12px;
  margin-top: 20px;
  color: red;
`

export const SearchInput = styled.div`
  display: flex;
  flex-direction: row;
`

export const SearchMessage = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`
