/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * DeleteShortcutPayloadBase
 * auto generated base class for the model DeleteShortcutPayloadModel.
 */
export const DeleteShortcutPayloadModelBase = ModelBase
  .named('DeleteShortcutPayload')
  .props({
    __typename: types.optional(types.literal("DeleteShortcutPayload"), "DeleteShortcutPayload"),
    shortcutId: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DeleteShortcutPayloadModelSelector extends QueryBuilder {
  get shortcutId() { return this.__attr(`shortcutId`) }
}
export function selectFromDeleteShortcutPayload() {
  return new DeleteShortcutPayloadModelSelector()
}

export const deleteShortcutPayloadModelPrimitives = selectFromDeleteShortcutPayload().shortcutId
