/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PrivilegeBase
 * auto generated base class for the model PrivilegeModel.
 */
export const PrivilegeModelBase = ModelBase
  .named('Privilege')
  .props({
    __typename: types.optional(types.literal("Privilege"), "Privilege"),
    id: types.identifier,
    privilegeName: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PrivilegeModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get privilegeName() { return this.__attr(`privilegeName`) }
}
export function selectFromPrivilege() {
  return new PrivilegeModelSelector()
}

export const privilegeModelPrimitives = selectFromPrivilege().privilegeName
