import React from 'react'

interface TimeProps {
  time: number
}

const Time = ({ time }: TimeProps) => {
  if (time <= 59) {
    return <>{Math.round(time)} seconds</>
  } else {
    const minutes = Math.floor(time / 60)
    return (
      <>
        {minutes} {minutes > 1 ? 'mins' : 'min'}{' '}
        {Math.round(time - minutes * 60)} seconds
      </>
    )
  }
}

export default Time
