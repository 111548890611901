import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import { GetApiKeysPayloadModelType } from 'models'

const ModifyApiKeysModal = observer(
  ({
    visibility,
    openFunction,
    organizationId,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    organizationId?: string
  }) => {
    const [telnyxKey, setTelnyxKey] = React.useState('')
    const [twilioId, setTwilioId] = React.useState('')
    const [twilioToken, setTwilioToken] = React.useState('')
    const [plivoId, setPlivoId] = React.useState('')
    const [plivoToken, setPlivoToken] = React.useState('')

    const { data, setQuery, store } = useQuery<
      | {
          apiKeys: GetApiKeysPayloadModelType
        }
      | undefined
    >()

    useEffect(() => {
      console.log(organizationId)
      setQuery(store.queryApiKeys())
    }, [organizationId, visibility])

    useEffect(() => {
      setTelnyxKey(data?.apiKeys?.telnyxApiKey || '')
      setTwilioId(data?.apiKeys?.twilioApiKeyId || '')
      setTwilioToken(data?.apiKeys?.twilioApiKeyToken || '')
      setPlivoId(data?.apiKeys?.plivoApiKeyId || '')
      setPlivoToken(data?.apiKeys?.plivoApiKeyToken || '')
    }, [data?.apiKeys])

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update API Keys</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="telnyxApiKey"
            label="Telnyx Key"
            type="text"
            fullWidth
            value={telnyxKey}
            onChange={(event) => setTelnyxKey(event.target.value)}
          />
          <TextField
            margin="dense"
            id="twilioKeyId"
            label="Twilio Key Id"
            type="text"
            fullWidth
            value={twilioId}
            onChange={(event) => setTwilioId(event.target.value)}
          />
          <TextField
            margin="dense"
            id="twilioApiToken"
            label="Twilio Key Token"
            type="text"
            fullWidth
            value={twilioToken}
            onChange={(event) => setTwilioToken(event.target.value)}
          />
          <TextField
            margin="dense"
            id="plivoKeyId"
            label="Plivo Key Id"
            type="text"
            fullWidth
            value={plivoId}
            onChange={(event) => setPlivoId(event.target.value)}
          />
          <TextField
            margin="dense"
            id="plivoKeyToken"
            label="Plivo Key Token"
            type="text"
            fullWidth
            value={plivoToken}
            onChange={(event) => setPlivoToken(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <SaveButton
            twilioApiKeyId={twilioId}
            twilioApiKeyToken={twilioToken}
            plivoApiKeyId={plivoId}
            plivoApiKeyToken={plivoToken}
            telnyxApiKey={telnyxKey}
          />
        </DialogActions>
      </Dialog>
    )
  },
)

interface SaveButtonProps {
  telnyxApiKey: string
  twilioApiKeyId: string
  twilioApiKeyToken: string
  plivoApiKeyId: string
  plivoApiKeyToken: string
}

const SaveButton: React.FC<SaveButtonProps> = ({
  telnyxApiKey,
  twilioApiKeyId,
  twilioApiKeyToken,
  plivoApiKeyId,
  plivoApiKeyToken,
}) => {
  const { setQuery, store } = useQuery()

  return (
    <Button
      onClick={() =>
        setQuery(
          store.mutateUpdateApiKeys({
            input: {
              telnyxApiKey,
              twilioApiKeyId,
              twilioApiKeyToken,
              plivoApiKeyId,
              plivoApiKeyToken,
            },
          }),
        )
      }
      color="primary"
    >
      Update
    </Button>
  )
}

export default ModifyApiKeysModal
