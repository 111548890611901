import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme: theme
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  font-family: ${(props: ThemeProps) => props.theme.contentFont.attribute};
  color: ${(props: ThemeProps) => props.theme.agentDashboardTextColor.color};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: scroll;
`

export const ScrollReset = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: scroll;
`

export const Title = styled.div`
  font-size: 18px;
  padding-left: 20px;
  font-weight: bold;
`

export const HistoricalTitleAndCollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ExpandOrContractHistory = styled.div`
  margin-top: 4px;
  margin-right: 20px;
  cursor: pointer;
`

export const ChatNote = styled.div`
  max-width: 265px;
  font-family: 'Roboto';
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`
