import styled from 'styled-components'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Roboto';
`

export const ThemeListAndPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 550px;
  margin-left: 16px;
`

export const List = styled.div`
  flex-grow: 1;
  width: 50%;
  flex-direction: column;
`

export const Preview = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`
