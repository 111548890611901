import React, { useState } from 'react'
import * as styled from './index.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCircle,
  faSignOutAlt,
  faChartBar,
  faCog,
} from '@fortawesome/free-solid-svg-icons'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Analytics from 'ui/components/Modals/Analytics'
import HubSettings from 'ui/components/Modals/HubSettings'

interface HeaderMenuProps {
  hub?: boolean
}

const HeaderMenu = ({ hub }: HeaderMenuProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [analyticsVisibility, setAnalyticsVisibility] = useState(false)
  const [hubSettingsVisibility, setHubSettingsVisibility] = useState(false)

  const openMenu = (event: any) => {
    setMenuAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
  }

  return (
    <styled.MenuContainer>
      {hub && (
        <>
          <FontAwesomeIcon
            icon={faChartBar}
            size="2x"
            onClick={() => setAnalyticsVisibility(!analyticsVisibility)}
          />
          {analyticsVisibility && (
            <Analytics
              visibility={analyticsVisibility}
              openFunction={setAnalyticsVisibility}
            />
          )}
        </>
      )}
      {hub && (
        <>
          <FontAwesomeIcon
            icon={faCog}
            size="2x"
            onClick={() => setHubSettingsVisibility(!hubSettingsVisibility)}
          />
          {hubSettingsVisibility && (
            <HubSettings
              visibility={hubSettingsVisibility}
              openFunction={setHubSettingsVisibility}
            />
          )}
        </>
      )}
      <FontAwesomeIcon icon={faUserCircle} size="2x" onClick={openMenu} />
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => window.location.replace('/logout')}>
          Log Out
          <FontAwesomeIcon
            style={{ marginLeft: 7 }}
            icon={faSignOutAlt}
            size="1x"
          />
        </MenuItem>
      </Menu>
    </styled.MenuContainer>
  )
}

export default HeaderMenu
