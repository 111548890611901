import styled from 'styled-components'

export const LogoAndMessageContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TextWithinIcon = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  font-size: 24px;
  position: absolute;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconText = styled.div`
  margin-top: 6px;
  margin-right: 3px;
  transform: rotate(5deg);
  position: absolute;
`

export const IconWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
