import React, { useState } from 'react'
import {
  SmsSettingsModelType,
  PreChatQuestionnaireModelType,
  ConditionExpression,
} from 'models'
import * as styled from './Settings.styled'
// import FloatingAddButton from 'ui/components/shared/FloatingAddButton'
import InlineAddButton from 'ui/components/shared/InlineAddButton'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import { observer, useLocalStore } from 'mobx-react'
import Route from 'ui/components/ManagementView/TabPanels/Widget/shared/Route'
import ConditionalRoute from 'ui/components/ManagementView/TabPanels/Widget/shared/ConditionalRoutes'
import GreetingMessage from 'ui/components/ManagementView/TabPanels/Widget/shared/GreetingMessage'
import PhoneNumber from 'ui/components/ManagementView/TabPanels/Widget/shared/PhoneNumber'
import QuestionnaireSelect from 'ui/components/ManagementView/TabPanels/Widget/shared/QuestionnaireSelect'
import * as copy from 'ui/components/ManagementView/TabPanels/Widget/shared/Settings.copy'
import { settingsLocalStore } from 'ui/components/ManagementView/TabPanels/Widget/shared/Settings.utilities'
import { RouteType } from 'models/RouteTypeEnum'
import { Channel } from 'models/ChannelEnum'
import { toJS } from 'mobx'
import orderBy from 'lodash/orderBy'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import TextLimitDecorator from 'ui/components/shared/TextLimitDecorator'

interface SMSSettingsProps {
  settings: SmsSettingsModelType
  widgetId: string
  questionnaireData?: PreChatQuestionnaireModelType[]
  routeTags?: any
}

const inputStyle = { width: '100%' }

const SMSSettings = observer(
  ({ settings, widgetId, questionnaireData, routeTags }: SMSSettingsProps) => {
    const settingsStore = useLocalStore(
      settingsLocalStore(toJS(settings), settings?.phoneNumber || ''),
    )
    const errorDefaultState = {
      error: false,
      errorMessage: '',
    }
    const [errors, setErrors] = useState(errorDefaultState)

    const { setQuery, store } = useQuery()

    const deleteConditional = (id?: number) => {
      setQuery(
        store.mutateRemoveSettingsRouteCondition({
          input: {
            channel: Channel['SMS'],
            routeConditionId: id!,
            widgetId,
          },
        }),
      )
    }

    const checkForConditionalRouteErrors = () => {
      let error = false
      const conditionals =
        settingsStore.formattingConditionalsForUpdating() || []
      if (conditionals && conditionals.length > 0) {
        let anyCount = 0
        conditionals.forEach((conditional) => {
          if (conditional.expression === ConditionExpression.ANY) {
            anyCount = anyCount + 1
            if (anyCount > 1) {
              setErrors({
                error: true,
                errorMessage:
                  'You may have only one "any" conditional expression for this setting.',
              })
              error = true
            }
          }

          if (
            conditional.expression === ConditionExpression.EQUALS &&
            (!conditional.expressionValue ||
              (conditional.expressionValue &&
                (conditional.expressionValue === null ||
                  conditional.expressionValue.length < 1)))
          ) {
            setErrors({
              error: true,
              errorMessage:
                'You must complete the expression criteria for any "equals" conditional expression.',
            })
            error = true
          }
        })
      }
      return error ? false : conditionals
    }

    const submitUpdates = () => {
      setErrors(errorDefaultState)
      if (settingsStore.conditionsToUpdate) {
        const conditions = checkForConditionalRouteErrors()
        if (conditions) {
          setQuery(
            store.mutateUpdateSettingsRouteConditions({
              input: {
                channel: Channel.SMS,
                conditions,
                widgetId,
              },
            }),
          )
          settingsStore.updateConditionalFlag(false)
        }
      }

      if (settingsStore.routeToUpdate) {
        setQuery(
          store.mutateUpdateDefaultRoute({
            input: {
              channel: Channel['SMS'],
              routeType: settingsStore.route.type,
              routeValue: String(settingsStore.route.value),
              widgetId,
            },
          }),
        )
        settingsStore.updateRouteFlag(false)
      }

      if (
        settingsStore.greetingMessageToUpdate ||
        settingsStore.phoneNumberToUpdate
      ) {
        let phoneNumber = settingsStore.phoneNumber || ''
        if (settingsStore.phoneNumberToUpdate) {
          let unformattedPhoneNumber = settingsStore.phoneNumber!.replace(
            /[- )(]/g,
            '',
          )
          phoneNumber = `+${unformattedPhoneNumber}`
        }
        setQuery(
          store.mutateUpdateWidgetChannelSettings({
            input: {
              channel: Channel['SMS'],
              greetingMessage: settingsStore.greetingMessage || '',
              phoneNumber,
              widgetId,
            },
          }),
        )
        settingsStore.updateGreetingMessageFlag(false)
      }
    }

    const createCondition = () => {
      settingsStore.addCondition(widgetId)
    }

    return (
      <styled.Container>
        <styled.Form>
          <styled.Title>Messages:</styled.Title>
          <styled.GreetingMessageContainer>
            <GreetingMessage
              value={settingsStore.greetingMessage}
              changeValue={settingsStore.updateGreetingMessage}
            />
            <TextLimitDecorator
              characterCount={settingsStore?.greetingMessage?.length || 0}
              characterLimit={160}
              showMessage={false}
            />
          </styled.GreetingMessageContainer>
          <styled.FormNote>
            {copy.greetingMessageNote}{' '}
            <em>{copy.smsGreetingMessageTextNote}</em>
          </styled.FormNote>
          <styled.Title>SMS Phone Number:</styled.Title>
          <div>
            <PhoneNumber
              value={
                settingsStore.phoneNumber
                  ? settingsStore.phoneNumber.replace('+', '')
                  : ''
              }
              changeValue={settingsStore.updatePhoneNumber}
            />
          </div>
          <styled.Title>
            Provider: {store.widgets.get(widgetId)?.smsProvider}
          </styled.Title>
          <styled.FormNote>{copy.phoneNumberMessageNote}</styled.FormNote>
          <styled.Title>Routes:</styled.Title>
          <styled.SubTitle>{copy.unspecifiedConditionsNotes}</styled.SubTitle>
          <Route
            value={settingsStore.route.type}
            changeRoute={settingsStore.updateRoute}
            placeHolder="Default Route"
            inputStyle={inputStyle}
            widgetId={widgetId}
            defaultRoute={true}
          />
          <styled.FormNote>{copy.defaultRouteNote}</styled.FormNote>
          {settingsStore.route.type === RouteType['PRECHATQUESTIONNAIRE'] && (
            <>
              <QuestionnaireSelect
                questionnaireData={questionnaireData}
                helperText={copy.questionnaireNote}
                value={settingsStore.route.value}
                defaultRoute={true}
                changeValue={settingsStore.updateRouteWithQuestionnaire}
              />
              <styled.FormNote />
            </>
          )}
          <styled.Title>Conditional Route:</styled.Title>

          {settingsStore &&
            settingsStore.conditions &&
            orderBy(settingsStore.conditions, 'id', 'asc').map((condition) => {
              return (
                <styled.ConditionalWrapper key={condition.id}>
                  <styled.ConditionalFormContainer>
                    <ConditionalRoute
                      questionnaireData={questionnaireData}
                      helperText={copy.questionnaireConditionalNote}
                      conditionId={condition.id}
                      selectedVariable={condition.variable}
                      setSelectedVariable={() => null}
                      variables={[settingsStore.responseVariable]}
                      value={condition.route.type}
                      changeValue={settingsStore.updateRoute}
                      widgetId={widgetId}
                      defaultRoute={false}
                      questionnaireSelectValue={condition.route.value}
                      questionnaireSelectChangeValue={
                        settingsStore.updateRouteWithQuestionnaire
                      }
                      selectedExpression={
                        condition.expression ? condition.expression : null
                      }
                      setConditionalExpression={
                        settingsStore.setConditionalExpression
                      }
                      expressionCriteria={condition.value}
                      changeExpressionCriteria={
                        settingsStore.changeExpressionCriteria
                      }
                      source="settings"
                      routeTags={routeTags}
                      changeConditionalRouteTag={settingsStore.setRouteTag}
                      selectedRouteTag={condition.route}
                    />
                  </styled.ConditionalFormContainer>
                  <styled.ConditionalDeleteIconContainer>
                    <InlineDeleteButton
                      passedFunction={settingsStore.deleteCondition}
                      deleteQuery={deleteConditional}
                      id={condition.id}
                    />
                  </styled.ConditionalDeleteIconContainer>
                </styled.ConditionalWrapper>
              )
            })}

          {errors.error && (
            <styled.SubmissionError>
              {errors.errorMessage}
            </styled.SubmissionError>
          )}

          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createCondition} />
            <styled.InlineButtonText>
              Click here to add a conditional route.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
          <styled.FormNote>{copy.conditionalRouteNote}</styled.FormNote>
          <Button
            style={{
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
              marginBottom: 20,
            }}
            onClick={submitUpdates}
          >
            Save SMS Settings
          </Button>
          {/* <FloatingAddButton passedFunction={() => null} /> */}
        </styled.Form>
      </styled.Container>
    )
  },
)

export default WithPermission(
  ['ManageOrganization'],
  SMSSettings,
  MissingPermission,
)
