/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PostChatQuestionBase
 * auto generated base class for the model PostChatQuestionModel.
 */
export const PostChatQuestionModelBase = ModelBase
  .named('PostChatQuestion')
  .props({
    __typename: types.optional(types.literal("PostChatQuestion"), "PostChatQuestion"),
    id: types.union(types.undefined, types.integer),
    ordinal: types.union(types.undefined, types.integer),
    question: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PostChatQuestionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get question() { return this.__attr(`question`) }
}
export function selectFromPostChatQuestion() {
  return new PostChatQuestionModelSelector()
}

export const postChatQuestionModelPrimitives = selectFromPostChatQuestion().ordinal.question
