export const bannedMockedSortedMessages = [
  {
    __typename: 'Message',
    body:
      'Your device has been banned.  If you believe this is in error please contact an administrator.',
    chat: '01749584a1e143c4928dd378c90d75d3',
    id: '01749584dbeb4f17b88d190c9c0d3a64',
    senderAlias: '',
    senderType: 'SYSTEMAGENT',
    timestamp: null,
    seen: true,
    soundPlayed: false,
    agent: {},
    agentId: '',
  },
]
