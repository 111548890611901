import React from 'react'
import * as styled from './WidgetAnalytics.styled'
import ActivityBar from './ActivityBar'
import Time from './Time'
import Agent from './Agent'
import { MaxChatValue } from 'models'
import ResponseTimeHistogram from './ResponseTimeHistogram'
import { observer } from 'mobx-react'
import ActivityBarXAxis from './ActivityBarXAxis'

interface WidgetAnalyticsProps {
  analytics: any
  maxChatValue?: MaxChatValue
  timePeriod: number
}

interface ActivityBarDataPoint {
  key: string
  value: number
}

interface ActvityBarContainerProps {
  week: boolean
  analytics: any
  maxChatValue?: MaxChatValue
}

const ActvityBarContainer = ({
  week,
  analytics,
  maxChatValue,
}: ActvityBarContainerProps) => {
  const days = Array(week ? 7 : 1).fill(0)
  return (
    <>
      {days.map((day, i) => {
        const date = new Date()
        date.setDate(date.getDate() - i)
        let calculatedDay = date.getDate()
        let month = date.getMonth() + 1

        const filteredData = analytics.chatDistribution.filter(
          (dataPoint: ActivityBarDataPoint) =>
            new Date(dataPoint.key).getTime() < date.getTime(),
        )

        return (
          <ActivityBar
            total={analytics.numberOfChats}
            data={filteredData}
            maxChatValue={maxChatValue}
            week={week}
            printDate={`${month}/${calculatedDay}`}
            daysAgo={i}
          />
        )
      })}
    </>
  )
}

const WidgetAnalytics = observer(
  ({ analytics, maxChatValue, timePeriod }: WidgetAnalyticsProps) => {
    if (analytics) {
      const timePeriodMessage = `In the last ${
        timePeriod === 24 ? '24 hours' : '7 Days'
      }`
      return (
        <styled.WidgetAnalytics>
          <styled.Name>{analytics.widgetName}</styled.Name>
          <styled.Stats>
            <styled.StatTimePerChat>
              <styled.StatChatsCountNumberAndText>
                <styled.StatTitle>Average Length of Chat</styled.StatTitle>
                <Time time={analytics.averageChatLengthSeconds} />
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                <styled.StatTitle type="subTitle">
                  Fastest Response
                </styled.StatTitle>
                <Time time={analytics.shortestResponseTimeSeconds} />
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                <styled.StatTitle type="subTitle">
                  Average Response
                </styled.StatTitle>
                <Time time={analytics.averageAnswerTimeSeconds} />
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                <styled.StatTitle type="subTitle">
                  Slowest Response
                </styled.StatTitle>
                <Time time={analytics.longestResponseTimeSeconds} />
              </styled.StatChatsCountNumberAndText>
            </styled.StatTimePerChat>

            <styled.StatTotalChats>
              <styled.StatChatsCountNumberAndText type="online">
                <div style={{ marginTop: '-17px' }}>
                  {analytics.numberOfOnlineChats}
                </div>
                <styled.StatTotalChatstext>
                  <div>Online Chats</div>
                  <styled.StatTotalChatSubtext>
                    {timePeriodMessage}
                  </styled.StatTotalChatSubtext>
                </styled.StatTotalChatstext>
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                +
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText type="offline">
                <div style={{ marginTop: '-17px' }}>
                  {analytics.numberOfChats - analytics.numberOfOnlineChats}
                </div>
                <styled.StatTotalChatstext>
                  <div>Offline Chats</div>
                  <styled.StatTotalChatSubtext>
                    {timePeriodMessage}
                  </styled.StatTotalChatSubtext>
                </styled.StatTotalChatstext>
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                =
              </styled.StatChatsCountNumberAndText>

              <styled.StatChatsCountNumberAndText>
                <div style={{ marginTop: '-17px' }}>
                  {analytics.numberOfChats}
                </div>
                <styled.StatTotalChatstext>
                  <div>Total Chats</div>
                  <styled.StatTotalChatSubtext>
                    {timePeriodMessage}
                  </styled.StatTotalChatSubtext>
                </styled.StatTotalChatstext>
              </styled.StatChatsCountNumberAndText>
            </styled.StatTotalChats>
          </styled.Stats>
          <styled.Agents>
            <styled.AgentsTitle>Online Agents</styled.AgentsTitle>
            {analytics?.widgetAgents &&
              analytics!.widgetAgents!.length > 0 &&
              analytics?.widgetAgents?.map((agent: any) => {
                return agent.online ? (
                  <Agent key={`agent_${agent.id}`} agent={agent} />
                ) : (
                  <></>
                )
              })}
          </styled.Agents>
          <styled.ResponseTimeHistogramContainer>
            <div style={{ marginBottom: 6 }}>Response Time Histogram</div>
            <ResponseTimeHistogram data={analytics.responseTimeHistogram} />
            {analytics.responseTimeHistogram.length === 0 && (
              <styled.ResponseTimeNoChatsMessage>
                There were no completed chats over this time period.
              </styled.ResponseTimeNoChatsMessage>
            )}
          </styled.ResponseTimeHistogramContainer>
          <styled.Activity>
            <styled.ActivityTitle>
              Activity (Last {timePeriod === 168 ? '7 Days' : '24 Hours'})
            </styled.ActivityTitle>
            <ActivityBarXAxis />
            <ActvityBarContainer
              week={timePeriod === 168}
              analytics={analytics}
              maxChatValue={maxChatValue}
            />
          </styled.Activity>
        </styled.WidgetAnalytics>
      )
    } else {
      return <div />
    }
  },
)

export default WidgetAnalytics
