import React from 'react'
import { PreChatQuestionnaireModelType } from 'models'
import * as styled from './ListQuestionnaires.styled'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'

interface ListQuestionnairesProps {
  questionnaireData: PreChatQuestionnaireModelType[]
  editQuestionnaire: (questionnaireId: number) => void
}

interface ListQuestionnaireProps {
  name?: string | null
  id?: number | null
  editQuestionnaire: (questionnaireId: number) => void
}

const ListQuestionnaire = ({
  name,
  id,
  editQuestionnaire,
}: ListQuestionnaireProps) => {
  return (
    <styled.Questionnaire onClick={() => editQuestionnaire(id!)}>
      <styled.Icon>
        <FontAwesomeIcon size="2x" icon={faQuestionCircle} />
      </styled.Icon>
      <styled.Name>{name}</styled.Name>
    </styled.Questionnaire>
  )
}

const ListQuestionnaires = observer(
  ({ questionnaireData, editQuestionnaire }: ListQuestionnairesProps) => {
    return (
      <styled.Container>
        {questionnaireData.map((questionnaire) => {
          return (
            <ListQuestionnaire
              key={questionnaire.id}
              name={questionnaire.name}
              id={questionnaire.id}
              editQuestionnaire={editQuestionnaire}
            />
          )
        })}
      </styled.Container>
    )
  },
)

export default ListQuestionnaires
