/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrganizationModel, OrganizationModelType } from "./OrganizationModel"
import { OrganizationModelSelector } from "./OrganizationModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  organization: OrganizationModelType;
}

/**
 * OrganizationRouteTagBase
 * auto generated base class for the model OrganizationRouteTagModel.
 */
export const OrganizationRouteTagModelBase = withTypedRefs<Refs>()(ModelBase
  .named('OrganizationRouteTag')
  .props({
    __typename: types.optional(types.literal("OrganizationRouteTag"), "OrganizationRouteTag"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    organization: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => OrganizationModel))),
    organizationId: types.identifier,
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class OrganizationRouteTagModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get organizationId() { return this.__attr(`organizationId`) }
  organization(builder?: string | OrganizationModelSelector | ((selector: OrganizationModelSelector) => OrganizationModelSelector)) { return this.__child(`organization`, OrganizationModelSelector, builder) }
}
export function selectFromOrganizationRouteTag() {
  return new OrganizationRouteTagModelSelector()
}

export const organizationRouteTagModelPrimitives = selectFromOrganizationRouteTag().name.organizationId
