import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observer } from 'mobx-react'
import { StoreContext } from 'models'

const DeleteWidgetModal = observer(
  ({
    visibility,
    openFunction,
    widgetDetails,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    widgetDetails: { id: string; name: string }
  }) => {
    const store = useContext(StoreContext)

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    const deleteWidget = () => {
      const query = store.mutateDeleteWidget(
        { widgetId: widgetDetails.id },
        (data) => data.id.deleted,
      )

      query.then((data) => {
        openFunction(false)
      })
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Delete Widget: {widgetDetails.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking continue will delete this widget. This action is
            irreversible. Continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteWidget} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default DeleteWidgetModal
