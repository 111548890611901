/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgentModel, AgentModelType } from "./AgentModel"
import { AgentModelSelector } from "./AgentModel.base"
import { ChatModel, ChatModelType } from "./ChatModel"
import { ChatModelSelector } from "./ChatModel.base"
import { MessageSenderEnumType } from "./MessageSenderEnum"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  chat: ChatModelType;
}

/**
 * MessageBase
 * auto generated base class for the model MessageModel.
 */
export const MessageModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Message')
  .props({
    __typename: types.optional(types.literal("Message"), "Message"),
    agent: types.union(types.undefined, types.null, types.late((): any => AgentModel)),
    agentId: types.union(types.undefined, types.null, types.integer),
    body: types.union(types.undefined, types.string),
    chat: types.union(types.undefined, MSTGQLRef(types.late((): any => ChatModel))),
    id: types.identifier,
    senderAlias: types.union(types.undefined, types.null, types.string),
    senderType: types.union(types.undefined, MessageSenderEnumType),
    timestamp: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class MessageModelSelector extends QueryBuilder {
  get agentId() { return this.__attr(`agentId`) }
  get body() { return this.__attr(`body`) }
  get id() { return this.__attr(`id`) }
  get senderAlias() { return this.__attr(`senderAlias`) }
  get senderType() { return this.__attr(`senderType`) }
  get timestamp() { return this.__attr(`timestamp`) }
  agent(builder?: string | AgentModelSelector | ((selector: AgentModelSelector) => AgentModelSelector)) { return this.__child(`agent`, AgentModelSelector, builder) }
  chat(builder?: string | ChatModelSelector | ((selector: ChatModelSelector) => ChatModelSelector)) { return this.__child(`chat`, ChatModelSelector, builder) }
}
export function selectFromMessage() {
  return new MessageModelSelector()
}

export const messageModelPrimitives = selectFromMessage().agentId.body.senderAlias.senderType.timestamp
