import React from 'react'
import * as styled from './Question.styled'
import TextField from '@material-ui/core/TextField'

const inputStyle = { width: '100%' }

interface QuestionProps {
  variableValue?: string
  variableChangeValue?: (questionId: number, variable: string) => void
  questionValue: string
  questionChangeValue: (questionId: number, question: string) => void
  ordinal: number
}

const Question = ({
  variableValue,
  variableChangeValue,
  questionValue,
  questionChangeValue,
  ordinal,
}: QuestionProps) => {
  return (
    <styled.QuestionContainer>
      {variableValue && variableChangeValue && (
        <styled.QuestionVariable>
          <TextField
            label={'Question Variable'}
            variant="filled"
            placeholder="Question Variable"
            value={variableValue}
            style={inputStyle}
            onChange={(event) =>
              variableChangeValue(ordinal, event.target.value)
            }
            rows={1}
          />
        </styled.QuestionVariable>
      )}
      <TextField
        label={'New Question'}
        variant="filled"
        placeholder="New Question"
        value={questionValue}
        style={inputStyle}
        onChange={(event) => questionChangeValue(ordinal, event.target.value)}
        rows={1}
        multiline
        rowsMax={4}
      />
    </styled.QuestionContainer>
  )
}

export default Question
