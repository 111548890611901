import React from 'react'
import { usePermission } from 'utilities/usePermission'

interface HasPermissionProps {
  children: React.ReactNode
  requiredPermissions: string[]
}

const HasPermission: React.FC<HasPermissionProps> = ({
  children,
  requiredPermissions,
}) => {
  const permission = usePermission(requiredPermissions)
  return permission ? <>{children}</> : <></>
}

export default HasPermission
