/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum PositionPreference {
  BOTTOMLEFT="BOTTOMLEFT",
BOTTOMCENTER="BOTTOMCENTER",
BOTTOMRIGHT="BOTTOMRIGHT",
MIDDLELEFT="MIDDLELEFT",
MIDDLERIGHT="MIDDLERIGHT",
TOPLEFT="TOPLEFT",
TOPCENTER="TOPCENTER",
TOPRIGHT="TOPRIGHT"
}

/**
* PositionPreference
*/
export const PositionPreferenceEnumType = types.enumeration("PositionPreference", [
        "BOTTOMLEFT",
  "BOTTOMCENTER",
  "BOTTOMRIGHT",
  "MIDDLELEFT",
  "MIDDLERIGHT",
  "TOPLEFT",
  "TOPCENTER",
  "TOPRIGHT",
      ])
