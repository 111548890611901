import React, { useState } from 'react'
import { observer } from 'mobx-react'
import * as styled from './index.styled'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { useQuery } from 'models/reactUtils'
import LogoSvg from 'ui/components/shared/LogoSvg'
import { useParams } from 'react-router-dom'
import { MessageSender } from 'models'
import Chat from 'ui/components/Chat'
import { format } from 'date-fns'
import { toJS } from 'mobx'

const ChatLog = observer(() => {
  const [themeName, setThemeName] = useState('california')
  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  const { chatId } = useParams<Record<string, string>>()

  const { data, store } = useQuery((store) =>
    store.queryChat({ chatId }, (data) =>
      data.id.createdTimestamp.timedOut.completed.completedTime.sourcePhoneNumber
        .originationWidget((widget) => widget.id.name)
        .activeAgentAlias.messages((message) =>
          message.id.body.senderAlias.senderType.timestamp.chat(
            (chat) => chat.id.timedOut.sourcePhoneNumber.activeAgentAlias,
          ),
        )
        .postChatQuestionnaireResponses((response) =>
          response.id.ordinalAtCompletion.response.postChatQuestion(
            (question) => question.question,
          ),
        ),
    ),
  )

  if (data && data.chat.length > 0) {
    store.setSelectedChat(data.chat[0])
  }

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <styled.PageContainer theme={themeObject[ThemeProviderValue.name]}>
        <styled.PageContainerFlexReset>
          <styled.LogoContainer>
            <styled.IconWrapper>
              <LogoSvg
                scale=".25"
                stopColorTop={
                  themeObject[ThemeProviderValue.name].secondaryLowlight.color!
                }
                stopColorBottom={
                  themeObject[ThemeProviderValue.name].secondaryHighlight.color!
                }
                dropShadow={true}
                width={78}
                height={80}
                strokeWidth={16}
              />
              <styled.TextWithinIcon>
                <styled.IconText>!</styled.IconText>
              </styled.TextWithinIcon>
            </styled.IconWrapper>
          </styled.LogoContainer>
          <styled.ChatLogAndControlsContainer>
            <styled.ChatLogControls>
              <styled.Title>Chat Details</styled.Title>
              <styled.ControlsOrInfo>
                {store.selectedChat ? (
                  <>
                    <styled.ControlOrInfoRow>
                      Completed:
                      {format(
                        new Date(store.selectedChat.completedTime),
                        'M/d/yyyy h:mm a',
                      ) || ' -'}
                    </styled.ControlOrInfoRow>
                    <styled.ControlOrInfoRow>
                      Widget Name:
                      {(store.selectedChat.originationWidget &&
                        store.selectedChat.originationWidget.name) ||
                        ' -'}
                    </styled.ControlOrInfoRow>
                    <styled.ControlOrInfoRow>
                      Agent Alias:
                      {} {store.selectedChat.activeAgentAlias || ' -'}
                    </styled.ControlOrInfoRow>
                    <styled.ControlOrInfoRow>
                      Phone Number:
                      {store.selectedChat.sourcePhoneNumber || ' -'}
                    </styled.ControlOrInfoRow>
                  </>
                ) : (
                  <>Loading</>
                )}
                {store.selectedChat &&
                  data!.chat[0] &&
                  data!.chat[0]!.postChatQuestionnaireResponses &&
                  data!.chat[0]!.postChatQuestionnaireResponses!.length > 0 && (
                    <styled.PostChatQuestionnaireContainer>
                      <styled.Title>Post Chat Questionnaire</styled.Title>
                      {data!.chat[0]!.postChatQuestionnaireResponses!.map(
                        (response) => {
                          return (
                            <styled.ResponseContainer>
                              <styled.RepsonseQuestion>
                                <strong>{response.ordinalAtCompletion}:</strong>{' '}
                                {response.postChatQuestion.question}
                              </styled.RepsonseQuestion>
                              <styled.Repsonse>
                                {response.response}
                              </styled.Repsonse>
                            </styled.ResponseContainer>
                          )
                        },
                      )}
                    </styled.PostChatQuestionnaireContainer>
                  )}
              </styled.ControlsOrInfo>
            </styled.ChatLogControls>
            <styled.ChatContainer>
              <div
                style={{
                  height: '98%',
                  width: '98%',
                }}
              >
                {data && store.selectedChat && (
                  <Chat
                    chat={store.selectedChat}
                    currentSenderTypes={[
                      MessageSender.AGENT,
                      MessageSender.SYSTEMUSER,
                      MessageSender.SYSTEM,
                    ]}
                    agent
                    sendMessage={() => null}
                  />
                )}
              </div>
            </styled.ChatContainer>
          </styled.ChatLogAndControlsContainer>
        </styled.PageContainerFlexReset>
      </styled.PageContainer>
    </ThemeContext.Provider>
  )
})

export default ChatLog
