import styled from 'styled-components'
import { theme } from 'types/style.types'

interface MessageProps {
  yourMessage: boolean
  typing?: string | null
  status?: boolean
  user?: boolean
  theme: theme
}

interface ThemeProps {
  theme: theme
}

export const Container = styled.div`
  display: flex;
  overflow-y: auto;
  transform: translateZ(0);
  flex-grow: 1;
  width: 100%;
  & > div {
    width: 100%;
    height: 100%;
  }

  & > div > div {
    position: relative;
  }

  & > div > div > div {
    bottom: 0px;
  }
`

export const MessageContainer = styled.div`
  width: 100%;
  margin-top: ${(props: ThemeProps) =>
    props.theme.chatBubbleSpacing && props.theme.chatBubbleSpacing.attribute
      ? `${props.theme.chatBubbleSpacing.attribute}px`
      : '4px'};
  z-index: 4;
  margin-bottom: ${(props: ThemeProps) =>
    props.theme.chatBubbleSpacing && props.theme.chatBubbleSpacing.attribute
      ? `${props.theme.chatBubbleSpacing.attribute}px`
      : '4px'};
`

export const InnerMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Message = styled.div`
  max-width: 70%;
  border-radius: 6px;
  padding: ${(props: MessageProps) =>
    props.theme.chatBubblePadding && props.theme.chatBubblePadding.attribute
      ? `${props.theme.chatBubblePadding.attribute}px`
      : '6px'};
  font-size: ${(props: MessageProps) => props.theme.chatFontSize.attribute};
  background-color: ${(props: MessageProps) =>
    props.yourMessage
      ? props.status && !props.user
        ? 'rgba(50, 125, 2, 1)'
        : props.theme.secondaryHighlight.color
      : props.theme.secondaryButtonBackgroundColor.color};
  float: ${(props: MessageProps) => (props.yourMessage ? 'right' : 'left')};
  color: ${(props: MessageProps) =>
    props.yourMessage
      ? props.theme.secondaryTextColor.color
      : props.theme.textOnWHiteColor.color};
  ${(props: MessageProps) =>
    props.yourMessage ? 'margin-right: 14px;' : 'margin-left: 14px;'};
  ${(props: MessageProps) => (props.typing ? 'min-height: 24px;' : '')}
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`

export const AliasAndTimestamp = styled.div`
  font-size: 10px;
  margin-top: -4px;
  text-align: ${(props: MessageProps) =>
    props.yourMessage ? 'right' : 'left'};
  ${(props: MessageProps) =>
    props.yourMessage ? 'margin-right: 14px;' : 'margin-left: 14px;'};
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
`

export const ChatEndMessage = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding-top: 14px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.4);
`

export const ReOpenChat = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 100%;
  padding-top: 14px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.4);
`

export const WidgetTitleContainer = styled.div`
  width: 100%;
  height: 24px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
`

export const WidgetTitle = styled.div`
  background-color: rgba(215, 222, 214, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 10px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(75, 78, 75, 1);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(98, 101, 97, 0.1); */
`

export const PostChatButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
