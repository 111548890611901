/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgentModel, AgentModelType } from "./AgentModel"
import { AgentModelSelector } from "./AgentModel.base"
import { KeyValuePairOfDateTimeOffsetAndInt32Model, KeyValuePairOfDateTimeOffsetAndInt32ModelType } from "./KeyValuePairOfDateTimeOffsetAndInt32Model"
import { KeyValuePairOfDateTimeOffsetAndInt32ModelSelector } from "./KeyValuePairOfDateTimeOffsetAndInt32Model.base"
import { KeyValuePairOfInt32AndInt32Model, KeyValuePairOfInt32AndInt32ModelType } from "./KeyValuePairOfInt32AndInt32Model"
import { KeyValuePairOfInt32AndInt32ModelSelector } from "./KeyValuePairOfInt32AndInt32Model.base"
import { RootStoreType } from "./index"


/**
 * WidgetAnalyticsPayloadBase
 * auto generated base class for the model WidgetAnalyticsPayloadModel.
 */
export const WidgetAnalyticsPayloadModelBase = ModelBase
  .named('WidgetAnalyticsPayload')
  .props({
    __typename: types.optional(types.literal("WidgetAnalyticsPayload"), "WidgetAnalyticsPayload"),
    averageAnswerTimeSeconds: types.union(types.undefined, types.number),
    averageChatLengthSeconds: types.union(types.undefined, types.number),
    chatDistribution: types.union(types.undefined, types.null, types.array(types.late((): any => KeyValuePairOfDateTimeOffsetAndInt32Model))),
    longestResponseTimeSeconds: types.union(types.undefined, types.number),
    numberOfChats: types.union(types.undefined, types.integer),
    numberOfOnlineChats: types.union(types.undefined, types.integer),
    responseTimeHistogram: types.union(types.undefined, types.null, types.array(types.late((): any => KeyValuePairOfInt32AndInt32Model))),
    shortestResponseTimeSeconds: types.union(types.undefined, types.number),
    widgetAgents: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => AgentModel)))),
    widgetId: types.identifier,
    widgetName: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WidgetAnalyticsPayloadModelSelector extends QueryBuilder {
  get averageAnswerTimeSeconds() { return this.__attr(`averageAnswerTimeSeconds`) }
  get averageChatLengthSeconds() { return this.__attr(`averageChatLengthSeconds`) }
  get longestResponseTimeSeconds() { return this.__attr(`longestResponseTimeSeconds`) }
  get numberOfChats() { return this.__attr(`numberOfChats`) }
  get numberOfOnlineChats() { return this.__attr(`numberOfOnlineChats`) }
  get shortestResponseTimeSeconds() { return this.__attr(`shortestResponseTimeSeconds`) }
  get widgetId() { return this.__attr(`widgetId`) }
  get widgetName() { return this.__attr(`widgetName`) }
  chatDistribution(builder?: string | KeyValuePairOfDateTimeOffsetAndInt32ModelSelector | ((selector: KeyValuePairOfDateTimeOffsetAndInt32ModelSelector) => KeyValuePairOfDateTimeOffsetAndInt32ModelSelector)) { return this.__child(`chatDistribution`, KeyValuePairOfDateTimeOffsetAndInt32ModelSelector, builder) }
  responseTimeHistogram(builder?: string | KeyValuePairOfInt32AndInt32ModelSelector | ((selector: KeyValuePairOfInt32AndInt32ModelSelector) => KeyValuePairOfInt32AndInt32ModelSelector)) { return this.__child(`responseTimeHistogram`, KeyValuePairOfInt32AndInt32ModelSelector, builder) }
  widgetAgents(builder?: string | AgentModelSelector | ((selector: AgentModelSelector) => AgentModelSelector)) { return this.__child(`widgetAgents`, AgentModelSelector, builder) }
}
export function selectFromWidgetAnalyticsPayload() {
  return new WidgetAnalyticsPayloadModelSelector()
}

export const widgetAnalyticsPayloadModelPrimitives = selectFromWidgetAnalyticsPayload().averageAnswerTimeSeconds.averageChatLengthSeconds.longestResponseTimeSeconds.numberOfChats.numberOfOnlineChats.shortestResponseTimeSeconds.widgetId.widgetName
