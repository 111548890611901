import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react'
import * as styled from './SoundSelect.styled'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useQuery } from 'models/reactUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { toJS } from 'mobx'

interface SoundProps {
  selectedSound?: number | null
  widgetId?: string | null
  userId?: string | null
}

const soundSelectAudioObject = new Audio('/humanWhistle.mp3')

const SoundSelect = observer(
  ({ selectedSound, widgetId, userId }: SoundProps) => {
    const { data, loading, store } = useQuery((store) =>
      store.querySounds({}, (data) => data.id.location.name),
    )

    const [selectedSoundValue, setSelectedSound] = useState(selectedSound || 1)

    const updateWidgetSelectedSound = (soundId: number) => {
      store.mutateUpdateWidgetSound(
        { input: { widgetId: widgetId!, soundId } },
        (data) => data.sound((sound) => sound.id.name.location),
      )
      setSelectedSound(soundId)
      const newSelectedSoundObject = data!.sounds.find(
        (sound) => sound.id === soundId,
      )
      soundSelectAudioObject.src = newSelectedSoundObject!.location!
    }

    if (data && data.sounds && selectedSound) {
      const newSelectedSoundObject = data!.sounds.find(
        (sound) => sound.id === selectedSound,
      )
      soundSelectAudioObject.src = newSelectedSoundObject!.location!
    }

    const saveSoundSelectionToAgentLocalStorage = (soundId: number) => {
      setSelectedSound(soundId)
      store.mutateUpdateUserSound(
        { input: { soundId, userId: userId! } },
        (data) => data.sound((sound) => sound.id.location.name),
      )
    }

    return (
      <styled.SoundContainer>
        <FormControl style={{ display: 'flex', flexGrow: 1 }} variant="filled">
          <InputLabel id="agentNotificationSoundLabel">
            Select a Agent Notification Sound
          </InputLabel>
          <Select
            labelId="agentNotificationSoundLabel"
            id="agentNotificationSound"
            value={selectedSoundValue}
            disabled={loading}
            onChange={(event) =>
              widgetId
                ? updateWidgetSelectedSound(event.target.value as number)
                : saveSoundSelectionToAgentLocalStorage(
                    event.target.value as number,
                  )
            }
          >
            {data &&
              data.sounds.map((sound) => (
                <MenuItem key={sound.id} value={sound.id}>
                  {sound.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: 10, backgroundColor: 'black', color: 'white' }}
          onClick={() => soundSelectAudioObject.play()}
        >
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            icon={faPlay}
            size="1x"
          />
          Play Sound
        </Button>
      </styled.SoundContainer>
    )
  },
)

export default SoundSelect
