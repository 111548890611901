import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'

const AddOrganizationUserModal = observer(
  ({
    visibility,
    openFunction,
    widgetId,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    widgetId: string
  }) => {
    const [email, setAdminEmail] = React.useState('')
    const { data, setQuery, store } = useQuery()

    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    if (data && (data as any).addOrganizationUser.id) {
      openFunction(false)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add a New Organization User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new organization user, please enter an email address and an invitation
            will be sent to them.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Organization User Email"
            type="text"
            fullWidth
            value={email}
            onChange={(event) => setAdminEmail(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              setQuery(
                store.mutateAddOrganizationUser(
                  {
                    input: { email },
                  },
                  (data) => data.id.email,
                ),
              )
            }
            color="primary"
          >
            Add Organization User
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default AddOrganizationUserModal
