/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum RouteType {
  DEFAULT="DEFAULT",
AGENTQUEUE="AGENTQUEUE",
PRECHATQUESTIONNAIRE="PRECHATQUESTIONNAIRE",
WIDGET="WIDGET",
ROUTETAG="ROUTETAG",
AGENT="AGENT"
}

/**
* RouteType
*/
export const RouteTypeEnumType = types.enumeration("RouteType", [
        "DEFAULT",
  "AGENTQUEUE",
  "PRECHATQUESTIONNAIRE",
  "WIDGET",
  "ROUTETAG",
  "AGENT",
      ])
