import React from 'react'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import * as styled from './index.styled'

interface InlineDeleteButtonProps {
  passedFunction: (id: string | number, deleteQuery?: () => void) => void
  deleteQuery?: () => void
  id: string | number
}

const InlineDeleteButton = ({
  passedFunction,
  deleteQuery,
  id,
}: InlineDeleteButtonProps) => {
  return (
    <styled.Container>
      <Fab
        style={{ color: 'white', backgroundColor: 'rgba(230, 56, 68, 1)' }}
        aria-label="add"
        size="small"
        onClick={() => passedFunction(id, deleteQuery)}
      >
        <DeleteIcon />
      </Fab>
    </styled.Container>
  )
}

export default InlineDeleteButton
