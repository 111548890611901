/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatModel, ChatModelType } from "./ChatModel"
import { ChatModelSelector } from "./ChatModel.base"
import { PostChatQuestionModel, PostChatQuestionModelType } from "./PostChatQuestionModel"
import { PostChatQuestionModelSelector } from "./PostChatQuestionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  chat: ChatModelType;
}

/**
 * PostChatQuestionnaireResponseBase
 * auto generated base class for the model PostChatQuestionnaireResponseModel.
 */
export const PostChatQuestionnaireResponseModelBase = withTypedRefs<Refs>()(ModelBase
  .named('PostChatQuestionnaireResponse')
  .props({
    __typename: types.optional(types.literal("PostChatQuestionnaireResponse"), "PostChatQuestionnaireResponse"),
    chat: types.union(types.undefined, MSTGQLRef(types.late((): any => ChatModel))),
    id: types.identifier,
    ordinalAtCompletion: types.union(types.undefined, types.integer),
    postChatQuestion: types.union(types.undefined, types.late((): any => PostChatQuestionModel)),
    response: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class PostChatQuestionnaireResponseModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get ordinalAtCompletion() { return this.__attr(`ordinalAtCompletion`) }
  get response() { return this.__attr(`response`) }
  chat(builder?: string | ChatModelSelector | ((selector: ChatModelSelector) => ChatModelSelector)) { return this.__child(`chat`, ChatModelSelector, builder) }
  postChatQuestion(builder?: string | PostChatQuestionModelSelector | ((selector: PostChatQuestionModelSelector) => PostChatQuestionModelSelector)) { return this.__child(`postChatQuestion`, PostChatQuestionModelSelector, builder) }
}
export function selectFromPostChatQuestionnaireResponse() {
  return new PostChatQuestionnaireResponseModelSelector()
}

export const postChatQuestionnaireResponseModelPrimitives = selectFromPostChatQuestionnaireResponse().ordinalAtCompletion.response
