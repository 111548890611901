import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observer } from 'mobx-react'

const BanConfirmationModal = observer(
  ({
    visibility,
    openFunction,
    banUser,
  }: {
    visibility: boolean
    openFunction: (visibility: boolean) => void
    banUser: () => void
  }) => {
    const closeModal = (event: React.ChangeEvent<{}>) => {
      event.stopPropagation()
      openFunction(false)
    }

    return (
      <Dialog
        open={visibility}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ban User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to temporarily, for the next 48 hours,
            ban this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={banUser} color="primary">
            Ban User
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default BanConfirmationModal
