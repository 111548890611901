import React, { useState } from 'react'
import { useQuery } from 'models/reactUtils'
import * as styled from './CreateQuestionnaire.styled'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { questionnaireViewEnum } from './index'
import { PreChatQuestionnaireModelType } from 'models'
import { observer } from 'mobx-react'

interface CreateQuestionnaireProps {
  widgetId: string
  cancelFunction: (questionnaireView: questionnaireViewEnum) => void
  editQuestionnaire: (id: number) => void
}

const CreateQuestionnaire = observer(
  ({
    widgetId,
    cancelFunction,
    editQuestionnaire,
  }: CreateQuestionnaireProps) => {
    const [name, changeQuestionnaireName] = useState('')
    const { data, setQuery, store } = useQuery()

    if (data && (data as any).addPreChatQuestionnaire) {
      // created so call back to parent with new id
      const newQuestionnaire = (data as any).addPreChatQuestionnaire.preChatQuestionnaires.find(
        (questionnaire: PreChatQuestionnaireModelType) =>
          questionnaire.name === name,
      )
      editQuestionnaire(newQuestionnaire.id)
    }

    return (
      <styled.Form>
        <styled.CreatePreChatQuestionnnaireText>
          There are currently no pre-chat questionnaires for this widget. Enter
          a name and then click the create button below to create one.
        </styled.CreatePreChatQuestionnnaireText>
        <div>
          <TextField
            label={'New Questionnaire Name'}
            variant="filled"
            placeholder="New Questionnaire Name"
            value={name}
            style={{ width: '100%', marginBottom: 20 }}
            onChange={(e) => changeQuestionnaireName(e.target.value)}
          />
        </div>
        <div>
          <Button
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginRight: 10,
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
            }}
            onClick={() => cancelFunction(questionnaireViewEnum.LIST)}
          >
            Cancel
          </Button>
          <Button
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
            }}
            onClick={() =>
              setQuery(
                store.mutateAddPreChatQuestionnaire(
                  {
                    input: { name, widgetId },
                  },
                  (data) =>
                    data.id.preChatQuestionnaires((questionnaire) =>
                      questionnaire.id.name.conditions((condition) =>
                        condition.id.expression.value.route(
                          (route) => route.id.type.value,
                        ),
                      ),
                    ),
                ),
              )
            }
          >
            Create Pre-chat Questionnaire
          </Button>
        </div>
      </styled.Form>
    )
  },
)

export default CreateQuestionnaire
