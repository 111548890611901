import React from 'react'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { PositionPreference } from 'models'

interface ChatImageIconProps {
  changeChatVisibility: () => void
  themePreview?: boolean
  imageUrl: string
  position?: PositionPreference
}

const ChatImageIcon = observer(
  ({
    changeChatVisibility,
    themePreview,
    imageUrl,
    position,
  }: ChatImageIconProps) => {
    // TODO: Add transition to rounded close button after click to open

    return (
      <styled.IconContainer
        themePreview={themePreview}
        onClick={changeChatVisibility}
        style={{ cursor: 'pointer' }}
        imageUrl={imageUrl}
        position={position}
      />
    )
  },
)

export default ChatImageIcon
