import React, { useState } from 'react'
import { PostChatQuestionnaireModelType } from 'models'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import * as styled from './ModifyQuestionnaire.styled'
import Button from '@material-ui/core/Button'
import { useQuery } from 'models/reactUtils'
import { toJS } from 'mobx'
import { observer, useLocalStore } from 'mobx-react'
import Question from './Question'
import { questionnaireDataLocalStore } from './ModifyQuestionnaire.utilities'
import InlineAddButton from 'ui/components/shared/InlineAddButton'
import InlineDeleteButton from 'ui/components/shared/InlineDeleteButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import orderBy from 'lodash/orderBy'

const completionMessageNote = `A formatted message sent to user after questionniare completed.`

const modifyQuestionnaireInputStyle = { width: '100%' }

interface ModifyQuestionnaireProps {
  questionnaireData: PostChatQuestionnaireModelType[]
  questionnaireId: number
  widgetId: string
  returnToList: () => void
}

const ModifyQuestionnaire = observer(
  ({
    questionnaireData,
    questionnaireId,
    widgetId,
    returnToList,
  }: ModifyQuestionnaireProps) => {
    const questionnaireToEdit = questionnaireData.find(
      (questionnaire) => questionnaire.id === questionnaireId,
    )
    const defaultErrorState = { error: false, errorMessage: '' }
    const [submitError, setSubmitErrors] = useState(defaultErrorState)
    const { loading, setQuery, store } = useQuery()

    const questionnaireDataStore = useLocalStore(
      questionnaireDataLocalStore(
        JSON.parse(JSON.stringify(questionnaireToEdit)),
      ),
    )

    const submitUpdates = () => {
      setSubmitErrors(defaultErrorState)
      if (
        questionnaireDataStore.data.completionMessageToUpdate ||
        questionnaireDataStore.data.questionsToUpdate
      ) {
        questionnaireDataStore.changeSubmitState(true)
        const questions =
          questionnaireDataStore.data.questions &&
          questionnaireDataStore.data.questions.length > 0
            ? questionnaireDataStore.formattingQuestionsForUpdating()
            : []
        setQuery(
          store.mutateUpdatePostChatQuestionnaire(
            {
              input: {
                completionMessage:
                  questionnaireDataStore.data.completionMessage,
                questions,
                id: questionnaireDataStore.data.id!,
              },
            },
            (data) =>
              data.postChatQuestionnaires((postChatQuestionnaire) =>
                postChatQuestionnaire.id.completionMessage.name.questions(
                  (question) => question.id.ordinal.question,
                ),
              ),
          ),
        )
      }
    }

    const createQuestion = () => {
      questionnaireDataStore.addQuestion(widgetId)
    }

    let showQuestionCreation = true
    if (
      questionnaireDataStore &&
      questionnaireDataStore.data &&
      questionnaireDataStore.data.questions &&
      questionnaireDataStore.data.questions.length
    ) {
      showQuestionCreation = false
    }

    // check for condition and question changes
    if (questionnaireDataStore.data.submitState && !loading) {
      questionnaireDataStore.replaceQuestions(
        toJS(questionnaireToEdit!.questions),
      )
      questionnaireDataStore.changeSubmitState(false)
    }

    return (
      <styled.Form>
        <styled.ReturnToListContainer>
          <Button onClick={returnToList}>
            <FontAwesomeIcon style={{ marginRight: 4 }} icon={faArrowLeft} />
            Return to Questionnaire List
          </Button>
        </styled.ReturnToListContainer>
        <styled.Title>Messages:</styled.Title>
        <TextField
          label={'Completion Message'}
          variant="filled"
          placeholder="Completion Message"
          value={questionnaireDataStore.data.completionMessage}
          style={modifyQuestionnaireInputStyle}
          rows={3}
          multiline
          rowsMax={4}
          onChange={(event) =>
            questionnaireDataStore.updateCompletionMessage(event.target.value)
          }
        />
        <styled.FormNote>{completionMessageNote}</styled.FormNote>
        <styled.Title>Questions:</styled.Title>
        {showQuestionCreation ? (
          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createQuestion} />
            <styled.InlineButtonText>
              Click here to add a new question.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
        ) : (
          <>
            {orderBy(
              questionnaireDataStore.data.questions,
              'ordinal',
              'asc',
            ).map((question) => {
              return (
                <styled.ConditionalWrapper
                  key={question.ordinal}
                  style={{ marginBottom: 16 }}
                >
                  <styled.ConditionalFormContainer>
                    <Question
                      questionValue={question.question}
                      questionChangeValue={
                        questionnaireDataStore.updateQuestion
                      }
                      ordinal={question.ordinal}
                    />
                  </styled.ConditionalFormContainer>
                  <styled.ConditionalDeleteIconContainer>
                    <InlineDeleteButton
                      passedFunction={questionnaireDataStore.deleteQuestion}
                      id={question.ordinal}
                    />
                  </styled.ConditionalDeleteIconContainer>
                </styled.ConditionalWrapper>
              )
            })}
          </>
        )}
        {!showQuestionCreation && (
          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={createQuestion} />
            <styled.InlineButtonText>
              Click here to add an additional question.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
        )}
        <styled.FormNote>
          Add or modify the questions that are asked customers when a chat is
          completed.
        </styled.FormNote>
        {submitError.error && (
          <styled.SubmissionError>
            {submitError.errorMessage}
          </styled.SubmissionError>
        )}
        <Button
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            color: 'white',
            backgroundColor: 'rgba(230, 56, 68, 1)',
            marginBottom: 20,
          }}
          onClick={submitUpdates}
        >
          Update Questionnaire
        </Button>
      </styled.Form>
    )
  },
)

export default ModifyQuestionnaire
