/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum SortOperationKind {
  ASC="ASC",
DESC="DESC"
}

/**
* SortOperationKind
*/
export const SortOperationKindEnumType = types.enumeration("SortOperationKind", [
        "ASC",
  "DESC",
      ])
