import React, { useState } from 'react'
import * as styled from './NewTheme.styled'
import { useQuery } from 'models/reactUtils'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import themeObject from 'ui/theme/CustomerView'
import { observer } from 'mobx-react'

interface NewTheme {
  returnFunction: (value: boolean) => void
}

const NewTheme = observer(({ returnFunction }: NewTheme) => {
  const [name, changeName] = useState('')
  const attributes = JSON.stringify(themeObject['california'])
  const { data, setQuery, store } = useQuery()

  if (data && (data as any).addTheme) {
    returnFunction(false)
  }

  return (
    <styled.Form>
      <div>
        <TextField
          label={'New Theme Name'}
          variant="filled"
          placeholder="New Theme Name"
          value={name}
          style={{ width: '100%', marginBottom: 20 }}
          onChange={(e) => changeName(e.target.value as string)}
        />
      </div>
      <div>
        <Button
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 10,
            color: 'white',
            backgroundColor: 'rgba(230, 56, 68, 1)',
          }}
          onClick={() => returnFunction(false)}
        >
          Cancel
        </Button>
        <Button
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            color: 'white',
            backgroundColor: 'rgba(230, 56, 68, 1)',
          }}
          onClick={() =>
            setQuery(
              store.mutateAddTheme(
                {
                  input: { name, attributes },
                },
                (data) =>
                  data.id.name.slug.themes((theme) => theme.attributes.id.name),
              ),
            )
          }
        >
          Create Theme
        </Button>
      </div>
    </styled.Form>
  )
})

export default NewTheme
