import { shortcutsType } from 'ui/components/Chat/ChatInput'

export const objectsEqual = (o1: any, o2: any): boolean =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => {
    if (o1[p] && typeof o1[p] === 'object') {
      return objectsEqual(o1[p], o2[p])
    }
    return o1[p] === o2[p]
  })

export const filterShortcuts = (
  message: string,
  shortcuts: shortcutsType,
  inlineShortcut?: boolean,
) => {
  const shortcutSearchValue = inlineShortcut
    ? message?.substr(message?.lastIndexOf('/') + 1)
    : message
  const filteredShortcuts = shortcuts.filter((shortcut) =>
    shortcut.name.toLowerCase().includes(shortcutSearchValue.toLowerCase()),
  )
  return filteredShortcuts
}
