import React from 'react'
import * as styled from './RouteTagSelect.styled'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { toJS } from 'mobx'

interface QuestionnaireSelectProps {
  helperText?: string
  value?: string | null
  conditionId?: string
  changeValue?: (conditionId: string, id: number, value: string) => void
  routeTags?: any
}

const QuestionnaireSelect = ({
  helperText,
  value,
  conditionId,
  changeValue,
  routeTags,
}: QuestionnaireSelectProps) => {
  const updateRouteTag = (value: number) => {
    const selectedRoute = routeTags!.find(
      (route: { id: number; value: string }) => route.id === value,
    )
    const selectedRouteName = selectedRoute.name
    changeValue!(conditionId!, value, selectedRouteName!)
  }
  let idOfValue = null
  if (value && routeTags && routeTags.length > 0) {
    const foundRoute = routeTags.find(
      (route: { id: number; name: string }) => route.name === value,
    )
    idOfValue = foundRoute.id
  }

  return (
    <>
      {routeTags && routeTags.length > 0 ? (
        <FormControl
          style={{ width: '100%' }}
          variant="filled"
          error={helperText && !value ? true : false}
        >
          <InputLabel id="conditionalExpressionLabel">
            Select a Route Tag:
          </InputLabel>
          <Select
            labelId="conditionalExpressionLabel"
            id="conditionalExpression"
            value={idOfValue}
            placeholder="Select a Questionnaire:"
            onChange={(event) => updateRouteTag!(event.target.value as number)}
          >
            <MenuItem value="">
              <em>Select one the available route tags below:</em>
            </MenuItem>
            {routeTags.map((routeTag: { id: number; name: string }) => (
              <MenuItem key={`route_tag_${routeTag.id}`} value={routeTag.id}>
                {routeTag.name}
              </MenuItem>
            ))}
          </Select>
          {helperText && !value && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      ) : (
        <styled.ZeroRouteTagWarning>
          There are no route tags to select from, please create one.
        </styled.ZeroRouteTagWarning>
      )}
    </>
  )
}

export default QuestionnaireSelect
