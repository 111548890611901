import styled from 'styled-components'

export const ModalBackround = styled.div`
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`

export const TagContainer = styled.div`
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TagDialog = styled.div`
  width: 50%;
  height: 300px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-rows: 60px auto;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-indent: 20px;
  font-size: 20px;
  font-weight: bold;
`

export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
