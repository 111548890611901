import React, { useContext } from 'react'
import * as styled from './ChatContainer.styled'
import ThemeContext from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import { StoreContext } from 'models/reactUtils'
import { theme } from 'types/style.types'
import Chat from 'ui/components/Chat'
import { ChatModelType, MessageSender } from 'models'
import chatContainerBackground from '../../assets/images/chatContainerBackground.svg'

interface ChatContainerProps {
  selectedChat?: ChatModelType | null
  sendMessage: (body: string) => void
  currentSenderTypes: MessageSender[]
  agent?: boolean
  endChat?: (id: string) => void
}

const ChatContainer = ({
  selectedChat,
  sendMessage,
  currentSenderTypes,
  agent,
  endChat,
}: ChatContainerProps) => {
  const themeContext = useContext(ThemeContext)
  const themeFromContext: theme = themeObject[themeContext.name]
  const store = useContext(StoreContext)
  return (
    <styled.ChatOuterContainer
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <styled.ChatContainerPrompt theme={themeFromContext}>
        {!store.onlineStatus ? (
          <>
            You must go <strong>online</strong> in order to see active chats,
            however you can review historical chats in the navigation menu if
            there are any.
          </>
        ) : (
          <>
            Open up a chat on the left, if there are any, in order to
            communicate with your user.
          </>
        )}
      </styled.ChatContainerPrompt>
      <styled.ChatContainer hidden={!selectedChat ? true : false}>
        <Chat
          chat={selectedChat!}
          currentSenderTypes={currentSenderTypes}
          sendMessage={sendMessage}
          agent={agent}
          endChat={endChat}
        />
      </styled.ChatContainer>
      <styled.ChatContainerBackgroundGradientCover />
      <styled.ChatContainerBackgroundImage>
        <img
          alt="Vector drawing of a city park"
          src={chatContainerBackground}
        />
      </styled.ChatContainerBackgroundImage>
    </styled.ChatOuterContainer>
  )
}

export default ChatContainer
