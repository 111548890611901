import React, { useState } from 'react'
import { PreChatQuestionnaireModelType } from 'models'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import CreateQuestionnaire from './CreateQuestionnaire'
import ModifyQuestionnaire from './ModifyQuestionnaire'
import ListQuestionnaires from './ListQuestionnaires'
import FloatingAddButton from 'ui/components/shared/FloatingAddButton'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import { useQuery } from 'models/reactUtils'
import Loading from 'ui/components/shared/Loading'

interface PrechatQuestionnairesProps {
  widgetSlug: string
  organizationSlug: string
}

export enum questionnaireViewEnum {
  LIST,
  ADD,
  EDIT,
}

const PrechatQuestionnaires = observer(
  ({ organizationSlug, widgetSlug }: PrechatQuestionnairesProps) => {
    const [questionnaireView, setQuestionnaireView] = useState(
      questionnaireViewEnum.LIST,
    )
    const [editQuestionnaireId, setEditQuestionnaireId] = useState<
      number | null
    >(null)

    const { loading, data, store } = useQuery((store) =>
      store.queryWidgetFromSlug(
        { input: { organizationSlug, widgetSlug } },
        (data) =>
          data.id.preChatQuestionnaires((preChatQuestionnaire) =>
            preChatQuestionnaire.id.agentMessage.completionMessage.name
              .questions((question) =>
                question.id.ordinal.question.variable(
                  (variable) => variable.id.name,
                ),
              )
              .conditions((condition) =>
                condition.id.expression.value.preChatQuestionnaireId
                  .route((route) => route.id.type.value)
                  .variable((variable) => variable.id.name),
              ),
          ),
      ),
    )

    const editQuestionnaire = (questionnaireId: number) => {
      setEditQuestionnaireId(questionnaireId)
      setQuestionnaireView(questionnaireViewEnum.EDIT)
    }

    const returnToList = () => {
      setQuestionnaireView(questionnaireViewEnum.LIST)
      setEditQuestionnaireId(null)
    }

    if (loading) {
      return <Loading tabPanel />
    }

    const questionnaireData = data!.widgetFromSlug
      .preChatQuestionnaires as PreChatQuestionnaireModelType[]

    return (
      <styled.Container>
        {questionnaireView === questionnaireViewEnum.LIST && (
          <>
            <ListQuestionnaires
              editQuestionnaire={editQuestionnaire}
              questionnaireData={questionnaireData}
            />
            <div
              onClick={() => setQuestionnaireView(questionnaireViewEnum.ADD)}
            >
              <FloatingAddButton passedFunction={() => null} />
            </div>
          </>
        )}

        {questionnaireView === questionnaireViewEnum.ADD && (
          <CreateQuestionnaire
            widgetId={data!.widgetFromSlug.id}
            cancelFunction={() =>
              setQuestionnaireView(questionnaireViewEnum.LIST)
            }
            editQuestionnaire={editQuestionnaire}
          />
        )}

        {questionnaireView === questionnaireViewEnum.EDIT &&
          editQuestionnaireId && (
            <ModifyQuestionnaire
              widgetId={data!.widgetFromSlug.id}
              questionnaireData={questionnaireData}
              questionnaireId={editQuestionnaireId}
              returnToList={returnToList}
              routeTags={data!.widgetFromSlug.routeTags}
            />
          )}
      </styled.Container>
    )
  },
)

export default WithPermission(
  ['ManageOrganization'],
  PrechatQuestionnaires,
  MissingPermission,
)
