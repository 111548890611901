import React from 'react'
import * as styled from './ActivityBar.styled'
import subMinutes from 'date-fns/subMinutes'

const ActivityBarXAxis = () => {
  // const now = new Date()
  const periodLengthInMins = 30
  // const nearest30Mins = roundToNearestMinutes(now, {
  //   nearestTo: periodLengthInMins,
  // })

  var interval = periodLengthInMins * 60 * 1000 // 30 minutes in milliseconds
  const next30Minutes = new Date(
    Math.ceil(new Date().getTime() / interval) * interval,
  )
  const rows = Array(48).fill(0)
  return (
    <styled.ActivityBarOuterContainer>
      <styled.Day>Day</styled.Day>
      <styled.ActivityBarContainer axis>
        {rows
          .map((row, i) => {
            const timeKey = subMinutes(next30Minutes, periodLengthInMins * i)
            return (
              <styled.ActivityTic
                key={`timeKey_${timeKey}`}
                backGroundColor="rgba(100, 200, 100, 0)"
                axis
                finalPosition={i === 0}
              >
                {i === 0 && '< now'}
                {i % 4 === 0 &&
                  i !== 0 &&
                  timeKey
                    .toLocaleTimeString()
                    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}
              </styled.ActivityTic>
            )
          })
          .reverse()}
      </styled.ActivityBarContainer>
    </styled.ActivityBarOuterContainer>
  )
}

export default ActivityBarXAxis
