import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme: theme
}

export const PageContainer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  background-color: 'rgba(0, 0, 0, 0.3)';
  overflow: hidden;
  font-family: 'Roboto';
`
