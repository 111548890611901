import styled from 'styled-components'

interface PageContainerProps {
  backgroundColor?: string
}

interface ContainerProps {
  width?: string
  height?: string
  justifyContent?: string
  alignItems?: string
  marginBottom?: string
  cursor?: string
  minHeight?: string
  maxHeight?: string

  overflowY?: string
  overflowX?: string
}

export const PageContainer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  background-color: ${(props: PageContainerProps) => props.backgroundColor};
  overflow-x: hidden;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props: ContainerProps) => (props.width ? `width: ${props.width};` : ``)}
  ${(props: ContainerProps) => (props.height ? `height: ${props.height};` : ``)}
  ${(props: ContainerProps) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ``}
  ${(props: ContainerProps) =>
    props.alignItems ? `align-items: ${props.alignItems};` : ``}
  ${(props: ContainerProps) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ``}
  ${(props: ContainerProps) =>
    props.cursor ? `cursor: ${props.cursor};` : ``}  
  ${(props: ContainerProps) =>
    props.minHeight ? `min-height: ${props.minHeight};` : ``}    
  ${(props: ContainerProps) =>
    props.maxHeight ? `max-height: ${props.maxHeight};` : ``}
`

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${(props: ContainerProps) => (props.width ? `width: ${props.width};` : ``)}
  ${(props: ContainerProps) => (props.height ? `height: ${props.height};` : ``)}
  ${(props: ContainerProps) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ``}
  ${(props: ContainerProps) =>
    props.alignItems ? `align-items: ${props.alignItems};` : ``}
  ${(props: ContainerProps) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ``}
  ${(props: ContainerProps) => (props.cursor ? `cursor: ${props.cursor};` : ``)}
  ${(props: ContainerProps) =>
    props.minHeight ? `min-height: ${props.minHeight};` : ``}    
  ${(props: ContainerProps) =>
    props.maxHeight ? `max-height: ${props.maxHeight};` : ``}
  ${(props: ContainerProps) =>
    props.overflowY ? `overflow-y: ${props.overflowY};` : ``}
  ${(props: ContainerProps) =>
    props.overflowX ? `overflow-x: ${props.overflowX};` : ``}
`

export const NewPageContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 225px auto;
  grid-template-rows: 85px auto;
`
