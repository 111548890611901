/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrganizationModel, OrganizationModelType } from "./OrganizationModel"
import { OrganizationRouteTagModel, OrganizationRouteTagModelType } from "./OrganizationRouteTagModel"
import { OrganizationRouteTagModelSelector } from "./OrganizationRouteTagModel.base"
import { PrivilegeModel, PrivilegeModelType } from "./PrivilegeModel"
import { PrivilegeModelSelector } from "./PrivilegeModel.base"
import { SpaceModel, SpaceModelType } from "./SpaceModel"
import { SpaceModelSelector } from "./SpaceModel.base"
import { ThemeModel, ThemeModelType } from "./ThemeModel"
import { ThemeModelSelector } from "./ThemeModel.base"
import { WidgetModel, WidgetModelType } from "./WidgetModel"
import { WidgetModelSelector } from "./WidgetModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  organizations: IObservableArray<OrganizationModelType>;
  privileges: IObservableArray<PrivilegeModelType>;
  space: SpaceModelType;
  widgets: IObservableArray<WidgetModelType>;
}

/**
 * OrganizationBase
 * auto generated base class for the model OrganizationModel.
 */
export const OrganizationModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Organization')
  .props({
    __typename: types.optional(types.literal("Organization"), "Organization"),
    deleted: types.union(types.undefined, types.boolean),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    organizations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => OrganizationModel))))),
    privileges: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => PrivilegeModel))))),
    routeTags: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => OrganizationRouteTagModel)))),
    slug: types.union(types.undefined, types.null, types.string),
    space: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => SpaceModel))),
    themes: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => ThemeModel)))),
    widgets: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late((): any => WidgetModel))))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class OrganizationModelSelector extends QueryBuilder {
  get deleted() { return this.__attr(`deleted`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get slug() { return this.__attr(`slug`) }
  organizations(builder?: string | OrganizationModelSelector | ((selector: OrganizationModelSelector) => OrganizationModelSelector)) { return this.__child(`organizations`, OrganizationModelSelector, builder) }
  privileges(builder?: string | PrivilegeModelSelector | ((selector: PrivilegeModelSelector) => PrivilegeModelSelector)) { return this.__child(`privileges`, PrivilegeModelSelector, builder) }
  routeTags(builder?: string | OrganizationRouteTagModelSelector | ((selector: OrganizationRouteTagModelSelector) => OrganizationRouteTagModelSelector)) { return this.__child(`routeTags`, OrganizationRouteTagModelSelector, builder) }
  space(builder?: string | SpaceModelSelector | ((selector: SpaceModelSelector) => SpaceModelSelector)) { return this.__child(`space`, SpaceModelSelector, builder) }
  themes(builder?: string | ThemeModelSelector | ((selector: ThemeModelSelector) => ThemeModelSelector)) { return this.__child(`themes`, ThemeModelSelector, builder) }
  widgets(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`widgets`, WidgetModelSelector, builder) }
}
export function selectFromOrganization() {
  return new OrganizationModelSelector()
}

export const organizationModelPrimitives = selectFromOrganization().deleted.name.slug
