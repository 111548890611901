import React, { useState } from 'react'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import * as styled from './index.styled'
import themeObject from 'ui/theme/CustomerView'
import { capitalize } from 'lodash'
import { theme } from 'types/style.types'
import { ThemeModelType, ThemeAttributeEnum } from 'models'
import { SketchPicker } from 'react-color'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'

interface ThemeAttributesProps {
  theme?: ThemeModelType
  showFonts: boolean
  editMode?: boolean
}

interface ThemeItemProps {
  colorOrFont: string
  themeProp: theme
  showFonts: boolean
  editMode?: boolean
  themeInstance: any | null
}

interface ColorItemProps {
  colorOrFont: string
  themeProp: theme
  themeInstance: any | null
  editMode?: boolean
}

const buttonStyles = {
  color: 'white',
  backgroundColor: 'rgba(230, 56, 68, 1)',
  marginLeft: 10,
}

const ColorItem = observer(
  ({ colorOrFont, themeProp, themeInstance, editMode }: ColorItemProps) => {
    const [editColor, setEditColor] = useState(false)
    const [colorPicker, setColorPickerColor] = useState(
      themeProp[colorOrFont].color!,
    )
    const [savedColor, setSavedColor] = useState(themeProp[colorOrFont].color!)

    const handleColorChange = (color: any) => {
      const colorString = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
      setColorPickerColor(colorString)
    }

    const cancelPicker = () => {
      setEditColor(false)
      setColorPickerColor(savedColor)
    }

    const saveColor = () => {
      themeInstance.modifyAttributes(
        colorOrFont,
        colorPicker,
        ThemeAttributeEnum.COLOR,
      )
      setSavedColor(colorPicker)
      cancelPicker()
    }

    return (
      <styled.ColorAndPickerContainer>
        <styled.ColorContainer
          editMode={editMode}
          onClick={() => setEditColor(true)}
          color={savedColor}
        />
        {editMode && editColor && (
          <styled.PickerContainer>
            <SketchPicker
              color={colorPicker}
              onChangeComplete={handleColorChange}
            />
            <styled.ButtonHolder>
              <Button style={buttonStyles} onClick={cancelPicker}>
                Cancel
              </Button>
              <Button style={buttonStyles} onClick={saveColor}>
                Set
              </Button>
            </styled.ButtonHolder>
          </styled.PickerContainer>
        )}
      </styled.ColorAndPickerContainer>
    )
  },
)

const ThemeItem = observer(
  ({
    colorOrFont,
    themeProp,
    showFonts,
    editMode,
    themeInstance,
  }: ThemeItemProps) => {
    if (colorOrFont.includes('Font') && showFonts) {
      return (
        <styled.FontContainer>
          {themeProp[colorOrFont].attribute}
        </styled.FontContainer>
      )
    } else {
      return (
        <ColorItem
          colorOrFont={colorOrFont}
          themeProp={themeProp}
          themeInstance={themeInstance}
          editMode={editMode}
        />
      )
    }
  },
)

const ThemeAttributes = observer(
  ({ theme, showFonts, editMode }: ThemeAttributesProps) => {
    let themeAttributes = themeObject['california']
    let themeInstance: any = null

    if (theme && theme.attributes) {
      themeAttributes = JSON.parse(theme!.attributes)
      themeInstance = theme
    }

    return (
      <layout.ContainerRow alignItems="center" cursor="pointer">
        <styled.ThemeName showFonts={showFonts}>
          {theme && theme.name ? capitalize(theme.name) : 'Default'}
        </styled.ThemeName>
        <layout.ContainerRow onClick={() => null}>
          {Object.keys(themeAttributes).map((colorOrFont: string) => {
            if (themeAttributes[colorOrFont].show) {
              return (
                <ThemeItem
                  key={`themeItem_${colorOrFont}`}
                  colorOrFont={colorOrFont}
                  showFonts={showFonts}
                  themeProp={themeAttributes}
                  editMode={editMode}
                  themeInstance={themeInstance}
                />
              )
            } else {
              return <></>
            }
          })}
        </layout.ContainerRow>
      </layout.ContainerRow>
    )
  },
)

export default ThemeAttributes
