import React, { useContext } from 'react'
import * as styled from './ActiveChats.styled'
import { observer } from 'mobx-react'
import { ChatModelType } from 'models'
import { StoreContext } from 'models/reactUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobeAmericas,
  faMobile,
  faTimesCircle,
  faHeadset,
} from '@fortawesome/free-solid-svg-icons'
import TimeAgo from 'timeago-react'
import Linkify from 'react-linkify'
import { toJS } from 'mobx'
import { PROVIDERS } from 'constants/constants'

interface ActiveChats {
  activeChats: ChatModelType[]
  setSelectedChat: (chat: ChatModelType) => void
  onlineStatus: boolean
  selectedChat?: ChatModelType | null
  openedChats: string[]
  chatHasBeenOpened: (id: string) => void
}

const ActiveChats = observer(
  ({
    activeChats,
    setSelectedChat,
    onlineStatus,
    selectedChat,
    openedChats,
    chatHasBeenOpened,
  }: ActiveChats) => {
    const store = useContext(StoreContext)

    const endChat = (chatId: string) => {
      store.mutateCompleteChat(
        { chatId },
        (data) => data.id.completed.createdTimestamp.id,
      )
      if (store.selectedChat && store.selectedChat!.id === chatId) {
        store.setSelectedChat(null)
      }
      store.showPostChatDialog(chatId)
    }

    return (
      <>
        {activeChats &&
          activeChats.length > 0 &&
          activeChats.map((chat: ChatModelType) => {
            const time =
              chat.latestMessage !== null
                ? new Date(chat.latestMessage.timestamp)
                : new Date(chat.createdTimestamp)
            const body =
              chat.latestMessage !== null ? chat.latestMessage.body : ''
            const selected = store.selectedChat
              ? store.selectedChat.id === chat.id
                ? true
                : false
              : false

            return (
              chat &&
              chat.messages &&
              chat.messages.length > 0 && (
                <styled.ConversationContainer
                  key={`conversation_container_active_${chat.id}`}
                  onClick={() => {
                    setSelectedChat(chat)
                    chatHasBeenOpened(chat.id)
                  }}
                  unread={chat.unreadAndBolded}
                >
                  <styled.Conversation selected={selected}>
                    <styled.ConversationActions
                      onClick={() => endChat!(chat.id)}
                      selected={selected}
                    >
                      <FontAwesomeIcon
                        size="1x"
                        icon={faTimesCircle}
                        style={{ marginLeft: 5 }}
                      />
                    </styled.ConversationActions>
                    <styled.ConversationHeader>
                      {chat.sourcePhoneNumber ? (
                        <>
                          <FontAwesomeIcon
                            icon={faMobile}
                            size="1x"
                            style={{ marginRight: 5 }}
                          />{' '}
                          {chat.sourcePhoneNumber
                            .replace('+1', '')
                            .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faGlobeAmericas}
                            size="1x"
                            style={{ marginRight: 5 }}
                          />{' '}
                          Web
                        </>
                      )}
                      <styled.WidgetName>({chat.widgetName})</styled.WidgetName>
                    </styled.ConversationHeader>
                    <styled.ConversationAndTime>
                      <styled.ConversationPreview>
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key,
                          ) => (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              key={key}
                              style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                color: `${
                                  store.selectedChat &&
                                  store.selectedChat.id === chat.id
                                    ? 'rgba(255, 255, 255, 1.0)'
                                    : 'rgba(45,125,155,1.0)'
                                }`,
                              }}
                              href={decoratedHref}
                            >
                              {PROVIDERS.some((provider) =>
                                decoratedHref.includes(provider),
                              )
                                ? 'Image Preview'
                                : decoratedHref}
                            </a>
                          )}
                        >
                          {body}
                        </Linkify>
                      </styled.ConversationPreview>
                      <styled.TimeAgoContainer>
                        <TimeAgo datetime={time} />
                      </styled.TimeAgoContainer>
                    </styled.ConversationAndTime>
                    {chat.activeAgentAlias && (
                      <styled.ActiveAgent>
                        <FontAwesomeIcon
                          icon={faHeadset}
                          size="1x"
                          style={{ marginRight: 4 }}
                        />
                        {chat.activeAgentAlias}
                      </styled.ActiveAgent>
                    )}
                  </styled.Conversation>
                </styled.ConversationContainer>
              )
            )
          })}
        {!(activeChats && activeChats.length > 0) && (
          <>
            {onlineStatus ? (
              <styled.ChatNote>
                There are currently no active chats.
              </styled.ChatNote>
            ) : (
              <styled.ChatNote>
                Go <strong>"Online"</strong> to see active chats.
              </styled.ChatNote>
            )}
          </>
        )}
      </>
    )
  },
)

export default ActiveChats
