import React from 'react'
import { PostChatQuestionnaireModelType } from 'models'
import * as styled from './ListQuestionnaires.styled'
import { observer } from 'mobx-react'
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Switch,
  Fab,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useQuery } from 'models/reactUtils'

interface ListQuestionnaireProps {
  name?: string | null
  id?: number | null
  editQuestionnaire: (questionnaireId: number) => void
  shade: boolean
  widgetId: string
  postchatQuestionnaireId?: number | null
}

const ListQuestionnaire = ({
  name,
  id,
  editQuestionnaire,
  shade,
  widgetId,
  postchatQuestionnaireId,
}: ListQuestionnaireProps) => {
  const { loading, data, setQuery, store } = useQuery()

  const setSelectedPostchatQuestionnaire = (event: any, id: number) => {
    if (event.target.checked) {
      setQuery(
        store.mutateUpdateWidgetEnablePostChatQuestionnaire(
          { input: { widgetId, postChatQuestionnaireId: id } },
          (data) => data.postChatQuestionnaireId.enablePostChatQuestionnaire,
        ),
      )
    } else {
      setQuery(
        store.mutateUpdateWidgetEnablePostChatQuestionnaire(
          { input: { widgetId, postChatQuestionnaireId: 0 } },
          (data) => data.postChatQuestionnaireId.enablePostChatQuestionnaire,
        ),
      )
    }
  }

  return (
    <TableRow
      style={{ background: `${shade ? 'rgba(231, 239, 230, 1)' : ''}` }}
    >
      <TableCell>{name}</TableCell>
      <TableCell onClick={() => editQuestionnaire(id!)}>
        <Fab
          style={{
            color: 'white',
            backgroundColor: 'rgba(230, 56, 68, 1)',
          }}
          aria-label="add"
          size="small"
        >
          <Edit />
        </Fab>
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              size="small"
              checked={postchatQuestionnaireId === id}
              onChange={(event) => setSelectedPostchatQuestionnaire(event, id!)}
            />
          }
          label="Enable"
        />
      </TableCell>
    </TableRow>
  )
}

interface ListQuestionnairesProps {
  questionnaireData: PostChatQuestionnaireModelType[]
  editQuestionnaire: (questionnaireId: number) => void
  widgetId: string
  enabledPostchatQuestionnaire?: boolean
  postchatQuestionnaireId?: number | null
}

const ListQuestionnaires = observer(
  ({
    questionnaireData,
    editQuestionnaire,
    widgetId,
    enabledPostchatQuestionnaire,
    postchatQuestionnaireId,
  }: ListQuestionnairesProps) => {
    const { loading, data, setQuery, store } = useQuery()

    const enablePostChatQuestionnaire = (event: any) => {
      setQuery(
        store.mutateUpdateWidgetEnablePostChatQuestionnaire(
          {
            input: {
              widgetId,
              enablePostChatQuestionnaire: event.target.checked,
            },
          },
          (data) => data.postChatQuestionnaireId.enablePostChatQuestionnaire,
        ),
      )
    }

    return (
      <styled.Container>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={enabledPostchatQuestionnaire}
              onChange={(event) => enablePostChatQuestionnaire(event)}
            />
          }
          label="Enable Postchat Questionnaire"
        />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Questionnaire Name</TableCell>
              <TableCell align="left">Edit</TableCell>
              <TableCell align="left">Enable/Disable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionnaireData.map((questionnaire, i) => {
              return (
                <ListQuestionnaire
                  key={questionnaire.id}
                  name={questionnaire.name}
                  id={questionnaire.id}
                  editQuestionnaire={editQuestionnaire}
                  shade={!!!(i % 2)}
                  widgetId={widgetId}
                  postchatQuestionnaireId={postchatQuestionnaireId}
                />
              )
            })}
          </TableBody>
        </Table>
      </styled.Container>
    )
  },
)

export default ListQuestionnaires
