import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme: theme
}

export const Container = styled.div`
  background-color: ${(props: ThemeProps) =>
    props.theme.secondaryBackground.color};
  background: ${(props: ThemeProps) =>
    `linear-gradient(${props.theme.secondaryLowlight.color}, ${props.theme.secondaryHighlight.color})`};
  color: ${(props: ThemeProps) => props.theme.secondaryTextColor.color};
  width: 100%;
  flex-grow: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`

export const Title = styled.div`
  font-family: ${(props: ThemeProps) => props.theme.titleFont.attribute};
  font-size: ${(props: ThemeProps) => props.theme.subtitleFontSize.attribute};
  margin-top: 7px;
`

export const Question = styled.div`
  padding-top: 20px;
  font-family: ${(props: ThemeProps) => props.theme.titleFont.attribute};
  font-size: ${(props: ThemeProps) => props.theme.subtitleFontSize.attribute};
  font-weight: normal;
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFilledInput-multiline {
    padding-top: 10px !important;
  }
`

export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
