import React, { useState, useEffect } from 'react'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Shortcut from './Shortcut'
import InlineAddButton from 'ui/components/shared/InlineAddButton'
import orderBy from 'lodash/orderBy'
import Chip from '@material-ui/core/Chip'
import WithPermission from 'ui/components/shared/WithPermission'
import MissingPermission from 'ui/components/shared/MissingPermission'
import SoundSelection from './SoundSelect'
import TextLimitDecorator from 'ui/components/shared/TextLimitDecorator'
import HasPermission from 'ui/components/shared/HasPermission'
import Loading from 'ui/components/shared/Loading'
import { WidgetModelType } from 'models'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface Shortcut {
  id: number
  name: string
  value: string
}

interface GeneralSettingsProps {
  widgetSlug: string
  organizationSlug: string
}

const offlineMessageNote = `This is the message users will see when they message your organization and there are no active agents.`
const chatTimeoutNote = `This is the length of time, in minutes, it takes before a chat is considered to have timed out and become inactive.`
const soundsNote = `This sound is what is played as a notification when an agent recieves a message.`
const widgetTitleNote = `This title is what users will see in the header bar of your web chat widget.`
const shortcutsNote = `These are predefined snippets of text that you can select to quickly send common responses to users.`
const tagsNote = `Routing tags can be added to select users to ensure that specific types of incoming messages or transfers are routed to the appropriate agent/group of agents.`
const postChatTagsNote = `Once a chat has completed agents may assign one of these tags to it.`
const offlineMessageSMSLimitNote = `SMS Specific Note: Your message can be longer than 160 characters however, it will be broken over multiple text messages which may not be ideal.`
const roundRobinNote = `When selected each chat will be assigned directly to the agent with the least number of in-progress chats. When not selected, all new chats can be answered by any agent.`
const requirePostChatTags = `Selecting this will require that agents select a tag, if any have been previously defined, before being able to move on to the next chat.`

enum DetectEnterTextFieldType {
  TAG = 'TAG',
  POST_CHAT_TAG = 'POST_CHAT_TAG',
}

const GeneralSettings = observer(
  ({ widgetSlug, organizationSlug }: GeneralSettingsProps) => {
    const { loading, data, store } = useQuery((store) =>
      store.queryWidgetFromSlug(
        { input: { organizationSlug, widgetSlug } },
        (data) =>
          data.id.slug.name.chatTimeout.offlineMesage.roundRobin.title.smsProvider.deleted.requirePostChatTags
            .sound((sound) => sound.id.location.name)
            .routeTags((tag) => tag.id.name.widgetId)
            .postChatTags((tag) => tag.id.name.widgetId)
            .shortcuts((shortcut) => shortcut.id.name.value)
            .organization((organization) =>
              organization.id.name.space((space) => space.id),
            ),
      ),
    )

    const [offlineMessageText, changeOfflineMessage] = useState('')
    const [chatTimeoutText, changeChatTimeout] = useState('')
    const [widgetTitleText, changeWidgetTitleText] = useState('')
    const [widgetNameText, changeWidgetNameText] = useState('')
    const [roundRobin, changeRoundRobin] = useState(false)
    const [requirePostChatTags, changeRequirePostChatTags] = useState(false)

    useEffect(() => {
      changeOfflineMessage(data?.widgetFromSlug?.offlineMesage || '')
      changeChatTimeout(String(data?.widgetFromSlug?.chatTimeout || ''))
      changeWidgetTitleText(data?.widgetFromSlug?.title || '')
      changeWidgetNameText(data?.widgetFromSlug?.name || '')
      changeRoundRobin(data?.widgetFromSlug?.roundRobin || false)
      changeRequirePostChatTags(
        data?.widgetFromSlug?.requirePostChatTags || false,
      )
    }, [data])

    const [newTag, changeNewTag] = useState('')
    const [newPostChatTag, changeNewPostChatTag] = useState('')
    const [updateOfflineMessage, changeUpdateOfflineMessage] = useState(false)
    const [updateChatTimeout, changeUpdateChatTimeout] = useState(false)
    const [updateWidgetTitleText, changeUpdateWidgetTitleText] = useState(false)
    const [updateWidgetNameText, changeUpdateWidgetNameText] = useState(false)
    const [updateRoundRobin, changeUpdateRoundRobin] = useState(false)
    const [
      updateRequirePostChatTags,
      changeUpdateRequirePostChatTags,
    ] = useState(false)

    const deleteShortcut = (widgetId: string, id: number) => {
      store.mutateDeleteShortcut(
        {
          input: {
            shortcutId: id,
            widgetId,
          },
        },
        (data) => data.shortcutId,
      )
    }

    const updateShortcut = (widgetId: string, id: number, type: string) => {
      // need to fix shortcuts any issue
      const shortcutToUpdate = widgetInstance!.shortcuts!.find(
        (shortcut: any) => shortcut.id === id,
      )
      store.mutateUpdateShortcut(
        {
          input: {
            shortcutId: (shortcutToUpdate as any).id,
            value: (shortcutToUpdate as any).value,
            widgetId,
          },
        },
        (data) => data.id.name.value,
      )
    }

    const createShortcut = (widgetId: string, id: number) => {
      // need to fix shortcuts any issue
      const shortcutToCreate = widgetInstance!.shortcuts!.find(
        (shortcut: any) => shortcut.id === id,
      )
      store.mutateCreateShortcut(
        {
          input: {
            name: (shortcutToCreate as any).name,
            value: (shortcutToCreate as any).value,
            widgetId,
          },
        },
        (data) => data.id.name.value,
      )
    }

    const submitUpdates = (widgetId: string) => {
      if (
        updateOfflineMessage ||
        updateChatTimeout ||
        updateWidgetTitleText ||
        updateRoundRobin ||
        updateWidgetNameText ||
        updateRequirePostChatTags
      ) {
        store.mutateUpdateWidgetSettings(
          {
            input: {
              chatTimeout: parseInt(chatTimeoutText),
              offlineMessage: offlineMessageText,
              title: widgetTitleText,
              roundRobin: roundRobin,
              name: widgetNameText,
              requirePostChatTags: requirePostChatTags,
              widgetId,
            },
          },
          (data) => data.id.chatTimeout.name.offlineMesage.title,
        )
        changeUpdateOfflineMessage(false)
        changeUpdateChatTimeout(false)
      }

      if (
        widgetInstance!.updateArray &&
        widgetInstance!.updateArray.length > 0
      ) {
        widgetInstance!.updateArray.forEach((update) => {
          if (
            update.status === 'delete' &&
            !widgetInstance!.createdShortcuts.includes(update.id)
          ) {
            deleteShortcut(data!.widgetFromSlug!.id, update.id)
          }

          if (
            update.status !== 'delete' &&
            widgetInstance!.createdShortcuts.includes(update.id)
          ) {
            createShortcut(data!.widgetFromSlug!.id, update.id)
          }

          if (
            // update.status === 'updateName' ||
            update.status === 'updateText'
          ) {
            updateShortcut(data!.widgetFromSlug!.id, update.id, update.status)
          }
        })
        widgetInstance!.removeAllShortcutUpdateFlags()
        widgetInstance!.removeAllShortcutCreatedFlags()
      }
    }

    const detectAndHandleEnter = (
      event: React.KeyboardEvent<HTMLDivElement>,
      type: DetectEnterTextFieldType,
    ) => {
      if (
        event.key === 'Enter' &&
        (newTag.length > 0 || newPostChatTag.length > 0)
      ) {
        event.preventDefault()
        if (type === DetectEnterTextFieldType.TAG) {
          store.mutateAddWidgetRouteTag(
            {
              input: {
                name: newTag,
                widgetId: data!.widgetFromSlug!.id,
              },
            },
            (data) => data.routeTags((tag) => tag.id.name.widgetId),
          )
          changeNewTag('')
        } else if (
          event.key === 'Enter' &&
          DetectEnterTextFieldType.POST_CHAT_TAG
        ) {
          event.preventDefault()
          store.mutateAddPostChatTag(
            {
              input: {
                name: newPostChatTag,
                widgetId: data!.widgetFromSlug!.id,
              },
            },
            (data) => data.postChatTags((tag) => tag.id.name.widgetId),
          )
        }
        changeNewPostChatTag('')
      }
    }

    if (loading) {
      return <Loading tabPanel />
    }

    let widgetInstance: WidgetModelType | null = null
    if (!loading && data?.widgetFromSlug?.id) {
      widgetInstance = store.widgets.get(data?.widgetFromSlug?.id)!
    }

    return (
      <styled.Container>
        <styled.Form>
          <styled.Title>General Settings:</styled.Title>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <TextField
              label={'Widget Name'}
              variant="filled"
              placeholder="Widget Name"
              value={widgetNameText}
              style={{ width: '100%' }}
              onChange={(e) => {
                changeWidgetNameText(e.target.value)
                changeUpdateWidgetNameText(true)
              }}
            />
            <styled.FormNote>{widgetTitleNote}</styled.FormNote>
          </HasPermission>
          <styled.OfflineMessageContainer>
            <TextField
              label={'Offline Message'}
              variant="filled"
              placeholder="Offline Message"
              value={offlineMessageText}
              style={{ width: '100%' }}
              rows={3}
              multiline
              rowsMax={4}
              onChange={(e) => {
                changeOfflineMessage(e.target.value)
                changeUpdateOfflineMessage(true)
              }}
            />
            <TextLimitDecorator
              characterCount={offlineMessageText.length || 0}
              characterLimit={160}
              showMessage={false}
            />
          </styled.OfflineMessageContainer>
          <styled.FormNote>
            {offlineMessageNote} <em>{offlineMessageSMSLimitNote}</em>
          </styled.FormNote>
          <TextField
            label={'Chat Timeout'}
            variant="filled"
            placeholder="Chat Timeout"
            type="number"
            value={chatTimeoutText}
            style={{ width: '100%' }}
            onChange={(e) => {
              changeChatTimeout(e.target.value)
              changeUpdateChatTimeout(true)
            }}
          />
          <styled.FormNote>{chatTimeoutNote}</styled.FormNote>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <TextField
              label={'Widget Title'}
              variant="filled"
              placeholder="Widget Title"
              value={widgetTitleText}
              style={{ width: '100%' }}
              onChange={(e) => {
                changeWidgetTitleText(e.target.value)
                changeUpdateWidgetTitleText(true)
              }}
            />
            <styled.FormNote>{widgetTitleNote}</styled.FormNote>
          </HasPermission>
          <SoundSelection
            widgetId={data!.widgetFromSlug!.id}
            selectedSound={
              (widgetInstance!.sound && widgetInstance!.sound.id) || null
            }
          />
          <styled.FormNote>{soundsNote}</styled.FormNote>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={roundRobin}
                  onChange={(event) => {
                    changeRoundRobin(event.target.checked as boolean)
                    changeUpdateRoundRobin(true)
                  }}
                />
              }
              label="Round Robin (Agent Assignment)"
            />
            <styled.FormNote>{roundRobinNote}</styled.FormNote>
          </HasPermission>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <styled.Title>Route Tags:</styled.Title>
            <styled.SubTitle>{tagsNote}</styled.SubTitle>
            <styled.TagsContainer>
              {data!.widgetFromSlug!.routeTags &&
                data!.widgetFromSlug!.routeTags.map((routeTag: any) => (
                  <Chip
                    key={routeTag.id}
                    color="primary"
                    label={routeTag.name}
                    style={{
                      marginRight: 6,
                      marginBottom: 4,
                      backgroundColor: 'black',
                      color: 'white',
                    }}
                  />
                ))}
              <TextField
                label={'Add a new tag and then press enter to save.'}
                variant="filled"
                placeholder="Add a new tag and then press enter to save."
                type=""
                value={newTag}
                style={{ width: 350, marginTop: 16 }}
                onChange={(e) => {
                  changeNewTag(e.target.value)
                }}
                onKeyDown={(event) =>
                  detectAndHandleEnter(event, DetectEnterTextFieldType.TAG)
                }
              />
            </styled.TagsContainer>
          </HasPermission>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <styled.Title>Post Chat Tags:</styled.Title>
            <styled.SubTitle>{postChatTagsNote}</styled.SubTitle>
            <styled.TagsContainer style={{ marginBottom: '0px' }}>
              {data!.widgetFromSlug &&
                data!.widgetFromSlug!.postChatTags &&
                data!.widgetFromSlug!.postChatTags!.map((postChatTag: any) => (
                  <Chip
                    key={postChatTag.id}
                    color="primary"
                    label={postChatTag.name}
                    style={{
                      marginRight: 6,
                      marginBottom: 4,
                      backgroundColor: 'black',
                      color: 'white',
                    }}
                  />
                ))}
              <TextField
                label={'Add a new post chat tag and then press enter to save.'}
                variant="filled"
                placeholder="Add a new post chat tag and then press enter to save."
                type=""
                value={newPostChatTag}
                style={{ width: 425, marginTop: 16 }}
                onChange={(e) => {
                  changeNewPostChatTag(e.target.value)
                }}
                onKeyDown={(event) =>
                  detectAndHandleEnter(
                    event,
                    DetectEnterTextFieldType.POST_CHAT_TAG,
                  )
                }
              />
            </styled.TagsContainer>
          </HasPermission>
          <HasPermission requiredPermissions={['ManageOrganization']}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={requirePostChatTags}
                  onChange={(event) => {
                    changeRequirePostChatTags(event.target.checked as boolean)
                    changeUpdateRequirePostChatTags(true)
                  }}
                />
              }
              label="Require Post Chat Tagging"
            />
            <styled.FormNote>{requirePostChatTags}</styled.FormNote>
          </HasPermission>
          <styled.Title>Shortcuts:</styled.Title>
          <styled.SubTitle>{shortcutsNote}</styled.SubTitle>
          {data!.widgetFromSlug!.shortcuts &&
            orderBy(data!.widgetFromSlug!.shortcuts, 'id', 'asc').map(
              (shortcut: any) => {
                return (
                  <Shortcut
                    key={`shortcut_${shortcut.id}`}
                    id={shortcut.id!}
                    nameValue={shortcut.name}
                    changeNameValue={widgetInstance!.modifyShortcutName}
                    shortcutValue={shortcut.value}
                    changeShortcutValue={widgetInstance!.modifyShortcutText}
                    deleteShortcut={widgetInstance!.deleteShortcut}
                  />
                )
              },
            )}
          <styled.InlineAddButtonContainer>
            <InlineAddButton passedFunction={widgetInstance!.createShortcut} />
            <styled.InlineButtonText>
              Click here to add a new shortcut/text snippet.
            </styled.InlineButtonText>
          </styled.InlineAddButtonContainer>
          <Button
            style={{
              color: 'white',
              backgroundColor: 'rgba(230, 56, 68, 1)',
            }}
            onClick={() => submitUpdates(data!.widgetFromSlug!.id)}
          >
            Save General Settings
          </Button>
        </styled.Form>
      </styled.Container>
    )
  },
)

export default WithPermission(
  ['ViewOrganization', 'ManageOrganization'],
  GeneralSettings,
  MissingPermission,
)
