import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  SortOperationKind,
  OrganizationModelType,
  WidgetModelType,
} from 'models'
import * as styled from './index.styled'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { format } from 'date-fns'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import Input from '@material-ui/core/Input'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import {
  startOfYesterday,
  startOfToday,
  oneDayAfterEndDate,
} from 'utilities/orgReports'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface ChatLogsProps {
  org: OrganizationModelType
  widgets: WidgetModelType[]
}

interface ChatLogProps {
  activeAgentAlias?: string
  id?: string
  completedTime?: string
  sourcePhoneNumber?: string
  widgetName?: string
}

interface ReturnedAgentData {
  id: string
  alias: string | null | undefined
}

const ChatLog = ({
  activeAgentAlias,
  id,
  completedTime,
  sourcePhoneNumber,
  widgetName,
}: ChatLogProps) => {
  return (
    <Link to={`/chat/${id}`} target="_blank">
      <styled.ChatLogContainer>
        <styled.LogItem>
          <styled.LogItemTitle>Completed Time</styled.LogItemTitle>
          <styled.LogItemContent>{completedTime}</styled.LogItemContent>
        </styled.LogItem>
        <styled.LogItem>
          <styled.LogItemTitle>Widget Name</styled.LogItemTitle>
          <styled.LogItemContent>{widgetName}</styled.LogItemContent>
        </styled.LogItem>
        <styled.LogItem>
          <styled.LogItemTitle>Agent Alias</styled.LogItemTitle>
          <styled.LogItemContent>{activeAgentAlias}</styled.LogItemContent>
        </styled.LogItem>
        <styled.LogItem>
          <styled.LogItemTitle>Source Phone Number</styled.LogItemTitle>
          <styled.LogItemContent>{sourcePhoneNumber}</styled.LogItemContent>
        </styled.LogItem>
      </styled.ChatLogContainer>
    </Link>
  )
}

const ChatLogs = observer(({ org, widgets }: ChatLogsProps) => {
  const [startDate, changeStartDate] = useState<Date | null>(startOfYesterday)
  const [endDate, changeEndDate] = useState<Date | null>(startOfToday)
  const [selectedWidgetID, changeSelectedWidgetID] = useState<string | null>('')
  const [selectedAgents, changeSelectedAgentds] = useState<string[]>([])
  const [offline, changeOffline] = useState(false)
  const [timedOut, changeTimedOut] = useState(false)
  const { data, setQuery, store } = useQuery((store) => {
    store.clearPreviousChatLogs()
    return store.queryChats(
      {
        orderBy: { completedTime: SortOperationKind.DESC },
        first: 10,
        agentIds:
          selectedAgents.length > 0
            ? selectedAgents.map((number) => parseInt(number))
            : undefined,
        widgetIds: selectedWidgetID ? [selectedWidgetID] : undefined,
        where: {
          completed: true,
          createdTimestamp_gte: startDate,
          createdTimestamp_lte: oneDayAfterEndDate(endDate!),
        },
      },
      (data) =>
        data
          .nodes((node) =>
            node.id.completed.timedOut.sourcePhoneNumber.activeAgentAlias.completedTime.originationWidget(
              (widget) => widget.id.name,
            ),
          )
          .pageInfo((info) => info.endCursor),
    )
  })

  const handleAgentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    changeSelectedAgentds(event.target.value as string[])
  }

  const clearFilters = () => {
    changeStartDate(startOfYesterday)
    changeEndDate(startOfToday)
    changeSelectedWidgetID(null)
    changeSelectedAgentds([])
    changeOffline(false)
    changeTimedOut(false)
  }

  const filterChatLogs = () => {
    store.clearPreviousChatLogs()
    store.queryChats(
      {
        orderBy: { completedTime: SortOperationKind.DESC },
        first: 10,
        agentIds: selectedAgents.map((number) => parseInt(number)) || [],
        widgetIds: selectedWidgetID ? [selectedWidgetID] : undefined,
        where: {
          completed: true,
          createdTimestamp_gte: startDate,
          createdTimestamp_lte: oneDayAfterEndDate(endDate!),
          offline: offline ? offline : undefined,
          timedOut: timedOut ? timedOut : undefined,
        },
      },
      (data) =>
        data
          .nodes((node) =>
            node.id.completed.timedOut.sourcePhoneNumber.activeAgentAlias.completedTime.originationWidget(
              (widget) => widget.id.name,
            ),
          )
          .pageInfo((info) => info.endCursor),
    )
  }

  return (
    <styled.ContentPositionReset>
      <styled.Container>
        <styled.FilterBar>
          <styled.FilterItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="start-date-picker-dialog"
                label="Start Date"
                format="MM/dd/yyyy"
                style={{ width: '100%' }}
                value={startDate}
                onChange={changeStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </styled.FilterItem>
          <styled.FilterItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="end-date-picker-dialog"
                label="End Date"
                format="MM/dd/yyyy"
                style={{ width: '100%' }}
                value={endDate}
                onChange={changeEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </styled.FilterItem>
          <styled.FilterItem paddingTop>
            <FormControl style={{ width: 200 }}>
              <InputLabel id="WidgetLabel">Select a widget</InputLabel>
              <Select
                labelId="widgetLabel"
                id="widget"
                value={selectedWidgetID}
                onChange={(event) =>
                  changeSelectedWidgetID(event.target.value as string)
                }
              >
                <MenuItem value="">
                  <em>Select a widget</em>
                </MenuItem>
                {widgets.map((widget) => {
                  return (
                    <MenuItem key={`widget_id_${widget.id}`} value={widget.id}>
                      {widget.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </styled.FilterItem>
          <styled.FilterItem paddingTop>
            <FormControl style={{ width: 200 }}>
              <InputLabel id="agent-mutiple-checkbox-label">Agent</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedAgents}
                onChange={handleAgentChange}
                input={<Input />}
                renderValue={() =>
                  selectedAgents.length > 0
                    ? selectedAgents
                        .map((number) => {
                          return org.agents.find(
                            (agent) => agent.id === String(number),
                          )!.alias
                        })
                        .join(', ')
                    : null
                }
              >
                {org.agents.map((agent) => (
                  <MenuItem key={agent.alias} value={agent.id}>
                    <Checkbox
                      color="primary"
                      checked={selectedAgents.indexOf(agent.id) > -1}
                    />
                    <ListItemText primary={agent.alias} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </styled.FilterItem>
          <styled.FilterItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={offline}
                  onChange={(event) =>
                    changeOffline(event.target.checked as boolean)
                  }
                  name="offline"
                  color="primary"
                />
              }
              label="Offline Chats"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={timedOut}
                  onChange={(event) =>
                    changeTimedOut(event.target.checked as boolean)
                  }
                  name="timedOut"
                  color="primary"
                />
              }
              label="Timed Out Chats"
            />
          </styled.FilterItem>
          <styled.FilterItem></styled.FilterItem>
          <styled.FilterItem>
            <Button
              style={{
                color: 'white',
                backgroundColor: 'rgba(230, 56, 68, 1)',
              }}
              color="primary"
              onClick={filterChatLogs}
            >
              Filter Chats
            </Button>
          </styled.FilterItem>
          <styled.FilterItem>
            <Button
              style={{
                color: 'white',
                backgroundColor: 'rgba(30, 30, 30, 1)',
              }}
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          </styled.FilterItem>
        </styled.FilterBar>
        {Array.from(store.historicalChats.values()).map((chat) => {
          let sourcePhoneNumber = ''
          if (chat && chat.sourcePhoneNumber) {
            const phoneNumber = parsePhoneNumberFromString(
              chat!.sourcePhoneNumber!.charAt(0) === '+'
                ? chat!.sourcePhoneNumber
                : `+${chat!.sourcePhoneNumber!}`,
            )
            sourcePhoneNumber = phoneNumber!.formatNational()
          }

          return (
            <ChatLog
              id={chat.id}
              key={chat.id}
              widgetName={chat.originationWidget.name || ''}
              sourcePhoneNumber={sourcePhoneNumber}
              activeAgentAlias={chat.activeAgentAlias || ''}
              completedTime={
                format(new Date(chat.completedTime), 'M/d/yyyy h:mm a') || ''
              }
            />
          )
        })}
        {data &&
        data.chats &&
        data.chats.pageInfo &&
        data.chats.pageInfo.endCursor &&
        Array.from(store.historicalChats.values()).length > 0 ? (
          <styled.LoadMoreButtonContainer>
            <Button
              style={{
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              size="small"
              onClick={() =>
                setQuery(
                  store.queryChats(
                    {
                      orderBy: { completedTime: SortOperationKind.DESC },
                      first: 10,
                      agentIds:
                        selectedAgents.length > 0
                          ? selectedAgents.map((number) => parseInt(number))
                          : undefined,
                      widgetIds: selectedWidgetID
                        ? [selectedWidgetID]
                        : undefined,
                      where: {
                        completed: true,
                        createdTimestamp_gte: startDate,
                        createdTimestamp_lte: oneDayAfterEndDate(endDate!),
                        offline: offline ? offline : undefined,
                        timedOut: timedOut ? timedOut : undefined,
                      },
                      after: data!.chats.pageInfo.endCursor,
                    },
                    (data) =>
                      data
                        .nodes((node) =>
                          node.id.completed.timedOut.sourcePhoneNumber.activeAgentAlias.completedTime.originationWidget(
                            (widget) => widget.id.name,
                          ),
                        )
                        .pageInfo((info) => info.endCursor),
                  ),
                )
              }
            >
              Load More
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="1x"
                style={{ marginTop: '-3px', marginLeft: 5 }}
              />
            </Button>
          </styled.LoadMoreButtonContainer>
        ) : (
          ''
        )}
      </styled.Container>
    </styled.ContentPositionReset>
  )
})

export default ChatLogs
