import styled from 'styled-components'

interface StatusProps {
  status?: boolean
  color?: string
}

export const Bubble = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: ${(props: StatusProps) =>
    props.status
      ? props.color
        ? props.color
        : 'rgba(0, 255, 0, 1)'
      : 'rgba(230, 57, 69, 1)'};
  margin-left: 4px;
  margin-right: 4px;
`
