import { PositionPreference } from 'models'
import styled from 'styled-components'

interface IconContainerProps {
  themePreview?: boolean
  imageUrl: string
  position?: PositionPreference
}

export const IconContainer = styled.div`
  position: ${(props: IconContainerProps) =>
    props.themePreview ? 'absolute' : 'fixed'};
  
  ${(props: IconContainerProps) => {
    if (props.themePreview) {
      return `
        bottom: -75px;
      `
    } else {
      if (props.position?.includes('BOTTOM')) {
        return `bottom: 5px;`
      } else if (props.position?.includes('TOP')) {
        return `top: 5px;`
      }
    }
  }}
  
    ${(props: IconContainerProps) => {
      if (props.themePreview) {
        return `
          right: 0px;
          background-position: right bottom;
        `
      } else {
        if (props.position?.includes('RIGHT')) {
          return `
            right: 26px;
            background-position: right top;
          `
        } else if (props.position?.includes('LEFT')) {
          return `
            left: 26px;
            background-position: left top;
          `
        } else {
          return `
            left: 50%;
            margin-left: -100px;
            background-position: center top;
          `
        }
      }
    }}
  width: 200px;
  height: 100px;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  background-image: url('${(props: IconContainerProps) => props.imageUrl}');
  background-repeat: no-repeat;
`
