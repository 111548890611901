import React from 'react'
import { usePermission } from 'utilities/usePermission'
import { observer } from 'mobx-react'

const BlankFallback = () => {
  return <></>
}

const WithPermission = <P extends object>(
  requiredPermissions: string[],
  WrappedComponent: React.ComponentType<P>,
  FallbackComponent: any = BlankFallback,
) => {
  const Permission = (props: any) => {
    const permission = usePermission(requiredPermissions)

    return permission ? (
      <WrappedComponent {...(props as P)} />
    ) : (
      <FallbackComponent />
    )
  }
  return observer(Permission)
}

export default WithPermission
