import React from 'react'
import * as styled from './index.styled'

interface LoadingDotsProps {
  widthAndHeight: string
  margin: string
  color: string
}

const TypingDots = ({ widthAndHeight, margin, color }: LoadingDotsProps) => {
  return (
    <styled.DotWrapper>
      <styled.Dot
        widthAndHeight={widthAndHeight}
        margin={margin}
        color={color}
        delay="0s"
      />
      <styled.Dot
        widthAndHeight={widthAndHeight}
        margin={margin}
        color={color}
        delay=".1s"
      />
      <styled.Dot
        widthAndHeight={widthAndHeight}
        margin={margin}
        color={color}
        delay=".2s"
      />
    </styled.DotWrapper>
  )
}

export default TypingDots
