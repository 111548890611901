import styled from 'styled-components'
import { theme } from 'types/style.types'

interface ThemeProps {
  theme: theme
}

export const PageContainer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  background-color: 'rgba(0, 0, 0, 0.3)';
  overflow: hidden;
  font-family: 'Roboto';
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageContainerFlexReset = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
`

export const LogoContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
`

export const ChatLogAndControlsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 1000px;
  height: 92%;
  border-radius: 4px;
  background-color: rgba(231, 239, 230, 0.5);
`

export const ChatLogControls = styled.div`
  width: 200px;
  height: 100%;
  padding: 20px;
`

export const Title = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
`

export const ControlsOrInfo = styled.div`
  margin-top: 20px;
  font-family: 'Roboto';
  font-size: 12px;
  display: flex;
  flex-direction: column;
`

export const ControlOrInfoRow = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
`

export const InfoRowTitle = styled.div`
  width: 150px;
  height: 100%;
`

export const InfoRowContent = styled.div`
  height: 100%;
`

export const ChatContainer = styled.div`
  width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TextWithinIcon = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  font-size: 24px;
  position: absolute;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconText = styled.div`
  margin-top: 6px;
  margin-right: 3px;
  transform: rotate(5deg);
  position: absolute;
`

export const IconWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const PostChatQuestionnaireContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const ResponseContainer = styled.div`
  margin-top: 10px;
`

export const RepsonseQuestion = styled.div`
  font-size: 10px;
`

export const Repsonse = styled.div`
  font-size: 10px;
  font-style: italic;
`
