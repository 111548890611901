/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgentModel, AgentModelType } from "./AgentModel"
import { AgentModelSelector } from "./AgentModel.base"
import { BannedUserModel, BannedUserModelType } from "./BannedUserModel"
import { BannedUserModelSelector } from "./BannedUserModel.base"
import { HtmlSettingsModel, HtmlSettingsModelType } from "./HtmlSettingsModel"
import { HtmlSettingsModelSelector } from "./HtmlSettingsModel.base"
import { OrganizationModel, OrganizationModelType } from "./OrganizationModel"
import { OrganizationModelSelector } from "./OrganizationModel.base"
import { PostChatQuestionnaireModel, PostChatQuestionnaireModelType } from "./PostChatQuestionnaireModel"
import { PostChatQuestionnaireModelSelector } from "./PostChatQuestionnaireModel.base"
import { PreChatQuestionnaireModel, PreChatQuestionnaireModelType } from "./PreChatQuestionnaireModel"
import { PreChatQuestionnaireModelSelector } from "./PreChatQuestionnaireModel.base"
import { ShortcutModel, ShortcutModelType } from "./ShortcutModel"
import { ShortcutModelSelector } from "./ShortcutModel.base"
import { SmsProviderEnumType } from "./SmsProviderEnum"
import { SmsSettingsModel, SmsSettingsModelType } from "./SmsSettingsModel"
import { SmsSettingsModelSelector } from "./SmsSettingsModel.base"
import { SoundModel, SoundModelType } from "./SoundModel"
import { SoundModelSelector } from "./SoundModel.base"
import { ThemeModel, ThemeModelType } from "./ThemeModel"
import { ThemeModelSelector } from "./ThemeModel.base"
import { WidgetPostChatTagModel, WidgetPostChatTagModelType } from "./WidgetPostChatTagModel"
import { WidgetPostChatTagModelSelector } from "./WidgetPostChatTagModel.base"
import { WidgetRouteTagModel, WidgetRouteTagModelType } from "./WidgetRouteTagModel"
import { WidgetRouteTagModelSelector } from "./WidgetRouteTagModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  organization: OrganizationModelType;
}

/**
 * WidgetBase
 * auto generated base class for the model WidgetModel.
 */
export const WidgetModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Widget')
  .props({
    __typename: types.optional(types.literal("Widget"), "Widget"),
    agents: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => AgentModel)))),
    bannedUsers: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => BannedUserModel)))),
    chatTimeout: types.union(types.undefined, types.integer),
    deleted: types.union(types.undefined, types.boolean),
    enablePostChatQuestionnaire: types.union(types.undefined, types.boolean),
    htmlSettings: types.union(types.undefined, types.null, types.late((): any => HtmlSettingsModel)),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    offlineMesage: types.union(types.undefined, types.null, types.string),
    organization: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => OrganizationModel))),
    postChatQuestionnaireId: types.union(types.undefined, types.null, types.integer),
    postChatQuestionnaires: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => PostChatQuestionnaireModel)))),
    postChatTags: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => WidgetPostChatTagModel)))),
    preChatQuestionnaires: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => PreChatQuestionnaireModel)))),
    requirePostChatTags: types.union(types.undefined, types.boolean),
    roundRobin: types.union(types.undefined, types.boolean),
    routeTags: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => WidgetRouteTagModel)))),
    shortcuts: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => ShortcutModel)))),
    slug: types.union(types.undefined, types.null, types.string),
    smsProvider: types.union(types.undefined, SmsProviderEnumType),
    smsSettings: types.union(types.undefined, types.null, types.late((): any => SmsSettingsModel)),
    sound: types.union(types.undefined, types.null, types.late((): any => SoundModel)),
    theme: types.union(types.undefined, types.null, types.late((): any => ThemeModel)),
    title: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class WidgetModelSelector extends QueryBuilder {
  get chatTimeout() { return this.__attr(`chatTimeout`) }
  get deleted() { return this.__attr(`deleted`) }
  get enablePostChatQuestionnaire() { return this.__attr(`enablePostChatQuestionnaire`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get offlineMesage() { return this.__attr(`offlineMesage`) }
  get postChatQuestionnaireId() { return this.__attr(`postChatQuestionnaireId`) }
  get requirePostChatTags() { return this.__attr(`requirePostChatTags`) }
  get roundRobin() { return this.__attr(`roundRobin`) }
  get slug() { return this.__attr(`slug`) }
  get smsProvider() { return this.__attr(`smsProvider`) }
  get title() { return this.__attr(`title`) }
  agents(builder?: string | AgentModelSelector | ((selector: AgentModelSelector) => AgentModelSelector)) { return this.__child(`agents`, AgentModelSelector, builder) }
  bannedUsers(builder?: string | BannedUserModelSelector | ((selector: BannedUserModelSelector) => BannedUserModelSelector)) { return this.__child(`bannedUsers`, BannedUserModelSelector, builder) }
  htmlSettings(builder?: string | HtmlSettingsModelSelector | ((selector: HtmlSettingsModelSelector) => HtmlSettingsModelSelector)) { return this.__child(`htmlSettings`, HtmlSettingsModelSelector, builder) }
  organization(builder?: string | OrganizationModelSelector | ((selector: OrganizationModelSelector) => OrganizationModelSelector)) { return this.__child(`organization`, OrganizationModelSelector, builder) }
  postChatQuestionnaires(builder?: string | PostChatQuestionnaireModelSelector | ((selector: PostChatQuestionnaireModelSelector) => PostChatQuestionnaireModelSelector)) { return this.__child(`postChatQuestionnaires`, PostChatQuestionnaireModelSelector, builder) }
  postChatTags(builder?: string | WidgetPostChatTagModelSelector | ((selector: WidgetPostChatTagModelSelector) => WidgetPostChatTagModelSelector)) { return this.__child(`postChatTags`, WidgetPostChatTagModelSelector, builder) }
  preChatQuestionnaires(builder?: string | PreChatQuestionnaireModelSelector | ((selector: PreChatQuestionnaireModelSelector) => PreChatQuestionnaireModelSelector)) { return this.__child(`preChatQuestionnaires`, PreChatQuestionnaireModelSelector, builder) }
  routeTags(builder?: string | WidgetRouteTagModelSelector | ((selector: WidgetRouteTagModelSelector) => WidgetRouteTagModelSelector)) { return this.__child(`routeTags`, WidgetRouteTagModelSelector, builder) }
  shortcuts(builder?: string | ShortcutModelSelector | ((selector: ShortcutModelSelector) => ShortcutModelSelector)) { return this.__child(`shortcuts`, ShortcutModelSelector, builder) }
  smsSettings(builder?: string | SmsSettingsModelSelector | ((selector: SmsSettingsModelSelector) => SmsSettingsModelSelector)) { return this.__child(`smsSettings`, SmsSettingsModelSelector, builder) }
  sound(builder?: string | SoundModelSelector | ((selector: SoundModelSelector) => SoundModelSelector)) { return this.__child(`sound`, SoundModelSelector, builder) }
  theme(builder?: string | ThemeModelSelector | ((selector: ThemeModelSelector) => ThemeModelSelector)) { return this.__child(`theme`, ThemeModelSelector, builder) }
}
export function selectFromWidget() {
  return new WidgetModelSelector()
}

export const widgetModelPrimitives = selectFromWidget().chatTimeout.deleted.enablePostChatQuestionnaire.name.offlineMesage.postChatQuestionnaireId.requirePostChatTags.roundRobin.slug.smsProvider.title
