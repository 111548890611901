/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * KeyValuePairOfInt32AndInt32Base
 * auto generated base class for the model KeyValuePairOfInt32AndInt32Model.
 */
export const KeyValuePairOfInt32AndInt32ModelBase = ModelBase
  .named('KeyValuePairOfInt32AndInt32')
  .props({
    __typename: types.optional(types.literal("KeyValuePairOfInt32AndInt32"), "KeyValuePairOfInt32AndInt32"),
    key: types.union(types.undefined, types.integer),
    value: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class KeyValuePairOfInt32AndInt32ModelSelector extends QueryBuilder {
  get key() { return this.__attr(`key`) }
  get value() { return this.__attr(`value`) }
}
export function selectFromKeyValuePairOfInt32AndInt32() {
  return new KeyValuePairOfInt32AndInt32ModelSelector()
}

export const keyValuePairOfInt32AndInt32ModelPrimitives = selectFromKeyValuePairOfInt32AndInt32().key.value
