import styled from 'styled-components'
import { theme } from 'types/style.types'
import * as layout from 'ui/components/shared/StyledContainers/layout'

interface ThemeProps {
  theme: theme
}

interface ButtonProps {
  type?: string
}

export const Container = styled(layout.ContainerRow)`
  width: 100%;
  position: relative;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  flex-grow: 0;
  background-color: ${(props: ThemeProps) =>
    props.theme.chatInputBackgroundColor.color};
  border-top-color: ${(props: ThemeProps) =>
    props.theme.chatInputBackgroundColor.color};
  border-top: 1px solid
    ${(props: ThemeProps) => props.theme.chatTopBorderColor.color};
`

export const TextInput = styled(layout.ContainerRow)`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
`

export const Button = styled.div`
  color: ${(props: ButtonProps & ThemeProps) =>
    props.type === 'primary'
      ? props.theme.secondaryHighlight.color
      : props.theme.secondaryDarkTextColor.color};
  opacity: 0.5;
  cursor: pointer;
  flex-grow: 0;
  width: 32px;
  &:hover {
    opacity: 1;
  }
`

export const EndChatButtonContainer = styled.div`
  color: white;
  font-size: 12px;
  background-color: rgba(255, 153, 164, 1);
  border-radius: 4px;
  margin-right: 6px;
  padding-right: 4px;
  padding-left: 4px;

  &:hover {
    background-color: rgba(232, 57, 68, 1);
  }
`

interface ShortCutAutoSuggestContainerProps {
  left: number
  bottom: number
}

export const ShortCutAutoSuggestContainer = styled.div`
  position: absolute;
  left: ${(props: ShortCutAutoSuggestContainerProps) => props.left}px;
  bottom: ${(props: ShortCutAutoSuggestContainerProps) => props.bottom + 50}px;
  z-index: 10000;
  background-color: rgba(225, 225, 225, 1);

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 20px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 20px;
      padding-left: 20px;
    }

    li:hover {
      background-color: rgba(100, 100, 100, 1);
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
`

interface ShortcutOptionProps {
  selected?: boolean | null | number
}

export const ShortcutOption = styled.li`
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  ${(props: ShortcutOptionProps) =>
    props.selected ? 'background-color: rgba(100, 100, 100, 1);' : ''}
  ${(props: ShortcutOptionProps) =>
    props.selected ? 'color: rgba(255, 255, 255, 1);' : ''}

  &:hover {
    background-color: rgba(100, 100, 100, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`

export const ShortcutTitle = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 16px;
  font-size: 12px;
`
