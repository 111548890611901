interface browserNotification {}

export const notificationSoundandBadgeRelay = (
  messageChatId: string,
  activeChatId: string | null,
  windowFocus: boolean,
  messageBody: string | undefined,
  browserNotificationPermission: boolean,
  audio: HTMLAudioElement,
) => {
  if (!windowFocus) {
    browserNotification(messageBody, browserNotificationPermission)
  }
  return playSound(audio, windowFocus, messageChatId, activeChatId)
}

const browserNotification = async (
  messageBody: string | undefined,
  browserNotificationPermission: boolean,
) => {
  await checkBrowserNotificationPermissiongs(browserNotificationPermission)
  messageBody && showBrowserNotification(messageBody)
}

const checkBrowserNotificationPermissiongs = async (
  browserNotificationPermission: boolean,
) => {
  if (browserNotificationPermission) {
    if (checkPushNotificationSupport()) {
      ;(async () => {
        await getNotificationPermission()
      })()
    }
  }
}

const showBrowserNotification = (messageBody: string) => {
  const options: NotificationOptions = {
    body: messageBody,
    badge: '/favicon.ico',
    icon: '/favicon.ico',
    image: '/favicon.ico',
    data: {
      chat: 'chat',
    },
    actions: [],
  }
  new Notification('New Unique Chat Message', options)
}

const checkPushNotificationSupport = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

const getNotificationPermission = async () => {
  return await Notification.requestPermission()
}

const getVolume = (
  windowFocus: boolean,
  chatId: string,
  activeChatId: string | null,
) => {
  if (!windowFocus) {
    return 1.0
  } else if (chatId !== activeChatId) {
    return 1.0
  } else {
    return 1.0
  }
}

export const playSound = (
  audio: HTMLAudioElement,
  windowFocus: boolean,
  chatId: string,
  activeChatId: string | null,
) => {
  const volume = getVolume(windowFocus, chatId, activeChatId)
  audio.volume = volume
  return audio.play()
}
