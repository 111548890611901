import React, { useState, ReactElement } from 'react'
import { observer } from 'mobx-react'
import * as styled from './index.styled'
import { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView'
import LogoSvg from 'ui/components/shared/LogoSvg'

interface LogOutModalContentProps {
  children: ReactElement
}

const LogOutModalContent = observer(({ children }: LogOutModalContentProps) => {
  const [themeName, setThemeName] = useState('california')
  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  return (
    <styled.LogoAndMessageContainer>
      <styled.IconWrapper>
        <LogoSvg
          scale=".25"
          stopColorTop={
            themeObject[ThemeProviderValue.name].secondaryLowlight.color!
          }
          stopColorBottom={
            themeObject[ThemeProviderValue.name].secondaryHighlight.color!
          }
          dropShadow={true}
          width={78}
          height={80}
          strokeWidth={16}
        />
        <styled.TextWithinIcon>
          <styled.IconText>!</styled.IconText>
        </styled.TextWithinIcon>
      </styled.IconWrapper>
      <div style={{ marginTop: 10 }}>{children}</div>
    </styled.LogoAndMessageContainer>
  )
})

export default LogOutModalContent
