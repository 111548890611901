import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import Button from '@material-ui/core/Button'
import { toJS } from 'mobx'
import useCsvDownloader from 'utilities/useCsvDownloader'

interface DetailedReportCSVLinkProps {
  startDate: Date | null
  endDate: Date | null
}

const DetailedReportDownload = observer(
  ({ startDate, endDate }: DetailedReportCSVLinkProps) => {
    const { loading, data, setQuery, store } = useQuery()

    const downloadCsv = useCsvDownloader()

    let reducedAndOrdered = {}
    if (data) {
      reducedAndOrdered = (data! as any).organizationDetailedReport
        .reducedAndOrderedDetailedReportData
      if (
        reducedAndOrdered &&
        Object.keys(reducedAndOrdered).length > 0 &&
        !(data! as any).organizationDetailedReport.downloaded
      ) {
        downloadCsv(reducedAndOrdered, 'unique-chat-detailed-data.csv')
        ;(data! as any).organizationDetailedReport.setDownloaded(true)
      }
    }

    const setQueryAndDownloadCount = () => {
      setQuery(
        store.queryOrganizationDetailedReport(
          { input: { startDate, endDate } },
          (data: any) =>
            data.edges(
              (edge: any) =>
                edge.agentAlias.chatType.end.organizationId.organizationName
                  .phone.start.widgetId.widgetName,
            ),
        ),
      )
    }

    return (
      <Button
        style={{
          color: 'white',
          backgroundColor: 'rgba(30, 30, 30, 1)',
          marginRight: 5,
        }}
        color="primary"
        onClick={setQueryAndDownloadCount}
      >
        Download Detailed Report
      </Button>
    )
  },
)

export default DetailedReportDownload
