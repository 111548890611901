/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgentRouteTagModel, AgentRouteTagModelType } from "./AgentRouteTagModel"
import { AgentRouteTagModelSelector } from "./AgentRouteTagModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { WidgetModel, WidgetModelType } from "./WidgetModel"
import { WidgetModelSelector } from "./WidgetModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModelType;
  widget: WidgetModelType;
}

/**
 * AgentBase
 * auto generated base class for the model AgentModel.
 */
export const AgentModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Agent')
  .props({
    __typename: types.optional(types.literal("Agent"), "Agent"),
    alias: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    online: types.union(types.undefined, types.boolean),
    routeTags: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => AgentRouteTagModel)))),
    user: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => UserModel))),
    widget: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => WidgetModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class AgentModelSelector extends QueryBuilder {
  get alias() { return this.__attr(`alias`) }
  get id() { return this.__attr(`id`) }
  get online() { return this.__attr(`online`) }
  routeTags(builder?: string | AgentRouteTagModelSelector | ((selector: AgentRouteTagModelSelector) => AgentRouteTagModelSelector)) { return this.__child(`routeTags`, AgentRouteTagModelSelector, builder) }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`user`, UserModelSelector, builder) }
  widget(builder?: string | WidgetModelSelector | ((selector: WidgetModelSelector) => WidgetModelSelector)) { return this.__child(`widget`, WidgetModelSelector, builder) }
}
export function selectFromAgent() {
  return new AgentModelSelector()
}

export const agentModelPrimitives = selectFromAgent().alias.online
