import styled from 'styled-components'

const colorGradients = {
  70: `background-color: rgba(232, 232, 232, 1); color: rgba(40, 40, 40, 1);`,
  80: `background-color: rgba(232, 202, 202, 1); color: rgba(40, 40, 40, 1);`,
  90: `background-color: rgba(232, 171, 171, 1); color: rgba(40, 40, 40, 1);`,
  100: `background-color: rgba(230, 56, 68, 1); color: rgba(255, 255, 255, 1); font-weight: bolder;`,
}

interface TextLimitContainerProps {
  characterCount: number
  characterLimit: number
}

const getColorGradient = ({
  characterCount,
  characterLimit,
}: TextLimitContainerProps): string => {
  const colorGradientsArray = Object.entries(colorGradients)
  let colorGradient = ''
  for (let i = 0; i < colorGradientsArray.length; i++) {
    if (
      Math.min((characterCount / characterLimit) * 100, 100) <=
      parseInt(colorGradientsArray[i][0])
    ) {
      colorGradient = colorGradientsArray[i][1]
      break
    }
  }
  return colorGradient
}

export const TextLimitContainer = styled.div`
  position: absolute;
  right: -8px;
  bottom: -10px;
  border-radius: 50%;
  background-color: rgba(232, 232, 232, 1);
  color: rgba(40, 40, 40, 1);
  z-index: 100;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px rgba(0, 0, 0, 0.08) solid;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  stroke: 1px black;

  ${(props: TextLimitContainerProps) => {
    return getColorGradient(props)
  }}
`
