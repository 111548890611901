import React, { useState } from 'react'
import * as layout from 'ui/components/shared/StyledContainers/layout'
import * as styled from './index.styled'
import { useHistory } from 'react-router-dom'
import ThemeContext, { ThemeContextType } from 'context/themeContext'
import themeObject from 'ui/theme/CustomerView/index'
import Menu from 'ui/components/ManagementView/Menu'
import Organizations from 'ui/components/ManagementView/TabPanels/Org/Orgs'
import HeaderIcon from 'ui/components/ManagementView/HeaderIcon'
import { useQuery } from 'models/reactUtils'
import { observer } from 'mobx-react'
import GoToHub from 'ui/components/shared/GoToHub'
import HeaderMenu from 'ui/components/shared/HeaderMenu'
import Loading from 'ui/components/shared/Loading'

const ManagementView = observer(() => {
  const [themeName, setThemeName] = useState('california')
  const ThemeProviderValue: ThemeContextType = {
    name: themeName,
    changeThemeName: setThemeName,
    mobileView: false,
  }

  const history = useHistory()
  const { loading, data } = useQuery((store) => store.getSpaces(history))

  if (loading) {
    return <Loading />
  }

  const space = data!.spaces[0]

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <layout.NewPageContainer>
        <styled.HeaderContainer theme={themeObject[ThemeProviderValue.name]}>
          <styled.LogoContainer>Unique Chat</styled.LogoContainer>
          <styled.MenuAndAccountContainer>
            <GoToHub />
            <HeaderMenu />
          </styled.MenuAndAccountContainer>
        </styled.HeaderContainer>
        <styled.FunctionContainer theme={themeObject[ThemeProviderValue.name]}>
          <Menu />
        </styled.FunctionContainer>
        <styled.ContentContainer>
          <styled.ContentTitle>Organizations</styled.ContentTitle>
          <styled.Content>
            {data!.spaces && data!.spaces.length > 0 && (
              <Organizations
                organizations={space.organizations}
                space={space}
              />
            )}
          </styled.Content>
        </styled.ContentContainer>
        <HeaderIcon />
      </layout.NewPageContainer>
    </ThemeContext.Provider>
  )
})

export default ManagementView
