import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'typeface-roboto'
import 'typeface-tinos'
import { RootStore } from 'models/RootStore'
import { StoreContext } from 'models/reactUtils'
import { createHttpClient } from 'mst-gql'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import 'mobx-react/batchingForReactDom'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn:
    'https://79173dc78cce4f65b1dcd426537b01b5@o278520.ingest.sentry.io/5367897',
})

const url = `${document.location.host}/graphql`
const wsProtocol = document.location.protocol === 'http:' ? 'ws:' : 'wss:'
const gqlWsClient = new SubscriptionClient(`${wsProtocol}//${url}`, {
  reconnect: true,
  lazy: true,
})

const rootStore = RootStore.create(undefined, {
  gqlHttpClient: createHttpClient(`${document.location.protocol}//${url}`),
  gqlWsClient,
})

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={rootStore}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
